import { Component, Input, ViewChild } from '@angular/core';
import { UserBankingInfo } from '../../../models';
import { CheckSellerComponent } from '../check-seller/check-seller.component';
import { TranslocoModule } from '@ngneat/transloco';
import { MatIcon } from '@angular/material/icon';
import { MatCard, MatCardContent } from '@angular/material/card';

@Component({
  selector: 'app-seller-payout-manager',
  templateUrl: './seller-payout-manager.component.html',
  styleUrl: './seller-payout-manager.component.scss',
  standalone: true,
  imports: [
    TranslocoModule,
    MatIcon,
    MatCard,
    MatCardContent,
    CheckSellerComponent,
  ],
})
export class SellerPayoutManagerComponent {
  @Input() userBanking: UserBankingInfo;
  @Input() userKyc: {
    kycStatus: string;
    kycRefusedReasonMessage: string;
    kycRefusedReasonType: string;
    kycRefusedFlags?: string[];
  };
  @Input() payinRefundable: boolean;
  @Input() displayPaidAfterEvent = false;
  @ViewChild(CheckSellerComponent) CheckSellerComponent: CheckSellerComponent;

  sellerUpload() {
    return this.CheckSellerComponent.upload();
  }

}
