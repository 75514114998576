import {Injectable} from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class SEOService {
  defaultReelaxCover = 'https://reelax-tickets.com/assets/img/reelax-tickets-cover.png';
  defaultSEOTranslations = {
    title: 'Reelax Tickets - Achat et revente de billets sécurisés',
    description: 'Reelax Tickets travaille avec les organisateurs afin de sécuriser l\'achat et la revente de billets des événements les plus demandés',
  };
  private domain = environment.domain?.slice(0, -1);

  constructor(private title: Title, private meta: Meta) { }

  updateTitle(title: string) {
    this.title.setTitle(title);
    this.meta.updateTag({ property: 'og:title', content: title });
    this.meta.updateTag({ name: 'twitter:title', content: title });
  }

  updateUrl(url: string) {
    this.meta.updateTag({ property: 'og:url', content: url });
    this.meta.updateTag({ name: 'twitter:url', content: url });
  }

  updateDescription(desc: string) {
    this.meta.updateTag({ name: 'description', content: desc });
    this.meta.updateTag({ property: 'og:description', content: desc });
    this.meta.updateTag({ name: 'twitter:description', content: desc });
  }

  updateImage(imgUrl: string, imgDesc: string, color?: string) {
    imgUrl = this.getSocialImgLink(imgUrl);

    this.meta.updateTag({ property: 'og:image', content: imgUrl });
    this.meta.updateTag({ name: 'twitter:image', content: imgUrl });

    this.meta.updateTag({ property: 'og:image:alt', content: imgDesc });
    this.meta.updateTag({ name: 'twitter:image:alt', content: imgDesc });

    if (color) {
      this.meta.updateTag({ name: 'theme-color', content: color });
    }
  }

  setNotFound() {
    this.meta.updateTag({ name: 'prerender-status-code', content: '404'});
  }

  getSocialImgLink(imgUrl: string) {
    if (!imgUrl) {
      return this.defaultReelaxCover;
    }
    const social = '-social';
    return this.domain + imgUrl.split('.')[0] + social + '.' + imgUrl.split('.')[1];
  }
}
