<span *transloco="let tl; read 'language'" class="d-flex --vertical-centered">
  <a class="full-link ml-2" [matMenuTriggerFor]="langMenu" matTooltip="{{ tl('language') }}">
    @if (!hideFlag) {
      <img [alt]="tl('currentCountry')" [src]="'assets/img/flag-' + tl('currentCountry') + '.svg'" class="country-flag">
    }
    @if (!hideCurrentLang) {
      <span class="ml-2">
        {{ tl(currentLang) }}
      </span>
    }
    <mat-icon>arrow_drop_down</mat-icon>
  </a>
  <mat-menu #langMenu="matMenu">
    <button mat-menu-item class="d-flex --vertical-centered" (click)="setActiveLang(Language.frFR)">
      <img alt="france" src="assets/img/flag-france.svg" class="country-flag mr-3">
      {{ 'Français' | titlecase }} (FR)
    </button>
    <button mat-menu-item class="language-item" (click)="setActiveLang(Language.enUS)">
      <img alt="united states" src="assets/img/flag-united-states.svg" class="country-flag mr-3">
      {{ 'English' | titlecase }} (US)
    </button>
    <button mat-menu-item class="language-item" (click)="setActiveLang(Language.esES)">
      <img alt="spain" src="assets/img/flag-spain.svg" class="country-flag mr-3">
      {{ 'Español' | titlecase }} (ES)
    </button>
  </mat-menu>
</span>
