export const press: Array<{name: string, logo: string, title: string, website: string}> = [
  {
    name: 'Ouest-France - Juillet 2023',
    logo: 'press/logo/ouest-france.svg',
    title: 'Une entreprise rennaise gère les bourses aux billets que les festivals s’arrachent - Juillet 2023',
    website: 'https://www.ouest-france.fr/festivals/fete-du-bruit/une-entreprise-rennaise-gere-les-bourses-aux-billets-que-les-festivals-sarrachent-c64032f6-1264-11ee-b11b-32df00462290',
  },
  {
    name: 'Ministère de la Culture - Juillet 2023',
    logo: 'press/logo/culture-gouv.png',
    title: 'Musique et innovation (3) : ces entrepreneurs qui font bouger les lignes du secteur de la musique - Juillet 2023',
    website: 'https://www.culture.gouv.fr/Actualites/Musique-et-innovation-3-ces-entrepreneurs-qui-font-bouger-les-lignes-du-secteur-de-la-musique',
  },
  {
    name: 'Ouest-France - Juillet 2023',
    logo: 'press/logo/ouest-france.svg',
    title: 'V and B Fest\'. Complet, le festival de musique met en place une bourse aux billets - Juillet 2023',
    website: 'https://www.ouest-france.fr/festivals/v-and-b-fest/v-and-b-fest-complet-le-festival-de-musique-met-en-place-une-bourse-aux-billets-74d96baa-2653-11ee-a174-4dc4bc9324e5',
  },
  {
    name: 'Actu Fr - Avril 2023',
    logo: 'press/logo/actu-fr.svg',
    title: 'Armor à Sons complet : un site officiel revend des billets d\'occasion  - Avril 2023',
    website: 'https://actu.fr/bretagne/bobital_22008/armor-a-sons-on-peut-acheter-son-billet-sur-un-site-de-revente-officiel_59276146.html',
  },
  {
    name: 'France 3 Régions - Juin 2023',
    logo: 'press/logo/france-3.svg',
    title: 'Hellfest 2023. Pas de pass, pas de metal, comment trouver un billet en évitant les fraudes et arnaques en tout genre ?  - Juin 2023',
    website: 'https://france3-regions.francetvinfo.fr/pays-de-la-loire/loire-atlantique/clisson/hellfest-2023-pas-de-pass-pas-de-metal-comment-trouver-un-billet-en-evitant-les-fraudes-et-arnaques-en-tout-genre-2788494.html',
  },
  {
    name: 'IFCIC - Juillet 2023',
    logo: 'press/logo/ifcic.svg',
    title: 'Les lauréats du prix IFCIC Entreprendre dans la Culture 2023 - Juillet 2023',
    website: 'http://www.ifcic.fr/infos-pratiques/communiques-de-presse/les-laureats-du-prix-ifcic-entreprendre-dans-la-culture-2023.html',
  },
  {
    name: 'Ouest-France - Juillet 2023',
    logo: 'press/logo/ouest-france.svg',
    title: 'Festival les Escales à Saint-Nazaire : le point sur la billetterie à trois semaines du coup d’envoi - Juillet 2023',
    website: 'https://www.ouest-france.fr/festivals/les-escales/festival-les-escales-a-saint-nazaire-le-point-sur-la-billetterie-a-trois-semaines-du-coup-denvoi-e32c08a2-1ff9-11ee-aaa7-17557ce412e6',
  },
  {
    name: 'Centre National de la Musique - Mai 2022',
    logo: 'institutions/cnm-logo.png',
    title: 'Le CNM a remis pour la première fois le Prix de l’innovation dans la musique à 3 structures innovantes - Mai 2022',
    website: 'https://cnm.fr/le-cnm-a-remis-pour-la-premiere-fois-le-prix-de-linnovation-dans-la-musique-a-3-structures-innovantes/',
  },
  {
    name: 'Ouest-France Entreprises - Décembre 2020',
    logo: 'press/logo/ouest-france-entreprises.png',
    title: 'Reelax Tickets sécurise l\'achat de billets de seconde main - Décembre 2020',
    website: '/assets/img/press/article/2020-12_ouest-france-entreprises.png',
  },
  {
    name: 'UFC Que Choisir - Février 2020',
    logo: 'press/logo/ufc-que-choisir.svg',
    title: 'Billets de spectacles - Place aux sites sérieux - Février 2020',
    website: '/assets/img/press/article/2020-02_ufc-que-choisir.png',
  },
  {
    name: 'Bretons - Septembre 2020',
    logo: 'press/logo/bretons.jpg',
    title: 'Une start-up bretonne pour revendre vos billets - Septembre 2020',
    website: '/assets/img/press/article/2020-09_bretons.png',
  },
  {
    name: 'Télérama - Octobre 2020',
    logo: 'press/logo/telerama.svg',
    title: 'Reelax Tickets - Octobre 2020',
    website: '/assets/img/press/article/2020-10_telerama.pdf',
  },
  {
    name: 'UFC Que Choisir - Février 2020',
    logo: 'press/logo/ufc-que-choisir.svg',
    title: 'Billets de spectacles - Place aux sites sérieux - Février 2020',
    website: '/assets/img/press/article/2020-02_ufc-que-choisir.png',
  },
  {
    name: 'ELLE supplément Bretagne - Novembre 2020',
    logo: 'press/logo/elle.svg',
    title: 'Reelax Tickets - Novembre 2020',
    website: '/assets/img/press/article/2020-11_elle-supplement-bretagne.pdf',
  },
  {
    name: 'Ouest-France - Décembre 2020',
    logo: 'press/logo/ouest-france.svg',
    title: 'Diogène Productions mise sur les services - Décembre 2020',
    website: '/assets/img/press/article/2020-12_ouest-france_diogene.png',
  },
];
