import { Injectable } from '@angular/core';
import MD5 from 'crypto-js/md5';
import AES from 'crypto-js/aes';
import Utf8 from 'crypto-js/enc-utf8';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root',
})

export class EncryptionService {

  private secret = environment.secretKey;

  constructor() { }

  encrypt(json: {}) {
    return AES.encrypt(JSON.stringify(json), this.secret).toString();
  }

  decryption(cipherJSON) {
    return JSON.parse(AES.decrypt(cipherJSON, this.secret).toString(Utf8));
  }

  MD5EncryptURI(message: string) {
    return decodeURIComponent(MD5(message).toString());
  }
}
