// countries where mangopay allow payments are listed here :
// https://support.mangopay.com/s/article/which-are-the-authorized-countries-where-you-can-process-payments
// and here : https://docs.mangopay.com/guide/restrictions-by-country
export const countries = [
  // Europe
  'DE', 'AD', 'AT', 'BE', 'BG', 'CY', 'HR', 'DK', 'ES', 'EE', 'FO', 'FI', 'FR', 'GB', 'GE', 'GI', 'GR', 'GL', 'HU', 'IE', 'IS', 'IT', 'LV', 'LI', 'LT', 'LU', 'MK', 'MT', 'IM', 'MC', 'MD', 'NO', 'NL', 'PL', 'PT', 'CZ', 'RO', 'SM', 'SK', 'SI', 'SE', 'CH', 'TR', 'VA',
  // France d'outre-mer
  'GP', 'GF', 'MQ', 'PF', 'RE', 'YT', 'BL', 'MF', 'PM', 'WF',
  // Afrique
  'ZA', 'BJ', 'BF', 'CM', 'CV', 'KM', 'DJ', 'KE', 'LS', 'MG', 'MW', 'MA', 'MU', 'RW', 'SH', 'SN', 'SR', 'SZ', 'TZ', 'TG',
  // Amérique
  'AR', 'BQ', 'BR', 'CA', 'CL', 'CR', 'CW', 'DM', 'US', 'FK', 'HN', 'JM', 'MX', 'PY', 'PE', 'PR', 'DO', 'SX', 'SV', 'UY', 'VG',
  // Asie
  'BT', 'CN', 'KR', 'HK', 'IN', 'JP', 'MY', 'PH', 'SG', 'TW', 'TH', 'TL', 'VN',
  // Océanie
  'AU', 'BN', 'CC', 'CK', 'FJ', 'KI', 'NC', 'NZ', 'SB', 'TO',
  // Moyen-Orient
  'AM', 'SA', 'BH', 'AE', 'IL', 'JO', 'OM', 'QA',
  // Others
  'AG', 'AI', 'AL', 'AO', 'AS', 'AW', 'AX', 'BA', 'BB', 'BD', 'BI', 'BM', 'BO', 'BS', 'BW', 'BZ', 'CG', 'CI', 'CO', 'CX', 'DZ', 'EC', 'EG', 'EH', 'FM', 'GA', 'GD', 'GG', 'GH', 'GM', 'GQ',
  'GT', 'GU', 'GW', 'GY', 'HT', 'ID', 'IO', 'JE', 'KH', 'KN', 'KW', 'KY', 'LA', 'LC', 'LK', 'ME', 'MH', 'MN', 'MO', 'MP', 'MR', 'MS', 'MV', 'MW', 'MZ', 'NA', 'NE', 'NF', 'NG', 'NI', 'NP', 'NR', 'NU', 'PA', 'PG', 'PN', 'PW', 'RS', 'SC', 'SJ', 'SL', 'ST',
  'TC', 'TD', 'TK', 'TN', 'TT', 'TV', 'UG', 'VC', 'VI', 'VU', 'WS', 'XK', 'ZM',
];

export const mangoRestrictedCountries = [
  'RU', // Russie
  'KZ', // Kazakhstan
  'BY', // Biélorussie
  'UA', // Ukraine
  'UZ', // Ouzbékistan
  'TM', // Turkménistan
  'KG', // Kirghizistan
  'TJ', // Tadjikistan
  'YE', // Yémen
];

export const notOnGooglePhoneLib = [
  'AQ', // Antarctique
  'TF', // Terres australes françaises (les)
  'UM', // Îles mineures éloignées des États-Unis (les)
  'PN', // Pitcairn
];
