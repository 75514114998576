<mat-form-field
  class="form__field"
  [subscriptSizing]="'dynamic'"
  >
  @if (options.label?.length > 0) {
    <mat-label>{{options.label}}</mat-label>
  }
  @if (options.increment) {
    <button
      mat-icon-button
      matPrefix
      aria-label="Remove"
      (click)="setValue(-options.increment); $event.stopPropagation(); control.markAsTouched()"
      [disabled]="disabled || (hasMin() && control.value <= options.min)"
      type="button"
      >
      <mat-icon>remove</mat-icon>
    </button>
  }
  <input
    class="input"
    [class.center]="options.increment"
    type="number"
    matInput
    [formControl]="control"
    [min]="options?.min"
    [max]="options?.max"
    [required]="validators.includes('required')"
    (onWheel)="$event?.preventDefault()"
    >
  <div matSuffix class="suffix">
    @if (options.increment) {
      <button
        mat-icon-button
        aria-label="Add"
        (click)="setValue(options.increment); $event.stopPropagation(); control.markAsTouched()"
        [disabled]="disabled || (hasMax() && control.value >= options.max)"
        type="button"
        >
        <mat-icon>add</mat-icon>
      </button>
    }
    @if (options?.iconName) {
      <mat-icon> {{ options.iconName }}</mat-icon>
    }
  </div>
  <mat-hint>{{options?.hint}}</mat-hint>
  @if (control.errors) {
    <mat-error>{{ 'formErrors.' + Objectkeys(control.errors)?.[0] | transloco }}</mat-error>
  }
</mat-form-field>
