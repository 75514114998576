import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import 'zone.js';
import { browserTracingIntegration, init, replayIntegration } from '@sentry/angular';
import { AppBrowserModule } from './app/app.browser.module';
import { environment } from './environments/environment';

import browserUpdate from 'browser-update';


browserUpdate({
  style: 'corner',
  required: {e: -6, f: -6, o: -6, s: -2, c: -6},
  // Specifies required browser versions
  // Browsers older than this will be notified.
  // f:22 ---> Firefox < 22 gets notified
  // Negative numbers specify how much versions behind current version.
  // c:-5 ---> Chrome < 35  gets notified if latest Chrome version is 40.
  // more details (in english)
  insecure: true,
  unsupported: false,
  reminder: 240,
  // after how many hours should the message reappear
  // 0 = show all the time
  reminderClosed: 140,
  // if the user explicitly closes message it reappears after x hours
  text: `Reelax Tickets est développé pour Firefox et Chromium. <br>
         Votre navigateur ({brow_name}) n'est pas supporté officiellement.<br>
         <a{up_but}>Mettre à jour le navigateur</a> <br> <a{ignore_but}>Ignorer</a>`,
  // custom notification text (html)
  // Placeholders {brow_name} will be replaced with the browser name,
  // {up_but} with contents of the update link tag and {ignore_but} with contents for the ignore link.
  // Example: "Your browser, {brow_name}, is too old: <a{up_but}>update</a> or <a{ignore_but}>ignore</a>."
  // more details (in english)

  // text_in_xx: '',
  // custom notification text for language "xx"
  // e.g. text_de for german and text_it for italian
  newwindow: true,
  // open link in new window/tab
  jsshowurl: '//browser-update.org/update.show.min.js',
  // URL where the script, that shows the notification, is located.
  // This is only loaded if the user actually has an outdated browser.
  // eslint-disable-next-line no-undef
  container: document.body,
  // DOM Element where the notification will be injected.
  api: 2022.05,
  // This is the version of the browser-update api to use. Please do not remove.
});

if (environment.production) {
  enableProdMode();
  init({
    dsn: 'https://1e4ced4fc4274c2b865769181eac97df@o312975.ingest.sentry.io/1783806' ,
    environment: environment?.envName,
    integrations: [
      browserTracingIntegration(),
      // Registers the Replay integration,
      // which automatically captures Session Replays
      replayIntegration(),
    ],
    ignoreErrors: [
      // Random plugins/extensions
      'top.GLOBALS',
      // See: http://blog.errorception.com/2012/03/tale-of-unfindable-js-error.html
      'originalCreateNotification',
      'canvas.contentDocument',
      'MyApp_RemoveAllHighlights',
      'http://tt.epicplay.com',
      "Can't find variable: ZiteReader",
      'jigsaw is not defined',
      'ComboSearch is not defined',
      'http://loading.retry.widdit.com/',
      'atomicFindClose',
      // Facebook borked
      'fb_xd_fragment',
      // ISP "optimizing" proxy - `Cache-Control: no-transform` seems to
      // reduce this. (thanks @acdha)
      // See http://stackoverflow.com/questions/4113268
      'bmi_SafeAddOnload',
      'EBCallBackMessageReceived',
      // See http://toolbar.conduit.com/Developer/HtmlAndGadget/Methods/JSInjection.aspx
      'conduitPage',
    ],
    denyUrls: [
      // Facebook flakiness
      /graph\.facebook\.com/i,
      // Facebook blocked
      /connect\.facebook\.net\/en_US\/all\.js/i,
      // Woopra flakiness
      /eatdifferent\.com\.woopra-ns\.com/i,
      /static\.woopra\.com\/js\/woopra\.js/i,
      // Chrome extensions
      /extensions\//i,
      /^chrome:\/\//i,
      // Other plugins
      /127\.0\.0\.1:4001\/isrunning/i, // Cacaoweb
      /webappstoolbarba\.texthelp\.com\//i,
      /metrics\.itunes\.apple\.com\.edgesuite\.net\//i,
    ],
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 0.005,
    tracePropagationTargets: [
      'localhost',
      'https://reelax-tickets.com/api',
      'https://preprod.reelax-tickets.com/api',
      'https://dev.reelax-tickets.com/api',
      'https://recette.reelax-tickets.com/api',
    ],
    release: (environment as unknown as {releaseId}).releaseId,
    // Capture Replay for 0.1% of all sessions,
    // plus for 10% of sessions with an error
    replaysSessionSampleRate: 0.001,
    replaysOnErrorSampleRate: 0.1,
  });
}

if (!environment.test) {
  if (window) {
    window.console.log = () => {};
  }
}

// https://github.com/angular/universal/blob/16.2.x/docs/transfer-http.md
document.addEventListener('DOMContentLoaded', () => {
  platformBrowserDynamic()
    .bootstrapModule(AppBrowserModule)
    // eslint-disable-next-line no-console
    .catch((err) => console.log(err));
});
