import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { forkJoin, of, from, iif, tap, map } from 'rxjs';
import { environment } from '../../environments/environment';
import { WaitListStore } from '../stores';
import { Event, Entry, UserProfile, WaitListPurchase, Category, Gauge } from '../models';
import { Utils } from '../shared/utils';
import { Router } from '@angular/router';
import { AuthService } from './auth.service';
import { RxjsUtils } from '../shared/utils/rxjs';

@Injectable({
  providedIn: 'root',
})
export class WaitListService {
  private configUrl = environment.apiUrl;

  constructor(
    private http: HttpClient,
    private router: Router,
    private authService: AuthService,
    private waitListStore: WaitListStore,
  ) { }

  addEntry(eventId) {
    const url = `${this.configUrl}waitList/add/${eventId}`;
    return this.http.post(url, this.waitListStore.getEntries(eventId));
  }

  getEntries(eventId?) {
    const url = `${this.configUrl}waitList/list`;
    const params = new HttpParams({
      fromObject: {
        eventId,
      },
    });
    return this.http.get<Entry[]>(url, {params});
  }

  getGauge(eventId: number) {
    const url = `${this.configUrl}waitList/eventGauges/${eventId}`;
    return this.http.get<{gauges: Gauge}>(url);
  }

  disableEntries(eventId, userInfoId, categoryId, token) {
    const url = `${this.configUrl}waitList/desactivate/${eventId}/${categoryId}/${userInfoId}/${token}`;
    return this.http.put<{entries?: Entry[], autoPurchase?: WaitListPurchase}>(url, null);
  }

  refreshWaitlistFromApi(eventId, user: UserProfile, eventCategory: Category[]) {
    return forkJoin({
      api: iif(
        () => !user?.id,
        of([]),
        this.getEntries(eventId),
      ),
      browser: from(this.waitListStore.getStorageState()),
    }).pipe(
      tap( ({api, browser}) => {
        if (!browser) {
          browser = [];
        }
        api.forEach( (e) => {
          e.Category = eventCategory?.find( (c) => c.id === e.categoryId);
        });
        browser.forEach( (e) => {
          e.Category = eventCategory?.find( (c) => c.id === e.categoryId);
        });
        this.refreshWaitLists(api, browser);
      }),
    );
  }

  getAllWaitlists() {
    const route = `${this.configUrl}myWaitlists`;
    return this.http.get<Event[]>(route).pipe(
      RxjsUtils.backToLoginIf401(this.router, this.authService),
      map( (events) => events.map( (event) => new Event(event))),
    );
  }

  refreshAllWaitlistFromApi(user: UserProfile) {
    return forkJoin({
      api: iif(
        () => !user?.id,
        of([] as Event[]),
        this.getAllWaitlists(),
      ),
      browser: from(this.waitListStore.getStorageState()),
    }).pipe(
      tap( ({api, browser}) => {
        if (!browser) {
          browser = [];
        }
        const apiWaitLists = api.reduce( (entries, e) => ([...entries, ...e.WaitLists]), []);
        this.refreshWaitLists(apiWaitLists, browser);
      }),
    );
  }

  private refreshWaitLists(api: Entry[], browser: Entry[]) {
    const {exist, missing, removed} = Utils.arrayCompare(api, browser, (a, b) => a.categoryId === b.categoryId);
    const updated = exist.map((e) => ({
      ...e.old,
      ...e.new,
    }));
    const missingNew = missing;
    const mergedEntries = updated.concat(removed).concat(missingNew);
    this.waitListStore.loadEntries(mergedEntries.map((e) => new Entry({
      ...e,
      oldValues: {},
    })));
  }

}
