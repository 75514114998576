// eslint-disable-next-line max-classes-per-file
export class CardInfo {
  fullName: string;
  cardNumber: string;
  cvv: string;
  exp: ExpDate;

  constructor(options?: any) {
    if (options) {
      this.fullName = options.fullName;
      this.cardNumber = options.carNumber;
      this.cvv = options.cvv;
      this.exp = options.exp;
    }
  }
}

export class ExpDate {
  month: number;
  year: number;

  constructor(options?: any) {
    if (options) {
      this.month = options.month;
      this.year = options.year;
    }
  }
}

export enum KYCLevel {
  REFUSED = 'REFUSED',
  STRONG = 'STRONG',
  LIGHT = 'LIGHT',
  CREATED = 'CREATED',
}

export enum BankAccountType {
  IBAN = 'IBAN',
  // GB = 'GB', // deprecated, often refused, use IBAN instead
  US = 'US',
  CA = 'CA',
  // OTHER = 'OTHER', // we don't want this account type to be available
}

export class UserBankingInfo {
  id: number;
  active: boolean;
  ownerName: string;
  type: BankAccountType;
  IBAN: string; // TODO move to a new UserBankingInfo extended class
  accountNumberPreview: string;
  hashedAccountNumber: string;
  USAccountNumber: string; // TODO move to a new UserBankingInfo extended class
  USAbaRouting: string; // TODO move to a new UserBankingInfo extended class
  CABranchCode: number; // TODO move to a new UserBankingInfo extended class
  CAInstitutionNumber: number; // TODO move to a new UserBankingInfo extended class
  CAAccountNumber: number; // TODO move to a new UserBankingInfo extended class
  CABankName: string; // TODO move to a new UserBankingInfo extended class
  mangoKYCLevel: KYCLevel;
  mangoKYCId: string;
  mangoBankAccountId: string;
  userInfoId: string;

  public readonly createdAt!: Date;
  public readonly updatedAt!: Date;

  constructor(options?: any) {
    if (options) {
      this.id = options.id;
      this.active = options.active;
      this.ownerName = options.ownerName;
      this.type = options.type === 'GB' ? undefined : options.type;
      this.accountNumberPreview = options.accountNumberPreview?.replace(/-/g, ' XXX ')?.trim();
      this.hashedAccountNumber = options.hashedAccountNumber;
      this.IBAN = options.IBAN;
      this.USAccountNumber = options.USAccountNumber;
      this.USAbaRouting = options.USAbaRouting;
      this.CABranchCode = options.CABranchCode;
      this.CAInstitutionNumber = options.CAInstitutionNumber;
      this.CAAccountNumber = options.CAAccountNumber;
      this.CABankName = options.CABankName;
      this.mangoKYCLevel = options.mangoKYCLevel;
      this.mangoKYCId = options.mangoKYCId;
      this.mangoBankAccountId = options.mangoBankAccountId;
      this.userInfoId = options.userInfoId;
      this.createdAt = options.createdAt ? new Date(options.createdAt) : null;
      this.updatedAt = options.updatedAt ? new Date(options.updatedAt) : null;
    }
  }

  public get areBankAccountAndKycCompleted() {
    return !!this && !!this.accountNumberPreview && this.mangoKYCLevel === 'STRONG';
  }

  public get hasBankAccount() {
    return !!this && !!this.accountNumberPreview;
  }

}
