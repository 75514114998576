<mat-radio-group
  class="form__field"
  [formControl]="control"
  [required]="validators.includes('required')"
  [value]="formModel[this.controlName]"
  >
  @for (option of radioOptions; track option) {
    <mat-radio-button
      class="mr-4"
      [disabled]="option.disabled"
      [value]="option.value"
      >
      {{option.viewValue}}
    </mat-radio-button>
  }
</mat-radio-group>
