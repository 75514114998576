import { Component } from '@angular/core';
import { orgaPartners } from '../../../../assets/ressources/orga-partners';
import { Platform } from '@angular/cdk/platform';
import { CarouselScrollComponent } from '../carousel-scroll/carousel-scroll.component';
import { PartnerCardComponent } from '../carousel/partner-card/partner-card.component';

@Component({
  selector: 'app-all-organizers-carousel',
  templateUrl: './all-organizers-carousel.component.html',
  styleUrls: ['./all-organizers-carousel.component.scss'],
  standalone: true,
  imports: [CarouselScrollComponent, PartnerCardComponent],
})
export class AllOrganizersCarouselComponent {
  orgaPartners: Array<{imgUrl: string, name: string, website: string}> = orgaPartners;

  // item size must be in px without unit
  public itemSize = '240';
  private isBrowser = false;

  constructor(
    private platform: Platform,
  ) {
    this.isBrowser = this.platform.isBrowser;
    if (this.isBrowser) {
      const mediaQuery = window.matchMedia('(min-width: 576px)');
      if (mediaQuery.matches) {
        this.itemSize = '300';
      }
    }
  }

}
