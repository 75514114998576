<ng-container *transloco="let te; read 'formErrors.controlDescription'">
  @for (
    invalidControl of findInvalidControlsRecursive(form); track
    invalidControl; let first = $first; let last = $last; let count = $count) {
    @if (count === 1) {
      <span>{{ te('invalidControl', { controlName: te(invalidControl)}) }}</span>
    } @else {
      @if (first) {
        <span>{{ te('invalidControls') }}</span>
      }
      <span>{{ te(invalidControl) }}</span>
      <span>{{ last ? '.' : ', '}}</span>
    }
  }
</ng-container>
