// eslint-disable-next-line max-classes-per-file
import { Category, CategoryStat } from './category.model';
import { Currencies, getCurrencySymbol } from './currencies.model';
import { priceFormatter } from './formatter';

export const defaultCategoriesGroup: Partial<CategoriesGroup> = {
  available: true,
};

export class CategoriesGroup {
  public id: number;
  public eventId: number;
  public name: string;
  public order: number;
  public available: boolean;
  public modifiedBy: number;
  public platformsForeignIds: {[key: string]: string};

  public createdAt: Date;
  public updatedAt: Date;

  public Categories?: Category[];

  constructor(options?: Partial<CategoriesGroup>, categories?: Category[]) {
    Object.assign(this, options);
    this.createdAt = options.createdAt ? new Date(options.createdAt) : null;
    this.updatedAt = options.updatedAt ? new Date(options.updatedAt) : null;
    this.platformsForeignIds = options.platformsForeignIds ?? {};
    this.Categories = categories
      ?.filter( (c) => (c.categoriesGroupId === this.id || (this.id === 0 && !c.categoriesGroupId)));
  }
}

export class CategoriesGroupStat extends CategoriesGroup {
  public averageSellingPrice: number;
  public soldTicketsNb: number;
  public onSaleTicketsNb: number;
  public currencies: Currencies[];

  public Categories?: CategoryStat[];

  constructor(options?: any, categories?: CategoryStat[]) {
    super(options);
    this.Categories = categories
      ?.filter( (c) => (c.categoriesGroupId === this.id || (this.id === 0 && !c.categoriesGroupId)));
    this.soldTicketsNb = this.Categories?.reduce( (tot, c) => tot + (c.soldTicketsNb ?? 0), 0);
    this.averageSellingPrice = this.Categories
      ?.reduce( (tot, c) => tot + (c.soldTicketsNb ?? 0) * (c.averageSellingPrice ?? 0), 0) / this.soldTicketsNb;
    this.onSaleTicketsNb = this.Categories?.reduce( (tot, c) => tot + (c.onSaleTicketsNb ?? 0), 0);
    this.currencies = [...new Set(categories.map( (c) => c.currency))];
  }

  get humanReadableAveragePrice(): string {
    const formattedValue = priceFormatter([this.averageSellingPrice]);
    return formattedValue !== 'NaN' ? formattedValue : null;
  }
  get currencySymbol() {
    return this.currencies?.map( (c) => getCurrencySymbol(c)).join(' ,') ?? '';
  }
}
