<ng-container *transloco="let t">
  @if (customCheckboxFields?.length > 0) {
    <p class="fs-16 mb-3">
      {{ t('sale.myTickets.IConfirmInformation') }}
    </p>
  }
</ng-container>
@for (c of customCheckboxFields; track c; let i = $index) {
  <div class="mb-3">
    @if (currentLang === 'fr-FR') {
      <app-checkbox-form-field
        class="form__field"
        [form]="form"
        [controlName]="'fr-FR' + i"
        [formModel]="c"
        [validators]="['requiredTrue']"
      [options]="{
        label: c['fr-FR'],
        required: true,
      }"
        >
      </app-checkbox-form-field>
    }
    <p>
      @if (currentLang === 'fr-FR' && getErrorCheckbox(form, i)) {
        <mat-error class="fs-12 error fw-400">{{ 'formErrors.requiredtrue' | transloco }}</mat-error>
      }
    </p>
    @if (currentLang === 'es-ES') {
      <app-checkbox-form-field
        class="form__field"
        [form]="form"
        [controlName]="'es-ES' + i"
        [formModel]="c"
        [validators]="['requiredTrue']"
      [options]="{
        label: c['es-ES'],
        required: true,
      }"
        >
      </app-checkbox-form-field>
    }
    <p>
      @if (currentLang === 'es-ES' && getErrorCheckbox(form, i)) {
        <mat-error class="fs-12 error fw-400">{{ 'formErrors.requiredtrue' | transloco }}</mat-error>
      }
    </p>
    @if (currentLang === 'en-US') {
      <app-checkbox-form-field
        class="form__field"
        [form]="form"
        [controlName]="'en-US' + i"
        [formModel]="c"
        [validators]="['requiredTrue']"
      [options]="{
        label: c['en-US'],
        required: true,
      }"
        >
      </app-checkbox-form-field>
    }
    <p>
      @if (currentLang === 'en-US' && getErrorCheckbox(form, i)) {
        <mat-error class="fs-12 error fw-400">{{ 'formErrors.requiredtrue' | transloco }}</mat-error>
      }
    </p>
  </div>
}
