import { Component, OnInit, ViewChild, Input, OnDestroy } from '@angular/core';
import { Validators, UntypedFormGroup, UntypedFormBuilder, UntypedFormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { tap, catchError, switchMap } from 'rxjs/operators';
import { forkJoin, of, Observable, Subscription } from 'rxjs';
import { UserBankingInfo, KYCDocumentTypeInfos, UserProfile, BankAccountType, KYCLevel } from '../../../models';
import { AuthService, UserService, UploaderService, NotificationService } from '../../../services';
import { UploaderComponent } from '../uploader/uploader.component';
import { MatDialog } from '@angular/material/dialog';
import { TranslocoService, TranslocoModule } from '@ngneat/transloco';
import { Utils } from '../../utils';
import { ConfirmAccountDeletionDialogComponent } from './confirm-account-deletion-dialog/confirm-account-deletion-dialog.component';
import { captureException } from '@sentry/angular';
import { countryNameValidator } from '../../../validators';
import { DialogBasicComponent } from '../dialog-basic/dialog-basic.component';
import { MatIcon } from '@angular/material/icon';
import { UpperCasePipe, TitleCasePipe, DatePipe, KeyValuePipe } from '@angular/common';
import { LoaderComponent } from '../loader/loader.component';
import { MatCard, MatCardContent } from '@angular/material/card';
import { MatRadioGroup, MatRadioButton } from '@angular/material/radio';
import { MatCheckbox } from '@angular/material/checkbox';
import { MatError } from '@angular/material/form-field';
import { MatTooltip } from '@angular/material/tooltip';
import { SelectFormFieldComponent } from '../inputs/select-form-field/select-form-field.component';
import { TextFormFieldComponent } from '../inputs/text-form-field/text-form-field.component';
import { AutocompleteFormFieldComponent } from '../inputs/autocomplete-form-field/autocomplete-form-field.component';
import { MatButton } from '@angular/material/button';

@Component({
  selector: 'app-check-seller',
  templateUrl: './check-seller.component.html',
  styleUrls: ['./check-seller.component.scss'],
  standalone: true,
  imports: [
    TranslocoModule,
    MatIcon,
    LoaderComponent,
    MatCard,
    MatCardContent,
    FormsModule,
    ReactiveFormsModule,
    MatRadioGroup,
    MatRadioButton,
    UploaderComponent,
    MatCheckbox,
    MatError,
    MatTooltip,
    SelectFormFieldComponent,
    TextFormFieldComponent,
    AutocompleteFormFieldComponent,
    MatButton,
    UpperCasePipe,
    TitleCasePipe,
    DatePipe,
    KeyValuePipe,
  ],
})
export class CheckSellerComponent implements OnDestroy, OnInit {

  @ViewChild(UploaderComponent)
  private UploaderComponent: UploaderComponent;
  public KYCDocumentTypeInfos = KYCDocumentTypeInfos;
  public KYCLevel = KYCLevel;
  public twoHoursAgo = new Date();
  public BankAccountType = BankAccountType;
  public bankAccountTypeOptions = Object.values(BankAccountType).map((t: string) => ({
    value: t,
    viewValue: this.translocoService.translate('bankAccountType.' + t + '.description'),
  }));

  public kycTypeForm: UntypedFormGroup = this.fb.group({
    choosenKYCDocumentType: [''],
  });

  public addressForm = new UntypedFormGroup({
    countryOfResidence: new UntypedFormControl('', [
      Validators.required,
    ]),
  },{
    validators: [
      countryNameValidator,
    ],
  });

  public checkboxesForm: UntypedFormGroup = this.fb.group({
    readable: [false, Validators.required],
    valid: [false, Validators.required],
    owner: [false, Validators.required],
  });

  // eslint-disable-next-line no-underscore-dangle,id-blacklist,id-match
  _userBanking: UserBankingInfo;
  @Input()
  set userBanking(ub: UserBankingInfo) {
    // eslint-disable-next-line no-underscore-dangle
    this._userBanking = ub;
    if (!!ub && !ub.type) {
      this.userBanking.type = this.BankAccountType.IBAN; // default account type is IBAN
    }
    this.isLoadingUserBankingInfo = false;
  }
  get userBanking() {
    // eslint-disable-next-line no-underscore-dangle
    return this._userBanking;
  }

  _userKyc: {
    kycStatus: string;
    kycRefusedReasonMessage: string;
    kycRefusedReasonType: string;
    kycRefusedFlags?: string[];
  };
  @Input()
  set userKyc(value: any) {
    if (value) {
      // eslint-disable-next-line no-underscore-dangle
      this._userKyc = value;
      this.isLoadingUserKyc = false;
    }
  }
  get userKyc(): typeof this._userKyc {
    // eslint-disable-next-line no-underscore-dangle
    return this._userKyc;
  }

  isLoadingUserKyc = true;
  isLoadingUserBankingInfo = true;
  bankAccountFormGroup = new UntypedFormGroup({});
  nameRegex = Utils.nameRegex;
  private userSub: Subscription;
  private autoRefreshActivated = false;
  private dialogSub: Subscription;
  user: UserProfile;
  public userCopy: UserProfile;
  isSavingData = false;
  isEdition = false;
  registeredBankingInfo: UserBankingInfo;
  error: string;
  bankAccountFormValid = true;

  private translationSub: Subscription;

  public countries: {[key: string]: { mangoAllowed: string, value: string, viewValue: string}} = {
    FR: {
      mangoAllowed: 'true',
      value: 'FR',
      viewValue: 'France',
    },
  };

  constructor(
    private userService: UserService,
    private uploaderService: UploaderService,
    private authService: AuthService,
    public dialog: MatDialog,
    private fb: UntypedFormBuilder,
    private notificationService: NotificationService,
    private translocoService: TranslocoService,
  ) {
  }

  ngOnInit() {
    this.twoHoursAgo.setHours( this.twoHoursAgo.getHours() - 2);
    this.userSub = this.authService.getUser().subscribe({
      next: (u) => {
        this.user = u;
        if(!this.userCopy) {
          this.userCopy = new UserProfile(u);
        }
        // update this.userBanking
        if (this.user?.UserBankingInfo && this.userBanking) {
          // if no userBanking but user has submitted a kyc
          if (!this.userBanking.mangoKYCLevel && this.user.UserBankingInfo.mangoKYCLevel) {
            this.userBanking.mangoKYCId = this.user.UserBankingInfo.mangoKYCId;
            this.userBanking.mangoKYCLevel = this.user.UserBankingInfo.mangoKYCLevel;
            // if kyc validated or rejected
          } else if ([KYCLevel.STRONG, KYCLevel.REFUSED].includes(this.user.UserBankingInfo.mangoKYCLevel) ) {
            this.userBanking.mangoKYCId = this.user.UserBankingInfo.mangoKYCId;
            this.userBanking.mangoKYCLevel = this.user.UserBankingInfo.mangoKYCLevel;
          }
        }
        // update autorefresh state
        if (
          !!u && this.userBanking &&
          this.userBanking?.mangoKYCLevel !== KYCLevel.STRONG &&
          !this.autoRefreshActivated
        ) {
          // for firstname and lastname updates
          this.authService.setUserAutoRefresh();
          this.autoRefreshActivated = true;
        } else if (
          !!u && this.userBanking &&
          this.userBanking?.mangoKYCLevel === KYCLevel.STRONG &&
          this.autoRefreshActivated
        ) {
          this.authService.disableUserAutoRefresh();
          this.autoRefreshActivated = false;
        }
        // need to be set only on first loading. If any update, it will be handled within appropriates functions
        if (!this.registeredBankingInfo) {
          this.registeredBankingInfo = new UserBankingInfo({...this.userBanking});
        }
      },
      error: (err) => {
        // eslint-disable-next-line no-console
        console.log('Error on constructor function on check-seller component :', err);
      },
    });

    this.translationSub = this.translocoService.selectTranslateObject('countries')
      .subscribe((translatedCountries) => {
        const resultedCountries: typeof this.countries = {};
        Object.keys( (translatedCountries))?.forEach( (key) => {
          if (translatedCountries[key]?.code) {
            resultedCountries[key] = {
              mangoAllowed: translatedCountries[key].mangoAllowed,
              value: translatedCountries[key].code,
              viewValue: translatedCountries[key].name,
            };
          } else {
            captureException(`Can not retrieve country code. key = ${key} , translatedCountries[key].code = ${translatedCountries[key]?.code} , lang = ${this.translocoService.getActiveLang()}`);
          }
        });
        this.countries = resultedCountries?.FR ? resultedCountries : this.countries;
      });
  }

  ngOnDestroy() {
    if (this.autoRefreshActivated) {
      this.authService.disableUserAutoRefresh();
      this.autoRefreshActivated = false;
    }
    this.userSub?.unsubscribe();
    this.dialogSub?.unsubscribe();
    this.translationSub?.unsubscribe();
  }

  openDialog() {
    const dialogRef = this.dialog.open(DialogBasicComponent, {
      autoFocus: false,
      data: {
        title: this.translocoService.translate('checkSeller.info.fightAgainstFraud'),
        text: this.translocoService.translate('checkSeller.info.europeanReguliations'),
      },
    });
  }

  public upload(): Observable<any> {
    this.error = '';
    this.bankAccountFormValid = true;
    this.bankAccountFormGroup.markAllAsTouched();

    if (!this.bankAccountFormGroup.valid) {
      this.bankAccountFormValid = false;
      return of(false);
    }
    if (this.addressForm && [BankAccountType.CA, BankAccountType.US].includes(this.bankAccountFormGroup.value.type)) {
      this.addressForm.markAllAsTouched();
      if (!this.addressForm.valid) {
        return of(false);
      }
    }

    const newUserBanking = new UserBankingInfo(this.bankAccountFormGroup.value);

    this.isSavingData = true;

    const requests: {[key: string]: Observable<any>} = {};
    const handleUploadError = ((err) => {
      // eslint-disable-next-line no-console
      console.log('Error into upload function on check-seller component : ', err);
      if (
        err.error &&
        err.error.errors &&
        (err.error.errors.IBAN || err.error.errors.SortCode || err.error.errors.AccountNumber)
      ) {
        this.error = 'incorrectBankAccountError';
      } else if (err.status && [408, 499].includes(err.status)) {
        this.error = 'uploadTimeout';
      } else if (err.error && err.error.errors && err.error.errors.File) {
        this.error = 'fileError';
      } else if (!this.error) {
        this.error = 'unknownError';
      }
      this.notificationService.open(this.translocoService.translate(`checkSeller.${this.error}`));
      return of({success: false});
    });

    // we create bank account request if we have a new account
    if (newUserBanking.IBAN || newUserBanking.CAAccountNumber || newUserBanking.USAccountNumber) {
      requests.updateUserBankAccount = this.userService.createMangoBankAccount(newUserBanking, this.addressForm.value)
        .pipe(
          catchError( handleUploadError ),
          tap(() => {
            if (!this.error) {
              this.notificationService.open(this.translocoService.translate('myAccount.sellerInformation.informationWellSent'));
            }
            return of({success: true});
          }),
        );
    }
    // we upload files only if user has one of :
    // never submitted a kyc
    // no kycId waiting for validation for more than 2 hours
    // refused kyc
    if (
      this.userBanking &&
      !this.userBanking.mangoKYCId &&
      ([KYCLevel.REFUSED, KYCLevel.LIGHT].includes(this.userBanking.mangoKYCLevel) ||
      (this.userBanking.mangoKYCLevel === KYCLevel.CREATED && this.user.UserBankingInfo?.updatedAt < this.twoHoursAgo))
    ) {
      const files = this.UploaderComponent?.files || [];
      if (files.length === 0) {
        this.error = 'noFileError';
        this.isSavingData = false;
        return of(false);
      } else if (
        files.length !== 2 &&
        KYCDocumentTypeInfos[this.kycTypeForm?.value?.choosenKYCDocumentType]?.isRectoVerso
      ) {
        this.error = 'missingSideError';
        this.isSavingData = false;
        return of(false);
      }

      this.checkboxesForm.markAllAsTouched();
      if (!this.checkboxesForm.valid) {
        this.error = 'missingAcceptanceError';
        this.isSavingData = false;
        return of(false);
      }

      requests.postFile = this.uploaderService.postKyc(files, this.user?.id);
    }

    if (Object.keys(requests).length === 0) {
      this.isSavingData = false;
      return of(true);
    }

    return forkJoin(requests).pipe(
      catchError( handleUploadError ),
      tap( (r: {[key: string]: any}) => {
        if (r.updateUserBankAccount && r.updateUserBankAccount?.hasBankAccount) {
          this.userBanking = r.updateUserBankAccount;
          this.registeredBankingInfo = new UserBankingInfo({...this.userBanking});
          this.isEdition = false;
        }
        if (r.postFile) {
          this.userKyc.kycStatus = r.postFile.kycStatus;
          this.userBanking.mangoKYCId = r.postFile.mangoKYCId;
          this.userBanking.mangoKYCLevel = KYCLevel.CREATED;
          if (this.userKyc.kycStatus === KYCLevel.REFUSED) {
            this.userKyc.kycRefusedReasonMessage = r.postFile.kycRefusedReasonMessage;
            this.userKyc.kycRefusedReasonType = r.postFile.kycRefusedReasonType;
          }
        }
        this.isSavingData = false;
      }),
    );
  }

  confirmAccountDelete() {
    const dialogRef = this.dialog.open(ConfirmAccountDeletionDialogComponent, {
      data: {
        userBanking: this.registeredBankingInfo,
      },
      autoFocus: false,
    });
    this.dialogSub = dialogRef.afterClosed().pipe(
      switchMap((dialog) => {
        if (dialog && !!dialog.hasConfirmed) {
          this.isLoadingUserBankingInfo = true;
          return this.userService.deleteBankingAccount();
        }
        return of(undefined);
      }),
    ).subscribe({
      next: (updatedBankingInfo) => {
        if (updatedBankingInfo) {
          this.userBanking = updatedBankingInfo;
          this.registeredBankingInfo = new UserBankingInfo({...this.userBanking});
          this.notificationService.open(this.translocoService.translate('checkSeller.bankingAcccountWellDeleted'));
        }
        this.isLoadingUserBankingInfo = false;
      },
      error: (err) => {
        this.notificationService.open(this.translocoService.translate('checkSeller.errorDuringBankingAccountDeletion'));
        this.isLoadingUserBankingInfo = false;
        // eslint-disable-next-line no-console
        console.log('Error into bankingAccountDelete function on check-seller component :', err);
      },
    });
  }

}
