<ng-container *appLet="(appService.settings.asObservable() | async) as setting">
  <div class="page-container" *transloco="let tnf; read 'notFound'">

    @if (isLoading) {
      <app-loader diameter="40" class="my-5 mx-auto"></app-loader>
    }

    @if (!isLoading) {
      <div class="not-found">
        <p class="title --page mb-5">{{ tnf('woops') }}</p>
        <div class="not-found__img-div">
          <img class="not-found__img-div__img" src="assets/img/illustration/not-found.svg" alt="not-found">
        </div>
        <p class="not-found__text">{{ tnf('thisPageDoestNotExist') }}</p>
        @if (!setting?.isWidget) {
          <div class="buttons-group --row --centered">
            <a routerLink="/evenements">
              <button mat-button class="button --outlined-primary">
                {{ 'button.seeAvailableEvents' | transloco }}
              </button>
            </a>
            <a routerLink="/">
              <button mat-button class="button --primary">
                {{ 'button.goToTheHomePage' | transloco }}
              </button>
            </a>
          </div>
        }
      </div>
    }
  </div>
</ng-container>
