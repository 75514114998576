
<div
  class="calendar-container"
>
  <mat-icon class="calendar-container__icon">calendar_today</mat-icon>
  <span
    class="calendar-container__date"
  >{{ date ? date.getDate() : '' }}</span>
</div>

