<mat-checkbox
  class="form__field"
  [name]="controlName"
  [formControl]="control"
  >
  <p class="checkbox-text">
    {{ options.label }}
    @if (options.required) {
      <span [matTooltip]="'formErrors.required' | transloco" class="custom-event"> *</span>
    }
  </p>
  @if (options.hint) {
    <mat-hint
      class="checkbox-hint"
      >
      {{ options.hint }}
    </mat-hint>
  }
</mat-checkbox>
