<mat-form-field
  *transloco="let t"
  class="form__field"
  [subscriptSizing]="'dynamic'"
  >
  <mat-label>{{ t(options?.label, options?.labelParam) }}</mat-label>
  <mat-select [formControl]="control" [required]="validators.includes('required')">
    <mat-select-trigger>
      <div>
        @if (getSelection(control.value)?.icon) {
          <mat-icon
            class="select-form-field__trigger__icon"
          >{{ getSelection(control.value).icon }}</mat-icon>
        }
        {{ t(getSelection(control?.value)?.viewValue) }}
      </div>
    </mat-select-trigger>
    <!-- display clear option -->
    @if (options?.displayClearButton && !!control.value) {
      <mat-option [value]="null">
        <mat-icon>clear</mat-icon>
        {{ t('dashboard.exploreTable.GenericDelete') }}
      </mat-option>
    }
    @if ($any(selections?.[0])?.values) {
      @for (group of $any(selections); track group) {
        <mat-optgroup [label]="t(group.name)">
          @for (option of group.values; track option) {
            <mat-option [value]="option.value" [disabled]="option.disabled">
              @if (option.icon) {
                <mat-icon>{{option.icon}}</mat-icon>
              }
              {{ t(option.viewValue) }}
            </mat-option>
          }
        </mat-optgroup>
      }
    }
    @if (!$any(selections?.[0])?.values) {
      @for (option of $any(selections); track option) {
        <mat-option [value]="option.value" [disabled]="option.disabled">
          @if (option.icon) {
            <mat-icon>{{option.icon}}</mat-icon>
          }
          {{ t(option.viewValue) }}
        </mat-option>
      }
    }
  </mat-select>
  @if (options?.iconName) {
    <mat-icon matSuffix> {{ options.iconName }}</mat-icon>
  }
  <mat-hint>{{ t(options?.hint) }}</mat-hint>
  @if (control.errors) {
    <mat-error>{{ 'formErrors.' + Objectkeys(control.errors)?.[0] | transloco }}</mat-error>
  }
</mat-form-field>
