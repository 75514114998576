import { TicketHolder } from '.';

export enum ProcessType {
  AsyncBuying = 'as-buying',
  Buying = 'buying',
  Selling = 'selling',
  WaitList = 'waitList',
  UpdateEmail = 'updateEmail',
}

export enum KYCDocumentType {
  DrivingLicense = 'drivingLicense',
  NationalIdentityCard = 'nationalIdentityCard',
  Passport = 'passport',
}

export const KYCDocumentTypeInfos = {
  drivingLicense: {
    identifier: KYCDocumentType.DrivingLicense,
    isRectoVerso: true,
    imgUrl: 'kyc-driving-license.svg',
  },
  nationalIdentityCard: {
    identifier: KYCDocumentType.NationalIdentityCard,
    imgUrl: 'kyc-id-card.svg',
    isRectoVerso: true,
  },
  passport: {
    identifier: KYCDocumentType.Passport,
    imgUrl: 'kyc-passport.svg',
    isRectoVerso: false,
  },
};

export class PurchaseInfo {
  ticketId: number;
  ticketHolders: TicketHolder;
  customBuyerFields: any;
  public customTicketAnswers?: {[fieldId: number]: unknown};

  constructor(options?: any) {
    if (options) {
      this.ticketId = options.ticketId;
      this.ticketHolders = new TicketHolder(options.ticketHolders);
      this.customBuyerFields = options.customBuyerFields;
      this.customTicketAnswers = options.customTicketAnswers;
    }
  }
}
