
import { Component, HostBinding, Input } from '@angular/core';

@Component({
  selector: 'app-loader',
  template: `<img
    class="arrows"
    alt="loading"
    src="assets/img/reelax-arrows.svg?v=1"
  >`,
  styles: [
    ':host { display: block }',
    `.arrows {
      animation: opacityAnimation 2s infinite;
    }`,
  ],
  standalone: true,
})
export class LoaderComponent {
  @Input() diameter = '40';
  @HostBinding('style.width.px')
  get width(): string {
    return this.diameter;
  }
  @HostBinding('style.height.px')
  get height(): string {
    return this.diameter;
  }

  constructor() {}

}
