import { Component, HostListener, Inject, OnInit, Renderer2 } from '@angular/core';
import { Meta } from '@angular/platform-browser';
import { LanguageService } from './transloco/language.service';
import { IframeService } from './services';
import { Platform } from '@angular/cdk/platform';
import { DOCUMENT } from '@angular/common';
import { Language } from './models';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {

  private isBrowser = false;
  private isIOS = false;

  @HostListener('window:message', ['$event'])
  onMessage(event: MessageEvent) {
    this.receiveMessage(event);
  }

  constructor(
    private languageService: LanguageService,
    private meta: Meta,
    private platform: Platform,
    private iframeService: IframeService,
    private renderer2: Renderer2,
    @Inject(DOCUMENT) private document: Document,
  ) {
    this.isBrowser = this.platform.isBrowser;
  }

  ngOnInit() {
    // browser display
    if (this.isBrowser) {
      // locale
      this.languageService.getLang().then((lang) => {
        if (lang) {
          this.languageService.setLang(lang);
        } else {
          this.languageService.setLang(Language.frFR);
        }
      });
      this.isIOS = this.platform.IOS;

      // ios : avoid unwanted field zoom
      if (this.isIOS) {
        this.meta.updateTag({ name: 'viewport', content: 'width=device-width, initial-scale=1, maximum-scale=1' });
      }
      // load iframe resizer only when display in iframed env
      if (this.iframeService.isIframed()) {
        this.loadIframeResizer();
      }
    }
  }

  private loadIframeResizer() {
    const script = this.renderer2.createElement('script');
    script.type = 'text/javascript';
    script.src = 'assets/ressources/iframeResizer.contentWindow.min.js';
    this.renderer2.appendChild(this.document.body, script);
    this.iframeService.init();
  }

  private receiveMessage(message: MessageEvent) {
    // debug iframe communication
    // console.log('receiveMessage in angular : ', message);
  }

}
