import { Component, Input } from '@angular/core';
import { WaitListPurchase } from '../../../models';
import { TranslocoModule } from '@ngneat/transloco';

@Component({
  selector: 'app-waitlistpurchase-error',
  templateUrl: './waitlistpurchase-error.component.html',
  styleUrls: ['./waitlistpurchase-error.component.scss'],
  standalone: true,
  imports: [TranslocoModule],
})
export class WaitlistpurchaseErrorComponent {
  @Input() waitlistpurchase: WaitListPurchase;
}
