<div class="page-container">

  @if (organizer.logoUrl) {
    <div class="partner">
      @if (organizer.logoUrl) {
        <img class="partner__logo" [src]="organizer.logoUrl">
      }
    </div>
  } @else {
    @if (organizer) {
      <p class="title --page">{{organizer.name}}</p>
    }
  }

  @if (organizerText) {
    <div class="fs-16 my-3 center" [innerHTML]="organizerText"></div>
  }

  <router-outlet></router-outlet>

</div>
