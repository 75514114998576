<div class="dialog">

  @if (data.message) {
    <div mat-dialog-content class="dialog-content">
      <div class="dialog-content__text mb-2" [innerText]="data.message"></div>
    </div>
  }

  <mat-dialog-actions>
    @if (data.button) {
      <button mat-button [mat-dialog-close]="{button: data.button}" class="ml-auto button --small --primary">
        {{ data.button }}
      </button>
    }
  </mat-dialog-actions>

</div>