import { LanguageMap } from './language.model';

// eslint-disable-next-line max-classes-per-file
export enum FaqSection {
  Seller = 'seller',
  Buyer = 'buyer',
  Organizer = 'organizer',
}

export interface IFaq {[key: string]: FAQItem}

export class FAQItem {
  name?: string | LanguageMap;
  question?: string | LanguageMap;
  answer?: string | LanguageMap;
  fragment?: string;
  score?: number;
  id?: number;
  children: Array<string>;

  constructor(options?: any) {
    if (options) {
      this.name = options.name;
      this.question = options.question;
      this.answer = options.answer;
      this.fragment = options.fragment;
      this.score = options.score;
      this.id = options.id;
      this.children = options.children;
    }
  }
}
