@if (hasOneTanslation() || translocoMessage) {
  <mat-card class="message-card" #messageCard>
    <mat-card-content>
      <div class="d-flex --row">
        <mat-icon class="message-card__icon custom-event">error</mat-icon>
        <div class="d-flex --column --rg-4">
          @if (hasOneTanslation()) {
            <div
              class="message-card__text"
            [innerHTML]="
              message?.['language.localeCountry' | transloco]
              ?? message[Language.frFR]
              ?? message[Language.enUS]
              ?? message[Language.esES]
            "
            ></div>
          }
          @if (translocoMessage) {
            <div
              class="message-card__text"
              [innerHTML]="translocoMessage"
            ></div>
          }
        </div>
      </div>
    </mat-card-content>
  </mat-card>
}
