import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { environment } from '../../../../../environments/environment';
import { MatTooltip } from '@angular/material/tooltip';
import { MatIcon } from '@angular/material/icon';

@Component({
  selector: 'app-press-card',
  templateUrl: './press-card.component.html',
  styleUrls: ['./press-card.component.scss'],
  standalone: true,
  imports: [
    MatTooltip,
    MatIcon,
  ],
})
export class PressCardComponent implements OnChanges {
  environment = environment;
  @Input() press: {logo: string, name: string, title: string, website: string};
  isLoaded = false;

  constructor() {}

  ngOnChanges(simpleChanges: SimpleChanges) {
    if (simpleChanges.partner) {
      this.isLoaded = false;
    }
  }

  handleLoading() {
    this.isLoaded = true;
  }

}
