import { Component, Inject, Input, OnDestroy, OnInit, PLATFORM_ID } from '@angular/core';
import { Subscription } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { EventsService } from '../../../services';
import { Event, EventSearchOptions } from '../../../models';
import { isPlatformBrowser } from '@angular/common';
import { LoaderComponent } from '../loader/loader.component';
import { CarouselComponent } from '../carousel/carousel.component';
import { EventCardComponent } from '../event-card/event-card.component';

@Component({
  selector: 'app-all-events-carousel',
  templateUrl: './all-events-carousel.component.html',
  styleUrls: ['./all-events-carousel.component.scss'],
  standalone: true,
  imports: [
    LoaderComponent,
    CarouselComponent,
    EventCardComponent,
  ],
})
export class AllEventsCarouselComponent implements OnInit, OnDestroy {
  environment = environment;
  @Input() events: Event[];
  isBrowser = false;

  private eventsSubscription: Subscription;
  public isLoadingEvents = true;

  constructor(
    private eventsService: EventsService,
    @Inject(PLATFORM_ID) protected platformId,
  ) {
    this.isBrowser = isPlatformBrowser(platformId);
  }

  ngOnInit(): void {
    if (!this.events) {
      this.eventsSubscription = this.eventsService.getPaginatedEvents().pipe(
      ).subscribe((newEvents) => {
        this.events = [
          ...newEvents.rows,
        ];
        this.isLoadingEvents = false;
      }, (err) => {
        this.isLoadingEvents = false;
        this.events = this.events ?? [];
        // eslint-disable-next-line no-console
        console.log('getPaginatedEvents error on AllEventsCarouselComponent component');
        throw err;
      });
      this.eventsService.getSomeEvents.next({
        limit: 50,
        offset: 0,
        ...(new EventSearchOptions()),
      });
    } else {
      this.isLoadingEvents = false;
    }
  }

  ngOnDestroy() {
    this.eventsSubscription?.unsubscribe();
  }

}
