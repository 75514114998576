import { ValidatorFn, AbstractControl } from '@angular/forms';
import { Event, Category } from '../models';

export function eventNameValidator(events: Event[]): ValidatorFn {
  return (control: AbstractControl): {[key: string]: any} | null => {
    let value;
    if (control.value) {
      if (control.value.name) {
        value = control.value.name;
      } else {
        value = control.value;
      }
    }
    if (!events || events.length === 0 || !value) {
      return null;
    }
    let isEvent: boolean;
    events.forEach((e) => {
      if (e.name && e.name.toLowerCase() === value.toLowerCase()) {
        isEvent = true;
      }
    });
    return !isEvent ? { wrongEventName : true } : null ;
  };
}

export function categoryNameValidator(categories: Category[]): ValidatorFn {
  return (control: AbstractControl): {[key: string]: any} | null => {
    let value;
    if (control.value) {
      if (control.value.name) {
        value = control.value.name;
      } else {
        value = control.value;
      }
    }
    if (!categories || categories.length === 0 || !value) {
      return null;
    }
    let isCategory: boolean;
    categories.forEach((c) => {
      if (c.name && c.name.toLowerCase() === value.toLowerCase()) {
        isCategory = true;
      }
    });
    return !isCategory ? { wrongCategoryName : true } : null ;
  };
}
