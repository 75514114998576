<div class="dialog" *transloco="let tav; read 'accountValidation'">

  <mat-icon class="close-icon" color="primary" (click)="onCloseClick()">close</mat-icon>

  <h1 mat-dialog-title class="dialog-title">🔒 {{ tav('howHavingPwd')}}</h1>

  <div mat-dialog-content class="dialog-content">

    <div class="dialog-content__text mb-2">
      <span [innerHTML]="tav('ourPolicyExplanation')"></span>
      <br><br>
      {{ tav('examples')}}<br>
      <div class="d-flex --row --wrap --space-between">
        <div class="my-3">
          {{ tav('password')}} : <strong>colmar1995</strong>
          <span class="requierements d-flex --column --first-baseline">
            <span [ngStyle]="{'color':  '#43cea2'}">
              <mat-icon class="">check_circle</mat-icon>
              {{tav('characters', {nb: 8})}}
            </span>
            <span [ngStyle]="{'color':  '#43cea2'}">
              <mat-icon>check_circle</mat-icon>
              {{tav('lower')}}
            </span>
            <span [ngStyle]="{'color': '#A30B37'}">
              <mat-icon>cancel</mat-icon>
              {{tav('upper')}}
            </span>
            <span [ngStyle]="{'color':'#43cea2'}">
              <mat-icon>check_circle</mat-icon>
              {{tav('number')}}
            </span>
            <span [ngStyle]="{'color': '#A30B37'}">
              <mat-icon>cancel</mat-icon>
              {{tav('symbol')}}
            </span>
          </span>
        </div>
        <div class="my-3">
          {{ tav('password')}} : <strong>vaGep789#</strong> 
          <span class="requierements d-flex --column --first-baseline">
            <span [ngStyle]="{'color':  '#43cea2'}">
              <mat-icon>check_circle</mat-icon>
              {{tav('characters', {nb: 8})}}
            </span>
            <span [ngStyle]="{'color':  '#43cea2'}">
              <mat-icon>check_circle</mat-icon>
              {{tav('lower')}}
            </span>
            <span [ngStyle]="{'color': '#43cea2'}">
              <mat-icon>check_circle</mat-icon>
              {{tav('upper')}}
            </span>
            <span [ngStyle]="{'color':'#43cea2'}">
              <mat-icon>check_circle</mat-icon>
              {{tav('number')}}
            </span>
            <span [ngStyle]="{'color': '#43cea2'}">
              <mat-icon>check_circle</mat-icon>
              {{tav('symbol')}}
            </span>
          </span>
        </div>
        <div class="my-3">
          {{ tav('password')}} : <strong>qjippfowgedhbz</strong>
          <span class="requierements d-flex --column --first-baseline">
            <span [ngStyle]="{'color':  '#43cea2'}">
              <mat-icon>check_circle</mat-icon>
              {{tav('characters', {nb: 12})}}
            </span>
          </span>
        </div>
      </div>
      <br>
      <span [innerHTML]="tav('mayNotBeAccepted')"></span><br>
    </div>
  </div>
</div>