import { TicketTemplate, Event } from '.';

export class File {
  public id!: number;
  public link: string;
  // eslint-disable-next-line no-undef
  public file: Buffer;
  public private: boolean;
  public token: string;
  public mimetype: string;
  public size?: string;

  public createdAt: Date;
  public updatedAt: Date;

  public CoverImg?: Event[];
  public SeatingPlanImg?: Event[];
  public TemplateImg?: TicketTemplate[];

  constructor(data: Partial<File>) {
    Object.assign(this, data);
  }

  get fileEventName() {
    if (this.CoverImg?.length > 0) {
      return this.CoverImg.map( (img) => img?.name).join(', ');
    }
    if (this.SeatingPlanImg?.length > 0) {
      return this.SeatingPlanImg.map( (img) => img?.name).join(', ');
    }
    if (this.TemplateImg?.length > 0) {
      return this.TemplateImg.map( (img) => img?.event?.name).join(', ');
    }
    return undefined;
  }
}
