@if (press) {
  <div class="press-card">
    <a class="press-card__link" [href]="press.website" target="_blank" rel="noopener" [matTooltip]="press.name">
      @if (!isLoaded) {
        <img class="press-card__link__img-placeholder" [alt]="press.name"
          src="assets/img/reelax-arrows.svg?v=1">
        }
        <img class="press-card__link__img" [alt]="press.name" [src]="'assets/img/' + press.logo"
          loading="lazy"
          [class.--isLoading]="!isLoaded"
          (load)="handleLoading()">
        <p class="press-card__link__title">
          <mat-icon>format_quote</mat-icon>
          {{press.title}}
        </p>
      </a>
    </div>
  }