import { Component, Input } from '@angular/core';
import { TranslocoModule } from '@ngneat/transloco';
import { MatIcon } from '@angular/material/icon';
import { MatTooltip } from '@angular/material/tooltip';

@Component({
  selector: 'app-saving-indicator',
  templateUrl: './saving-indicator.component.html',
  styleUrls: ['./saving-indicator.component.scss'],
  standalone: true,
  imports: [
    TranslocoModule,
    MatIcon,
    MatTooltip,
  ],
})
export class SavingIndicatorComponent {
  @Input() public pendingSave = false;
  @Input() public saving = false;
  @Input() public saved = false;
}
