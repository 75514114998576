import { isPlatformBrowser, NgTemplateOutlet } from '@angular/common';
import {
  AfterViewChecked,
  AfterViewInit,
  Component,
  ContentChild,
  ElementRef,
  HostListener,
  Inject,
  Input,
  OnDestroy,
  PLATFORM_ID,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { MatIcon } from '@angular/material/icon';

function mod(n: number, m: number) {
  return ((n % m) + m) % m;
}

@Component({
  selector: 'app-carousel',
  templateUrl: './carousel.component.html',
  styleUrls: ['./carousel.component.scss'],
  standalone: true,
  imports: [
    MatIcon,
    NgTemplateOutlet,
  ],
})
export class CarouselComponent<Item = unknown> implements AfterViewChecked, AfterViewInit, OnDestroy {
  @ContentChild(TemplateRef) template: TemplateRef<any>;
  @ViewChild('carousel_container') container: ElementRef<HTMLDivElement>;
  @Input() items: Item[];
  @Input() maxItems: number;
  @Input() buttons = false;

  public countVisible: number;
  private currentIndex = 0;
  private interval;
  private isBrowser = false;

  constructor(
    @Inject(PLATFORM_ID) platformId,
  ) {
    this.isBrowser = isPlatformBrowser(platformId);
  }

  @HostListener('window:resize', [])
  onResize() {
    this.initSize();
  }

  ngAfterViewChecked() {
    if (this.countVisible) {
      return;
    }
    this.initSize();
  }

  ngAfterViewInit() {
    if (this.isBrowser ) {
      this.run();
    }
  }

  ngOnDestroy() {
    if (this.isBrowser ) {
      this.stop();
    }
  }

  initSize() {
    if (this.isBrowser && (this.container?.nativeElement.children.length ?? 0) > 0) {
      const item = this.container.nativeElement.children[0];
      const itemWidth = item.getBoundingClientRect().width;

      const carousel = this.container.nativeElement;
      // Reset of carousel width. Needed to well reset countVisible later
      carousel.style.width = 'unset';
      const carouselWidth = carousel.getBoundingClientRect().width;

      if (this.maxItems > 0) {
        this.countVisible = this.maxItems;
      } else {
        this.countVisible = Math.floor(carouselWidth / itemWidth) || 1;
      }
      carousel.style.width = `${this.countVisible * itemWidth}px`;
    }
  }

  stop() {
    clearInterval(this.interval);
  }

  run() {
    this.interval = setInterval(() => {
      this.next();
    }, 2500);
  }

  next() {
    this.currentIndex = mod(this.currentIndex + 1, 1 + this.items?.length - this.countVisible);
  }

  previous() {
    this.currentIndex = mod(this.currentIndex - 1, 1 + this.items?.length - this.countVisible);
  }

  getTransform() {
    return `translateX(-${this.currentIndex}00%)`;
  }
}

