<div [class]="event? (event.isPartner? 'event-cover partner' : 'event-cover') : 'hidden'">
  <div class="event-cover__background">
    <div #backgroundImg class="event-cover__background__image"></div>
  </div>
  @if (event) {
    <img
      class="event-cover__foreground-image"
      [alt]="event.name"
      [src]="event.imgUrl ? event.imgUrl : 'assets/img/default-event-cover.jpg'"
      >
  }
  @if (event && event.isPartner) {
    <div class="page-container event-cover__partner">
      <ng-container *transloco="let te; read 'events'">
        <p class="event-cover__partner__title">
          {{ te('officialMarketplace') }}
        </p>
        @if (!(event.EventPersonalization?.getFieldTrad('platformPagePartnershipText', currentLang))) {
          <p class="event-cover__partner__text">
            <a class="event-cover__partner__text --link" [routerLink]="['/e', 'n', event.url]">{{partnerName}}</a>
            {{ te('offersSafeTicketBuyAndSale') }}
          </p>
        } @else {
          <p class="event-cover__partner__text">
            {{ event.EventPersonalization?.getFieldTrad('platformPagePartnershipText', currentLang)}}
          </p>
        }
      </ng-container>
    </div>
  }
</div>
