import { Directive, HostListener, Input } from '@angular/core';
import { diffChars } from 'diff';

@Directive({
  selector: 'input[appUppercaseSpace4]',
  standalone: true,
})
export class UppercaseSpace4Directive {
  @Input() appUppercaseSpace4: boolean | '' = true;

  constructor() { }

  @HostListener('input', ['$event'])
  change($event) {
    if (this.appUppercaseSpace4) {
      const input = $event.target as HTMLInputElement;
      const initialValue = input.value as string;
      const initialPosition = input.selectionStart; // get the position of the cursor
      if (initialValue && initialPosition && initialValue.length > 0) {
        const spaceNumberBefore = initialValue.match(/([\s]+)/g)?.length ?? 0;
        const uppNoSpace = initialValue.split(/[\s]+/)?.join('').toUpperCase();
        const uppNoSpaceSplitted = uppNoSpace?.match(/.{1,4}/g);
        const uppercaseSpace4 = uppNoSpaceSplitted?.join(' ');
        input.value = uppercaseSpace4;
        const spaceNumberAfter = uppercaseSpace4?.match(/([\s]+)/g)?.length ?? 0;
        let newPosition = initialPosition + spaceNumberAfter - spaceNumberBefore;
        if (initialValue !== uppercaseSpace4) {
          const stringDiff = diffChars(initialValue, uppercaseSpace4);
          // in case we only added one space char then we change selection position
          if (
            stringDiff.length === 3 &&
            stringDiff[1].added &&
            stringDiff[1].count === 1 &&
            (newPosition - stringDiff[0].count) === 1
          ) {
            newPosition = stringDiff[0].count;
          }
          // forces detection changes
          input.dispatchEvent(new Event('input'));
        }
        input.setSelectionRange(newPosition, newPosition);
      }
    }

  }

}
