<ng-container *transloco="let t">
  <ng-container *appLet="(appService.settings.asObservable() | async) as setting">

    @if (!isLandingPage && isTestMode) {
      <div class="demo">
        <div class="page-container">
          <span class="demo__text"> {{ t('navbar.demo') }} </span>
        </div>
      </div>
    }

    @if (setting?.isWidget && isProcessPages) {
      <div *transloco="let tw; read 'widget'" class="strip mb-4">
        <app-back-button class="strip__back-button"></app-back-button>
        <div class="strip__icon-tabs">
          @if (buckets?.length > 0) {
            <a class="tab-link" matTooltip="{{ t('navbar.cart') }}"
              [matBadge]="buckets.length.toString()" [matMenuTriggerFor]="bucketMenu" matBadgePosition="below" matBadgeColor="primary">
              <mat-icon>shopping_cart</mat-icon>
            </a>
          }
          @if (user?.role === 'anonymous') {
            <a class="tab-link" routerLink="/connexion" routerLinkActive="--active" [matTooltip]="t('navbar.connect')" aria-label="Connexion"><mat-icon aria-hidden="true">account_box</mat-icon></a>
          }
          @if (!!user === false) {
            <a class="tab-link" routerLink="/connexion" routerLinkActive="--active" [matTooltip]="t('navbar.connect')" aria-label="Connexion"><mat-icon aria-hidden="true">account_circle</mat-icon></a>
          }
          @if (!!user && user?.role !== 'anonymous') {
            <a class="tab-link" routerLink="/mon-compte" routerLinkActive="--active" [matTooltip]="t('navbar.account')" aria-label="Mon compte" matBadgePosition="below" [matBadge]="needUserAction ? '!' : ''" matBadgeColor="warn"><mat-icon aria-hidden="true">account_circle</mat-icon></a>
          }
          @if (['organizer', 'admin'].includes(user?.role)) {
            <a class="tab-link" routerLink="/orga" routerLinkActive="--active" [matTooltip]="t('navbar.dashboard') + ' ' + t('navbar.organizer')" aria-label="Tableau de bord Organisateur"><mat-icon>insert_chart</mat-icon></a>
          }
          @if (user?.role === 'admin') {
            <a class="tab-link" routerLink="/admin" routerLinkActive="--active" [matTooltip]="t('navbar.dashboard') + ' ' + t('navbar.admin')" aria-label="Tableau de bord Administrateur"><mat-icon>dashboard</mat-icon></a>
          }
          <app-language-selector [hideCurrentLang]="true"></app-language-selector>
        </div>
        <p class="strip__text fw-600">
          <!-- iframe title : 1 custom orga; 2 custom event; 3 default-->
          @if (!event && hasOneTanslation(organizer?.iframeTitle, Language)) {
            <span
              [innerHTML]="
                organizer?.iframeTitle?.['language.localeCountry' | transloco]
                ?? organizer?.iframeTitle[Language.frFR]
                ?? organizer?.iframeTitle[Language.enUS]
                ?? organizer?.iframeTitle[Language.esES]
              "
            ></span>
          }
          @if (hasOneTanslation(event?.EventPersonalization?.iframeTitle, Language)) {
            <span
              [innerHTML]="
                event?.EventPersonalization?.iframeTitle?.['language.localeCountry' | transloco]
                ?? event?.EventPersonalization?.iframeTitle[Language.frFR]
                ?? event?.EventPersonalization?.iframeTitle[Language.enUS]
                ?? event?.EventPersonalization?.iframeTitle[Language.esES]
              "
            ></span>
          }
          @if ((!event && !hasOneTanslation(organizer?.iframeTitle, Language)) || (event && !hasOneTanslation(event?.EventPersonalization?.iframeTitle, Language))) {
            {{ tw('officialMarketplace') }}
            @if (setting?.isWidget && event?.url && event?.name) {
              <a
                class="tab-link --max-len"
                [routerLink]="'/e/n/' + event?.url"
                routerLinkActive="--active"
                >
                {{ event.name }}
              </a>
            }
          }
        </p>
      </div>
    }

    @if (!setting?.isWidget || !isProcessPages) {
      <mat-toolbar class="header">
        <div class="header__main-content">
          <div class="d-flex --centered">
            @if (setting?.isWidget && event?.url && event?.name) {
              <a
                class="tab-link --max-len"
                [routerLink]="'/e/n/' + event?.url"
                routerLinkActive="--active"
                >
                {{ event.name }}
              </a>
            }
            @if (!setting?.isWidget) {
              <app-reelax-tickets-logo></app-reelax-tickets-logo>
            }
          </div>
          <!-- medium and large screens -->
          <div class="hide-on-small-only">
            <div class="header__main-content--nav">
              @if (!setting?.isWidget) {
                <a
                  class="tab-link"
                  routerLink="/evenements"
                  routerLinkActive="--active"
                >{{ t('common.events') }}</a>
              }
              @if (!setting?.isWidget) {
                <a class="tab-link" routerLink="/pro" routerLinkActive="--active">{{ t('common.organizers') }}</a>
              }
              @if (!setting?.isWidget) {
                <a class="tab-link" routerLink="/faq" routerLinkActive="--active">{{ t('common.help') }}</a>
              }
              @if (buckets?.length > 0) {
                <a class="tab-link" matTooltip="{{ t('navbar.cart') }}"
                  [matBadge]="buckets.length.toString()" [matMenuTriggerFor]="bucketMenu" matBadgeColor="accent">
                  <mat-icon>shopping_cart</mat-icon>
                </a>
              }
              @if (user?.role === 'anonymous') {
                <a class="tab-link" routerLink="/connexion" routerLinkActive="--active" [matTooltip]="t('navbar.connect')" aria-label="Connexion"><mat-icon aria-hidden="true">account_box</mat-icon></a>
              }
              @if (!!user === false) {
                <a class="tab-link" routerLink="/connexion" routerLinkActive="--active" [matTooltip]="t('navbar.connect')" aria-label="Connexion"><mat-icon aria-hidden="true">account_circle</mat-icon></a>
              }
              @if (!!user && user?.role !== 'anonymous') {
                <a class="tab-link" routerLink="/mon-compte" routerLinkActive="--active" [matTooltip]="t('navbar.account')" aria-label="Mon compte" [matBadge]="needUserAction ? '!' : ''" matBadgeColor="warn"><mat-icon aria-hidden="true">account_circle</mat-icon></a>
              }
              @if (['organizer', 'admin'].includes(user?.role)) {
                <a class="tab-link" data-cy="organizer-dashboard" routerLink="/orga" routerLinkActive="--active" [matTooltip]="t('navbar.dashboard') + ' ' + t('navbar.organizer')" aria-label="Tableau de bord Organisateur"><mat-icon>insert_chart</mat-icon></a>
              }
              @if (user?.role === 'admin') {
                <a class="tab-link" data-cy="admin-dashboard" routerLink="/admin" routerLinkActive="--active" [matTooltip]="t('navbar.dashboard') + ' ' + t('navbar.admin')" aria-label="Tableau de bord Administrateur"><mat-icon>dashboard</mat-icon></a>
              }
              <app-language-selector class="tab-link" [hideCurrentLang]="true"></app-language-selector>
            </div>
          </div>
          <!-- small screens -->
          <div class="header__main-content__sidenav show-on-small-only">
            <span class="menu-icon" (click)="sidenav.open()">
              <mat-icon
                [matBadge]="needUserAction ? '!' : (buckets?.length || '')"
                [matBadgeColor]="needUserAction ? 'warn' : 'primary'"
                >menu
              </mat-icon>
            </span>
            <mat-sidenav #sidenav position="end" mode="over" fixedInViewport="true" [style.display]="sidenav.opened? 'block' : 'none'"
              class="header__main-content__sidenav__content">
              <div class="header__main-content__sidenav__content__close-div">
                <app-reelax-tickets-logo [isBurger]="true"></app-reelax-tickets-logo>
                <div class="header__main-content__sidenav__content__close-div__button" (click)="closeSidenav()">
                  <mat-icon>close</mat-icon>
                </div>
              </div>
              <div class="header__main-content__sidenav__content__menu-links">
                @if (setting?.isWidget && event?.url && event?.name) {
                  <a
                    class="tab-link --max-len"
                    (click)="closeSidenav()"
                    [routerLink]="'/e/n/' + event?.url"
                    routerLinkActive="--active"
                    >
                    {{ event.name }}
                  </a>
                }
                @if (!setting?.isWidget) {
                  <a class="tab-link" (click)="closeSidenav()" routerLink="/evenements" routerLinkActive="--active">{{ t('common.events') }}</a>
                }
                @if (!setting?.isWidget) {
                  <a class="tab-link" (click)="closeSidenav()" routerLink="/pro" routerLinkActive="--active">{{ t('common.organizers') }}</a>
                }
                <a class="tab-link" (click)="closeSidenav()" routerLink="/faq" routerLinkActive="--active">{{ t('common.help') }}</a>
                @if (buckets?.length > 0) {
                  <a class="tab-link"
                    [matBadge]="buckets?.length.toString()" [matMenuTriggerFor]="bucketMenu" matBadgeColor="accent">
                    <mat-icon>shopping_cart</mat-icon>
                    {{ t('navbar.cart') }}
                  </a>
                }
                @if (!!user === false || user?.role === 'anonymous') {
                  <a class="tab-link" routerLink="/connexion" routerLinkActive="--active" (click)="closeSidenav()">{{ t('navbar.connect') }}</a>
                }
                @if (user && user?.role !== 'anonymous') {
                  <a class="tab-link" routerLink="/mon-compte" routerLinkActive="--active" (click)="closeSidenav()" [matBadge]="needUserAction ? '!' : ''" matBadgeColor="warn">{{ t('navbar.account') }}</a>
                }
                @if (['organizer', 'admin'].includes(user?.role)) {
                  <a class="tab-link" (click)="closeSidenav()" routerLink="/orga" routerLinkActive="--active">{{ t('navbar.dashboard') }}</a>
                }
                @if (user?.role === 'admin') {
                  <a class="tab-link" (click)="closeSidenav()" routerLink="/admin" routerLinkActive="--active">{{ t('navbar.admin') }}</a>
                }
                <app-language-selector class="tab-link" [hideCurrentLang]="true"></app-language-selector>
              </div>
            </mat-sidenav>
          </div>
        </div>
      </mat-toolbar>
    }

    <!-- template of bucket menu (used by both desktop and mobile menu)-->
    <mat-menu #bucketMenu="matMenu">
      @for (bucket of buckets; track bucket) {
        <button
          mat-menu-item
          [routerLink]="['/e/n', bucket.Event?.url, 'achat', bucket.ticketId.toString()]"
          routerLinkActive="--active"
          (click)="closeSidenav()"
          class="bucket-item"
          >
          <mat-icon class="mr-4">local_play</mat-icon>
          <span class="d-flex">
            <span class="bucket-item__details">
              <span class="fw-600">
                {{bucket.eventName}}
              </span>
              <br>
                @if (bucket.Ticket?.Category?.name) {
                  <span>
                    {{bucket.Ticket.Category?.name}}
                  </span>
                }
                <br>
                  @if (bucket.Ticket?.price && bucket.Ticket?.fees) {
                    <span>
                      {{ t('price', {
                      price: (bucket.Ticket.price + bucket.Ticket.fees)/100 | number: '1.2-2':('language.locale' | transloco),
                      currency: bucket.Ticket.currencySymbol
                      })}}
                    </span>
                  }
                </span>
                <button mat-button class="ml-4 button --small --danger white" (click)="removeBucketItem(bucket); $event.stopPropagation()">
                  <mat-icon [matTooltip]="t('navbar.removeFromCart')" class="mr-0 reelax-white">delete</mat-icon>
                </button>
              </span>
            </button>
          }
        </mat-menu>
      </ng-container>
    </ng-container>