import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Organizer } from '../models';

@Injectable({providedIn: 'root'})
export class OrganizerStore {
  private readonly organizers = new BehaviorSubject<Organizer[]>([]);
  private readonly currentOrganizerId = new BehaviorSubject<number>(undefined);

  readonly organizers$ = this.organizers.asObservable();
  readonly currentOrganizerId$ = this.currentOrganizerId.asObservable();

  getOrganizers(eventId?: number): Organizer[] {
    if (eventId >= 0) {
      return this.getOrganizers().filter((e) => e.id === eventId);
    }
    return this.organizers.getValue();
  }

  getCurrentOrganizer() {
    return this.getOrganizers(this.currentOrganizerId.getValue()).pop();
  }

  setCurrentOrganizer(eventId) {
    this.currentOrganizerId.next(eventId);
  }

  loadOrganizers(organizers: Organizer[]) {
    this.organizers.next([
      ...organizers,
      ...this.getOrganizers()
        .filter((oldE) => organizers.reduce( (insert, newE) => insert && oldE.id !== newE.id, true)),
    ]);
  }

}
