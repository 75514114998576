
export class Redirect {
  public id!: number;
  public source: string;
  public target: string;

  public createdAt: Date;
  public updatedAt: Date;

  constructor(data: Partial<Redirect>) {
    Object.assign(this, data);
  }
}
