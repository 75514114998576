<mat-card class="social-card" *transloco="let tsp; read 'socialPreview'">
  <img class="social-card__img" [src]="seoService.getSocialImgLink(event?.imgUrl)">
  <div class="social-card__text">
    <p class="social-card__text__website">REELAX-TICKETS.COM</p>
    @if (type === 'sell') {
      <p class="social-card__text__title">{{tsp('resellTicketsFor')}} {{event.name}}</p>
    }
    @if (type === 'buy') {
      <p class="social-card__text__title">
        @if (ticket.price) {
          {{ tsp('buyATicketAtPriceFor', {
          price: (ticket.buyerPrice)/100 | number: '1.2-2':('language.locale' | transloco),
          currency: ticket.currencySymbol
          }) }} {{event.name}}
        } @else {
          {{ tsp('buyATicketFor') }} {{event.name}}
        }
      </p>
    }
    <p class="social-card__text__description">
      {{event.organizerName ?? event.name}} {{ tsp('hasEnteredIntoAPartnershipWithReelax') }}
    </p>
  </div>
</mat-card>
