import { Injectable } from '@angular/core';
import { AsyncValidator, AbstractControl, ValidationErrors } from '@angular/forms';
import { Observable, map, catchError, of, delay, switchMap, timeout } from 'rxjs';
import { EmailService } from '../services';

@Injectable({ providedIn: 'root' })
export class EmailCheckValidator implements AsyncValidator {

  constructor(private emailService: EmailService) {}

  validate(
    control: AbstractControl,
  ): Observable<ValidationErrors | null> {
    return of(control.value).pipe(
      delay(500),
      switchMap((email) => this.emailService.checkEmail(email).pipe(
        timeout(1000),
        map( (res) => (res?.isValid ? null : { emailDoesNotExist: true })),
        catchError((err) => of(null)),
      )),
    );
  }
}
