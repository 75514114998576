<ng-container *transloco="let tts; read 'ticketSharing'">
  @if (tickets?.[0]?.id && (event?.id || event?.url)) {
    <app-social-media-preview
      [event]="event"
      [ticket]="tickets[0]"
      type="buy"
      (click)="copyAnnounceURL(tickets?.[0]?.id)" [matTooltip]="tts('copyMySaleAdLink')"
      [appTrack]="{category: 'sale', action: 'share-ticket', name: event.url, value: tickets?.[0]?.id, customDimension: '{ticketId: ' + tickets?.[0]?.id +'}'}"
      >
    </app-social-media-preview>
    @for (ticket of tickets; track ticket; let i = $index; let last = $last) {
      @if (tickets?.length > 1) {
        <p class="confirmation-page__text text-left fw-700">{{ tts('saleAdLink', {number: (tickets?.length > 1 ? (i+1) : '')}) }}</p>
      }
      <div class="d-flex --vertical-centered" [ngClass]="{'--horizontal-centered': tickets.length <= 1}">
        <span class="confirmation-page__text text-left selectable">{{ announceUrls?.[ticket?.id] }}</span>
        <button
          type="button"
          mat-icon-button
          matSuffix
          (click)="copyAnnounceURL(ticket?.id)"
          [appTrack]="{category: 'sale', action: 'share-ticket', name: event.url, value: ticket?.id, customDimension: '{ticketId: ' + ticket?.id +'}'}"
          [matTooltip]="tts('copyMySaleAdLink')">
          <mat-icon>content_copy</mat-icon>
        </button>
      </div>
      @if (!last) {
        <br>
        }
      }
    }
  </ng-container>
