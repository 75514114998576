import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';

interface LetContext {
  $implicit;
  appLet;
}

@Directive({
  selector: '[appLet]',
  standalone: true,
})
export class LetDirective {

  constructor(
    private viewContainer: ViewContainerRef,
    private templateRef: TemplateRef<LetContext>,
  ) {
  }

  @Input()
  set appLet(value) {
    this.viewContainer.clear();
    this.viewContainer.createEmbeddedView(this.templateRef, {
      $implicit: value,
      appLet: value,
    });
  }

}
