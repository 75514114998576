import { Injectable } from '@angular/core';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { TranslocoService } from '@ngneat/transloco';

@Injectable({
  providedIn: 'root',
})
export class PaginatorTranslation extends MatPaginatorIntl {

  constructor(
    private translocoService: TranslocoService,
  ) {
    super();
  }

  itemsPerPageLabel = this.translocoService.translate('dashboard.paginator.itemsPerPageLabel');
  nextPageLabel = this.translocoService.translate('dashboard.paginator.nextPageLabel');
  previousPageLabel = this.translocoService.translate('dashboard.paginator.previousPageLabel');
  firstPageLabel = this.translocoService.translate('dashboard.paginator.firstPageLabel');
  lastPageLabel = this.translocoService.translate('dashboard.paginator.lastPageLabel');

  getRangeLabel = (page: number, pageSize: number, length: number) => {
    if (length === 0 || pageSize === 0) {
      return `0 ${this.translocoService.translate('dashboard.paginator.on')} ${length}`;
    }
    length = Math.max(length, 0);
    const startIndex = page * pageSize;
    // If the start index exceeds the list length, do not try and fix the end index to the end.
    const endIndex = startIndex < length ?
      Math.min(startIndex + pageSize, length) :
      startIndex + pageSize;
    return `${startIndex + 1} - ${endIndex} ${this.translocoService.translate('dashboard.paginator.on')} ${length}`;
  };

}
