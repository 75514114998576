import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormControlOptions, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { map, Observable, startWith } from 'rxjs';
import { SelectOption } from '../../../../models';
import { FormField } from '../form';
import { TranslocoModule } from '@ngneat/transloco';
import { MatFormField, MatLabel, MatSuffix, MatHint, MatError } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { MatAutocompleteTrigger, MatAutocomplete } from '@angular/material/autocomplete';
import { AsyncPipe } from '@angular/common';
import { MatOption } from '@angular/material/core';
import { MatIcon } from '@angular/material/icon';


@Component({
  selector: 'app-autocomplete-form-field',
  templateUrl: './autocomplete-form-field.component.html',
  styleUrls: ['./autocomplete-form-field.component.scss'],
  standalone: true,
  imports: [
    TranslocoModule,
    MatFormField,
    MatLabel,
    MatInput,
    FormsModule,
    MatAutocompleteTrigger,
    ReactiveFormsModule,
    MatAutocomplete,
    MatOption,
    MatIcon,
    MatSuffix,
    MatHint,
    MatError,
    AsyncPipe,
  ],
})
export class AutocompleteFormFieldComponent extends FormField<SelectOption> implements OnInit, OnDestroy {
  @Input() public selections: {[id: string | number]: SelectOption};
  public filteredSelections: Observable<SelectOption[]>;

  constructor() {
    super();
  }

  ngOnInit() {
    super.ngOnInit();
    this.filteredSelections = this.control.valueChanges.pipe(
      startWith(''),
      map((value) => value ? Object.values(this.selections)
        .filter((s) => s.viewValue.toLowerCase().includes(
          value.hasOwnProperty('toLowerCase') || typeof value === 'string' ? value.toLowerCase() : value,
        )) : Object.values(this.selections)),
    );
  }

  displayFn(pick: SelectOption | number | string) {
    if (!pick) {
      return '';
    }
    if (typeof pick === 'string') {
      return pick;
    }
    if (typeof pick === 'number') {
      return pick.toString();
    }
    return pick.viewValue;
  }

  getFormControlOptions(): FormControlOptions {
    return {updateOn: 'change'};
  }

  /*
  TODO use display function to avoid saving whole object
  https://github.com/angular/components/issues/4863

  displayFn(filteredSelections): (pickId: string | number) => string {
    return (pickId: string | number) => {
      if (!pickId || !!filteredSelections) {
        return '';
      }
      console.log(new Date().getTime())
      // const picked = this.selections.find(option => option.value === pickId);
      const picked = this.selections[pickId];
      return picked ? picked.viewValue : 'error';
    };
  }
  */
}
