import { AbstractControl, AsyncValidatorFn, ValidationErrors } from '@angular/forms';
import { Observable, debounceTime, delay, distinctUntilChanged, map, take } from 'rxjs';
import { Event } from '../models';

export function templateNameUnicityValidator(event$: Observable<Event>): AsyncValidatorFn {
  return (
    control: AbstractControl,
  ) => event$.pipe(
    take(1),
    delay(300),
    map( (event: Event): ValidationErrors | null => {
      let isMultipleTemplateName = false;
      const duplicates = [];
      const names = event.TicketTemplates?.map((template) => template.name)?.filter((t) => !!t);
      names.forEach((name, index) => {
        if (names.indexOf(name) !== index) {
          isMultipleTemplateName = true;
          duplicates.push(name);
        }
      });
      return isMultipleTemplateName ? { templateNameDuplicates: duplicates } : null;
    }),
    debounceTime(300),
    distinctUntilChanged(),
  );
}
