// eslint-disable-next-line max-classes-per-file
import { UserProfile } from './user-profile.model';
import { Event } from './event.model';

export class RecurrenceRule {
  /**
   * Day of the month.
   */
  date: number;
  dayOfWeek: number;
  hour: number;
  minute: number;
  month: number;
  second: number;
  year: number;
  tz: string;
}

export enum AutomationsStatus {
  Updated = 'updated',
  Planned = 'planned',
  Created = 'created',
  Executed = 'executed',
  // not used status :
  Expired = 'expired',
  Canceled = 'canceled',
  Error = 'error',
}

export const automationsStatusInfos = {
  [AutomationsStatus.Updated]: {
    key : 'updated',
    name : 'mis à jour',
  },
  [AutomationsStatus.Planned]: {
    key : 'planned',
    name : 'planifié',
  },
  [AutomationsStatus.Created]: {
    key : 'created',
    name : 'créé',
  },
  [AutomationsStatus.Executed]: {
    key : 'executed',
    name : 'exécuté',
  },
  [AutomationsStatus.Expired]: {
    key : 'expired',
    name : 'expiré',
  },
  [AutomationsStatus.Canceled]: {
    key : 'canceled',
    name : 'annulé',
  },
  [AutomationsStatus.Error]: {
    key : 'error',
    name : 'erreur',
  },
};

export class Automation {
  public id!: number;
  public executions: number;
  public status: string;
  public task: string;
  public type: 'Date' | 'RecurrenceRule';
  public time: Date;// | RecurrenceRule;
  public modifiedBy: number;

  public readonly createdAt!: Date;
  public readonly updatedAt!: Date;

  // Foreign IDs
  public eventId: number;
  // public modifiedBy: number;

  public event?: Event;
  public modifiedByUser?: UserProfile;

  constructor(data: Partial<Automation>) {
    Object.assign(this, data);
    this.executions = this.executions ?? 0;
    this.event = data.event ? new Event(data.event) : undefined;
    this.modifiedByUser = data.modifiedByUser ? new UserProfile(data.modifiedByUser) : undefined;

    if (data.type === 'Date' && data.time) {
      this.time = data.time ? new Date(data.time) : null;
    }
    this.createdAt = data.createdAt ? new Date(data.createdAt) : null;
    this.updatedAt = data.updatedAt ? new Date(data.updatedAt) : null;

  }
}
