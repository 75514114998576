import { Injectable } from '@angular/core';
import { NativeDateAdapter } from '@angular/material/core';

// override firstDayOfWeek on calendar to get Monday instead of Sunday as first day of week
@Injectable({
  providedIn: 'root',
})
export class DatepickerTranslation extends NativeDateAdapter {
  getFirstDayOfWeek(): number {
    return 1;
  }
}
