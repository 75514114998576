export enum Format {
  Pdf = 'pdf',
  Csv = 'csv',
  Yurplan = 'yurplan',
  Weezevent = 'weezevent',
  Aparte = 'aparte',
  Trianon = 'trianon',
  Wilout = 'wilout',
  Json = 'json',
  DotXAparte = 'dot_x_aparte',
  Omniris = 'omniris',
}

export enum ExportStatus {
  Canceled = 'canceled',
  RemoveCanceled = 'remove-canceled',
  Buyer = 'buyer',
  Subscriber = 'subscriber',
  WaitListEntries = 'waitListEntries', // legacy
  UnpaidSellers = 'unpaid-sellers',
  Tickets = 'tickets',
  Waiters = 'waiters',
  Organizers = 'organizers',
  AutoPurchasers = 'auto-purchasers',
  Created = 'created', // only created
  CsvAll = 'csv-all', // all valid ticket from csv imported + resold
  CsvImported = 'csv-imported', // only imported csv list,
  CsvMovements = 'csv-movements', // all barcodes : sold and created
  Users = 'users',
  Payouts = 'payouts',
}
