@if (payinRefundable) {
  <ng-container *transloco="let tc read 'cardRefund'">
    <div class="small-container my-4">
      <div class="icon-text mb-3">
        <mat-icon class="custom-event">payments</mat-icon>
        <span class="confirmation-page__text icon-text__text fw-700">
          {{ tc('payoutMethod') }}
        </span>
      </div>
      <mat-card>
        <mat-card-content>
          <p class="text --normal text-left" [innerHTML]="tc('willBeRefunded')"></p>
          @if (displayPaidAfterEvent) {
            <p class="text --normal text-left mt-5">
              {{ 'sale.information.toSecureTransaction' | transloco }}
              <span class="fw-700">{{ 'sale.information.onceTheEventIsOver' | transloco }}</span>.
            </p>
          }
        </mat-card-content>
      </mat-card>
    </div>
  </ng-container>
} @else {
  <app-check-seller [userBanking]="userBanking" [userKyc]="userKyc"></app-check-seller>
}

