// eslint-disable-next-line max-classes-per-file
import { UserProfile } from './user-profile.model';

export class UserInet {
  public id!: number;

  public connectionCount: number;

  public readonly createdAt!: Date;
  public readonly updatedAt!: Date;

  // Foreign IDs
  public User?: UserProfile;
  public Inet?: Inet;

  constructor(options?: any) {
    Object.assign(this, options);
    this.createdAt = options.createdAt ? new Date(options.createdAt) : undefined;
    this.updatedAt = options.updatedAt ? new Date(options.updatedAt) : undefined;
    this.User = options?.User ? new UserProfile(options.User) : undefined;
    this.Inet = options?.Inet ? new Inet(options.Inet) : undefined;
  }
}

export class Inet {
  inet: string;
  blockedCount: number;
  comments: string;
  isBlocked: boolean;
  isWhitelisted: boolean;

  createdAt: Date;
  updatedAt: Date;

  Users?: UserProfile[];
  UsersInet?: UserInet[];

  constructor(options?: any) {
    Object.assign(this, options);
    this.createdAt = options.createdAt ? new Date(options.createdAt) : undefined;
    this.updatedAt = options.updatedAt ? new Date(options.updatedAt) : undefined;
    this.Users = options?.Users ? options.users.map( (u) => new UserProfile(u)) : undefined;
    this.UsersInet = options?.UsersInet ? options.UsersInet.map( (i) => new UserInet(i)) : undefined;
  }

}
