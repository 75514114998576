import { UntypedFormGroup, ValidationErrors } from '@angular/forms';

export function expiryDateValidator(control: UntypedFormGroup): ValidationErrors | null {
  const value = control.get('expiryDate').value;
  if (!value) {
    return null;
  }
  // if year is not set yet, we don't return any error for the moment. Required year error will already show up
  const month = value.split('/')[0];
  if (month[0] === 0 ){
    month.slice(1);
  }
  let year = value.split('/')[1];
  if (!year || year?.length < 2) {
    return null;
  } else {
    year = '20' + year;
  }
  const currentDate = new Date();
  const currentMonth = currentDate.getMonth() + 1;
  const currentYear = currentDate.getFullYear();
  if (parseInt(year, 10) > currentYear || (parseInt(year, 10) === currentYear && month >= currentMonth)) {
    return null;
  } else {
    return { validExpiryDate: true };
  }
}

export function creditCardFormatValidator(control: UntypedFormGroup): ValidationErrors | null {
  let value = control.get('cardNumber').value;
  if (value === undefined) {
    return null;
  }

  // accept only digits, dashes or spaces
  if (/[^0-9-\s]+/.test(value)) {
    return null;
  }

  // Luhn Algorithm
  let nCheck = 0;
  let nDigit = 0;
  let bEven = false;
  value = value.replace(/\D/g, '');

  for (let n = value.length - 1; n >= 0; n--) {
    const cDigit = value.charAt(n);
    nDigit = parseInt(cDigit, 10);

    if (bEven) {
      nDigit = 2 * nDigit;
      if (nDigit > 9) {
        nDigit -= 9;
      }
    }

    nCheck += nDigit;
    bEven = !bEven;
  }
  const hasError = !((nCheck % 10) === 0);
  return hasError ? { validCreditCardFormat : true } : null ;
}

export function creditCardAcceptableValidator(control: UntypedFormGroup): ValidationErrors | null {
  const value = control.get('cardNumber').value?.replace(/\s/g, ''); // value without spaces
  if (value === undefined) {
    return null;
  }

  // accept only digits
  if (/[^0-9]+/.test(value)) {
    return null;
  }

  /* set card_number_regex_pattern */
  const visaRegEx = '(?:4[0-9]{12}(?:[0-9]{3})?)';
  const mastercardRegEx = '(?:5[1-5][0-9]{2}|222[1-9]|22[3-9][0-9]|2[3-6][0-9]{2}|27[01][0-9]|2720)[0-9]{12}';
  const cardNumberRegexPattern = new RegExp(visaRegEx + '|' + mastercardRegEx);

  const hasError = !cardNumberRegexPattern.test(value);

  return hasError ? { validCreditCardAcceptable : true } : null ;
}
