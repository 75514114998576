<div class="form" [formGroup]="formGroup">
  @for (field of customBuyerFields; track field) {
    @if (field.type === 'string') {
      <mat-form-field class="form__field mb-2" >
        <input [appTrim]="true" [formControlName]="field.name" matInput [placeholder]="field.name" [required]="field.isRequired">
        @if (getError(field.name)) {
          <mat-error>{{ 'formErrors.' + getError(field.name) | transloco }}</mat-error>
        }
      </mat-form-field>
    }
    @if (field.type === 'number') {
      <mat-form-field class="form__field mb-2">
        <input [formControlName]="field.name" type="number" (onWheel)="$event?.preventDefault()" matInput [placeholder]="field.name" [required]="field.isRequired">
        @if (getError(field.name)) {
          <mat-error>{{ 'formErrors.' + getError(field.name) | transloco }}</mat-error>
        }
      </mat-form-field>
    }
    @if (field.type === 'boolean') {
      @if (field.type === 'boolean') {
        <mat-checkbox class="form__field mb-2" [name]="field.name" [formControlName]="field.name" [required]="field.isRequired">
          <p class="checkbox-text">{{field.name}}</p>
        </mat-checkbox>
      }
      @if (getError(field.name)) {
        <mat-error>{{ 'formErrors.' + getError(field.name) | transloco }}</mat-error>
      }
    }
    @if (field.type === 'date') {
      <p class="mb-3">{{ field.name }}</p>
      <div [formGroupName]="field.name" class="form --one-line-xs full-width" *transloco="let tu; read 'timeUnit'">
        <mat-form-field class="form__field --day">
          <mat-label>{{ tu('long.day') }}</mat-label>
          <mat-select matNativeControl formControlName="day" (selectionChange)="onDateChange(field.name)" required>
            @for (day of availableDays; track day) {
              <mat-option [value]="day">{{day}}</mat-option>
            }
          </mat-select>
          @if (getError(field.name + 'day')) {
            <mat-error>{{ 'formErrors.' + getError(field.name + 'day') | transloco }}</mat-error>
          }
        </mat-form-field>
        <mat-form-field class="form__field" *transloco="let tcm; read 'calendar.months'">
          <mat-label>{{ tu('long.month') }}</mat-label>
          <mat-select matNativeControl formControlName="month" (selectionChange)="onDateChange(field.name)" required>
            @for (month of availableMonths; track month; let i = $index) {
              <mat-option [value]="i+1">{{tcm(month)}}</mat-option>
            }
          </mat-select>
          @if (getError(field.name + 'month')) {
            <mat-error>{{ 'formErrors.' + getError(field.name + 'month') | transloco }}</mat-error>
          }
        </mat-form-field>
        <mat-form-field class="form__field">
          <mat-label>{{ tu('long.year') }}</mat-label>
          <mat-select matNativeControl formControlName="year" (selectionChange)="onDateChange(field.name)" required>
            @for (year of availableYears; track year) {
              <mat-option [value]="year">{{year}}</mat-option>
            }
          </mat-select>
          @if (getError(field.name + 'year')) {
            <mat-error>{{ 'formErrors.' + getError(field.name + 'year') | transloco }}</mat-error>
          }
        </mat-form-field>
      </div>
      @if (getError(field.name)) {
        <mat-error>{{ 'formErrors.' + getError(field.name) | transloco }}</mat-error>
      }
    }
  }

</div>
