
export enum ImportType {
  Reelax = 'Reelax',
  Pims = 'pims',
  Manual = 'manual',
  Digitick = 'digitick',
  Dispobillet = 'dispobillet',
  Aparte = 'aparte',
  Soticket = 'soticket',
  Ticketnet = 'ticketnet',
  Dat = 'dat',
  Autre = 'autre',
  Weezevent = 'weezevent',
  Billetweb = 'billetweb',
  Yurplan = 'yurplan',
  Festicket = 'festicket',
  Destination = 'destination',
}

export class ValidTicket {
  public id!: number;

  public access: string;
  public category: string;
  public codeBarre: string;
  public fiscalNumber: string;
  public gate: string;
  public nom: string;
  public prenom: string;
  public purchaseDate: string;
  public row: string;
  public seat: string;
  public support: string;
  public type: string;
  public zone: string;
  public importType: ImportType;
  public readonly createdAt!: Date;
  public readonly updatedAt!: Date;

  // Foreign keys
  public eventId: number;

}
