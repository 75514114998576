import {
  getCurrencySymbol,
  Category,
  Currencies,
  SeatDetails,
} from '.';

export const priceFormatter = ( arrayOfIntPrice: (string | number)[] ) => (+arrayOfIntPrice.reduce( (sum, p) => +sum + parseInt(p as string, 10), 0) / 100).toFixed(2).replace('.', ',');

export const sellingPricesFormatter = ( minimalSalePrice: number, maximalSalePrice: number , currency: Currencies) => {
  if (minimalSalePrice === maximalSalePrice) {
    return priceFormatter([minimalSalePrice]) + ` ${getCurrencySymbol(currency)} uniquement`;
  } else {
    return `de ${priceFormatter([minimalSalePrice])} ${getCurrencySymbol(currency)} à ${priceFormatter([maximalSalePrice])} ${getCurrencySymbol(currency)}`;
  }
};

export const percentageFormatter = ( fullNumber: number ) => (Math.round(fullNumber) / 100).toString().replace('.', ',') + ' %';

export const seatFormatter = (
  seatDetails,
  translation: Record<string, string>,
  displayedSeatingInformation?: Category['displayedSeatingInformation'],
  locale?: string,
) => Object.keys(seatDetails)?.filter( (key) => seatDetails[key] &&
    (displayedSeatingInformation && displayedSeatingInformation[key] || !displayedSeatingInformation),
)?.map((key) => {
  if (key === 'dateStart' && translation && translation[key]) {
    return translation[key] + ' ' + new Date(seatDetails[key]).toLocaleString(locale);
  }
  if (translation && translation[key]) {
    return (translation[key]) + ' ' + seatDetails[key];
  }
  return (key) + ' ' + seatDetails[key];
})?.join(' - ');

export const filterDisplayedSeatDetails = (
  seatDetails,
  displayedSeatingInformation: Category['displayedSeatingInformation'],
) => {
  const filteredSeatDetails = {};
  Object.entries(displayedSeatingInformation).forEach(([key, value]) => {
    if (value) {
      filteredSeatDetails[key] = seatDetails[key];
    }
  });
  return filteredSeatDetails;
};

export const humanReadableSeatDetails = (
  seatDetails: SeatDetails,
  translation: Record<string, string>,
  displayedSeatingInformation?: Category['displayedSeatingInformation'],
  locale?: string,
): string => seatFormatter(seatDetails, translation, displayedSeatingInformation, locale);
