@if (event) {
  <mat-card>
    <mat-card-content class="p-0">
      <a
        class="platform-link"
        [routerLink]="['/e/n/', event.url]"
        routerLinkActive="--active"
        [routerLinkActiveOptions]="{exact: true}"
        >
        <div class="info">
          <div class="info__column">
            <div class="info__column__text">
              <p class="info__column__text__name">
                {{event.name}}
              </p>
              <p class="info__column__text__location">
                @if (event.PostalAddress?.id) {
                  {{ event.PostalAddress.shortAddress }}
                } @else {
                  {{event.location}}
                }
                <ng-template #location>
                  {{event.location}}
                </ng-template>
              </p>
              <p class="info__column__text__date">
                <span>{{event.dateStart | date: 'dd.MM.yyyy'}}</span>
                @if (event.dateEnd) {
                  <span> - {{event.dateEnd | date: 'dd.MM.yyyy'}}</span>
                }
              </p>
            </div>
          </div>
          <div class="info__column">
            <img [src]="event.imgUrl || 'assets/img/default-event-cover.jpg'" class="info__column__img">
          </div>
        </div>
      </a>
    </mat-card-content>
  </mat-card>
}
