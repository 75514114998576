import { isPlatformBrowser } from '@angular/common';
import { Directive, ElementRef, HostBinding, HostListener, Inject, Input, PLATFORM_ID } from '@angular/core';
import { NotificationService } from '../../services';
import { TranslocoService } from '@ngneat/transloco';

@Directive({
  selector: '[appClipboard]',
  standalone: true,
})
export class ClipboardDirective {
  @HostBinding('style.cursor')  cursor:string = 'pointer';
  @Input('appClipboard') text?: string;
  private isBrowser = false;

  constructor(
    private notificationService: NotificationService,
    private translocoService: TranslocoService,
    private elementRef: ElementRef,
    @Inject(PLATFORM_ID) platformId,
  ) {
    this.isBrowser = isPlatformBrowser(platformId);
  }

  @HostListener('click')
  onClick() {
    if (this.isBrowser) {
      const textToCopy = this.text ?? this.elementRef.nativeElement.innerText;
      navigator?.clipboard?.writeText(textToCopy).then(() => {
        this.notificationService.open(this.translocoService.translate('clipboard.wellCopied'));
      }, (err) => {
        this.notificationService.open(this.translocoService.translate('clipboard.copyError'));
      });
    }
  }

}
