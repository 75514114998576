import { Directive, ElementRef, HostBinding, HostListener, Input } from '@angular/core';
import { captureException } from '@sentry/angular';

@Directive({
  selector: '[appDragScroll]',
  standalone: true,
})
export class DragScrollDirective {
  @HostBinding('style.cursor') cursorStyle = 'grab';
  @Input() interval?: number; // NodeJs.Interval;
  private mouseDown = false;
  private dragOffset = 0;
  private isDebug = false;

  constructor(
    private el: ElementRef<HTMLElement>,
  ) {
  }

  private debug(action: string, mouvement?: number) {
    if (this.isDebug && this.mouseDown) {
      // eslint-disable-next-line no-console
      console.log('[drag-scroll]', action, mouvement, ' ele X position', this.el.nativeElement?.firstElementChild?.getBoundingClientRect()?.left);
    }
  }

  // we listen on the whole window in order to keep scroll if mouse leaves host component
  @HostListener('window:mousemove', ['$event']) onMouseEnter(event: MouseEvent) {
    this.move(event?.movementX);
    this.debug('window:mousemove', event?.movementX);
  }

  @HostListener('mousedown', ['$event']) onMouseDown() {
    this.startDragging();
    this.debug('mousedown');
  }

  @HostListener('window:mouseup', ['$event']) onMouseUp() {
    this.debug('window:mouseup');
    this.stopDragging();
  }

  // we listen to drag event in case user moused down on a draggable item (ex: picture)
  @HostListener('window:dragover', ['$event']) onDrag(event: DragEvent) {
    const xMove = event.clientX;
    this.offsetMove(xMove);
    this.debug('window:dragover', xMove);
  }

  @HostListener('window:dragend', ['$event']) onDragEnd(event: DragEvent) {
    this.dragOffset = undefined;
    this.debug('window:dragend');
    this.stopDragging();
  }

  // For mobile UI action
  @HostListener('touchstart', ['$event']) onTouchStart(event: TouchEvent) {
    this.startDragging();
    this.debug('touchstart');
  }

  @HostListener('window:touchmove', ['$event']) onTouchMove(event: TouchEvent) {
    const xMove = event.changedTouches?.[0]?.clientX;
    this.offsetMove(xMove);
    this.debug('window:touchmove', xMove);
  }

  @HostListener('window:touchend', ['$event']) onTouchEnd(event: TouchEvent) {
    this.dragOffset = undefined;
    this.debug('window:touchend');
    this.stopDragging();
  }

  private startDragging() {
    if (this.interval) {
      try {
        clearInterval(this.interval);
      } catch (error) {
        captureException(error);
      }
    }
    this.mouseDown = true;
  }

  private stopDragging() {
    this.mouseDown = false;
  }

  private offsetMove(xMove: number) {
    // save initial position
    if (!this.dragOffset) {
      this.dragOffset = xMove;
    } else {
      const dragX = Math.round((xMove - this.dragOffset));
      // save new position on each move
      this.dragOffset = xMove;
      this.move(dragX);
    }
  }

  private move(offset: number) {
    if(!this.mouseDown || offset === 0) {
      return;
    }
    this.el.nativeElement.scrollBy({
      left: -offset,
      top: 0,
    });
  }

}
