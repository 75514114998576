import { Injectable } from '@angular/core';
import { StorageService } from '../services';

@Injectable({
  providedIn: 'root',
})
export class TokenStore {
  private storageKey = '[token]';
  private tmpToken: string;

  constructor(
    private storageService: StorageService,
  ) {
  }

  getToken() {
    if (this.tmpToken) {
      return Promise.resolve(this.tmpToken);
    }
    return this.storageService.getItem<string>(this.storageKey).then( (token) => {
      this.tmpToken = token;
      return token;
    });
  }

  setToken(token: string) {
    this.tmpToken = token;
    this.storageService.setItem(this.storageKey, token);
  }

  cleanToken() {
    this.storageService.removeItem(this.storageKey);
  }

}
