import { AbstractControl, AsyncValidatorFn, ValidationErrors } from '@angular/forms';
import { map, debounceTime, delay, Observable, take, distinctUntilChanged } from 'rxjs';
import { Event, Platforms } from '../models';

export function categoryAsyncValidator(
  event$: Observable<Event>,
  eventPlatformWithForeignIds: Platforms[],
): AsyncValidatorFn {
  return (
    control: AbstractControl,
  ) => event$.pipe(
    take(1),
    delay(100),
    map( (event): ValidationErrors | null => {
      const categoryError = event.Categories
        .filter( (c) => c.hasErrors(eventPlatformWithForeignIds)?.length > 0)
        .map( (c) => `${c.name} (${c.id}) : ${c.hasErrors(eventPlatformWithForeignIds).join(', ')}`)
        .map( (errorMessage) => errorMessage + '<br>');
      return categoryError?.length > 0 ? { categoryError} : null;
    }),
    debounceTime(300),
    distinctUntilChanged(),
  );
}
