import { Input, Directive } from '@angular/core';

interface InputOptions {
  label: string;
  iconName?: string;
  hint?: string;
}

const defaultOptions = {
  label: '',
  hint: '',
  icon: '',
  required: false,
};

// Component is a special type of Directive. So it is okay to inherit from an abstract Directive.
@Directive()
// eslint-disable-next-line @angular-eslint/directive-class-suffix
export abstract class InputDirective {
  private _options: Partial<InputOptions> = {};

  // OPTIONS INPUT SETTER / GETTER
  @Input()
  set options(value: Partial<InputOptions>) {
    // eslint-disable-next-line no-underscore-dangle
    this._options = {
      ...defaultOptions,
      ...value,
    };
    // run options dependent code, i.e class bindings etc ...
    this.onOptionsChange();
  }
  get options(): Partial<InputOptions> {
    // eslint-disable-next-line no-underscore-dangle
    return this._options;
  }

  constructor() {
  }

  protected onOptionsChange() {
  }
}
