import { UntypedFormGroup, ValidationErrors } from '@angular/forms';

export function legalAgeValidator(control: UntypedFormGroup): ValidationErrors | null {
  const day = control.get('day').value;
  const month = control.get('month').value;
  const year = control.get('year').value;

  if (day === '' || month === '' || year === '') {
    return null;
  }

  // javascript months start at 0 and user values start at 1 so we need to delete this offset by substracting 1
  const birthday: Date = new Date(year, month - 1, day);
  const today: Date = new Date();

  let age: number = today.getFullYear() - birthday.getFullYear();
  const monthDiff: number = today.getMonth() - birthday.getMonth();
  const dayDiff: number = today.getDate() - birthday.getDate();
  if (monthDiff < 0 || (monthDiff === 0 && dayDiff < 0)) {
    age = age - 1;
  }

  return age < 18 ? { legalAge: true } : null;
}
