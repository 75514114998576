import { Currencies, Language, LanguageMap, TicketSaleData } from '../../models';
import removeMd from 'remove-markdown';
import { environment } from '../../../environments/environment';
import chardet from 'chardet';

interface Icompared<A, B> {
  exist: Array < {new: A, old: B} >;
  missing: A[];
  removed: B[];
}


// Functions to calculate contrast ration following http://www.w3.org/TR/2008/REC-WCAG20-20081211
const getLum = (rgb) => {
  let i;
  let x;
  const a = []; // so we don't mutate
  for (i = 0; i < rgb.length; i++) {
    x = rgb[i] / 255;
    a[i] = x <= 0.03928 ? x / 12.92 : Math.pow((x + 0.055) / 1.055, 2.4);
  }
  return 0.2126 * a[0] + 0.7152 * a[1] + 0.0722 * a[2];
};
const HEX_RGB = (str) => {
  const RE_HEX_RGB = /[a-f0-9]{6}|[a-f0-9]{3}/i;
  const match = str.toString(16).match(RE_HEX_RGB);
  if (!match) {
    return [0, 0, 0];
  }

  let colorString = match[0];

  // Expand 3 character shorthand triplet e.g. #FFF -> #FFFFFF
  if (match[0].length === 3) {
    const Astr = colorString.split('');
    for (let i = 0; i < Astr.length; i++) {
      const ch = Astr[i];
      Astr[i] = ch + ch;
    }
    colorString = Astr.join('');
  }

  const integer = parseInt(colorString, 16);

  return [
    // eslint-disable-next-line no-bitwise
    (integer >> 16) & 0xFF,
    // eslint-disable-next-line no-bitwise
    (integer >> 8) & 0xFF,
    // eslint-disable-next-line no-bitwise
    integer & 0xFF,
  ];
};

export class Utils {

  static transparentToOpaque(foregroundColorHex: string, backgroundColorHex?: string) {
    const rgbColor = Utils.hexToRgba(foregroundColorHex);
    const foregroundColor = [rgbColor.r, rgbColor.b, rgbColor.g];
    let opacity = rgbColor.a ? rgbColor.a : 1;
    if (opacity > 1) {
      opacity = opacity / 100;
    }
    const backgroundColorObj = backgroundColorHex ?
      Utils.hexToRgba(backgroundColorHex) : {r: 255, g: 255, b: 255, a: 1};
    const backgroundColor = [backgroundColorObj.r, backgroundColorObj.b, backgroundColorObj.g, backgroundColorObj.a];
    return foregroundColor.map( (colFg, idx) => opacity * colFg + ( 1 - opacity ) * backgroundColor[idx] );
  }

  static rgbaToHex = (red: number, green: number, blue: number, alpha: number) => {
    let r = Math.round(red).toString(16);
    let g = Math.round(green).toString(16);
    let b = Math.round(blue).toString(16);
    let a = alpha ? Math.round(alpha * 255).toString(16) : undefined;
    if (r.length === 1) {
      r = '0' + r;
    }
    if (g.length === 1) {
      g = '0' + g;
    }
    if (b.length === 1) {
      b = '0' + b;
    }
    if (a?.length === 1) {
      a = '0' + a;
    }
    return '#' + r + g + b + (a ? a : '');
  };

  static hexToRgba = (hex: string) => {
    let alpha = false;
    let h = hex.slice(hex.startsWith('#') ? 1 : 0);
    if (h.length === 3) {
      h = [...h].map((x) => x + x).join('');
    } else if (h.length === 8) {
      alpha = true;
    }
    const base16h = parseInt(h, 16);
    return {
      // eslint-disable-next-line no-bitwise
      r: base16h >>> (alpha ? 24 : 16),
      // eslint-disable-next-line no-bitwise
      g: (base16h & (alpha ? 0x00ff0000 : 0x00ff00)) >>> (alpha ? 16 : 8),
      // eslint-disable-next-line no-bitwise
      b: (base16h & (alpha ? 0x0000ff00 : 0x0000ff)) >>> (alpha ? 8 : 0),
      // eslint-disable-next-line no-bitwise
      a: alpha ? base16h & 0x000000ff : undefined,
    };
  };

  static currenciesOfCategories = (categories: Array<{currency: Currencies}>) => {
    const currencies = new Set<Currencies>();
    categories?.forEach( (c) => currencies.add(c?.currency));
    return [...currencies];
  };

  static nameRegex = new RegExp('^[A-zÀ-ú]+(?:[-\' ]+[A-zÀ-ú]+)*$');

  static uniqBy<T>(a: T[], key: (o: T) => any) {
    const seen = new Set();
    return a.filter((item) => {
      const k = key(item);
      return seen.has(k) ? false : seen.add(k);
    });
  }

  static isBirthdayToday(birthday: Date) {
    const today = new Date();
    return birthday && birthday.getDate() === today.getDate() && birthday.getMonth() === today.getMonth();
  }

  static stringToURLallowed = (name: string) => {
    if (!name) {
      return 'undef';
    }
    return name.replace(/\s+/g, '')?.replace(/([^a-z0-9]+)/gi, '-') || 'undef';
  };

  static getMangopayProfile = (mangopayId: string) => {
    if (!mangopayId) {
      return undefined;
    }
    return `${environment.mangopayDashboard}User/${mangopayId}/Details`;
  };

  static base64ToBlob(data: string) {
    let mimeString = '';
    let raw;
    let i;
    const rImageType = /data:(image\/.+);base64,/;

    raw = data.replace(rImageType, (header, imageType) => {
      mimeString = imageType;
      return '';
    });

    raw = atob(raw);
    const rawLength = raw.length;
    const uInt8Array = new Uint8Array(rawLength);

    for (i = 0; i < rawLength; i += 1) {
      uInt8Array[i] = raw.charCodeAt(i);
    }

    return new Blob([uInt8Array], {type: mimeString});
  }

  static blobToFile = (blob: Blob, filename: string): File => new File([blob], filename, {type: blob.type ?? 'image/jpeg'});

  static arrayCompare = <A, B>(
    newArray: A[],
    oldArray: B[],
    compare: (A: A, B: B) => boolean, keepOldArrayMatch = false,
  ): Icompared<A, B> => {
    const oldMatched = [];
    const compared = newArray.reduce( (acc, elOne) => {
      const existIndex = acc.removed.findIndex((elTwo) => compare(elOne, elTwo));
      if (existIndex === -1) {
        acc.missing.push(elOne);
      } else {
        acc.exist.push({new: elOne, old: acc.removed[existIndex]});
        if (keepOldArrayMatch) {
          oldMatched.push(acc.removed[existIndex]);
        } else {
          acc.removed.splice(existIndex, 1);
        }
      }
      return acc;
    }, {exist: [], missing: [], removed: oldArray});
    if (keepOldArrayMatch) {
      compared.removed = compared.removed.filter(
        (rem) => oldMatched.findIndex( (matched) => compare(matched, rem)) < 0);
      return compared;
    }
    return compared;
  };

  static ticketsToQueryString = (tickets: Partial<TicketSaleData>[]) => {
    const element = (index: number, type: 'f' | 'l' | 'b', value: string) => {
      if (value) {
        return `&t[${index}][${type}]=${encodeURIComponent(value)}`;
      }
      return '';
    };
    return tickets
      .map( (t, i) => `${element(i, 'f', t.originalFirstName)}${element(i, 'l', t.originalLastName)}${element(i, 'b', t.originalTicketNumber)}`)
      .join('')
      .substring(1);
  };

  static msToTime = (milliseconds: number, translationObj) => {
    let time = '';
    const d = Math.floor((milliseconds / (1000 * 60 * 60 * 24)));
    const h = Math.floor((milliseconds / (1000 * 60 * 60)) % 24);
    const m = Math.floor((milliseconds / (1000 * 60)) % 60);
    const s = Math.floor((milliseconds / 1000) % 60);
    const ms = milliseconds % 1000;
    if (d > 0) {
      time += `${d} ${translationObj.day} `;
    }
    if (h > 0) {
      time += `${h} ${translationObj.hour} `;
    }
    if (m > 0) {
      time += `${m} ${translationObj.minute} `;
    }
    if (s > 0) {
      time += `${s} ${translationObj.second} `;
    }
    if (ms > 0) {
      time += `${ms} ${translationObj.millisecond}`;
    }
    return time;
  };

  static isToday = (someDate: Date) => {
    const today = new Date() ;
    return someDate.getUTCDate () === today.getUTCDate() &&
      someDate.getUTCMonth() === today.getUTCMonth() &&
      someDate.getUTCFullYear() === today.getUTCFullYear();
  };

  static getFullDay(date: Date): string {
    // we add 24 hours minus 1 milisecond
    return new Date(date.getTime() + (24 * 60 * 60 * 1000 - 1)).getTime().toString();
  }

  /**
   * @param cardDate is formated like "0212".
   * The two first digits matches the month, and the two last the year of the card expiration date.
   * @returns true if card will expire before waitlist expiration date, else false
   */
  static cardWillExpire = (cardDate: string, waitlistDate: Date) =>
    // The '20' workaround does not work if cardDate > 2099 and if waitlistDate < 2100.
    // To fix when Reelax Tickets will celebrate its 70 years old !
    waitlistDate && (waitlistDate.getFullYear() > +('20' + cardDate.slice(-2)) ||
      (waitlistDate.getFullYear() === +('20' + cardDate.slice(-2)) && (waitlistDate.getMonth() + 1) > +cardDate.slice(0,2)));


  static readFile(file: File, fileType = 'dataUrl'): Promise<string | ArrayBuffer> {
    return new Promise((resolve, reject) => {
      const fr = new FileReader();
      fr.onload = () => {
        if (fileType === 'text') {
          /**
           * For text file we use 2 steps to make sure the file is opened with the right encoding
           */
          const array = new Uint8Array(fr.result as ArrayBuffer);
          const encoding = chardet.detect(array);
          const decoder = new TextDecoder(encoding); // always utf-8
          resolve(decoder.decode(array));
        }
        resolve(fr.result);
      };
      fr.onerror = reject;
      if (fileType === 'dataUrl') {
        fr.readAsDataURL(file);
      } else if (fileType === 'text') {
        fr.readAsArrayBuffer(file);
      }
    });
  }

  static readFileAsArrayBuffer(file: File): Promise<ArrayBuffer> {
    return new Promise((resolve, reject) => {
      const fr = new FileReader();
      fr.onload = () => {
        resolve(fr.result as ArrayBuffer);
      };
      fr.onerror = reject;
      fr.readAsArrayBuffer(file);
    });
  }

  static findFirstMatch(exemple: string, options: string[]): {match: string, relevance: number}  {
    const results = [];
    for (const option of options) {
      const relevance = Utils.stringSimilarity(exemple, option);
      const obj = {match: option, relevance};
      results.push(obj);
    }

    return results.sort((a, b) => b.relevance - a.relevance)[0];
  }

  private static stringSimilarity = (str1: string, str2: string) => {
    if (str1.length > 0 && str2.length > 0) {
      const pairs1 = Utils.getBigrams(str1);
      const pairs2 = Utils.getBigrams(str2);
      const union = pairs1.length + pairs2.length;
      let hitCount = 0;
      for (const x of pairs1) {
        for (const y of pairs2) {
          if (x === y) {
            hitCount++;
          }
        }
      }
      if (hitCount > 0) {
        return ((2.0 * hitCount) / union);
      }
    }
    return 0.0;
  };

  private static getBigrams = (stringInput) => {
    const s = stringInput.toLowerCase();
    const v = new Array(s.length - 1);
    for (let i = 0; i < v.length; i++) {
      v[i] = s.slice(i, i + 2);
    }
    return v;
  };

  static removeMd(str: string) {
    return removeMd(str, {
      stripListLeaders: false , // strip list leaders (default: true)
      listUnicodeChar: '',     // char to insert instead of stripped list leaders (default: '')
      gfm: false,               // support GitHub-Flavored Markdown (default: true)
      useImgAltText: true,      // replace images with alt-text, if present (default: true)
    });
  }

  static resizeImgBase64(imgBase64: string, max_height: number, max_width: number) {
    return new Promise<string>((resolve, reject) => {
      const imgBlob = Utils.base64ToBlob(imgBase64);
      const blobURL = window.URL.createObjectURL(imgBlob); // and get it's URL
      const image = new Image();
      image.src = blobURL;
      image.onload = () => {
        resolve(Utils.resizeCanvasImage(image, max_height, max_width));
      };
      image.onerror = reject;
    });
  }

  static resizeCanvasImage(img: HTMLImageElement, max_height: number, max_width: number) {
    const canvas = document.createElement('canvas');
    let width = img.width as number;
    let height = img.height as number;

    // calculate the width and height, constraining the proportions
    if (width > height) {
      if (width > max_width) {
        // height *= max_width / width;
        height = Math.round(height *= max_width / width);
        width = max_width;
      }
    } else {
      if (height > max_height) {
        // width *= max_height / height;
        width = Math.round(width *= max_height / height);
        height = max_height;
      }
    }

    // resize the canvas and draw the image data into it
    canvas.width = width;
    canvas.height = height;
    const ctx = canvas.getContext('2d');
    ctx.drawImage(img, 0, 0, width, height);

    return canvas.toDataURL('image/jpeg', 0.7); // get the data from canvas as 70% JPG (can be also PNG, etc.)
  }

  static keepOrder(a, b) {
    return a;
  }

  static simpleId() {
    return Date.now().toString(36) + Math.random().toString(36).substring(2);
  }

  /**
   * @description contrastRatio function returns a number between 1 and 21,
   * which serves as the first number in the ratio.
   * @param hex1 hexa color without the # character
   * @param hex2 hexa color without the # character
   * */
  static contrastRatio(hex1, hex2) {
    if (!hex1 || !hex2) {
      return 10; // default value average
    }
    const l1 = getLum(HEX_RGB(hex1));
    const l2 = getLum(HEX_RGB(hex2));
    return (Math.max(l1, l2) + 0.05) / (Math.min(l1, l2) + 0.05);
  }

  /** @description returns last url part without queryParams */
  static getLastUrlPart(url) {
    return url?.split('/').pop().split('?')[0];
  }

  /** @returns valid category name, …. are banned */
  static matCatToKey(categoryName: string) {
    return categoryName.replace(/[….]/g, '-');
  }

  static hasOneTanslation(message: LanguageMap, languages: typeof Language) {
    return message && languages && Object.values(languages)?.some((l) => message[l]);
  }

  static getTranslation(languageMap: LanguageMap, locale: string) {
    if (!languageMap) {
      return;
    }
    return languageMap[locale]
    ?? languageMap[Language.frFR]
    ?? languageMap[Language.enUS]
    ?? languageMap[Language.esES];
  }

}
