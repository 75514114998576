import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Currencies, WaitListPurchase } from '../models';

@Injectable({providedIn: 'root'})
export class WaitListPurchaseStore {
  private readonly wlPurchase = new BehaviorSubject<WaitListPurchase[]>([]);
  public storageKey = 'waitlistpurchase';

  constructor(
  ) {}

  public getWLPurchase(eventId: number, currency: Currencies) {
    return this.wlPurchase.getValue().find( (wlp) => wlp.eventId === eventId && wlp.currency === currency);
  }

  public getEventWLPurchase(eventId: number) {
    return this.wlPurchase.getValue().filter( (wlp) => wlp.eventId === eventId);
  }

  private getAllWLPurchase() {
    return this.wlPurchase.getValue();
  }

  public addWLPurchase(wlPurchase: WaitListPurchase) {
    wlPurchase.purchaseAutoEnabled = true;
    const allWLP = this.getAllWLPurchase();
    if (allWLP) {
      this.wlPurchase.next([
        wlPurchase,
        ...allWLP.filter( (oldWLP) => oldWLP.eventId !== wlPurchase.eventId && oldWLP.currency !==wlPurchase.currency),
      ]);
    }
    this.wlPurchase.next([wlPurchase]);
  }

  public removeWLPurchase(eventId: number, currency: Currencies) {
    const wlPurchase = this.getWLPurchase(eventId, currency);
    if (wlPurchase) {
      wlPurchase.purchaseAutoEnabled = false;
      this.wlPurchase.next([
        wlPurchase,
        ...this.getAllWLPurchase().filter(
          (oldWLP) => oldWLP.eventId !== wlPurchase.eventId && oldWLP.currency !==wlPurchase.currency,
        ),
      ]);
    }
  }

  public loadWLPurchase(wlPurchases: WaitListPurchase[]) {
    if (!wlPurchases || !Array.isArray(wlPurchases)) {
      this.wlPurchase.next([]);
    }
    this.wlPurchase.next(wlPurchases);
  }

}
