<div id="event" class="organizers">
  <div class="organizers__carousel">

    <app-carousel-scroll
      [items]="orgaPartners"
      [itemSize]="itemSize"
     >
      <ng-template let-item="item">
        <app-partner-card [partner]="item" class="organizers__carousel__item"></app-partner-card>
      </ng-template>
    </app-carousel-scroll>

  </div>
</div>
