import { Injectable, OnDestroy } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslocoService } from '@ngneat/transloco';
import { NoopScrollStrategy } from '@angular/cdk/overlay';
import { Subscription } from 'rxjs';
import { DialogSnackComponent } from '../shared/components/dialog-snack/dialog-snack.component';

@Injectable({
  providedIn: 'root',
})
export class NotificationService implements OnDestroy {
  private activityDisplayed: {[eventId: number]: boolean} = {};
  translationSub: Subscription;

  constructor(
    private snackBar: MatSnackBar,
    private dialog: MatDialog,
    private translocoService: TranslocoService,
  ) { }

  activity(count: number, eventId: number) {
    if (this.activityDisplayed[eventId] || !count) {
      return;
    }
    this.activityDisplayed[eventId] = true;
    this.translationSub?.unsubscribe();
    this.translationSub = this.translocoService.selectTranslate('events.ticketsAmountSoldThisMonth', {count}).subscribe((translation) => {
      this.snackBar.open(`🔥 ${translation} `, 'x', {
        panelClass: ['activity'],
        horizontalPosition: 'start',
        verticalPosition: 'top',
        duration: 10000,
      });
    });
  }

  open(message: string, seconds = 10, buttonLabel = 'x') {
    this.snackBar.open(message, buttonLabel, {
      panelClass: ['reelax-colors'],
      duration: seconds * 1000, // we convert milliseconds to seconds
    });
  }

  noCookie() {
    this.translationSub?.unsubscribe();
    this.translocoService.selectTranslate('common.noTrackingHere').subscribe( (translation) => {
      this.dialog.open(DialogSnackComponent, {
        maxWidth: '100vw',
        hasBackdrop: false,
        position: {
          bottom: '0px',
          left: '0px',
        },
        panelClass: 'as-snackbar',
        data: {
          button: 'Ok',
          content: translation,
          duration: 10000,
        },
        scrollStrategy: new NoopScrollStrategy(), // allows to scroll with opened dialog
      });
    });
  }

  dismiss() {
    this.snackBar.dismiss();
  }

  ngOnDestroy() {
    this.translationSub?.unsubscribe();
  }
}
