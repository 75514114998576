// eslint-disable-next-line max-classes-per-file
import { Invoice, Ticket, UserProfile } from '.';

export class TicketHolder {
  ticketId: number;
  originalTicketNumber: string;
  originalFirstName: string;
  originalLastName: string;
  newFirstName: string;
  newLastName: string;
  newTicketNumber: string;

  constructor(options?: any) {
    if (options) {
      this.ticketId = options.ticketId;
      this.originalTicketNumber = options.originalTicketNumber;
      this.originalFirstName = options.originalFirstName;
      this.originalLastName = options.originalLastName;
      this.newFirstName = options.newFirstName;
      this.newLastName = options.newLastName;
      this.newTicketNumber = options.newTicketNumber;
    }
  }
}

export enum PayoutStatus {
  Done = 'done',
  Pending = 'pending',
  Hold = 'hold',
}

export enum PurchaseFlow { // database type is STRING(6)
  AutomaticPurchase = 'auto',
  FriendSale = 'friend',
  PublicSale = 'public',
  WLMail = 'wlmail',
}

export const purchaseFlowIcons = {
  auto: '⚡️',
  friend: '👥',
  public: '🌐',
  wlmail: '✉️',
};

export const payoutStatusInfos = {
  [PayoutStatus.Hold]: {
    key : PayoutStatus.Hold,
    icon : 'hourglass_disabled',
    name : 'paiement en attente',
    color: '#ce4358',
  },
  [PayoutStatus.Done]: {
    key : PayoutStatus.Done,
    icon : 'outbond',
    name : 'paiement effectué',
    color: '#3bceac',
  },
  [PayoutStatus.Pending]: {
    key : PayoutStatus.Pending,
    icon : 'hourglass_full',
    name : 'paiement exécuté dans la journée',
    color: '#ce4358',
  },
};

export enum PayoutMethod {
  MangoPayout = 'mgPayout',
  MangoPayinRefund = 'mgPayinRefund'
}

export const payoutMethodInfos = {
  [PayoutMethod.MangoPayout]: {
    name: 'Virement sur IBAN',
  },
  [PayoutMethod.MangoPayinRefund]: {
    name: 'Remboursement sur CB',
  },
};

export class SaleRecord extends TicketHolder {
  id: number;
  seller: UserProfile;
  buyer: UserProfile;
  sellerId: number;
  buyerId: number;
  purchaseFlow: PurchaseFlow;
  updatedAt: Date;
  ticketToken: string;
  payoutStatus: PayoutStatus;
  mangoPayInId: string;
  mangoPayOutId: string;
  mangoTransfertId: string;
  mangoPayinRefundId: string;
  previousSaleId: number;
  Invoice?: Invoice;
  KycInvoice?: Invoice;
  Ticket?: Ticket;
  // properties from extended TicketHolder object
  ticketId: number;
  payoutMethod: PayoutMethod;
  originalTicketNumber: string;
  originalFirstName: string;
  originalLastName: string;
  newFirstName: string;
  newLastName: string;
  newTicketNumber: string;
  newTicketFileName: string;
  newTicketDowngradeFileName: string;
  Resale?: SaleRecord[];
  PreviousSale?: SaleRecord;

  constructor(data?: Partial<SaleRecord>) {
    super(data);
    Object.assign(this, data);
    if (data?.Ticket) {
      this.Ticket = new Ticket(data.Ticket);
    }
    if (data?.Invoice) {
      this.Invoice = new Invoice(data.Invoice);
    }
    if (data?.KycInvoice) {
      this.KycInvoice = new Invoice(data.KycInvoice);
    }
    if (data?.seller) {
      this.seller = new UserProfile(data.seller);
    }
    if (data?.buyer) {
      this.buyer = new UserProfile(data.buyer);
    }
  }
}
