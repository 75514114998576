<div [class]="helpDialog? 'hide-on-extra-small-only' : ''">
  <div class="d-flex" *transloco="let t; read: 'help'">
    <button mat-fab class="ml-auto extended-fab-button" aria-label="Aide" (click)="triggerModal()">
      @if (!helpDialog) {
        <mat-icon>help_outline</mat-icon>
      }
      @if (helpDialog) {
        <mat-icon>highlight_off</mat-icon>
      }
      <span class="extended-fab-button__text">{{ t('help') }}</span>
    </button>
  </div>
</div>
