export class ContactInfo {
  fullName: string;
  mailAddress: string;
  subject: string;
  message: string;
  context?: string;
  event?: string;
  missingEventName?: string;
  missingEventDate?: Date;
  missingEventType?: 'purchase' | 'sell';

  constructor(options?: any) {
    if (options) {
      this.fullName = options.fullName;
      this.mailAddress = options.mailAddress;
      this.subject = options.subject;
      this.message = options.message;
      this.context = options.context;
      this.event = options.event;
      this.missingEventName = options.missingEventName;
      this.missingEventDate = options.missingEventDate;
      this.missingEventType = options.missingEventType;
    }
  }
}
