import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SelectOption, CurrenciesData } from '../../../../models';
import { FormField } from '../form';
import { MatFormField, MatLabel, MatSuffix, MatHint, MatError } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { MatIcon } from '@angular/material/icon';
import { MatSelect } from '@angular/material/select';
import { MatOption } from '@angular/material/core';
import { TranslocoModule } from '@ngneat/transloco';

@Component({
  selector: 'app-currency-form-field',
  templateUrl: './currency-form-field.component.html',
  styleUrls: ['./currency-form-field.component.scss'],
  standalone: true,
  imports: [
    MatFormField,
    MatLabel,
    FormsModule,
    MatInput,
    ReactiveFormsModule,
    MatIcon,
    MatSuffix,
    MatSelect,
    MatOption,
    MatHint,
    MatError,
    TranslocoModule,
  ],
})
export class CurrencyFormFieldComponent extends FormField<number> implements OnInit, OnDestroy {
  public CurrenciesData = CurrenciesData;
  public currencyControl = new FormControl<SelectOption | null>(null);
  @Output() reloadModel = new EventEmitter<boolean>();

  constructor(
  ) {
    super();
  }

  ngOnInit(): void {
    this.currencyControl.patchValue((this.formModel as {currency}).currency);
    this.initForm();
  }

  public updateCurrency($event: any) {
    if (this.currencyControl?.valid) {
      (this.formModel as {currency}).currency = this.currencyControl.value;
      this.reloadModel.next(true);
      setTimeout(() => {
        this.currencyControl.updateValueAndValidity();
      });
    }
  }

  setFormModel(m) {
    if (m.currency) {
      this.currencyControl.patchValue((m as {currency}).currency);
    }
    super.setFormModel(m);
  }

}
