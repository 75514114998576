@if (partner) {
  <div class="partner-card">
    <a class="partner-card__link" [href]="partner.website" target="_blank" rel="noopener" [matTooltip]="partner.name">
      @if (!isLoaded) {
        <img class="partner-card__link__img-placeholder" [alt]="partner.name"
          src="assets/img/reelax-arrows.svg?v=1">
        }
        <img class="partner-card__link__img"
          [alt]="partner.name"
          [src]="'assets/img/' + partner.imgUrl"
          loading="lazy"
          [class.--isLoading]="!isLoaded"
          (load)="handleLoading()"
          >
      </a>
    </div>
  }