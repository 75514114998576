import { Component } from '@angular/core';
import { MatDialogRef, MatDialogTitle, MatDialogContent } from '@angular/material/dialog';
import { TranslocoModule } from '@ngneat/transloco';
import { MatIcon } from '@angular/material/icon';
import { CdkScrollable } from '@angular/cdk/scrolling';
import { NgStyle } from '@angular/common';


@Component({
  selector: 'app-password-policy-dialog',
  templateUrl: './password-policy-dialog.component.html',
  styleUrls: ['./password-policy-dialog.component.scss'],
  standalone: true,
  imports: [
    TranslocoModule,
    MatIcon,
    MatDialogTitle,
    CdkScrollable,
    MatDialogContent,
    NgStyle,
  ],
})
export class PasswordPolicyDialogComponent {


  constructor(
    public dialogRef: MatDialogRef<PasswordPolicyDialogComponent>,
  ) {}

  onCloseClick(): void {
    this.dialogRef.close();
  }
}
