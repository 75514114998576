import { Injectable, OnDestroy } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { Subscription } from 'rxjs';
import { Language, UserProfile } from '../models';
import { AuthService, StorageService, UserService } from '../services';

@Injectable({
  providedIn: 'root',
})
export class LanguageService implements OnDestroy {
  private storageKey = '[lang]';
  private langSub: Subscription;
  private userSub: Subscription;
  private user: UserProfile;

  constructor(
    private storageService: StorageService,
    private translocoService: TranslocoService,
    private userService: UserService,
    private authService: AuthService,
  ) {
    this.userSub = this.authService.getUser().subscribe({
      next: (u: UserProfile) => {
        this.user = u;
      },
    });
  }

  getLang() {
    return this.storageService.getItem<string>(this.storageKey).then((prefLang: Language) => {
      if (prefLang) {
        return prefLang;
      } else if (navigator.language.includes('fr')) {
        return Language.frFR;
      } else if (navigator.language.includes('en')) {
        return Language.enUS;
      } else if (navigator.language.includes('es')) {
        return Language.esES;
      }
      return undefined;
    });
  }

  setLang(lang: Language) {
    this.translocoService.setActiveLang(lang);
    this.storageService.setItem(this.storageKey, lang);
    if (this.user?.id) {
      this.langSub = this.userService.updateLanguage(lang).subscribe();
    }
  }

  ngOnDestroy() {
    this.langSub?.unsubscribe();
    this.userSub?.unsubscribe();
  }
}
