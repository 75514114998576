import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormField } from '../form';
import { MatFormField, MatHint, MatLabel } from '@angular/material/form-field';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CdkTextareaAutosize } from '@angular/cdk/text-field';
import { MatInput } from '@angular/material/input';

@Component({
  selector: 'app-textarea-form-field',
  templateUrl: './textarea-form-field.component.html',
  styleUrls: ['./textarea-form-field.component.scss'],
  standalone: true,
  imports: [
    MatFormField,
    MatLabel,
    MatInput,
    CdkTextareaAutosize,
    FormsModule,
    ReactiveFormsModule,
    MatHint,
  ],
})
export class TextareaFormFieldComponent extends FormField<string> implements OnInit, OnDestroy {

  constructor() {
    super();
  }

}
