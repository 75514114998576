import { Directive, HostListener, Input } from '@angular/core';
import { TrackingService } from '../../services';

@Directive({
  selector: '[appTrack]',
  standalone: true,
})
export class TrackingDirective {
  @Input('appTrack') event: {category: string, action: string, name: string, value?: number, customDimension?: {}};

  constructor(
    private trackingService: TrackingService,
  ) {
  }

  @HostListener('click')
  onClick() {
    this.trackingService.addEvent(this.event);
  }

}
