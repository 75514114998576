@if (hasOneTranslation) {
  <a
    class="link --primary fs-12"
    (click)="displayed = !displayed ; $event.stopPropagation()"
  >{{ (displayed ? 'button.reduce' : 'button.findOutMore') | transloco }}</a>
  @if (displayed) {
    <div
      class="fs-12 reelax-dark-grey fw-400 ws-normal"
      [innerHTML]="getTranslatedDescription()?.trim()"
    ></div>
  }
}
