
export class ReelaxError extends Error {
  public ResultCode: any;
  public ResultMessage: any;
  public ReturnUrl: any;
  public Status: string;
  constructor( ResultMessage, ResultCode?, ReturnUrl?, params = null) {
    // Passer les arguments restants (incluant ceux spécifiques au vendeur) au constructeur parent
    super(params ?? `${ResultCode} - ${ResultMessage}`);
    this.name = 'ReelaxError';
    this.ResultCode = ResultCode;
    this.ResultMessage = ResultMessage;
    this.ReturnUrl = ReturnUrl;
    this.Status = 'FAILED';
  }
}
