import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialogTitle, MatDialogContent } from '@angular/material/dialog';
import { UserBankingInfo } from '../../../../models';
import { TranslocoModule } from '@ngneat/transloco';
import { MatIcon } from '@angular/material/icon';
import { CdkScrollable } from '@angular/cdk/scrolling';
import { MatCard, MatCardContent } from '@angular/material/card';
import { MatButton } from '@angular/material/button';

@Component({
  selector: 'app-confirm-account-deletion-dialog',
  templateUrl: './confirm-account-deletion-dialog.component.html',
  standalone: true,
  imports: [
    TranslocoModule,
    MatIcon,
    MatDialogTitle,
    CdkScrollable,
    MatDialogContent,
    MatCard,
    MatCardContent,
    MatButton,
  ],
})
export class ConfirmAccountDeletionDialogComponent {
  userBanking: UserBankingInfo;

  constructor(
    public dialogRef: MatDialogRef<ConfirmAccountDeletionDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: {
      userBanking: UserBankingInfo,
    },
  ) {
    this.userBanking = this.data.userBanking;
  }

  public onCloseClick(): void {
    this.dialogRef.close({hasConfirmed: false});
  }

  public onConfirmClick() {
    this.dialogRef.close({hasConfirmed: true});
  }

}
