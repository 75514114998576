export class PhoneModel {
  digits: string;
  extension: string;
  e164PhoneNumber: string;

  constructor(phoneNumber?: string | PhoneModel) {
    if (typeof phoneNumber === 'string') {
      this.e164PhoneNumber = phoneNumber;
    } else if (phoneNumber && phoneNumber.digits && phoneNumber.extension) {
      this.digits = phoneNumber.digits;
      this.extension = phoneNumber.extension;
      this.e164PhoneNumber = phoneNumber.e164PhoneNumber;
    }
  }

}
