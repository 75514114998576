@if (feedback) {
  <mat-card class="feedback-card">
    <p class="feedback-card__quote">
      {{feedback.quote}}
    </p>
    <div class="feedback-card__person">
      <div class="feedback-card__person__avatar">
        @if (feedback.gender === 'MAN') {
          <img src="assets/img/illustration/user-man.svg" alt="user-man">
        }
        @if (feedback.gender === 'WOMAN') {
          <img src="assets/img/illustration/user-woman.svg" alt="user-woman">
        }
      </div>
      <div class="feedback-card__person__info">
        <p class="feedback-card__person__info__name">
          {{feedback.name}}
        </p>
        <p class="feedback-card__person__info__event">
          {{feedback.event}}
        </p>
      </div>
    </div>
  </mat-card>
}
