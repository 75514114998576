import { Component, Input } from '@angular/core';
import { AppService, ColorService } from '../../../services';
import { LetDirective } from '../../directives/let.directive';
import { RouterLink } from '@angular/router';
import { NgClass, AsyncPipe } from '@angular/common';
import { PipesModule } from '../../../pipes/pipes.module';

@Component({
  selector: 'app-reelax-tickets-logo',
  templateUrl: './reelax-tickets-logo.component.html',
  styleUrls: ['./reelax-tickets-logo.component.scss'],
  standalone: true,
  imports: [
    LetDirective,
    RouterLink,
    NgClass,
    AsyncPipe,
    PipesModule,
  ],
})
export class ReelaxTicketsLogoComponent {
  @Input() public isBurger = false;

  constructor(
    public colorService: ColorService,
    public appService: AppService,
  ) { }

}
