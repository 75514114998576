<div class="carousel" [ngClass]="{'--full-height': fullHeight}">

  <div #carousel_container class="carousel__container">
    @for (item of items; track item) {
      <div>
        <ng-container *ngTemplateOutlet="template; context: {item: item}"></ng-container>
      </div>
    }
  </div>

</div>
