<ng-container *transloco="let t; read: 'help'">
  @if (!displayFrequent) {
    <h2 class="faq-title mb-3">
      {{ t('youAre') }}
      @for (s of faqSections; track s; let isLast = $last) {
        {{ t(s) | uppercase }} {{isLast?'':', '}}
      }
    </h2>
  }

  <form [formGroup]="faqForm" class="form">
    <app-text-form-field
      class="form__field --large"
      [form]="faqForm"
      [controlName]="'filter'"
      [formModel]="{filter: helpStore.getFaqSearch()}"
      [options]="{
        label: 'help.howCanWeHelp',
        iconName: 'search'
      }"
      >
    </app-text-form-field>
  </form>

  @if (isLoading) {
    <app-loader diameter="40" class="m-auto"></app-loader>
  }

  @if (filteredItems?.length <= 0 && displayFrequent) {
    <p class="fs-16 italic">
      {{ t('nothingFound') }}
    </p>
  }

  @if (displayFrequent && helpStore.getFaqSearch()?.length <= 0) {
    <p class="fs-16 italic mt-2">
      {{ t('FAQ') }}
    </p>
  }

  @for (faqItem of filteredItems; track faqItem; let index = $index) {
    <div
      class="faq-item"
      [ngClass]="{'--last-opened': lastOpenedFragment === faqItem.fragment}"
      [appTrack]="{category: 'faq', action: 'open-question', name: faqItem.fragment, value: displayedQuestions.includes(faqItem.fragment) ? 0 : 1}"
      >
      @if (!filteredItems[index-1] || faqItem.parentName !== filteredItems[index-1].parentName) {
        <h3 class="faq-title mt-5 mb-4">{{faqItem.parentName}}</h3>
      }
      <button class="faq-item__question" (click)="switchAnswerDisplay(faqItem.fragment)" [id]="faqItem.fragment">
        <mat-icon class="mt-1">
          {{displayedQuestions.includes(faqItem.fragment) ? 'keyboard_arrow_down' : 'keyboard_arrow_right'}}
        </mat-icon>
        <span [innerHTML]="faqItem.question"></span>
      </button>
      <div class="faq-item__answer" [ngClass]="displayedQuestions.includes(faqItem.fragment)? '--opened' : '--hidden'">
        <div [innerHTML]="faqItem.answer"></div>
      </div>
    </div>
  }

  @if (displayFrequent && helpStore.getFaqSearch()?.length <= 0) {
    <p class="fs-16 italic my-5">
      ... {{ t('andManyOtherAnswers', { questionsNb: faqSearchService?.anwsersNb - filteredItems?.length}) }}
    </p>
  }

</ng-container>
