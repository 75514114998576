import { countries } from '../../assets/ressources/countries';
import { UntypedFormGroup, ValidationErrors } from '@angular/forms';

export const findCountry = (value: string | {code: string}) => {
  if (!value) {
    return null;
  }
  if (
    typeof value === 'object' &&
    Object.prototype.hasOwnProperty.call(value, 'code')
  ) {
    value = value.code;
  }
  return countries.find((c) => c === value);
};

export function nationalityValidator(control: UntypedFormGroup): ValidationErrors | null {
  const value = control.get('citizenship').value;
  const country = findCountry(value);
  return !country ? { wrongNationality : true } : null ;
}

export function countryNameValidator(control: UntypedFormGroup): ValidationErrors | null {
  const value = control.get('countryOfResidence')?.value;
  const country = findCountry(value);
  return !country ? { wrongCountryName : true } : null ;
}

export function countryValidator(control: UntypedFormGroup): ValidationErrors | null {
  const value = control.get('country').value;
  const country = findCountry(value);
  return !country ? { wrongCountryName : true } : null ;
}

