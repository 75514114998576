
export class MangoError extends Error {
  public ResultCode: string | number;
  public ResultMessage: string;
  public Status: string;
  public constructor(ResultCode: string | number, ResultMessage: string, Status: string, ...params: undefined[]) {
    // Passer les arguments restants (incluant ceux spécifiques au vendeur) au constructeur parent
    if (params) {
      super(...params);
    } else {
      super(`${ResultCode} - ${ResultMessage}`);
    }

    // Maintenir dans la pile une trace adéquate de l'endroit où l'erreur a été déclenchée (disponible seulement en V8)
    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, MangoError);
    }
    this.ResultCode = ResultCode;
    this.ResultMessage = ResultMessage;
    this.Status = Status;
    this.name = 'MangoError';
  }
}
