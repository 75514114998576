import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Event } from '../models';

@Injectable({providedIn: 'root'})
export class EventStore {
  private readonly events = new BehaviorSubject<Event[]>([]);
  private readonly currentEventId = new BehaviorSubject<number>(undefined);

  readonly events$ = this.events.asObservable();
  readonly currentEventId$ = this.currentEventId.asObservable();

  getEvents(eventId?: number): Event[] {
    if (eventId >= 0) {
      return this.getEvents().filter((e) => e.id === eventId);
    }
    return this.events.getValue();
  }

  getCurrentEvent() {
    return this.getEvents(this.currentEventId.getValue()).pop();
  }

  setCurrentEvent(eventId) {
    this.currentEventId.next(eventId);
  }

  loadEvents(events: Event[]) {
    this.events.next([
      ...events,
      ...this.getEvents().filter((oldE) => events.reduce( (insert, newE) => insert && oldE.id !== newE.id, true)),
    ]);
  }

}
