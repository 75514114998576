import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormField } from '../form';
import { MatRadioGroup, MatRadioButton } from '@angular/material/radio';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

@Component({
  selector: 'app-radiogroup-form-field',
  templateUrl: './radiogroup-form-field.component.html',
  standalone: true,
  imports: [
    MatRadioGroup,
    FormsModule,
    ReactiveFormsModule,
    MatRadioButton,
  ],
})
export class RadiogroupFormFieldComponent extends FormField<any> implements OnInit, OnDestroy {
  @Input() public radioOptions: {value, viewValue: string, disabled?: boolean}[];

  constructor() {
    super();
  }

}
