import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { SiteComponent } from './components/site/site.component';

import { LoginComponent } from './pages/login/login.component';
import { NotFoundComponent } from './pages/not-found/not-found.component';
import { OrganizerComponent } from './pages/organizer/organizer.component';
import { ResetPasswordComponent } from './pages/reset-password/reset-password.component';
import { ThreeDsecureComponent } from './pages/three-dsecure/three-dsecure.component';
import { VerifyAccountComponent } from './pages/verify-account/verify-account.component';
import {
  SEOResolver,
  OrganizerResolver,
  PublicEventResolver,
} from './shared/resolvers';

const routes: Routes = [
  {
    path: '',
    component: SiteComponent,
    resolve: {
      SEOTranslations: SEOResolver,
    },
    pathMatch: 'prefix',
    children: [
      {
        path: '',
        loadChildren: () => import('./home/home.module').then((mod) => mod.HomeModule),
      },
      {
        path: 'orga',
        loadChildren: () => import('./organizer-dashboard/organizer-dashboard.module').then((mod) => mod.OrganizerDashboardModule),
      },
      {
        path: 'o/:organizerUrl',
        component: OrganizerComponent,
        resolve: {
          organizer: OrganizerResolver,
        },
        loadChildren: () => import('./events-browse/events-browse.module').then((mod) => mod.EventsBrowseModule),
      },
      {
        path: 'events',
        redirectTo: 'evenements',
      },
      {
        path: 'evenements',
        loadChildren: () => import('./platform/platform.module').then((mod) => mod.PlatformModule),
      },
      {
        path: 'login',
        redirectTo: 'connexion',
      },
      {
        path: 'connexion',
        component: LoginComponent,
      },
      {
        path: 'event/:eventId',
        redirectTo: 'evenement/:eventId',
      },
      {
        path: 'evenement',
        loadChildren: () => import('./platform/platform.module').then((mod) => mod.PlatformModule),
      },
      {
        path: 'e',
        loadChildren: () => import('./platform/platform.module').then((mod) => mod.PlatformModule),
      },
      {
        path: 'event/:eventId/purchase/:ticketId',
        redirectTo: 'evenement/:eventId/achat/:ticketId',
      },
      {
        path: 'e/n/:eventUrl/purchase/:ticketId',
        redirectTo: 'e/n/:eventUrl/achat/:ticketId',
      },
      {
        path: 'e',
        loadChildren: () => import('./platform/platform.module').then((mod) => mod.PlatformModule),
      },
      {
        path: 'event/:eventId/purchase',
        redirectTo: 'evenement/:eventId/achat',
      },
      {
        path: 'e/n/:eventUrl/purchase',
        redirectTo: 'e/n/:eventUrl/achat',
      },
      {
        path: 'event/:eventId/sale',
        redirectTo: 'evenement/:eventId/vente',
      },
      {
        path: 'evenement',
        loadChildren: () => import('./platform/platform.module').then((mod) => mod.PlatformModule),
      },
      {
        path: ':eventId/purchase',
        redirectTo: 'evenement/:eventId/achat',
      },
      {
        path: 'e',
        loadChildren: () => import('./platform/platform.module').then((mod) => mod.PlatformModule),
      },
      {
        path: 'three-d-secure',
        component: ThreeDsecureComponent,
      },
      {
        path: 'three-d-secure/w/:eventId/:wlPurchaseId/:paymentToken',
        component: ThreeDsecureComponent,
        resolve: {
          event: PublicEventResolver,
        },
      },
      {
        // redirect legacy route to new one
        path: 'three-d-secure/:eventId/:ticketId/:paymentToken',
        redirectTo: 'three-d-secure/t/:eventId/:ticketId/:paymentToken',
        resolve: {
          event: PublicEventResolver,
        },
      },
      {
        path: 'three-d-secure/t/:eventId/:ticketId/:paymentToken',
        component: ThreeDsecureComponent,
        resolve: {
          event: PublicEventResolver,
        },
      },
      {
        // redirect legacy route to new one
        path: 'confirmation/:eventId/:transactionType/:ticketId/:ticketToken',
        redirectTo: 'evenement/:eventId/achat/confirmation/:transactionType/:ticketId/:ticketToken',
      },
      {
        path: 'verify-account/:token',
        redirectTo: 'verification-de-compte/:token',
      },
      {
        path: 'verification-de-compte/:token',
        component: VerifyAccountComponent,
      },
      {
        path: 'account-unlock/:token',
        component: VerifyAccountComponent,
      },
      {
        path: 'reset-password/:token',
        redirectTo: 'reinitialisation-de-mot-de-passe/:token',
      },
      {
        path: 'reinitialisation-de-mot-de-passe/:token',
        component: ResetPasswordComponent,
      },
      {
        path: 'alert-unsubscription/:token',
        redirectTo: 'desinscription-alerte/:token',
      },
      {
        path: 'seller-information',
        redirectTo: 'mon-compte/compte-bancaire',
      },
      {
        path: 'informations-vendeur',
        redirectTo: 'mon-compte/compte-bancaire',
      },
      {
        path: 'terms',
        redirectTo: 'mentions-legales',
      },
      {
        path: 'mon-compte',
        loadChildren: () => import('./my-account/my-account.module').then((mod) => mod.MyAccountModule),
      },
      {
        path: 'page-introuvable',
        component: NotFoundComponent,
      },
    ],
  },
  {
    path: 'w',
    loadChildren: () => import('./widget/widget.module').then((m) => m.WidgetModule),
  },
  {
    path: '**',
    redirectTo: 'page-introuvable',
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    anchorScrolling: 'enabled',
    // enableTracing: true, // to debug routing
  })],
  exports: [RouterModule],
})
export class AppRoutingModule { }
