import { FAQItem } from './faq.model';
import { LanguageMap } from './language.model';

export class EventCustomFaq {
  public id!: number;
  public customQuestions: {
    parent: string,
    children: string[],
    question: string | LanguageMap,
    answer?: string | LanguageMap,
    score?: number,
    name?: string | LanguageMap,
    id: number,
  }[];
  public changesFromOriginal: Record<string, FAQItem>;
  public eventId: number;
  public selectedFragments?: string[];

  constructor(data: any) {
    if (data) {
      Object.assign(this, data);
    } else {
      this.customQuestions = [];
      this.selectedFragments = [];
    }
  }
}
