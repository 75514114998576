import { environment } from '../../environments/environment';

export enum InvoiceType {
  Ticket = 'T',
  AutoPurchase = 'A',
  KYC = 'K',
}

export class Invoice {
  id: number;
  token: string;
  invoiceFileName: string;
  date: Date;
  // eslint-disable-next-line id-denylist
  number: string;
  invoiceAmount: number;
  tvaRate: number;
  type: InvoiceType;

  constructor(options?: any) {
    if (options) {
      this.id = options.id;
      this.token = options.token;
      this.invoiceFileName = options.invoiceFileName;
      this.invoiceAmount = options.invoiceAmount;
      this.date = options.date;
      // eslint-disable-next-line id-denylist
      this.number = options.number;
      this.tvaRate = options.tvaRate;
    }
  }

  get downloadUrl(): string {
    return `${environment.apiUrl}invoice/pdf/${this.id}/${this.token}`;
  }
}
