import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { ContactInfo } from '../models';
import { Platform } from '@angular/cdk/platform';
import { PurchaseInfosStore } from './purchase.store';
import { TicketsService } from '../services';
import { UserStore } from './user.store';

@Injectable({providedIn: 'root'})
export class HelpStore {
  private readonly contactInfo = new BehaviorSubject<ContactInfo>(new ContactInfo());
  readonly contactInfo$ = this.contactInfo.asObservable();

  private readonly faqSearch = new BehaviorSubject<string>('');
  readonly faqSearch$ = this.faqSearch.asObservable();

  private isBrowser = false;
  private userId: number;

  constructor(
    private platform: Platform,
    private purchaseInfoStore: PurchaseInfosStore,
    private ticketsService : TicketsService,
    private userStore: UserStore,
  ) {
    this.isBrowser = this.platform.isBrowser;
    this.userStore.getUser().then(
      (u) => {
        if (u) {
          this.userId = u.id;
        }
      },
    );
  }

  getContactInfo() {
    return this.contactInfo.getValue();
  }

  loadContactInfo(contactInfo: ContactInfo) {
    this.contactInfo.next(contactInfo);
  }

  getContext() {
    if (this.isBrowser) {
      return JSON.stringify({
        userId: this.userId,
        page: window.location.href,
        purchase: this.purchaseInfoStore.getPurchaseInfos(),
        sale: this.ticketsService.saleTickets,
      });
    }
    return '';
  }

  getFaqSearch() {
    return this.faqSearch.getValue();
  }

  loadFaqSearch(search: string) {
    this.faqSearch.next(search);
  }

}
