// eslint-disable-next-line max-classes-per-file
'use strict';
import { Event } from '.';

export interface IEventsMapTiles {
  url: string;
  options?: {
    maxZoom?: number;
    minZoom?: number;
    attribution?: string;
  };
}

export interface IPositionStackRes {
  latitude: number;
  longitude: number;
  label: string;
  name: string;
  type: string;
  // eslint-disable-next-line id-denylist
  number: string;
  street: string;
  postal_code: string;
  confidence: number;
  region: string;
  region_code: string;
  administrative_area: unknown;
  neighbourhood: string;
  country: string;
  country_code: string;
  map_url: string;
}

// postgis geometry
export class Point {
  public type: 'Point';
  public coordinates: [number, number]; // GeoJson format: [lng, lat]
}

export class PostalAddress {
  public id!: number;
  public address1: string;
  public address2: string;
  public city: string;
  public zip_code: string;
  public state: string;
  public country: string;
  public name: string;
  public location: Point;

  public readonly createdAt!: Date;
  public readonly updatedAt!: Date;

  public Events?: Event[];

  // virtual
  public eventsNb?: number; // number of event associated

  constructor(options?: Partial<PostalAddress>) {
    if (options) {
      Object.assign(this, options);

      this.createdAt = options.createdAt ? new Date(options.createdAt) : null;
      this.updatedAt = options.updatedAt ? new Date(options.updatedAt) : null;

      this.Events = options.Events?.map( (e) => new Event(e));
    }

  }

  get address() {
    return [
      this.address1,
      this.address2,
      this.zip_code,
      this.city,
      this.state,
      this.country,
    ].filter( (e) => !!e).join(', ');
  }

  get shortAddress() {
    return [
      this.name,
      this.city,
    ].filter( (e) => !!e).join(', ');
  }
}
