import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormField } from '../form';
import { MatCheckbox } from '@angular/material/checkbox';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatTooltip } from '@angular/material/tooltip';
import { MatHint } from '@angular/material/form-field';
import { TranslocoModule } from '@ngneat/transloco';

@Component({
  selector: 'app-checkbox-form-field',
  templateUrl: './checkbox-form-field.component.html',
  standalone: true,
  imports: [
    MatCheckbox,
    FormsModule,
    ReactiveFormsModule,
    MatTooltip,
    MatHint,
    TranslocoModule,
  ],
})
export class CheckboxFormFieldComponent extends FormField<boolean> implements OnInit, OnDestroy {

  constructor() {
    super();
  }

}
