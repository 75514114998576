<ng-container *appLet="(appService.settings.asObservable() | async) as setting">

  <a class="reelax-logo-container" [routerLink]="setting?.isWidget ? null : '/'">
    <div class="logo-size" *appLet="(colorService.color | async) as color">
      <object
        extra="origin"
        class="custom-color-svg reelax-logo"
        [ngClass]="{'--background-logo': color === colorService.defaultColor}"
        type="image/svg+xml"
        [data]="'assets/img/reelax-black-logo-customized.svg' + (setting.referer ? '?wref=' + setting.referer : '') | safe:'resourceUrl'"
      >
        <param name="color" [value]="color">  
      </object>
      <object
        class="reelax-logo"
        [ngClass]="{'--background-logo': color !== colorService.defaultColor}"
        type="image/svg+xml"
        [data]="'assets/img/reelax-black-logo.svg' + (setting.referer ? '?wref=' + setting.referer : '') | safe:'resourceUrl'"
      >
      </object>
    </div>
  </a>

</ng-container>
