import { captureException } from '@sentry/angular';
import { JwtPayload, jwtDecode } from 'jwt-decode';

export class WebToken {
  public token: string;

  // seconds the request might take before server authentification
  // prevents the token been valid before sent and expired on server
  // once the request authenticated it can take a longer time to compute
  private requestMaxTime = 20;
  private decodedToken: JwtPayload;
  // time drift is the time difference between client and server
  // token expiration has to take into account the time drift
  private timeDrift: number;

  constructor(token?: string) {
    if (token) {
      try {
        this.decodedToken = jwtDecode(token);
        this.token = token;
        this.timeDrift = Math.round(Date.now() / 1000) - this.decodedToken.iat;
      } catch (error) {
        // eslint-disable-next-line no-console
        console.log('WebToken decode error');
        // eslint-disable-next-line no-console
        console.error(error);
        captureException(error);
      }
    }
  }

  /**
   * A token is expired if the timestamp of the expiration date is smaller than the timestamp of the current time
   *
   */
  public get isExpired() {
    if (!this.token) {
      return true;
    }
    const timestampSeconds = Math.round(Date.now() / 1000);
    const expired = this.decodedToken.exp < (timestampSeconds - this.requestMaxTime - this.timeDrift);
    return expired;
  }

}
