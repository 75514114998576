import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { from, of } from 'rxjs';

export function LeafletResolver(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
  if (window.L) {
    return of('ok');
  }
  return from(import('leaflet')
    .then((L) => {
      window.L = L;
      return Promise.all([
        import('leaflet.markercluster'),
        import('leaflet.locatecontrol'),
      ]);
    })
    .then( (L) => of('ok')).catch((err) => {
      // eslint-disable-next-line no-console
      console.log('error importing leaflet', err);
      return of('ok');
    }));
}

