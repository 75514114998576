import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { TranslocoService, TranslocoModule } from '@ngneat/transloco';
import { Subscription } from 'rxjs';
import { Category, SeatDetails, humanReadableSeatDetails } from '../../../models';

@Component({
  selector: 'app-seat-details',
  templateUrl: './seat-details.component.html',
  standalone: true,
  imports: [TranslocoModule],
})
export class SeatDetailsComponent implements OnInit, OnDestroy {
  @Input() public seatDetails: SeatDetails;
  @Input() public displayedSeatingInformation: Category['displayedSeatingInformation'];
  public seatTranslation: Record<string, string>;
  private translationSub: Subscription;
  public humanReadableSeatDetails = humanReadableSeatDetails;

  constructor(
    private translocoService: TranslocoService,
  ) { }

  ngOnInit(): void {
    this.translationSub = this.translocoService.selectTranslateObject('seatDetails')
      .subscribe( (seatTranslation) => {
        this.seatTranslation = seatTranslation;
      });
  }

  ngOnDestroy() {
    this.translationSub?.unsubscribe();
  }
}
