import { AbstractControl, AsyncValidatorFn, ValidationErrors } from '@angular/forms';
import { map, debounceTime, delay, Observable, take, distinctUntilChanged } from 'rxjs';
import { Event } from '../models';

export function categoryPlatformIdModelAsyncValidator(event$: Observable<Event>): AsyncValidatorFn {
  return (
    control: AbstractControl,
  ) => event$.pipe(
    take(1),
    delay(300),
    map( (event): ValidationErrors | null => {
      const platformIds: {[platformName: string]: string[]} = {};
      let isMultipleTemplateName = false;
      const duplicates = [];
      event.Categories?.forEach((category) => {
        if (category.platformsForeignIds) {
          const keys = Object.keys(category.platformsForeignIds);
          let i = 0;
          const controlsLength = keys?.length ?? 0;
          while (i < controlsLength) {
            // keys[i] // name
            // (category.get('platformsForeignIds') as FormGroup)?.controls[keys[i]] // value
            if (!platformIds[keys[i]]) {
              platformIds[keys[i]] = [];
            }
            const catIds = category.platformsForeignIds[keys[i]]?.split(';');
            let j = 0;
            const catIdsLen = catIds?.length ?? 0;
            while (j < catIdsLen) {
              if (platformIds[keys[i]].indexOf(catIds[j]) >= 0 ) {
                isMultipleTemplateName = true;
                duplicates.push(`${category.name} (${category.id}): ${catIds[j]}`);
              } else {
                platformIds[keys[i]].push(catIds[j]);
              }
              j++;
            }
            i++;
          }
        }
      });
      return isMultipleTemplateName ? { platformIdDuplicate: duplicates } : null;
    }),
    debounceTime(300),
    distinctUntilChanged(),
  );
}
