import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Observable, of, Subject, timer, throwError } from 'rxjs';
import { first, map, switchMap, catchError, filter } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { Challenge, Ticket, TicketSaleData, CategoryPrices, TicketStatus, SaleRecord, PayoutPolicy } from '../models';
import { isPlatformBrowser } from '@angular/common';

const REFRESH_INTERVAL = 100000; // in ms, 1 s <=> 1000 ms , 100 000 <=> 100s

export enum RedirectionFragment {
  sold = 'Vendu',
  pendingTicketDelivery = 'Vendu',

  apiError = 'Annulé',
  withdrawn = 'Annulé',
  notValid = 'Annulé',
  pendingSellerConfirmation = 'Annulé',

  sale = 'Vente',
  privateSale = 'Vente',
  friendSale = 'Vente',
  pendingPayment = 'Vente',
  pendingPurchase = 'Vente',
  pendingSwap = 'Vente',
  pendingValidation = 'Vente',
}

interface ValidityResponse {
  challenge: Challenge;
  tickets: {[id: string]: {
    categoryId?: string,
    categoryName?: string,
    isValid: boolean,
    pendingValidation: boolean,
    originalTicketNumber: string,
    parsedTicketNumber?: string,
    cardRefundCeil: number,
    payoutPolicy: PayoutPolicy,
    personalization: {validity: string},
    reresaleAttempt?: boolean;
    reresaleRedirection?: TicketStatus;
    seatDetails;
    upgraded: boolean;
  }};
}

@Injectable({
  providedIn: 'root',
})
export class TicketsService {
  private eventId: number;

  public saleTickets: {[originalTicketNumber: string ]: TicketSaleData};

  private ticketsListCache$: Observable<Ticket[]>;
  private reloadTicketsList$ = new Subject<void>();
  private isBrowser = false;

  constructor(
    private http: HttpClient,
    @Inject(PLATFORM_ID) private platformId,
  ) {
    this.isBrowser = isPlatformBrowser(platformId);
  }

  configUrl = environment.apiUrl;

  getOnSaleTickets(eventId: number) {
    if (this.eventId !== eventId) {
      this.eventId = eventId;
      this.ticketsListCache$ = null;
    }
    if (!this.ticketsListCache$ ) {
      // Set up timer that ticks every X milliseconds
      const timer$ = timer(0, REFRESH_INTERVAL);

      // For each timer tick make an http request to fetch new data
      // We use shareReplay(X) to multicast the cache so that all
      // subscribers share one underlying source and don't re-create
      // the source over and over again. We use takeUntil to complete
      // this stream when the user forces an update.
      this.ticketsListCache$ = this.reloadTicketsList$.asObservable().pipe(
        filter( () => this.isBrowser),
        catchError((val) => of('In getOnSaleTickets function, I caught: ', val)),
        switchMap(() => timer$),
        switchMap(() => this.requestTicketsList(eventId)),
      );
    }

    return this.ticketsListCache$;
  }

  // Public facing API to force the cache to reload the tickets list
  public forceTicketsListReload() {
    this.reloadTicketsList$.next();
  }

  // Helper method to actually fetch the ticket
  private requestTicketsList(eventId: number) {
    const route = `${this.configUrl}events/${eventId}/tickets`;
    return this.http.get<Ticket[]>(route).pipe(
      catchError((val) => of('In requestTicketsList function, I caught: ', val)),
      map((tickets) => (tickets && Array.isArray(tickets)) ? tickets.map((t) => new Ticket(t)) : []),
    );
  }

  getInSaleTicketsPrices(eventId: number, categoryId: number) {
    const route = `${this.configUrl}events/${eventId}/categoryPrices/${categoryId}`;
    return this.http.get<CategoryPrices>(route).pipe(
      catchError((val) => of('In getInSaleTicketsPrices function, I caught: ', val)),
      first(),
    );
  }

  public setTicketOnSale(eventId: number, userId: number) {
    const route = `${this.configUrl}create/tickets`;

    return this.http.post<{saleRecords: SaleRecord[], payoutPolicy, friendSale}>(route, {
      tickets: Object.values(this.saleTickets),
      sellerId: userId,
      eventId,
    }).pipe(
      catchError(this.handleTicketsError),
    );
  }

  public checkTicketValidity(eventId: number, tickets: {[id: string]: TicketSaleData}, challenge: (Challenge | {}) )
  : Observable<ValidityResponse> {

    const route = `${this.configUrl}ticket/${eventId}/validity`;
    return this.http.post<ValidityResponse>(route, {ticketsData: tickets, challengeData: challenge});
  }

  public getAvailableTicketsForWaiter(eventId: number, categoryId: number, waitlistToken: string) {
    let route = `${this.configUrl}waitList/tickets-available/e/${eventId}`;

    const params = Object.entries({categoryId, waitlistToken})
      .filter( ([k, p]) => !!p)
      .map( ([k, p]) => `${k}=${encodeURI(p as string)}`).join('&');

    if (params) {
      route += `?${params}`;
    }
    return this.http.get<{[key: number]: Ticket[]}>(route).pipe(
      map( (cat) => {
        Object.keys(cat)?.forEach( (id) => cat[id] = cat[id].map( (t) => new Ticket(t)));
        return cat;
      }),
    );
  }

  public updateTicketStatusAdmin(ticketId: number, status: TicketStatus): Observable<boolean> {
    const route = `${this.configUrl}t/u/${ticketId}`;
    return this.http.put<boolean>(route, {ticketId, status})
      .pipe(
        catchError(this.handleTicketsError),
      );
  }

  public updateTicket(ticket: Ticket): Observable<boolean> {
    const route = `${this.configUrl}myTickets/u/${ticket.id}`;
    return this.http.put<boolean>(route, {ticket});
  }

  private handleTicketsError(error: HttpErrorResponse) {
    // TODO refacto, we should not throw all errors
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      // eslint-disable-next-line no-console
      console.error('An error occurred:', error.error.message);
    } else if (error.error && (error.error.name === 'MangoError' || error.error.name === 'ReelaxError')) {
      // eslint-disable-next-line no-console
      console.log('mango error');
      return throwError(error.error);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      // eslint-disable-next-line no-console
      console.error(
        `Backend returned code ${error.status}, ` +
        'body was:');
      // eslint-disable-next-line no-console
      console.error(error.error);
    }
    // return an observable with a user-facing error message
    return throwError(
      'Something bad happened into tickets service; please try again later.');
  }

  // test ticket valid with yurplan api ADMIN
  public checkYurplanValidateAPI( ticketNb: string) {
    ticketNb = encodeURIComponent(ticketNb);
    const route = `${this.configUrl}yurplan-validate/${ticketNb}`;
    return this.http.get<{apiStatus; reelaxTicket}>(route).pipe(
      catchError((err) => {
        if ( err.status === 400) {
          return of(err.error.message);
        }
        // eslint-disable-next-line no-console
        console.warn(err);
        return of('In checkYurplanValidateAPI function, I caught: ', err);
      }),
      first(),
    );
  }

  // test ticket valid with weezevent api ADMIN
  public checkWeezeventValidateAPI({ticketNb, organizerId, eventId}) {
    if (!ticketNb || !organizerId || !eventId ) {
      // eslint-disable-next-line no-console
      console.log('missing params ', ticketNb, organizerId, eventId);
      return of('missing params');
    }
    ticketNb = encodeURIComponent(ticketNb);
    eventId = encodeURIComponent(eventId);
    organizerId = encodeURIComponent(organizerId);
    const route = `${this.configUrl}weezevent-validate/organizations/${organizerId}/events/${eventId}/${ticketNb}`;
    return this.http.get<{apiStatus; reelaxTicket}>(route).pipe(
      catchError((err) => {
        if ( err.status === 400) {
          return of(err.error.message);
        }
        // eslint-disable-next-line no-console
        console.warn(err);
        return of('In checkWeezeventValidateAPI function, I caught: ', err);
      }),
      first(),
    );
  }

  // test ticket valid with billet web api ADMIN
  public checkBilletWebValidateAPI({ticketNb, eventId, categoryId}) {
    const categoryParam = categoryId ? `categoryId=${encodeURIComponent(categoryId)}` : '';
    ticketNb = encodeURIComponent(ticketNb);
    eventId = encodeURIComponent(eventId);
    const route = `${this.configUrl}billetweb-validate/${eventId}/${ticketNb}?${categoryParam}`;
    return this.http.get<{apiStatus; reelaxTicket}>(route).pipe(
      catchError((err) => {
        if ( err.status === 400) {
          return of(err.error.message);
        }
        // eslint-disable-next-line no-console
        console.warn(err);
        return of('In checkBilletWebValidateAPI function, I caught: ', err);
      }),
      first(),
    );
  }

  // test ticket valid with billet web api ADMIN
  public checkOneticketValidateAPI( ticketNb: string) {
    ticketNb = encodeURIComponent(ticketNb);
    const route = `${this.configUrl}oneticket-validate/${ticketNb}`;
    return this.http.get<{apiStatus; reelaxTicket}>(route).pipe(
      catchError((err) => {
        if ( err.status === 400) {
          return of(err.error.message);
        }
        // eslint-disable-next-line no-console
        console.warn(err);
        return of('In checkOneticketValidateAPI function, I caught: ', err);
      }),
      first(),
    );
  }

  public checkWiloutValidateAPI( ticketNb: string) {
    ticketNb = encodeURIComponent(ticketNb);
    const route = `${this.configUrl}wilout-validate/${ticketNb}`;
    return this.http.get<{apiStatus; reelaxTicket}>(route).pipe(
      catchError((err) => {
        if ( err.status === 400) {
          return of(err.error.message);
        }
        // eslint-disable-next-line no-console
        console.warn(err);
        return of('In checkWiloutValidateAPI function, I caught: ', err);
      }),
      first(),
    );
  }

  public checkSupersoniksValidateAPI({ticketNb, organizerId}) {
    if (!ticketNb || !organizerId ) {
      // eslint-disable-next-line no-console
      console.log('missing params ', ticketNb, organizerId);
      return of('missing params');
    }
    ticketNb = encodeURIComponent(ticketNb);
    const organizerIdParam = organizerId ? `organizerId=${encodeURIComponent(organizerId)}` : '';

    const route = `${this.configUrl}supersoniks-validate/${ticketNb}?${organizerIdParam}`;
    return this.http.get<{apiStatus; reelaxTicket}>(route).pipe(
      catchError((err) => {
        if ( err.status === 400) {
          return of(err.error);
        }
        // eslint-disable-next-line no-console
        console.warn(err);
        return of('In checkSupersoniksValidateAPI function, I caught: ', err);
      }),
      first(),
    );
  }

  public checkRodrigueValidateAPI({ticketNb, organizerId, eventId}) {
    if (!ticketNb || !organizerId || !eventId ) {
      // eslint-disable-next-line no-console
      console.log('missing params ', ticketNb, organizerId, eventId);
      return of('missing params');
    }
    ticketNb = encodeURIComponent(ticketNb);
    eventId = encodeURIComponent(eventId);
    organizerId = encodeURIComponent(organizerId);
    const route = `${this.configUrl}rodrigue-validate/organizations/${organizerId}/events/${eventId}/${ticketNb}`;
    return this.http.get<{apiStatus; reelaxTicket}>(route).pipe(
      catchError( (err) => {
        if ( err.status === 400) {
          return of(err.error);
        }
        // eslint-disable-next-line no-console
        console.warn(err);
        return of('In checkRodrigueValidateAPI function, I caught: ', err);
      }),
      first(),
    );
  }

  // test ticket valid with festik api ADMIN
  public checkFestikValidateAPI( ticketNb: string) {
    ticketNb = encodeURIComponent(ticketNb);
    const route = `${this.configUrl}festik-validate/${ticketNb}`;
    return this.http.get<{apiStatus; reelaxTicket}>(route).pipe(
      catchError((err) => {
        if ( err.status === 400) {
          return of(err.error.message);
        }
        // eslint-disable-next-line no-console
        console.warn(err);
        return of('In checkFestikValidateAPI function, I caught: ', err);
      }),
      first(),
    );
  }

}
