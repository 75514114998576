import { Component, Input } from '@angular/core';
import { Event } from '../../../models';
import { DatePipe } from '@angular/common';
import { MatCard, MatCardContent } from '@angular/material/card';
import { RouterLinkActive, RouterLink } from '@angular/router';

@Component({
  selector: 'app-event-info',
  templateUrl: './event-info.component.html',
  styleUrls: ['./event-info.component.scss'],
  standalone: true,
  imports: [
    MatCard,
    MatCardContent,
    RouterLinkActive,
    RouterLink,
    DatePipe,
  ],
})
export class EventInfoComponent {
  @Input() public event: Event;

  constructor() {}

}
