/* eslint-disable max-classes-per-file */
import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Subscription, take, tap } from 'rxjs';
import { EventCustomFaq, FAQItem, Language, Role, UserProfile } from '../../../models';
import { EventStore, HelpStore } from '../../../stores';
import { faq as faqFR } from '../../../../assets/ressources/faq-fr';
import { faq as faqEN } from '../../../../assets/ressources/faq-en';
import { faq as faqES } from '../../../../assets/ressources/faq-es';
import { TranslocoService, TranslocoModule } from '@ngneat/transloco';
import { Platform } from '@angular/cdk/platform';
import { MatDialogRef } from '@angular/material/dialog';
import { AuthService, EventsService } from '../../../services';
import { MatIcon } from '@angular/material/icon';
import { TitleCasePipe } from '@angular/common';
import { MatCard, MatCardContent } from '@angular/material/card';
import { MatButton } from '@angular/material/button';
import { TrackingDirective } from '../../directives/tracking.directive';
import { MatAccordion, MatExpansionPanel, MatExpansionPanelHeader, MatExpansionPanelTitle } from '@angular/material/expansion';
import { RouterLink } from '@angular/router';
import { ContactFormComponent } from '../contact-form/contact-form.component';
import { PipesModule } from '../../../pipes/pipes.module';

@Component({
  selector: 'app-help-dialog',
  templateUrl: './help-dialog.component.html',
  styleUrls: ['./help-dialog.component.scss'],
  standalone: true,
  imports: [
    TranslocoModule,
    MatIcon,
    MatCard,
    MatCardContent,
    MatButton,
    TrackingDirective,
    MatAccordion,
    MatExpansionPanel,
    MatExpansionPanelHeader,
    MatExpansionPanelTitle,
    RouterLink,
    ContactFormComponent,
    TitleCasePipe,
    PipesModule,
  ],
})
export class HelpDialogComponent implements OnInit, OnDestroy {
  @ViewChild('scrollContainer') scrollContainer: ElementRef;

  public faq = faqFR;
  public customFaq: EventCustomFaq;
  public archives: FAQItem[] = [];

  public currentTreeNode: FAQItem;
  public lastQuestionOpened = '';

  public isSatisfied: boolean;
  public isWaiting = false;
  public showfeedback = false;

  private langSub: Subscription;
  public currentLanguage: string = Language.frFR;

  public buttonsClicked: string[] = [];

  private isBrowser = false;

  private eventSub: Subscription;
  public eventId: number;

  private authSub: Subscription;
  private user: UserProfile;

  constructor(
    public helpStore: HelpStore,
    private translocoService: TranslocoService,
    private platform: Platform,
    public dialogRef: MatDialogRef<HelpDialogComponent>,
    private eventStore: EventStore,
    private eventsService: EventsService,
    private authService: AuthService,
  ) {
    this.isBrowser = this.platform.isBrowser;
  }

  ngOnInit(): void {
    this.eventSub = this.eventStore.currentEventId$.pipe(
      tap( (eventId) => {
        if (eventId) {
          this.eventId = eventId;
          this.eventsService.getEventCustomFaq(eventId).pipe(take(1)).subscribe((res) => {
            this.customFaq = new EventCustomFaq(res);
            this.customFaq.customQuestions?.forEach((questionItem, index) => {
              // We generate a fragment to put custom questions in the faq
              const generatedFragment = `${questionItem.parent}_${index}`;
              this.faq[generatedFragment] = questionItem;
              this.faq[generatedFragment].fragment = questionItem.question?.['fr-FR'] || questionItem.name?.['fr-FR'];
              Object.entries(this.faq).forEach(([key, value]) => {
                if (value.id === parseInt(questionItem.parent,10) &&
                  !this.faq[key].children.includes(generatedFragment)
                ){
                  this.faq[key].children.push(generatedFragment);
                }
              });
            });
          });
        }
      }),
    ).subscribe();

    this.langSub = this.translocoService.langChanges$.subscribe((lang) => {
      this.currentLanguage = lang;
      if (lang.includes('en-US')) {
        this.faq = faqEN;
      } else if (lang.includes('es-ES')) {
        this.faq = faqES;
      } else {
        this.faq = faqFR;
      }
    });

    this.authSub = this.authService.getUser().subscribe((u) => {
      this.user = u;
    });
    this.currentTreeNode = this.faq.root;
  }

  public canDisplay(child: string) {
    if (!this.eventId || (!!child && !this.faq?.[child])) {
      // Added the second condition for group of groups. Quite a hack
      return true;
    }
    // Orga faq is shown only if it's checked on customFaq and the user's role is orga
    if (child === 'organizer') {
      if (
        (
          this.customFaq?.selectedFragments.length < 1
          || this.customFaq?.selectedFragments.includes(this.faq[child].fragment)
        )
        &&
        (this.user?.role === Role.Orga || this.user?.role === Role.Admin)) {
        return true;
      }
      return false;
    }
    if (this.customFaq?.selectedFragments.length < 1) {
      return true;
    }
    return this.customFaq?.selectedFragments.includes(this.faq[child].fragment);
  }

  public openNextTreeNode(child: string, node: FAQItem) {
    this.isWaiting = true;
    this.scrollBottom();
    this.buttonsClicked.push(child);
    this.archives.push(this.currentTreeNode);
    this.currentTreeNode = node;
    this.currentTreeNode.name = this.customOrOriginalData(child)?.name;
    setTimeout( () => {
      this.isWaiting = false;
      this.scrollBottom();
    }, 1000);
  }

  public changeResponse(e: number, newNodeValue: string) {
    this.isWaiting = true;
    this.scrollBottom();
    this.isSatisfied = undefined;
    this.showfeedback = false;
    this.currentTreeNode = this.faq?.[newNodeValue];
    this.currentTreeNode.name = this.customOrOriginalData(newNodeValue)?.name;
    this.archives.length = e + 1;
    this.buttonsClicked.length = e + 1;
    this.buttonsClicked.pop();
    this.buttonsClicked.push(newNodeValue);
    setTimeout( () => {
      this.isWaiting = false;
      this.scrollBottom();
    }, 1000);
  }

  private scrollBottom() {
    if (this.isBrowser) {
      const el = this.scrollContainer?.nativeElement as HTMLElement;
      if (el) {
        setTimeout( () => {
          el.scrollIntoView({ block: 'end', inline: 'end', behavior: 'smooth' });
        }, 250);
      }
    }
  }

  public scrollTopOfThis(panel: HTMLElement) {
    this.showfeedback = true;
    if (this.isBrowser) {
      if (panel) {
        setTimeout( () => {
          panel.scrollIntoView({ block: 'start', inline: 'start', behavior: 'smooth' });
        }, 250);
      }
    }
  }

  public customOrOriginalData(key: string) {
    return this.customFaq?.changesFromOriginal[this.faq[key]?.fragment] ?? this.faq[key];
  }

  public satisfied(){
    this.isSatisfied = true;
    this.scrollBottom();
  }

  public unsatisfied() {
    this.isSatisfied = false;
    this.scrollBottom();
  }

  public onCloseClick(): void {
    this.dialogRef.close();
  }

  ngOnDestroy() {
    this.langSub?.unsubscribe();
    this.eventSub?.unsubscribe();
    this.authSub?.unsubscribe();
  }

}
