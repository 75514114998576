
<mat-form-field
  class="form__field"
  *transloco="let t"
  [subscriptSizing]="'dynamic'"
  >
  <mat-label>{{ options?.label }}</mat-label>
  <input
    #input
    type="text"
    matInput
    [formControl]="control"
    [matAutocomplete]="auto"
    [required]="validators.includes('required')"
    >
  <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn">
    @for (option of filteredSelections | async; track option) {
      <mat-option
        [value]="option.value"
        [disabled]="option.disabled"
        (click)="input.blur()"
        >
        {{ option.viewValue }}
      </mat-option>
    }
  </mat-autocomplete>
  @if (options?.iconName) {
    <mat-icon matSuffix> {{ options.iconName }}</mat-icon>
  }
  <mat-hint>{{options?.hint}}</mat-hint>
  @if (control?.errors) {
    <mat-error>{{ t('formErrors.' + Objectkeys(control.errors) ) }}</mat-error>
  }
</mat-form-field>
