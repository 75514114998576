export const environment = {
  production: true,
  test: false,
  domain: 'https://reelax-tickets.com/',
  apiUrl: 'https://reelax-tickets.com/api/',
  strapiUrl: 'https://reelax-tickets.com/strapi/',
  port: 4080,
  secretKey: 'galaxy-strap-if-noise-strange-self-forbid-leash-meeting',
  envName: 'prod',
  mangopayDashboard: 'https://dashboard.mangopay.com/',
  matomo: {
    trackers: [
      {
        trackerUrl: 'https://mtm.reelax-tickets.com',
        siteId: '1',
        scriptUrl: 'https://mtm.reelax-tickets.com/matomo.js',
      },
    ],
  },
};
