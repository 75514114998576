import { IFaq } from '../../app/models';

export const faq: IFaq = {
  root: {
    id: 500,
    fragment: 'root',
    question: 'To make it easier for help to arrive, can you tell us a little more about your profile? 👨‍🚒',
    children: ['buyer', 'seller', 'organizer'],
  },
  // Depth = 1
  buyer: {
    id: 501,
    name: 'Buyer',
    fragment: 'acheteur',
    question: 'My question concerns...',
    children: ['buyer_event', 'buyingTicket', 'waitingList', 'autoPurchase', 'myAccountBuyer', 'order', 'buyer_aboutReelax'],
  },
  seller: {
    id: 502,
    name: 'Seller',
    fragment: 'vendeur',
    question: 'My question concerns...',
    children: ['seller_event', 'putOnSale', 'myAccountSeller', 'friendSale', 'payement',  'cancelSelling', 'seller_aboutReelax'],
  },
  organizer: {
    id: 503,
    name: 'Organizer',
    fragment: 'organisateur',
    question: 'My question concerns...',
    children: ['settingUpMarketPlace', 'orga_aboutReelax', 'accounting', 'data'],
  },
  // Depth = 2
  seller_aboutReelax: {
    id: 504,
    name: 'How Reelax Tickets works',
    fragment: 'vendeur_fontionnement_reelax',
    children: ['seller_whyReelax'],
  },
  putOnSale: {
    id: 505,
    name: 'A put on sale',
    fragment: 'vendeur_mise-en-vente',
    question: 'And more specifically ...',
    children: ['sellPrice', 'categoryOrBarcode', 'cashless', 'privateSale', 'saleProcess'],
  },
  cancelSelling: {
    id: 506,
    name: 'The cancellation of a sale',
    fragment: 'vendeur_annulation-vente',
    children: ['howToWithdraw', 'ticketNotResale', 'resaleOnMyOwn', 'putOnSaleBySomeone'],
  },
  myAccountSeller: {
    id: 507,
    name: 'My account',
    fragment: 'vendeur_mon-compte',
    children: ['cantValidIKYC', 'whyIDCard', 'cantFindConfirmationMail', 'forgotPassword', 'noMoreAccess', 'modifyEmail', 'deleteAccount'],
  },
  friendSale: {
    id: 508,
    name: 'A friend sale',
    fragment: 'vendeur_vente-ami',
    children: ['howToFriendSale', 'modifyTicketNameSeller'],
  },
  payement: {
    id: 509,
    name: 'A payement',
    fragment: 'vendeur_paiement',
    children: ['whenReceiveMoney'],
  },
  seller_event: {
    id: 510,
    name: 'An event',
    fragment: 'vendeur_event',
    children: ['dontFindEventSeller'],
  },
  buyer_aboutReelax: {
    id: 511,
    name: 'How Reelax Tickets works',
    fragment: 'acheteur_fontionnement_reelax',
    children: ['buyer_whyReelax', 'differentPage'],
  },
  buyer_event: {
    id: 512,
    name: 'An event',
    fragment: 'acheteur_event',
    children: ['dontFindEventBuyer', 'canceledEvent'],
  },
  buyingTicket: {
    id: 513,
    name: 'Buying a ticket',
    fragment: 'acheteur_achat-billet',
    children: ['howToBuy', 'searchForATichet', 'whatIsReserved', 'howManyFees', 'howToBuyToFriend'],
  },
  waitingList: {
    id: 514,
    name: 'The waiting list',
    fragment: 'acheteur_liste-attente-btn',
    children: ['waitingListWorking', 'modifyWaitingList', 'losingPlaceWaitingList', 'knowingMyPosition', 'stepbackInJauge', 'multipleTickets', 'quantityLimit', 'dontReceiveAlerts', 'desinscriptionWaitingList', 'availableTicketsNoAlert'],
  },
  autoPurchase: {
    id: 515,
    name: 'The auto purchase',
    fragment: 'acheteur_achat-auto',
    children: ['whyActivateAutomaticPurchase', 'activateDesactivateAutoPurchase', 'cantActivateAutoPurchase', 'modifyCreditCard', 'purchaseOptionFunctioning', 'autoPurchaseEventDate', 'dontReceiveAlertSinceAutoPurchase', 'SMSAlertAutomaticPurchase', 'deleteCardAutomaticPurchase'],
  },
  myAccountBuyer: {
    id: 516,
    name: 'My account',
    fragment: 'acheteur_mon-compte',
    children: ['modifyEmailBuyer', 'forgotPasswordBuyer', 'noMoreAccessBuyer', 'deleteAccountBuyer'],
  },
  order: {
    id: 517,
    name: 'My order',
    fragment: 'acheteur_ma-commande',
    children: ['modifyTicketNameBuyer', 'pickupTicket', 'invoiceAfterBuying', 'emailMistakeInPurchase', 'dontReceiveTicket', 'canICancelSale'],
  },
  settingUpMarketPlace: {
    id: 518,
    name: 'The setting up of a marketplace',
    fragment: 'orga_mise-en-place-bob',
    children: ['softwareCompatibility', 'priceSettingUp', 'howSettingUp', 'timeSettingUp', 'reelaxConsultant', 'canIRefundBuyers', 'presenceReelax', 'whoTakesCareSAV'],
  },
  orga_aboutReelax: {
    id: 519,
    name: 'How Reelax Tickets works',
    fragment: 'orga_fontionnement_reelax',
    children: ['orga_whyReelax', 'howSecure', 'whyDoingThat', 'whereAreWe'],
  },
  accounting: {
    id: 520,
    name: 'Accounting',
    fragment: 'orga_compatibilite',
    children: ['softwareCompatibility', 'interferenceDashboard', 'reelaxInTicketingSystem'],
  },
  data: {
    id: 521,
    name: 'My data',
    fragment: 'orga_donnees',
    children: ['integrabilityData', 'whoOwnsData', 'myCgv', 'reelaxProvider'],
  },
  // Depth = 3+
  seller_whyReelax: {
    id: 35,
    fragment: 'vendeur_pourquoi-reelax',
    question: 'Why is Reelax Tickets the most secure resale platform to buy and sell your used tickets?',
    score: 0,
    answer: 'We use <strong>the highest security protocol</strong> to protect you from ticket fraud. Specifically, we are connected to ticketing databases to check if the tickets offered for sale on our platform are valid. Then, as soon as a ticket is purchased on our platform, we instantly create a <strong>new ticket</strong> with <strong>your name</strong>. ',
    children: [],
  },
  sellPrice: {
    id: 522,
    name: 'A selling price',
    children: ['canIChoosePrice', 'priceOfSelling', 'whyPriceDifference', 'howChangePrice'],
  },
  canIChoosePrice: {
    id: 21,
    fragment: 'vendeur_choix-prix',
    question: 'Can I freely define my selling price?',
    score: 1,
    answer: 'The pricing of tickets is not free and is governed by two conditions:      <br>- The pricing policy of the organizer, who sets the minimum and maximum amounts at which tickets can be resold;      <br>- In France, for example, when the event is subsidized or assisted by the State, a town hall, a department or a region, resale is possible on condition that the price of the ticket is not higher than the one displayed.      ',
    children: [],
  },
  priceOfSelling: {
    id: 22,
    fragment: 'vendeur_cout-vente',
    question: 'How much does it cost to put my ticket on sale?',
    score: 1,
    answer: `It's <span class="fw-700">free</span> to put a ticket on sale on Reelax Tickets!      <br>
<br>The service fee is added to the ticket price you set and is paid by the buyer. The service fee is a percentage of the ticket price and can vary significantly depending on the security protocols in place to secure the transaction.      <br>
<br>No other additional fees will be charged on Reelax Tickets services.      <br>
<br>All prices are displayed inclusive of tax on Reelax Tickets services.      <br>
<br>
<br><span class="fw-700">Related question:</span>     <br>- <a href="/faq/vendeur#vendeur_delai-paiement" class="link --primary">When do I get my money?</a>     `,
    children: [],
  },
  whyPriceDifference: {
    id: 25,
    fragment: 'vendeur_frais-de-service',
    question: 'Why is my ticket not the same price as the one I indicated?',
    score: 1,
    answer: `When you sell a ticket on Reelax Tickets, you need to indicate the amount you want to receive in your bank account and we will indicate the price at which your ticket will be available for purchase.      <br>
<br>These two prices are different because we add a <strong>bank fee</strong> and our <strong>service fee</strong> to be paid by the buyer to guarantee the validity of the purchased ticket and maximize your chances of reselling your tickets.      `,
    children: [],
  },
  howChangePrice: {
    id: 34,
    fragment: 'vendeur_modifier-prix',
    question: 'How do I change my ticket price?',
    score: 0,
    answer: `From the <a href="/mon-compte/mes-billets#Vente" target="_blank" class="link --primary"><span class="material-icons" translate="no">launch</span>My tickets</a> tab, access your ticket on sale via the <span class="fw-700">On sale ticket(s)</span> section, then click on <strong>Edit the price</strong>. You can change the selling price of your ticket if the event organizer allows it! Once you have chosen your price, click on the <strong>Validate</strong> button. 
<img src="assets/img/faq/enUS/vente/18-1-comment-faire-pour-modifier-le-prix-de-mon-billet.png" class="faq-screenshots" />The value on the left is the amount you will receive and the value on the right is what the buyer will have to pay. Once you have chosen your price, click on the <strong>Validate</strong> button. <img src="assets/img/faq/enUS/vente/18-2-comment-faire-pour-modifier-le-prix-de-mon-billet.png" class="faq-screenshots" />`,
    children: [],
  },
  categoryOrBarcode: {
    id: 523,
    name: 'My ticket category or barcode',
    fragment: 'vendeur_categorie_code_barres',
    children: ['cantPutOnSale', 'dontFindBarcode', 'dontFindCategory', 'culturePassResalable', 'sellAlreadyUsed'],
  },
  cashless: {
    id: 524,
    name: 'Preloaded cashless',
    fragment: 'vendeur_cashless',
    children: ['linkCashless'],
  },
  privateSale: {
    id: 525,
    name: 'My ticket is on private sale',
    fragment: 'vendeur_vente-prive',
    children: ['ticketNotVisible'],
  },
  saleProcess: {
    id: 526,
    name: 'The sales process',
    fragment: 'vendeur_deroulement-vente',
    children: ['howToSell', 'increaseChanceToSell', 'canIResellReelaxTicket', 'sendTicketToBuyer'],
  },
  cantPutOnSale: {
    id: 29,
    fragment: 'vendeur_mise-en-vente-impossible',
    question: 'Why can\'t I put my ticket on sale?',
    score: 0,
    answer: `There may be several reasons why your ticket cannot be put on sale.     <br>
<br>Most often, it is an error in the barcode (the barcode usually begins with one of the following numbers: 66, 69, 1, 02, 002, 99, 43...).     <br>Don't forget to click on the <span class="material-icons" translate="no">info</span> point to get examples of barcodes!     <br>We recommend that you load your ticket to avoid errors. Also, some tickets have a hidden number in the barcode that is not visible on the ticket. <img src="assets/img/faq/enUS/vente/13-pourquoi-je-n-arrive-pas-a-mettre-en-vente-mon-billet.png" class="faq-screenshots" />However, some categories may not be allowed for resale or may only be allowed when the event is sold out.    <br>
<br>Of course, if your ticket is an invitation or has already been refunded by the organizer, you will not be able to resell it.   <br>
<br>If your ticket doesn't go on sale, you can click on the <strong>Ask for help</strong> button.
We will then receive your ticket and be able to guide you!
<br>
<br>
<br><span class="fw-700">Related questions:</span>    <br>- <a href="/faq/vendeur#vendeur_vente-privee" class="link --primary">How do I sell a ticket to a friend?</a>    <br>- <a href="/faq/vendeur#vendeur_comment-vendre" class="link --primary">How do I put my ticket on sale?</a>    <br>- <a href="/faq/vendeur#vendeur_revendre-billet-reelax" class="link --primary">Can I resell my ticket purchased on Reelax Tickets?</a>    <br>`,
    children: [],
  },
  howToSell: {
    id: 16,
    fragment: 'vendeur_comment-vendre',
    question: 'How do I put my ticket on sale?',
    score: 1,
    answer: `1) Select the event for which you wish to sell a ticket from the <a href="/evenements" target="_blank" class="link --primary"><span class="material-icons" translate="no">launch</span>Events</a> tab.    <br>
<br>2) Click on <strong>Sell a ticket</strong>. <img src="assets/img/faq/enUS/vente/1-1-comment-vendre-mon-billet.png" class="faq-screenshots" />3) Indicate the category of your ticket. <img src="assets/img/faq/enUS/vente/1-2-comment-vendre-mon-billet.png" class="faq-screenshots" />4) Upload your ticket or manually enter your first and last name and the barcode of your ticket (click on the <span class="material-icons" translate="no">info</span> point if you can't find your barcode). <img src="assets/img/faq/enUS/vente/1-3-comment-vendre-mon-billet.png" class="faq-screenshots" /><strong>⚠️ Some tickets hide a number in the barcode that is not displayed on the ticket. We recommend you use automatic recognition to avoid errors!</strong>     <br>
<br>5) Determine and validate the price of your ticket.      <br>You can always change the price later, if you wish. <img src="assets/img/faq/enUS/vente/1-4-comment-vendre-mon-billet.png" class="faq-screenshots" />6) <a href="/connexion" target="_blank" class="link --primary"><span class="material-icons" translate="no">launch</span>Log in</a>, or if you don't have an account <a href="/connexion?register=true" target="_blank" class="link --primary"><span class="material-icons" translate="no">launch</span>Sign up</a> by confirming your email, then fill in your personal information or the information of the person who will receive the money if it is not you. <img src="assets/img/faq/enUS/vente/1-5-comment-vendre-mon-billet.png" class="faq-screenshots" />7) Fill in your ID and bank account or the information of the person who will receive the money (this step can be done later if you wish) and then validate to complete the sale of your ticket by clicking on <strong>Put up for public sale</strong>   <br>
<br>8) Your ticket is now on sale on the event page or in <a href="/faq/vendeur#vendeur_billet-liste-attente" class="link --primary">our private waiting lists</a> 🎉<br> In order to multiply your chances to sell your ticket, share the link of your sale announcement on your social networks ;)  <br>
<br>Once the sale is confirmed, you will receive a confirmation email with the summary of your ad and a reminder of the ticket sale price.      <br>
<br>Once your ticket is on sale, <strong>it can be purchased at any time</strong> without any confirmation and you will receive an email notification that your ticket has been sold.     <br>
<br>As a reminder, Reelax Tickets does not save any identity documents in its system, each of them is immediately sent to MANGOPAY, which will securely perform the necessary checks.      <br>
<br>
<br><span class="fw-700">Related questions:</span>    <br>- <a href="/faq/vendeur#vendeur_modifier-prix" class="link --primary">How do I change the price of my ticket?</a>    <br>- <a href="/faq/vendeur#vendeur_billet-liste-attente" class="link --primary">Why isn't my ticket visible to everyone?</a>    <br>- <a href="/faq/vendeur#vendeur_delai-paiement" class="link --primary">When do I receive my money?</a>     `,
    children: [],
  },
  increaseChanceToSell: {
    id: 28,
    fragment: 'vendeur_augmenter-chances-vente',
    question: 'How can I increase the chances of selling my ticket?',
    score: 0,
    answer: `First of all, it is important to know that the majority of tickets are resold in the last two weeks before the event.      <br>
<br>That said, here are two tips to maximize your chances of reselling your ticket:      <br>
<br>1) When you go to <a href="/mon-compte/mes-billets#Vente" target="_blank" class="link --primary"><span class="material-icons" translate="no">launch</span>My tickets</a>, if the organizer has authorized the sale at a price lower than the purchase price, you can change the ticket's sale price by clicking on <span class="fw-700">Edit the price</span> from <span class="fw-700">On sale ticket(s)</span>.     <br>
<br>2) Share the link of your ad on your social networks by going to <a href="/mon-compte/mes-billets#Vente" target="_blank" class="link --primary"><span class="material-icons" translate="no">launch</span>My tickets</a> (see screenshot below)!      <br>To get the link, click on the <span class="fw-700">Share my ticket</span> button.      <br><span class="fw-700">This tip multiplies by 3 your chances to resell your ticket!</span> <img src="assets/img/faq/enUS/vente/12-comment-augmenter-les-chances-de-vendre-mon-billet.png" class="faq-screenshots" />3) Finally, your ticket remains valid as long as it has not been sold, so you can go to the event with the ticket or give it to a relative.      <br>
<br>However, a last minute purchase is not impossible, so if you decide to go to the event or give your ticket away, <span class="fw-700">you must absolutely withdraw your ticket</span> from the <span class="fw-700"> <a href="/mon-compte/mes-billets#Vente" target="_blank" class="link --primary"><span class="material-icons" translate="no">launch</span>My tickets</a> page to avoid being denied access to the event!</span>     <br>In fact, <span class="fw-700">your ticket will be deactivated the moment it is sold and will therefore no longer allow you access to the event!</span>     <br>
<br>
<br><span class="fw-700">Related questions:</span><br>- <a href="/faq/vendeur#vendeur_modifier-prix" class="link --primary">How do I change the price of my ticket?</a>     <br>- <a href="/faq/vendeur#vendeur_non-vendu" class="link --primary">If my ticket is not sold, can I go to the concert with it?</a>  <br>- <a href="/faq/vendeur#vendeur_vendu-ailleurs" class="link --primary">What if I finally sold my ticket on my own? </a>   `,
    children: [],
  },
  dontFindBarcode: {
    id: 37,
    fragment: 'vendeur_trouver-code-barres',
    question: 'I can\'t find my barcode number when I try to sell it, what can I do?',
    score: 0,
    answer: `Don't bother looking for your barcode, we'll take care of it for you! All you have to do is drag and drop your ticket into the dedicated slot, or take a photo of it if you're using a smartphone.
    <br>
    <br>
    <img src="assets/img/faq/enUS/vente/37-detection-code-barres.png" class="faq-screenshots" />
    If automatic recognition does not find a barcode, you can try entering it manually via the <strong>Fill in my barcode manually</strong> button. This number can usually be found underneath the barcode, or next to the heading " Ticket number". The barcode usually begins with one of the following numbers: 66, 69, 1, 02, 002, 99, 43... It should not be confused with order numbers (which sometimes begin with the letter T).
    If this still doesn't work, you can click on the <strong>Ask for help</strong> button. We will then receive your ticket and be able to guide you!
<br><span class="fw-700">Related questions:</span><br>- <a href="/faq/vendeur#vendeur_comment-vendre" class="link --primary">How do I put my ticket on sale?</a>    <br>- <a href="/faq/vendeur#vendeur_mise-en-vente-impossible" class="link --primary">Why can't I put my ticket on sale?</a>    <br>`,
    children: [],
  },
  linkCashless: {
    id: 33,
    fragment: 'vendeur_recuperer-cashless',
    question: 'What if I linked my ticket to a cashless account for the event?',
    score: 0,
    answer: `Your money will not be <span class="fw-700">transferred to the new ticket holder!</span>     <br>
<br>After the event, the event organizer will make available to the spectators a module allowing you to recover the money linked to your cashless account by filling in your ticket information.      <br>
<br><strong>This action will not be automatic</strong>, so we recommend that you regularly check the social networks and/or the organizer's website after the event to find this information.      `,
    children: [],
  },
  dontFindCategory: {
    id: 38,
    fragment: 'vendeur_categorie-indisponible',
    question: 'I can\'t find my category in the list, what should I do?',
    score: 0,
    answer: `Some categories may not be authorized for resale (reduced rates, CE, Culture Pass...).      <br>
<br>
If an error message says “Resale is not open for tickets in this category”, click on the <strong>Send to support</strong> button to send us your ticket. If your ticket is resaleable, we'll get back to you.
<img src="assets/img/faq/enUS/vente/38-trouve-pas-categorie.png" class="faq-screenshots" />
<br>
<br>
<br><span class="fw-700">Related questions:</span><br>- <a href="/faq/vendeur#vendeur_comment-vendre" class="link --primary">How do I put my ticket on sale?</a>    <br>- <a href="/faq/vendeur#vendeur_mise-en-vente-impossible" class="link --primary">Why can't I put my ticket on sale?</a>    <br>`,
    children: [],
  },
  culturePassResalable: {
    id: 68,
    fragment: 'vendeur_pass-culture',
    question: 'Can I sell my ticket purchased with the culture pass?',
    score: 0,
    answer: `
    Unfortunately, we are not authorized to allow the resale of tickets purchased via the culture pass.      `,
    children: [],
  },
  sellAlreadyUsed: {
    id: 69,
    fragment: 'vendeur_diviser-pass',
    question: 'Can I sell a pass I\'ve already used?',
    score: 0,
    answer: `
    If your ticket has already been scanned, you will no longer be able to sell it on our platform, even if it is still valid for the following days. Ticket offices do not allow us to accept resales of tickets that have already been used.
    <br><br>
If you wish to access the event on a particular day and not on all days as indicated on your pass. You can register on the waiting list, via the dedicated event page on Reelax Tickets, and try to obtain a ticket for the desired day.
Then, once you've obtained your new ticket, you can put your first pass on sale.
      `,
    children: [],
  },
  ticketNotVisible: {
    id: 59,
    fragment: 'vendeur_billet-liste-attente',
    question: 'Why isn\'t my post visible to everyone?',
    score: 0,
    answer: `Your ticket can be reserved temporarily (24 hours maximum) for users on the waiting list. If your ticket is not sold after this period, it will be available on the platform for all users to buy.    <br>
<br>You can find the link to share your ticket with your social networks in your <a href="/mon-compte/mes-billets#Vente" target="_blank"class="link --primary"><span class="material-icons" translate="no">launch</span>My tickets</a> page in the <span class="fw-700">On sale ticket(s)</span> section or in the confirmation email. To make it easier to find this email, please note that the subject line is: <strong>Your ticket has been put on sale</strong>.     <br>
<br>You can check at any time that your ticket is on sale from your <a href="/mon-compte/mes-billets#Vente" target="_blank" class="link --primary"><span class="material-icons" translate="no">launch</span>My tickets</a> page in the <span class="fw-700">On sale ticket(s)</span> section. <img src="assets/img/faq/enUS/vente/3-pourquoi-mon-billet-n-est-pas-visible-par-tout-le-monde.png" class="faq-screenshots" />
<br><span class="fw-700">Related questions:</span><br>- <a href="/faq/vendeur#vendeur_vente-privee" class="link --primary">How do I sell a ticket to a friend?</a>    <br>- <a href="/faq/vendeur#vendeur_delai-paiement" class="link --primary">When do I get my money?</a>    <br>- <a href="/faq/vendeur#vendeur_augmenter-chances-vente" class="link --primary">How can I increase my chances of selling my ticket?</a>     `,
    children: [],
  },
  sendTicketToBuyer: {
    id: 27,
    fragment: 'vendeur_envoi-acheteur',
    question: 'Do I have to send my ticket to the buyer?',
    score: 1,
    answer: `No, you don't have to do anything!      <br>
<br>Indeed, when you sell your ticket on Reelax Tickets, your old ticket is invalidated and <strong>the buyer automatically receives a new ticket</strong> in his name.     <br>
<br>You can get rid of your old ticket since once the ticket is sold, the barcode has been disabled.      <br>
<br>
<br><span class="fw-700">Related questions:</span>     <br>- <a href="/faq/vendeur#vendeur_vente-privee" class="link --primary">How do I sell a ticket to a friend? </a>     `,
    children: [],
  },
  canIResellReelaxTicket: {
    id: 31,
    fragment: 'vendeur_revendre-billet-reelax',
    question: 'Can I resell my ticket purchased on Reelax Tickets?',
    score: 0,
    answer: `<strong>Yes !</strong><br>You can use the <strong>Resell this ticket</strong> button, available on the <a href="/mon-compte/mes-billets#Achat" target="_blank"class="link --primary"><span class="material-icons" translate="no">launch</span>My Tickets</a> page, in the <strong>Purchased tickets</strong> section.      <br>
<br>Please note that for security reasons, we may limit the number of resales per ticket. If the sale is unsuccessful, click on the <strong>Ask for help</strong> button. We will then receive your ticket and be able to guide you!      <br>
<br>
<br><span class="fw-700">Related question:</span><br>- <a href="/faq/vendeur#vendeur_comment-vendre" class="link --primary">How do I put my ticket on sale?</a>    `,
    children: [],
  },
  howToWithdraw: {
    id: 32,
    fragment: 'vendeur_annuler-vente',
    question: 'How do I remove my ticket from sale?',
    score: 0,
    answer: `You can withdraw you ticket from sale from your personal space via <a href="/mon-compte/mes-billets#Vente" target="_blank" class="link --primary"><span class="material-icons" translate="no">launch</span>My tickets</a>, provided that the ticket in question is neither sold nor reserved.      <br>
<br>After selecting the ticket you wish to cancel, click on <strong>Withdraw from sale</strong> and then click on <strong>Confirm</strong>. <img src="assets/img/faq/enUS/vente/16-1-comment-retirer-de-la-vente-mon-billet.png" class="faq-screenshots" />
<img src="assets/img/faq/enUS/vente/16-2-comment-retirer-de-la-vente-mon-billet.png" class="faq-screenshots" />As a reminder, Reelax Tickets is not responsible for the sale of your ticket and cannot cancel a sale with a buyer.      `,
    children: [],
  },
  ticketNotResale: {
    id: 19,
    fragment: 'vendeur_non-vendu',
    question: 'If my ticket is not sold, can I still go to the concert with it?',
    score: 1,
    answer: `Yes, your ticket remains valid if it has not been sold.     <br>
<br>If you decide to go to the event, you <strong>must absolutely remove your</strong> sale <strong>announcement</strong> by going to the tab <a href="/mon-compte/mes-billets#Vente" target="_blank" class="link --primary"><span class="material-icons" translate="no">launch</span>My tickets</a> in <span class="fw-700">On sale ticket(s)</span> to avoid a bad surprise!      <br>
<br>Indeed, if your ticket is purchased on our platform, your barcode will be invalidated and you will not be able to access the event anymore.      <br>
<br>
<br><span class="fw-700">Related question:</span>     <br>- <a href="/faq/vendeur#vendeur_annuler-vente" class="link --primary">How to remove my ticket from sale?</a>    `,
    children: [],
  },
  resaleOnMyOwn: {
    id: 24,
    fragment: 'vendeur_vendu-ailleurs',
    question: 'What if I finally sold my ticket on my own?',
    score: 0,
    answer: `In case you find a buyer on your own, you will have to <span class="fw-700">remove your ticket from</span> the <a href="/mon-compte/mes-billets#Vente" target="_blank" class="link --primary"><span class="material-icons" translate="no">launch</span>My tickets</a> page to avoid that the person who bought your ticket is refused at the entrance of the event.      <br>
<br>Indeed, if this same ticket is bought on our platform, your barcode will be invalidated, so the person who bought your ticket outside the platform will be refused entry!      <br>
<br><strong>As a reminder, it is strictly forbidden to resell a ticket that has already been resold on our platform! It is your duty to check and cancel your sale before trying to sell your ticket through another means than our platform</strong>.      <br>
<br>
<br><span class="fw-700">Related questions:</span><br>- <a href="/faq/vendeur#vendeur_annuler-vente" class="link --primary">How do I remove my ticket from the sale?</a>    <br>- <a href="/faq/vendeur#vendeur_vente-privee" class="link --primary">How do I sell a ticket to a friend? </a>     <br>`,
    children: [],
  },
  putOnSaleBySomeone: {
    id: 85,
    fragment: 'vendeur_mis-en-vente-par-autrui',
    question: 'What can I do if my ticket has been put on sale without my authorization?',
    score: 0,
    answer: `
      Depending on the ticket shop where you bought your ticket, if someone put your ticket on sale with a different email than yours, you may have received an email "Your ticket has just been put on sale on Reelax Tickets".
      <br>
      <br>
      This email mentions part of the first and last name of the person responsible. If you know this person, we recommend that you contact them directly to resolve the situation.
      <br>
      <br>
      In the event of theft or if you do not recognize the person in question, please
      <a href="/contact" target="_blank" class="link --primary"><span class="material-icons" translate="no">launch</span>contact us</a>.
      <br>
      We can then act to block the sale.
      `,
    children: [],
  },
  cantValidIKYC: {
    id: 30,
    fragment: 'vendeur_aide-identite',
    question: 'I can\'t get my identity validated, what should I do?',
    score: 0,
    answer: `Some problems are recurrent during the validation of the identity documents:      <br>
<br>- The name entered in your account is not your birth name,     <br>- The photo sent is too blurred or does not contain the entire document,      <br>- The ID is no longer valid,      <br>- The ID does not belong to an adult.      <br>
<br>In order to submit your ID, we recommend the following tips:     <br>- Documents must be in PNG or JPEG format,      <br>- Documents must be valid, legible, uncut, and in color,      <br>- photos taken with a smartphone work better than scans,      <br>- the size of all documents must be less than 7 MB.   <br>
<br>In case your documents are rejected, you will receive an e-mail indicating that your document could not be validated by our payment provider and that you have to start the process again. Usually, the reason for the rejection will be described in the same e-mail, so please take it into account when sending your documents again.      <br>
<br>If you realize that any of the information in your profile is incorrect, please go to the <a href="/mon-compte/informations-personnelles" target="_blank" class="link --primary"><span class="material-icons" translate="no">launch</span>Personal Information</a> tab to change your information. If you are a minor, you can fill in a piece of identification for a relative, remembering to change your Reelax Tickets profile information first.      <br>Please note that if you edit your personal information, the documents previously sent will be cancelled and you will have to send a new ID corresponding to the new information entered.      <br>
<br>If you still have problems, please contact us via our <a href="/mon-compte/mes-billets#Vente" target="_blank" class="link --primary"><span class="material-icons" translate="no">launch</span>Contact</a> page.     <br>
<br>There is no time limit to validate your identity, as your money is stored in an electronic safe indefinitely. This safe can only be unlocked with the validation of your ID.   <br>
<br>As a reminder, Reelax Tickets does not save any identity documents in its system, but each one is immediately sent to MANGOPAY, which takes care of the necessary verifications in complete security.`,
    children: [],
  },
  whyIDCard: {
    id: 39,
    fragment: 'vendeur_identite',
    question: 'Why do I need to send an ID for the sale? ',
    score: 0,
    answer: `Rest assured, Reelax Tickets is the most secure platform in the ticket resale market, which is why we partner with organizers to allow everyone to resell their tickets, safely.      <br>
<br>Regarding the ID, in accordance with the latest European regulations (AML4 and PSD2) aimed at protecting consumers and reducing money laundering, our payment provider MangoPay needs to verify the identity of our sellers. Without this verification, our payment provider does not allow us to send the money to the sellers. Please note that we do not keep any documents: they are directly transmitted to MangoPay where they are processed in complete security      `,
    children: [],
  },
  cantFindConfirmationMail: {
    id: 36,
    fragment: 'vendeur_mail-confirmation',
    question: 'I can\'t find the account confirmation email, what should I do?',
    score: 0,
    answer: `If you can't find the confirmation email (with the subject <strong>Confirm your email address</strong>), we advise you to check if your mailbox has not redirected this email in your Spam section (or in the Promotions tab for Gmail).      <br>In this case, please make sure to indicate that Reelax Tickets is not spam!     <br>Indeed, we may have to send you important emails to send you your tickets or to notify you of your ticket sale.     <br>
<br>Some email addresses may receive the confirmation email with a delay, in this case, we advise you to be patient. However, if you have any difficulties to validate your account, please contact us via the <a href="/contact" target="_blank" class="link --primary"><span class="material-icons" translate="no">launch</span>Contact</a> page.     `,
    children: [],
  },
  forgotPassword: {
    id: 40,
    fragment: 'vendeur_mot-de-passe-oublie',
    question: 'I forgot my password, what should I do?',
    score: 0,
    answer: 'If you lose or forget your password, go to the <a href="/connexion" target="_blank" class="link --primary"><span class="material-icons" translate="no">launch</span>Login</a> page and click on the <span class="fw-700">forgot your password?</span> link which will allow you to create a new password by keeping the e-mail address you used when you created your account.      <br>Then, when you click the <span class="fw-700">Confirm</span> button, we will email you a link to create a new password.      ',
    children: [],
  },
  forgotPasswordBuyer: {
    id: 81,
    fragment: 'acheteur_mot-de-passe-oublie',
    question: 'I forgot my password, what should I do?',
    score: 0,
    answer: 'If you lose or forget your password, go to the <a href="/connexion" target="_blank" class="link --primary"><span class="material-icons" translate="no">launch</span>Login</a> page and click on the <span class="fw-700">forgot your password?</span> link which will allow you to create a new password by keeping the e-mail address you used when you created your account.      <br>Then, when you click the <span class="fw-700">Confirm</span> button, we will email you a link to create a new password.      ',
    children: [],
  },
  modifyEmail: {
    id: 70,
    fragment: 'vendeur_modifier-mail',
    question: 'How do I change my account e-mail address?',
    score: 0,
    answer: `
    If you would like to change the email address that is linked to your Reelax Tickets account, you can <a href="/contact" target="_blank" class="link --primary">contact us</a> with your new address so that we can make the change.    `,
    children: [],
  },
  modifyEmailBuyer: {
    id: 72,
    fragment: 'acheteur_modifier-mail',
    question: 'How do I change my account e-mail address?',
    score: 0,
    answer: `
    If you would like to change the email address that is linked to your Reelax Tickets account, you can <a href="/contact" target="_blank" class="link --primary">contact us</a> with your new address so that we can make the change.    `,
    children: [],
  },
  deleteAccount: {
    id: 79,
    fragment: 'vendeur_supprimer-compte',
    question: 'I want to delete my account. What should I do?',
    score: 0,
    answer: `
    For security reasons, you cannot delete your account directly from your My Account pages.
    <br>
    If you wish to delete your account, please contact us from the <a href="/contact" target="_blank" class="link --primary"><span class="material-icons" translate="no">launch</span>contact</a> page. Our team will verify your request and proceed with the deletion of your account.
    <br>
    <br>
    <br>
    <span class="fw-700">Related question:</span>
    <br>
    - <a href="/faq/vendeur#vendeur_modifier-mail" class="link --primary">How do I change my account e-mail address?</a>
    `,
    children: [],
  },
  deleteAccountBuyer: {
    id: 80,
    fragment: 'acheteur_supprimer-compte',
    question: 'I want to delete my account. What should I do?',
    score: 0,
    answer: `
    For security reasons, you cannot delete your account directly from your My Account pages.
    <br>
    If you wish to delete your account, please contact us from the <a href="/contact" target="_blank" class="link --primary"><span class="material-icons" translate="no">launch</span>contact</a>. Our team will verify your request and proceed with the deletion of your account.
    <br>
    <br>
    <br>
    <span class="fw-700">Related question:</span>
    <br>
    - <a href="/faq/acheteur#acheteur_modifier-mail" class="link --primary">How do I change my account e-mail address?</a>
    `,
    children: [],
  },
  noMoreAccess: {
    id: 82,
    fragment: 'vendeur_plus-acces',
    question: 'Je n\'ai plus accès à mon compte. Que faire ?',
    score: 0,
    answer: `
    If you can no longer access your account, it may have been locked due to too many login attempts. In this case, you should have received a "Your Reelax Tickets account is locked" email containing a link to unlock your account.
    <br>
    <br>
    If you haven't received an e-mail, try changing your password. A <strong>Forgot your password?</strong> feature is available on the
    <a href="/connexion" target="_blank" class="link --primary"><span class="material-icons" translate="no">launch</span>login</a> page.
    <br>
    <br>
    If the problem persists, it is possible that your e-mail address has been blocked. In this case, please
    <a href="/contact" target="_blank" class="link --primary"><span class="material-icons" translate="no">launch</span>contact us</a>
    so that we can unblock it for you.
    <br>
    <br>
    <br>
    <span class="fw-700">Related questions:</span>
    <br>
    - <a href="/faq/vendeur#vendeur_modifier-mail" class="link --primary">How do I change my account e-mail address?</a>
    <br>
    - <a href="/faq/vendeur#vendeur_mot-de-passe-oublie" class="link --primary">I forgot my password, what should I do?</a>
    `,
    children: [],
  },
  noMoreAccessBuyer: {
    id: 83,
    fragment: 'acheteur_plus-acces',
    question: 'Je n\'ai plus accès à mon compte. Que faire ?',
    score: 0,
    answer: `
    If you can no longer access your account, it may have been locked due to too many login attempts. In this case, you should have received a "Your Reelax Tickets account is locked" email containing a link to unlock your account.
    <br>
    <br>
    If you haven't received an e-mail, try changing your password. A <strong>Forgot your password?</strong> feature is available on the
    <a href="/connexion" target="_blank" class="link --primary"><span class="material-icons" translate="no">launch</span>login</a> page.
    <br>
    <br>
    If the problem persists, it is possible that your e-mail address has been blocked. In this case, please
    <a href="/contact" target="_blank" class="link --primary"><span class="material-icons" translate="no">launch</span>contact us</a>
    so that we can unblock it for you.
    <br>
    <br>
    <br>
    <span class="fw-700">Related questions:</span>
    <br>
    - <a href="/faq/acheteur#acheteur_modifier-mail" class="link --primary">How do I change my account e-mail address?</a>
    <br>
    - <a href="/faq/acheteur#acheteur_mot-de-passe-oublie" class="link --primary">I forgot my password, what should I do?</a>
    `,
    children: [],
  },
  howToFriendSale: {
    id: 23,
    fragment: 'vendeur_vente-privee',
    question: 'How to sell a ticket to a friend?',
    score: 0,
    answer: `When putting the ticket on sale, you need to check the <span class="fw-700">Enable private resale to a friend only</span> box in step 2 (below the price choice). When the sale is over, you will then need to share the ticket link with your friend so that they can buy it. <img src="assets/img/faq/enUS/vente/8-1-comment-vendre-un-billet-a-un-e-ami-e.png" class="faq-screenshots" />If you find a buyer after your ticket has gone on sale, you can retrieve a private link from your Account in the <a href="/mon-compte/mes-billets#Vente" target="_blank"class="link --primary"><span class="material-icons" translate="no">launch</span>My tickets</a> tab. Simply go to the ticket in question, then click on <span class="fw-700">Put on friend sale only</span>. With this feature, your ticket will no longer be on public sale. <img src="assets/img/faq/enUS/vente/8-2-comment-vendre-un-billet-a-un-e-ami-e.png" class="faq-screenshots" />Then click on <strong>Share my ticket</strong> to get the private link to share.      <br>
<br>
<br><span class="fw-700">Related questions:</span><br>- <a href="/faq/vendeur#vendeur_augmenter-chances-vente" class="link --primary">How to increase the chances of selling my ticket?</a>     <br>- <a href="/faq/vendeur#vendeur_modifier-prix" class="link --primary">How do I change the price of my ticket?</a>    <br>`,
    children: [],
  },
  modifyTicketNameBuyer: {
    id: 12,
    fragment: 'acheteur_modifier-nom-billet',
    question: 'How do I change the name on my ticket?',
    score: 0,
    answer: `
        You can use the resell to a friend feature by purchasing your own ticket through a second Reelax Tickets account.      <br>
    <br>
    <br>
  <span class="fw-700">Related questions:</span>     <br>
    - <a href="/faq/vendeur#vendeur_vente-privee" class="link --primary">How do I sell a ticket to a friend?</a>     <br>
    - <a href="/faq/acheteur#acheteur_alertes-mails" class="link --primary">I am no longer receiving email alerts. Is it my turn? </a>    `,
    children: [],
  },
  modifyTicketNameSeller: {
    id: 74,
    fragment: 'vendeur_modifier-nom-billet',
    question: 'How do I change the name on my ticket?',
    score: 0,
    answer: `
        You can use the resell to a friend feature by purchasing your own ticket through a second Reelax Tickets account.      <br>
    <br>
    <br>
  <span class="fw-700">Related questions:</span>     <br>
    - <a href="/faq/vendeur#vendeur_vente-privee" class="link --primary">How do I sell a ticket to a friend?</a>     <br>
    - <a href="/faq/acheteur#acheteur_alertes-mails" class="link --primary">I am no longer receiving email alerts. Is it my turn? </a>    `,
    children: [],
  },
  whenReceiveMoney: {
    id: 20,
    fragment: 'vendeur_delai-paiement',
    question: 'When do I get my money?',
    score: 1,
    answer: `
    The payment deadline will be communicated to you <span class="fw-700">before the sale is confirmed</span>.
      <br>
      <br>
      In order for us to send you your bank transfer, your <span class="fw-700">IBAN</span> and your <span class="fw-700">ID</span> must be filled in on <a href="/mon-compte/compte-bancaire" target="_blank "class="link --primary"><span class="material-icons" translate="no">launch</span>Bank Account</a>. The transfer can only be made once your ID has been validated by our payment service provider (Mangopay). In the meantime, your money will be stored in an electronic safe. This safe can only be unlocked with the validation of your ID.
      <br><br>
      Depending on the event and type of ticket, payment can be made within <span class="fw-700">5 working days after the sale</span>, or within <span class="fw-700">8 working days once the event has passed. </span> You can track your payment, directly from your <a href="/mon-compte/compte-bancaire" target="_blank" class="link --primary"><span class="material-icons" translate="no">launch</span>Account</a>. 
      <br><br>
      The transfer to your bank account should be made out to Mangopay, Mgp*Reelax Tick or reelax tick.
<br><br><br>
<span class="fw-700">Related question:</span>     <br>- <a href="/faq/vendeur#vendeur_aide-identite" class="link --primary">I can't get my ID validated, what should I do?</a>  <br>`,
    children: [],
  },
  dontFindEventBuyer: {
    id: 26,
    fragment: 'acheteur_evenement-indisponible',
    question: 'Why can\'t I find the event in the list?',
    score: 1,
    answer: `
    In order to guarantee the validity of the tickets that are exchanged on the platform, we propose the resale <strong>only for our partner events</strong>.      <br>
    If the event does not appear in the list of <a href="/evenements" target="_blank"class="link --primary"><span class="material-icons" translate="no">launch</span>Events</a>, do not hesitate to <a href="/contact" target="_blank"class="link --primary"><span class="material-icons" translate="no">launch</span>contact</a> us and tell us which event you are interested in. Our team will contact the organizer concerned.     <br>
    You can also send a message to the organizer to ask him to activate our service.
    `,
    children: [],
  },
  dontFindEventSeller: {
    id: 73,
    fragment: 'vendeur_evenement-indisponible',
    question: 'Why can\'t I find the event in the list?',
    score: 1,
    answer: `
    In order to guarantee the validity of the tickets that are exchanged on the platform, we propose the resale <strong>only for our partner events</strong>.      <br>
    If the event does not appear in the list of <a href="/evenements" target="_blank"class="link --primary"><span class="material-icons" translate="no">launch</span>Events</a>, do not hesitate to <a href="/contact" target="_blank"class="link --primary"><span class="material-icons" translate="no">launch</span>contact</a> us and tell us which event you are interested in. Our team will contact the organizer concerned.     <br>
    You can also send a message to the organizer to ask him to activate our service.
    `,
    children: [],
  },
  canceledEvent: {
    id: 66,
    fragment: 'acheteur_evenement-annule-reporte',
    question: 'What happens if the event is cancelled or postponed?',
    score: 0,
    answer: `
    In case of postponement or cancellation of the event, you will have the possibility to receive a refund via the event organizer or directly via Reelax Tickets. <strong>The modalities of reimbursement will be specified to you before the confirmation of your order</strong>.      <br>
    Apart from the cancellation or postponement of the event, in accordance with article L.121-28 of the French Consumer Code, the purchase of tickets on Reelax Tickets cannot be subject to a right of withdrawal. The ticket is neither exchangeable nor refundable.      <br>
    Nevertheless, if you are no longer interested in your ticket, you can easily resell it on our website.      <br>
    <br>
    <br>
  <span class="fw-700">Related questions:</span><br>
    - <a href="/faq/vendeur#vendeur_comment-vendre" class="link --primary">How do I put my ticket up for sale?</a>    <br>
    - <a href="/faq/acheteur#acheteur_annuler-achat" class="link --primary">I want to cancel my purchase. Can I get my money back? </a>    <br>
    - <a href="/faq/acheteur#acheteur_achat-automatique" class="link --primary">How do I activate/deactivate the automatic purchase option?</a>     `,
    children: [],
  },
  howToBuy: {
    id: 0,
    fragment: 'acheteur_comment-acheter',
    question: 'How to buy a ticket?',
    score: 1,
    answer: `
    1) Go to <a href="/evenements" target="_blank" class="link --primary"><span class="material-icons" translate="no">launch</span>Events</a>     <br>
    <br>
    2) Click on the event of your choice.      <br>
    <br>
    3) Click on the <span class="fw-700">Buy a ticket</span> button. <img src="assets/img/faq/enUS/achat/1-1-comment-acheter-un-billet.png" class="faq-screenshots" />4) Click on the ticket category you are interested in. <img src="assets/img/faq/enUS/achat/1-2-comment-acheter-un-billet.png" class="faq-screenshots" />5) Select the ticket you are interested in and click on the <span class="fw-700">Buy</span> button. <img src="assets/img/faq/enUS/achat/1-3-comment-acheter-un-billet.png" class="faq-screenshots" />6) If you want to see the price details you can click on the information point <span class="material-icons" translate="no">info</span>. 
    Then you can click on <strong>Next</strong>. <img src="assets/img/faq/enUS/achat/1-4-comment-acheter-un-billet.png" class="faq-screenshots" />7) Fill in the fields with the information of the buyer and the ticket holder, then click on <span class="fw-700">Next</span>. <img src="assets/img/faq/enUS/achat/1-5-comment-acheter-un-billet.png" class="faq-screenshots" />8) Fill in your credit card information and wait until you validate the 3D Secure payment of your bank which is automatically displayed on the page. You will then automatically return to the Reelax Tickets page.     <br>
    <br>
    9) You will then be able to download your ticket on the purchase confirmation page or directly in the email sent.     <br>
    <br>
    <br>
    <br>
    <span class="fw-700">Related questions:</span><br>
    - <a href="/faq/acheteur#acheteur_evenement-indisponible" class="link --primary">Why can't I find the event in the list? </a>    <br>
    - <a href="/faq/acheteur#acheteur_liste-attente" class="link --primary">How does the waiting list work?</a> (Useful if there are no tickets available for the category you are looking for!)     <br>
    - <a href="/faq/acheteur#acheteur_rang" class="link --primary">How do I know where I stand on the waiting list?</a>
    `,
    children: [],
  },
  searchForATichet: {
    id: 78,
    question: 'I\'m looking for a ticket. What should I do?',
    fragment: 'acheteur_cherche-billet',
    score: 1,
    answer: `
      On the event page in question, you can click on <strong>Buy a ticket</strong>. then click on <strong>Join the waiting list</strong>.
      This way, you will be notified if new tickets go on sale.
      <br>
      <br>
      Joining the waiting list is the only way to be informed of availability. Contacting us directly will not improve your chances of obtaining a ticket.
      <br>
      <br>
      <br>
      <span class="fw-700">Related questions:</span><br>
      - <a href="/faq/acheteur#acheteur_evenement-indisponible" class="link --primary">Why can't I find the event in the list? </a>    <br>
      - <a href="/faq/acheteur#acheteur_liste-attente" class="link --primary">How does the waiting list work?</a> (Useful if there are no tickets available for the category you are looking for!)     <br>
      - <a href="/faq/acheteur#acheteur_rang" class="link --primary">How do I know where I stand on the waiting list?</a>
      `,
    children: [],
  },
  whatIsReserved: {
    id: 10,
    fragment: 'acheteur_billet-reserve',
    question: 'What does "Reserved" mean on some tickets?',
    score: 1,
    answer: `
    When a ticket in the list of available tickets is marked as <strong>Reserved</strong> and not followed by the Buy button, it means that the ticket is in the shopping cart of another user and that he/she is finalizing his/her purchase.      <br>
    <br>
    A ticket can stay in a cart for a maximum of 10 minutes. If the payment is not made after 10 minutes, it will be available again.      <br>
    <br>
    Some tickets can be reserved before they are available on the public purchase page. These are tickets for sale to a friend, or tickets offered to people on the waiting list. <img src="assets/img/faq/enUS/achat/9-que-signifie-reserve-sur-certains-billets.png" class="faq-screenshots" />
    <br><span class="fw-700">Related questions:</span>     <br>
    - <a href="/faq/acheteur#acheteur_liste-attente" class="link --primary">How does the waiting list work?</a>    <br>
    - <a href="/faq/acheteur#acheteur_rang" class="link --primary">How do I know where I stand on the waiting list?</a>    `,
    children: [],
  },
  howManyFees: {
    id: 18,
    fragment: 'acheteur_frais-de-service',
    question: 'How much is the service charge for a ticket purchase? ',
    score: 0,
    answer: `
      Our service fee is in addition to the ticket price and is a percentage of the ticket price.      <br>
      <br>
      These fees may vary significantly depending on the <strong>security protocols</strong> in place to ensure transactions.      <br>
      <br>
      You can, at any time, access the price details before the order is confirmed by clicking on the <strong>Buy</strong> button of the ticket of your choice, then on the <span class="material-icons" translate="no">info</span>icon (see screenshot below): <img src="assets/img/faq/enUS/achat/17-1-a-combien-s-elevent-les-frais-de-service-pour-l-achat-d-un-billet.png" class="faq-screenshots" />
      
      <img src="assets/img/faq/enUS/achat/17-2-a-combien-s-elevent-les-frais-de-service-pour-l-achat-d-un-billet.png" class="faq-screenshots" />No other additional fees will be charged on Reelax Tickets services.      <br>
      All prices are displayed inclusive of all taxes on Reelax Tickets services.      <br>
      <br>
      <br>
    <span class="fw-700">Related question:</span>     <br>
      - <a href="/faq/acheteur#acheteur_pourquoi-reelax" class="link --primary">Why is Reelax Tickets the most secure resale platform to buy and sell your used tickets?</a>     <br>
  `,
    children: [],
  },
  howToBuyToFriend: {
    id: 16,
    fragment: 'acheteur_achat-prive',
    question: 'How do I buy a ticket from a friend?',
    score: 0,
    answer: `
    When the ticket is put on sale, your friend must check the <strong>Enable private resale to a friend only</strong> box in step 2 (below the price choice). At the end of the sale, he/she will then have to share with you the private link of the ticket that you will then be able to buy. <img src="assets/img/faq/enUS/achat/15-comment-acheter-un-billet-a-un-e-ami-e.png" class="faq-screenshots" />
    You don't need to give your email address to the seller. If he asks for it, it's probably a scam. 
    <br><br>
    Be careful, malicious people can send you false addresses from our website. Always check the <span class="fw-700">site address</span> (URL) and <span class="fw-700">do not share your e-mail with individuals on social networks</span>. Our URLs start with https://reelax-tickets.com/ . Different websites are fraudulent (e.g. "https://reelax-tickets.com.de/"). If in doubt, contact us.
    <br><br><br><span class="fw-700">Related questions:</span><br>
    - <a href="/faq/vendeur#vendeur_vente-privee" class="link --primary">How do I sell a ticket to a friend?</a>     <br>
    - <a href="/faq/acheteur#acheteur_modifier-nom-billet" class="link --primary">How do I change the name on my ticket? </a>     <br>
    - <a href="/faq/acheteur#acheteur_chances-billets" class="link --primary">I am looking for multiple tickets. Does this reduce my chances of getting tickets?</a>     `,
    children: [],
  },
  waitingListWorking: {
    id: 1,
    fragment: 'acheteur_liste-attente',
    question: 'How does the waiting list work?',
    score: 1,
    answer: `
    You want to buy a ticket for one of our events, but no tickets are available?      <br>
    Reelax, this is normal, tickets usually sell out very quickly.      <br>
    <br>
    When no tickets are available, you must register in the dedicated waiting list. As soon as a ticket goes on sale, we send an email with a link to order the ticket to a small group of people. This link is private for a given time and allows one person in the group to reserve the ticket.      <br>
    The group is selected in chronological or random order.      <br>
    <br>
    To join a waiting list, go to the Reelax Tickets page dedicated to the event and click on the <span class="fw-700">Buy a Ticket</span> button and then <span class="fw-700">Join the waiting list</span> button to join the waiting list. <img src="assets/img/faq/enUS/achat/2-1-comment-fonctionne-la-liste-d-attente.png" class="faq-screenshots" />
    <img src="assets/img/faq/enUS/achat/2-2-comment-fonctionne-la-liste-d-attente.png" class="faq-screenshots" />
    Then, select the categories you are interested in. <img src="assets/img/faq/enUS/achat/2-3-comment-fonctionne-la-liste-d-attente.png" class="faq-screenshots" />In step 2, you will need to log in (or register by confirming your email if you don't have an account) and fill in your personal information.      <br>
    <br>
    In step 3, validate and choose your maximum price and the quantity of tickets you want. <img src="assets/img/faq/enUS/achat/2-4-comment-fonctionne-la-liste-d-attente.png" class="faq-screenshots" />Finally, by validating, you will be registered in the waiting list, you will receive emails when it will be your turn and when a ticket will correspond to your expectations.      <br>
    <br>
    For most events, it is also possible to activate the automatic purchase feature, which allows you to automatically purchase your ticket rather than being notified by email. This optional option does not guarantee you will get a ticket, but it will save you from constantly checking your email.      <br>
    If you do not have automatic purchase enabled, once you receive the email that a ticket is available, you will need to be quick to make sure you get it (usually 10 to 50 of you will receive the email).      <br>
    <br>
    To do this, simply click on the <strong>Get Ticket</strong> button in the email! <img src="assets/img/faq/enUS/achat/2-5-comment-fonctionne-la-liste-d-attente.png" class="faq-screenshots" /><strong>Don't worry, if you missed a ticket, you'll be more likely to receive future alerts until you've been able to purchase the number of tickets you want!</strong>    <br>
    <br>
    Finally, if you want to change your listing (category, number of tickets you're looking for, automatic purchase...), you can edit your listing from the <a href="/mon-compte/mes-listes-d-attente" target="_blank"class="link --primary"><span class="material-icons" translate="no">launch</span>My Waiting Lists</a> page. Then click the <strong>Edit My Listing</strong> button to make changes.      <br>
    <br>
    <br>
  <span class="fw-700">Related questions:</span><br>
    - <a href="/faq/acheteur#acheteur_rang" class="link --primary">What is my waitlist rank?</a>    <br>
    - <a href="/faq/acheteur#acheteur_modification-inscription" class="link --primary">Can I change my waitlist entry?</a>     <br>
    - <a href="/faq/acheteur#acheteur_chances-billets" class="link --primary">I am looking for multiple tickets. Does this reduce my chances of getting tickets?</a>     <br>
    - <a href="/faq/acheteur#acheteur_achat-automatique" class="link --primary">How do I activate/deactivate the automatic purchase option?</a>     `,
    children: [],
  },
  modifyWaitingList: {
    id: 4,
    fragment: 'acheteur_modification-inscription',
    question: 'Can I change my registration on the waiting list?',
    score: 1,
    answer: `
    Yes, you can modify your registration (change the number of tickets you want, activate or deactivate the automatic purchase, unsubscribe...) in a waiting list at any time from the <a href="/mon-compte" target="_blank" class="link --primary"><span class="material-icons" translate="no">launch</span>My Account</a> page.     <br>
  <strong>Your initial waitlist ranking remains the same</strong>, even if you make changes (add new fares, change the price, change the number of tickets desired, enable automatic purchase...) to your registration.      <br>
    <br>
    <br>
  <span class="fw-700">Related questions:</span><br>
    - <a href="/faq/acheteur#acheteur_liste-attente" class="link --primary">How does the waiting list work?</a>    <br>
    - <a href="/faq/acheteur#acheteur_achat-automatique" class="link --primary">How do I activate/deactivate the automatic purchase option?</a>     <br>
    - <a href="/faq/acheteur#acheteur_rang" class="link --primary">How do I know where I stand on the waiting list?</a>    `,
    children: [],
  },
  losingPlaceWaitingList: {
    id: 5,
    fragment: 'acheteur_modification-rang',
    question: 'If I change my registration, do I lose my place on the waiting list? ',
    score: 1,
    answer: `
    No! <strong>Your initial waitlist ranking remains the same</strong>, even if you make changes (add new fares, change the price, change the number of tickets you want, activate automatic purchase...) to your registration.      <br>Likewise, if you unregister and reactivate your registration later, you will get your original waitlist ranking back.     <br>
    <br>
    <br>
    <span class="fw-700">Related questions:</span>     <br>
    - <a href="/faq/acheteur#acheteur_modification-inscription" class="link --primary">Can I change my registration in the waiting list?</a>    <br>
    - <a href="/faq/acheteur#acheteur_chances-billets" class="link --primary">I am looking for multiple tickets. Does this reduce my chances of getting tickets?</a>     <br>
    - <a href="/faq/acheteur#acheteur_liste-attente" class="link --primary">How does the waiting list work?</a>    `,
    children: [],
  },
  knowingMyPosition: {
    id: 2,
    fragment: 'acheteur_rang',
    question: 'How do I know where I stand on the waiting list?',
    score: 1,
    answer: `
    You can now find out your waiting list ranking from your 
    <a href="/mon-compte/mes-listes-d-attente" target="_blank"class="link --primary"><span class="material-icons" translate="no">launch</span>My Waiting Lists</a>
    page.
    <br>
    <img src="assets/img/faq/enUS/achat/3-classement-liste-d-attente.png" class="faq-screenshots" />
    The cursor corresponds to your position, and your chances of obtaining tickets will be optimized when the colored gauge exceeds your first name.
    <br>
    <br>
    For your information, here's how the waiting list works: as soon as a ticket goes on sale, our system sends an e-mail alert with a private link to buy the ticket to a small group of people who will be selected in chronological order (90% for most events) or at random (10%).
    <br>
    <br>
    <br>
  <span class="fw-700">Related questions:</span>     <br>
    - <a href="/faq/acheteur#acheteur_liste-attente" class="link --primary">How does the waiting list work?</a>    <br>
    - <a href="/faq/acheteur#acheteur_jauge_reculer" class="link --primary">Why do I feel like I'm moving backwards on the gauge?</a> <br>
    - <a href="/faq/acheteur#acheteur_modification-inscription" class="link --primary">Can I change my registration on the waiting list?</a>    <br>
    - <a href="/faq/acheteur#acheteur_chances-billets" class="link --primary">I am looking for multiple tickets. Does this reduce my chances of getting tickets?</a>     <br>
    - <a href="/faq/acheteur#acheteur_achat-automatique" class="link --primary">How do I activate/deactivate the automatic purchase option?</a>     `,
    children: [],
  },
  stepbackInJauge: {
    id: 3,
    fragment: 'acheteur_jauge_reculer',
    question: 'Why do I feel like I\'m moving backwards on the gauge?',
    score: 1,
    answer: `
      If you feel that you're moving at a slower pace, or that your percentage is dropping, this is normal, as registrations on the waiting list increase as the event approaches. 
      <br>
      <br>
      But don't worry, this doesn't affect your ranking on the waiting list. Your ranking remains the same.
      <br>
      <br>
      For your information, here's how the waiting list works: as soon as a ticket goes on sale, our system sends an e-mail alert with a private link to buy the ticket to a small group of people who will be selected in chronological order (90% for most events) or at random (10%).
      <br>
      <br>
      <br>
      <span class="fw-700">Related questions:</span>
      <br>
      - <a href="/faq/acheteur#acheteur_liste-attente" class="link --primary">How does the waiting list work?</a>
      <br>
      - <a href="/faq/acheteur#acheteur_modification-inscription" class="link --primary">Can I change my registration on the waiting list?</a>
      `,
    children: [],
  },
  multipleTickets: {
    id: 6,
    fragment: 'acheteur_chances-billets',
    question: 'I am looking for multiple tickets. Does this reduce my chances of getting tickets? ',
    score: 1,
    answer: `
    <strong>No</strong>, the number of tickets searched for has no influence on the allocation of alerts. All alerts contain only one ticket. If you search for multiple tickets, you will receive alerts until you have the desired number of tickets.      <br>
    <br>
    <br>
  <span class="fw-700">Related Questions:</span>     <br>
    - <a href="/faq/acheteur#acheteur_liste-attente" class="link --primary">How does the waiting list work?</a>    <br>
    - <a href="/faq/acheteur#acheteur_modification-rang" class="link --primary">If I change my registration, do I lose my place on the waiting list? </a>    <br>
    - <a href="/faq/acheteur#acheteur_rang" class="link --primary">How do I know where I stand on the waiting list?</a>    <br>
    - <a href="/faq/acheteur#acheteur_achat-automatique" class="link --primary">How do I activate/deactivate the automatic purchase option?</a> <br>
    - <a href="/faq/acheteur#acheteur_options-achat-auto" class="link --primary">How purchase options work when automatic purchase is activated?</a>
   `,
    children: [],
  },
  quantityLimit: {
    id: 84,
    fragment: 'acheteur_quantite-limitee',
    question: 'Why can\'t I get as many tickets as I want via the waiting list?',
    score: 1,
    answer: `
    Each waiting list entry entitles the holder to a limited number of tickets in all categories. This limit may vary depending on the event.
    <br>
    <br>
    The maximum number of tickets you can claim is indicated when you register on the waiting list.
    <br>
    <br>
    This limit gives everyone a chance to get tickets.
    <br>
    <br>
    <br<span class="fw-700">Related questions:</span>     <br>
    - <a href="/faq/acheteur#acheteur_modification-inscription" class="link --primary">Can I change my registration on the waiting list?</a>    <br>
    - <a href="/faq/acheteur#acheteur_chances-billets" class="link --primary">I am looking for multiple tickets. Does this reduce my chances of getting tickets?</a> 
    `,
    children: [],
  },
  purchaseOptionFunctioning: {
    id: 67,
    fragment: 'acheteur_options-achat-auto',
    question: 'How purchase option work when automatic purchase is activated?',
    score: 0,
    answer: `At step 3 when you register on the waiting list, when you activate automatic purchase, you will be offered two options if you are looking for several tickets: 
    <br><br>
    -I want to go to the event only if I get 2 tickets. 
    <br>
    - I only want tickets for the same category.
    <br><br>
    <img src="assets/img/faq/enUS/achat/8-1Comment-fonctionnent-les-choix-d’options-lorsqu’on-active-l’achat-automatique.png" class="faq-screenshots" />
    
    For your information, these options may reduce your chances of being selected, but they can help you refine your search. :)<br>
    You can activate both options at the same time, or deactivate them individually from your account.
    <br><br><br>
    <span class="fw-700">Related questions:</span>
    <br>
    - <a href="/faq/acheteur#acheteur_liste-attente" class="link --primary">How does the waiting list work?</a>
    <br>
    - <a href="/faq/acheteur#acheteur_modification-rang" class="link --primary">If I change my registration, do I lose my place on the waiting list?</a>
    <br>
    - <a href="/faq/acheteur#acheteur_achat-automatique" class="link --primary">How do I activate/deactivate the automatic purchase option?</a>
    <br>
    - <a href="/faq/acheteur#acheteur_rang" class="link --primary">How do I know where I stand on the waiting list?</a>
    <br>     
     - <a href="/faq/acheteur#acheteur_chances-billets" class="link --primary">I am looking for multiple tickets. Does this reduce my chances of getting tickets?</a>
    `,
    children: [],
  },
  dontReceiveAlerts: {
    id: 13,
    fragment: 'acheteur_alertes-mails',
    question: 'I no longer receive email alerts. Is it my turn? ',
    score: 0,
    answer: `No, this does not mean that your tour has passed!      <br>
    We will continue to send you alerts as long as you are registered and do not get all the tickets you requested.      <br>
    <br>
    <br>
  <span class="fw-700">Related questions:</span>     <br>
    - <a href="/faq/acheteur#acheteur_liste-attente" class="link --primary">How does the waiting list work?</a>    <br>
    - <a href="/faq/acheteur#acheteur_rang" class="link --primary">How do I know where I stand on the waiting list?</a>    `,
    children: [],
  },
  desinscriptionWaitingList: {
    id: 64,
    fragment: 'acheteur_desinscription',
    question: 'How do I unsubscribe from the waiting list? ',
    score: 0,
    answer: `If you wish to unsubscribe from a waiting list, you can go to <a href="/mon-compte/mes-listes-d-attente" target="_blank" class="link --primary"><span class="material-icons" translate="no">launch</span>My Waiting Lists</a> and click the <strong>Unsubscribe from All Categories</strong> button. If you wish to unsubscribe from only certain categories, you will need to click the <strong>Edit My Subscription</strong> button to apply the changes in detail.      <br>
    <br>
    <br>
  <span class="fw-700">Related Questions:</span>     <br>
    - <a href="/faq/acheteur#acheteur_annuler-achat" class="link --primary">I want to cancel my purchase. Can I get my money back? </a>    <br>
    - <a href="/faq/acheteur#acheteur_achat-automatique" class="link --primary">How do I activate/deactivate the automatic purchase option?</a>     `,
    children: [],
  },
  availableTicketsNoAlert: {
    id: 71,
    fragment: 'acheteur_pas-alertes',
    question: 'Why are tickets available on the event page, but I haven\'t received any alerts even though I\'m on the waiting list?',
    score: 0,
    answer: `As soon as a ticket goes on sale, we send an e-mail with a link enabling the ticket to be ordered by a small group of people in chronological (90% chance) or random (10% chance) order. If the ticket is not ordered within 24 hours, it becomes available on the public page and can be ordered by anyone.
    <br>
    <br>
    If you haven't received any alerts indicating that a ticket is available, it's because your tour hasn't arrived yet, but you still have priority for alerts of available tickets as long as you're on the waiting list. 
    You can find out more about your ranking here : <a href="/mon-compte/mes-listes-d-attente" target="_blank" class="link --primary"><span class="material-icons" translate="no">launch</span>My Waiting Lists</a>    
      `,
    children: [],
  },
  whyActivateAutomaticPurchase: {
    id: 77,
    fragment: 'acheteur_pourquoi-achat-automatique',
    question: 'Why activate automatic purchase?',
    score: 1,
    answer: `
    The automatic purchase feature is <span class="fw-700">free of charge</span>, so <span class="fw-700">you don't have to monitor your notifications</span> day and night in search of a ticket. When your turn comes, the ticket is purchased directly. 
    <br><br>
To activate it, all you need to do is perform <span class="fw-700">3D Secure authentication</span>, enabling us to debit <span class="fw-700">the amount you've authorized</span> when your tour arrives. 
<br><br>
When you activate this feature, you can manage the parameters set, at any time, from your <span class="fw-700">Account</span>, including : <br>
- The <span class="fw-700">debit deadline</span>; <br>
- The <span class="fw-700">deletion of the credit card entered</span>;<br>
- The <span class="fw-700">modification of the credit card</span>; <br>
- And the <span class="fw-700">deactivation</span> of the feature at any time. <br>
<br>
Bank details are not stored on Reelax Tickets: they are transmitted directly to our payment provider (MangoPay) and processed in total <span class="fw-700">security</span>.
<br><br>
The maximum amount you authorize must be <span class="fw-700">available</span> on your bank card. No amount is frozen on your bank account. If the transaction is unsuccessful, the automatic purchase will be deactivated. 
<br><br>
As a reminder, your initial waitlist ranking remains the same, even if you make changes (adding new fares, modifying the price, the number of tickets required, activating automatic purchase, etc.) to your registration.
<br><br><br>
<span class="fw-700">Related questions:</span><br>
- <a href="/faq/acheteur#acheteur_achat-automatique" class="link --primary">How to activate/deactivate the automatic purchase option?</a>     <br>
- <a href="/faq/acheteur#acheteur_modifier-achat-automatique" class="link --primary">How to change the credit card used for the automatic purchase?</a>     <br>
- <a href="/faq/acheteur#acheteur_liste-attente" class="link --primary">How does the waiting list work?</a>    <br>  
`,
    children: [],
  },
  activateDesactivateAutoPurchase: {
    id: 7,
    fragment: 'acheteur_achat-automatique',
    question: 'How to activate / deactivate the automatic purchase?',
    score: 1,
    answer: `
    On most events, instead of being notified by email, it is possible to <strong>automatically purchase</strong> your ticket! This optional feature doesn't guarantee you'll get tickets, but it will save you the hassle of monitoring your notifications day and night.      <br>
    <br>
    Once you have registered on the waiting list of your choice, click on <strong>Edit my registration</strong> and then go to <strong>step 3</strong>. <img src="assets/img/faq/enUS/achat/7-1-comment-activer-desactiver-l-achat-automatique.png" class="faq-screenshots" />You can then activate the automatic purchase and <strong>define the deadline beyond which you no longer wish to be debited</strong>.      <br>
    <br>
    Then, fill in the information of the ticket holder and your bank information. <img src="assets/img/faq/enUS/achat/7-2-comment-activer-desactiver-l-achat-automatique.png" class="faq-screenshots" />⚠️ <strong>Attention, your payment method must be a Visa or Mastercard and must be eligible for 3D Secure authentication. </strong>Then, click on the <strong>Confirm</strong> button to validate the activation of the automatic purchase.      <br>
    Once auto-purchase is activated, <strong>you will receive an email with a summary.</strong> <img src="assets/img/faq/enUS/achat/7-3-comment-activer-desactiver-l-achat-automatique.png?v=2" class="faq-screenshots" />If you need to change your payment method for auto-purchase, you can do so directly by going to <a href="/mon-compte/mes-listes-d-attente" target="_blank"class="link --primary"><span class="material-icons" translate="no">launch</span>My Waiting Lists</a> page, by clicking on the <strong>"Change my subscription</strong>" button.      <br>
    You can <strong>deactivate</strong> the automatic purchase at any time. You just have to go on your <a href="/mon-compte" target="_blank" class="link --primary"><span class="material-icons" translate="no">launch</span>Account</a>, then from the event concerned, you can check/uncheck the automatic purchase option.      <br>
    As a reminder, your initial waitlist ranking remains the same, even if you make changes (adding new prices, changing the price, the number of tickets you want, activating the automatic purchase...) to your registration.      <br>
    <br>
    <br>
  <span class="fw-700">Related questions:</span><br>
    - <a href="/faq/acheteur#acheteur_modifier-achat-automatique" class="link --primary">How to change the credit card used for the automatic purchase?</a>     <br>
    - <a href="/faq/acheteur#acheteur_liste-attente" class="link --primary">How does the waiting list work?</a>    <br>
    - <a href="/faq/acheteur#acheteur_rang" class="link --primary">How do I know where I stand on the waiting list?</a>    <br>
    - <a href="/faq/acheteur#acheteur_modification-inscription" class="link --primary">Can I change my registration in the waiting list?</a>    <br>
    - <a href="/faq/acheteur#acheteur_chances-billets" class="link --primary">I am looking for multiple tickets. Does this reduce my chances of getting tickets?</a>     `,
    children: [],
  },
  cantActivateAutoPurchase: {
    id: 14,
    fragment: 'acheteur_refus-achat-automatique',
    question: 'I can\'t activate the automatic purchase. What should I do?',
    score: 0,
    answer: `
    To activate the automatic purchase, your payment method must be a Visa or Mastercard and must be eligible for 3D Secure authentication. Amex cards and some foreign cards do not allow you to activate this feature.      <br>
    <br>
    Make sure you fill in all the fields (Maximum price, Number of tickets, Expiration date of the registration, Names of the ticket holders, Bank card...).      <br>
    <br>
    You may not be able to activate automatic purchasing as the event approaches. This is normal: to avoid unpleasant surprises, automatic purchasing is deactivated the day before the event.<br>
    <br>
    <br>
    <span class="fw-700">Related questions:</span><br>
    - <a href="/faq/acheteur#acheteur_achat-automatique" class="link --primary">How to activate/deactivate the automatic purchase option?</a>     <br>
    - <a href="/faq/acheteur#acheteur_rang" class="link --primary">How do I know where I stand on the waiting list?</a>    `,
    children: [],
  },
  modifyCreditCard: {
    id: 9,
    fragment: 'acheteur_modifier-achat-automatique',
    question: 'How to change the credit card used for the automatic purchase? ',
    score: 0,
    answer: `
    If you need to change your payment method for automatic purchase, you can do so directly by going to <a href="/mon-compte/mes-listes-d-attente" target="_blank"class="link --primary"><span class="material-icons" translate="no">launch</span>My Waiting Lists</a>, and clicking on the <strong>Change My Subscription</strong> button. <img src="assets/img/faq/enUS/achat/8-1-comment-modifier-la-carte-bancaire-renseignee-pour-l-achat-automatique.png" class="faq-screenshots" />You can go directly to step 3, and then click on <strong>Change Credit Card</strong>. ⚠️ <strong>Please note that your payment method must be a Visa or Mastercard and must be eligible for 3D Secure authentication. </strong> <img src="assets/img/faq/enUS/achat/8-2-comment-modifier-la-carte-bancaire-renseignee-pour-l-achat-automatique.png" class="faq-screenshots" />After filling in the new credit card information, you can click on <strong>Confirm</strong>.     <br>
    <br>
    <br>
  <span class="fw-700">Related question:</span>     <br>
    - <a href="/faq/acheteur#acheteur_achat-automatique" class="link --primary">How to activate/deactivate the automatic purchase option?</a>     `,
    children: [],
  },
  autoPurchaseEventDate: {
    id: 8,
    fragment: 'acheteur_achat-automatique-jour-event',
    question: 'Why can\'t I activate automatic purchase on the day of the event?',
    score: 1,
    answer: `
      On the day of the event, we disable automatic purchasing for everyone to avoid unpleasant surprises.
      <br>
      For festivals, automatic purchasing is deactivated from the first day of the event, although you will receive e-mail alerts when tickets are available. :)
      <br><br>
      This does not affect your ranking in the waiting list, so remember to check your inbox/spam regularly.
      <br>
      <br>
      <br>
      <span class="fw-700">Questions relatives :</span>
      <br>
      - <a href="/faq/acheteur#acheteur_liste-attente" class="link --primary">How does the waiting list work?</a>
      <br>
      - <a href="/faq/acheteur#acheteur_rang" class="link --primary">How do I know where I stand on the waiting list?</a>
      <br>
      - <a href="/faq/acheteur#acheteur_modification-inscription" class="link --primary">Can I change my registration in the waiting list?</a>
      <br>
      - <a href="/faq/acheteur#acheteur_chances-billets" class="link --primary">JI am looking for multiple tickets. Does this reduce my chances of getting tickets?</a>
      <br>
      - <a href="/faq/acheteur#acheteur_achat-automatique" class="link --primary">How to activate/deactivate automatic purchasing?</a>
      `,
    children: [],
  },
  dontReceiveAlertSinceAutoPurchase: {
    id: 15,
    fragment: 'acheteur_alertes-achat-automatique',
    question: 'Why don\'t I receive any more e-mail alerts since I activated the automatic purchase?',
    score: 0,
    answer: `
    No email alerts will be sent to you if you activate the automatic purchase. Automatic purchase saves you the trouble of checking your email.      <br>
    When it's your turn, the ticket will be purchased directly!      <br>
    <br>
    However, on the day of the event, we deactivate the automatic purchase for everyone to avoid unpleasant surprises. In this case, if it is your turn, you will be notified by email.      <br>
    <br>
    <br>
  <span class="fw-700">Related questions:</span>     <br>
    - <a href="/faq/acheteur#acheteur_liste-attente" class="link --primary">How does the waiting list work?</a>    <br>
    - <a href="/faq/acheteur#acheteur_achat-automatique" class="link --primary">How do I activate/deactivate the automatic purchase option?</a>     <br>
    - <a href="/faq/acheteur#acheteur_modification-rang" class="link --primary">If I change my registration, do I lose my place on the waiting list? </a>    `,
    children: [],
  },
  SMSAlertAutomaticPurchase: {
    id: 75,
    fragment: 'acheteur_sms-achat-automatique',
    question: 'How can I be notified by SMS, in the event of an automatic purchase?',
    score: 0,
    answer: `From now on, in addition to being notified by e-mail, you can also be notified by SMS, in the event of an automatic ticket purchase! 
    <br><br>
    You can enter your telephone number in the following field, when activating automatic purchase:
    <img src="assets/img/faq/enUS/achat/75-1-sms-achat-auto.png" class="faq-screenshots" />
    <img src="assets/img/faq/enUS/achat/75-2-sms-achat-auto.png" class="faq-screenshots" />
    Please note: some countries in which we have very few users are not eligible to receive our SMS messages.
    <br>
    You can retrieve your tickets and registrations from your <a href="/mon-compte/mes-billets" target="_blank" class="link --primary"><span class="material-icons" translate="no">launch</span>Account</a>.
    <br><br><br>
    <span class="fw-700">Related questions:</span>
    <br>
    - <a href="/faq/acheteur#acheteur_alertes-achat-automatique" class="link --primary">Why don't I receive any more e-mail alerts since I activated the automatic purchase?</a>
    <br>
    - <a href="/faq/acheteur#acheteur_liste-attente" class="link --primary">How does the waiting list work?</a>
    <br>
    - <a href="/faq/acheteur#acheteur_rang" class="link --primary">How do I know where I stand on the waiting list?</a>
    `,
    children: [],
  },
  deleteCardAutomaticPurchase: {
    id: 76,
    fragment: 'acheteur_supprimer-carte-bancaire',
    question: 'How do I delete the credit card used for automatic purchase?',
    score: 0,
    answer: `Reelax Tickets does not store any bank details in its system. We transfer them directly to our secure payment provider, Mangopay. 
    <br><br>
    You can delete the credit card used for the automatic purchase directly from
    <a href="/mon-compte/mes-listes-d-attente" target="_blank"class="link --primary"><span class="material-icons" translate="no">launch</span>My waiting lists</a>.
    <img src="assets/img/faq/enUS/achat/76-comment-supprimer-carte-bancaire.png" class="faq-screenshots" />
    <br>
    <span class="fw-700">Related questions:</span>
    <br>
    - <a href="/faq/acheteur#acheteur_achat-automatique" class="link --primary">How do I activate/deactivate the automatic purchase option?</a>
    <br>
    - <a href="/faq/acheteur#acheteur_modifier-achat-automatique" class="link --primary">How to change the credit card used for the automatic purchase?</a>
    <br>
    - <a href="/faq/acheteur#acheteur_alertes-achat-automatique" class="link --primary">Why don't I receive any more e-mail alerts since I activated the automatic purchase?</a>
    `,
    children: [],
  },
  pickupTicket: {
    id: 11,
    fragment: 'acheteur_recuperer-billets',
    question: 'How do I get my tickets back after I purchase them? ',
    score: 0,
    answer: `
    You will systematically receive an e-mail at the address associated with your Reelax account, on which the Download my ticket button allows you to obtain the ticket directly in PDF format.      <br>
    <br>
  <strong>Be careful, if you don't find the e-mail, don't forget to check your junk mail or promotions tab in your mailbox! </strong>     <br>
    <br>
    If you have a Reelax Tickets account, you can also retrieve your ticket in PDF format from your Account, in the tab <a href="/mon-compte/mes-billets" target="_blank"class="link --primary"><span class="material-icons" translate="no">launch</span>My Tickets</a>. <img src="assets/img/faq/enUS/achat/10-1-comment-est-ce-que-je-recupere-les-billets-apres-mon-achat.png" class="faq-screenshots" />If you don't have an account, you can retrieve your ticket after creating an account <strong>with the same email address</strong>. Once the e-mail address has been validated, you will find your ticket on the <a href="/mon-compte/mes-billets" target="_blank"class="link --primary"><span class="material-icons" translate="no">launch</span>My Tickets</a> page.     <br>
    <br>
    The message <strong>"Ticket available soon"</strong> may appear on your personal space. This means that your new ticket is being created to guarantee you access to the event. This delay can be from 24 hours to 15 minutes on the day of the event. <img src="assets/img/faq/enUS/achat/10-2-comment-est-ce-que-je-recupere-les-billets-apres-mon-achat.png" class="faq-screenshots" />
    <br><span class="fw-700">Related question:</span>     <br>
    - <a href="/faq/acheteur#acheteur_modifier-nom-billet" class="link --primary">How do I change the name on my ticket? </a>     `,
    children: [],
  },
  invoiceAfterBuying: {
    id: 65,
    fragment: 'acheteur_facture',
    question: 'Can I get an invoice for my purchase?',
    score: 0,
    answer: `
    Yes, if you have a Reelax Tickets account, you can find our service fee invoice on the <a href="/mon-compte/mes-billets#Achat" target="_blank" class="link --primary"><span class="material-icons" translate="no">launch</span>My Tickets</a> page. <img src="assets/img/faq/enUS/achat/23-puis-je-me-procurer-une-facture-pour-mon-achat.png" class="faq-screenshots" />Otherwise, you need to create an account using the email address you purchased your ticket with.     <br>
    For your information, <strong>we only charge a service fee</strong> on the amount of the ticket sale for using our online platform. VAT (20%) is charged at the time of payment and is applied to the total service fee. The buyer pays the order amount to the seller through Reelax Tickets. You will find a summary of the total transaction in the e-mail "Thank you for your purchase".      `,
    children: [],
  },
  emailMistakeInPurchase: {
    id: 61,
    fragment: 'acheteur_erreur-email',
    question: 'I got the wrong email address: how do I get my ticket back?',
    score: 0,
    answer: `
    You can contact us at support@reelax-tickets.com and give us as much information as possible (first and last name of the ticket holder, event, category, date and time of purchase, e-mail address with the entry error).      <br>
    We will then be able to email your ticket back to you.    <br>
    <br>
    <br>
  <span class="fw-700">Related questions:</span><br>
    - <a href="/faq/vendeur#vendeur_comment-vendre" class="link --primary">How do I put my ticket on sale?</a>    <br>
    - <a href="/faq/vendeur#vendeur_mail-confirmation" class="link --primary">I can't find my account confirmation e-mail. What should I do?</a>  <br>
    - <a href="/faq/vendeur#vendeur_aide-identite" class="link --primary">I can't validate my identity What should I do?</a>  `,
    children: [],
  },
  dontReceiveTicket: {
    id: 17,
    fragment: 'acheteur_probleme-reception-billet',
    question: 'I don\'t receive my ticket even though I have paid. What should I do?',
    score: 0,
    answer: `
      This may be due to a failed payment, if you did not wait for the 3D Secure payment step to be completed. In this case, an e-mail will be sent to you within 30 minutes to inform you that the payment was not successful. All you have to do is click on the link in the email to retry the payment. <img src="assets/img/faq/enUS/achat/16-je-ne-recois-pas-mon-billet-alors-que-j-ai-paye-que-faire.png" class="faq-screenshots" />If you have passed this step but still have not received your ticket, the email containing your ticket may have slipped into your spam or Promotions.      <br>
      <br>
      <br>
    <span class="fw-700">Related questions:</span>     <br>
      - <a href="/faq/acheteur#acheteur_erreur-email" class="link --primary">I got the wrong email address: how do I get my ticket back?</a>    <br>
      - <a href="/faq/acheteur#acheteur_recuperer-billets" class="link --primary">How do I get my tickets back after my purchase?</a>     <br>
  `,
    children: [],
  },
  canICancelSale: {
    id: 60,
    fragment: 'acheteur_annuler-achat',
    question: 'I want to cancel my purchase. Can I get my money back?',
    score: 0,
    answer: `
    Apart from the cancellation or postponement of the event, in accordance with Article L.121-28 of the French Consumer Code, the purchase of Tickets on Reelax Tickets cannot be subject to a right of withdrawal. The ticket is neither exchangeable nor refundable.      <br>
    <br>
    However, if you are no longer interested in your ticket, you can easily resell it from your <a href="/mon-compte" target="_blank" class="link --primary"><span class="material-icons" translate="no">launch</span>Account</a>.
    <br>
    For security reasons, we can limit the number of times a ticket can be resold. If the sale is unsuccessful, click on the <strong>Ask for help</strong> button. We will then receive your ticket and be able to guide you.
    <br>
    <br>
    <br>
  <span class="fw-700">Related questions:</span><br>
    - <a href="/faq/vendeur#vendeur_comment-vendre" class="link --primary">How do I put my ticket up for sale?</a>    <br>
    - <a href="/faq/vendeur#vendeur_non-vendu" class="link --primary">If my ticket is not sold, can I still go to the concert with it?</a>  `,
    children: [],
  },
  buyer_whyReelax: {
    id: 62,
    fragment: 'acheteur_pourquoi-reelax',
    question: 'Why is Reelax Tickets the most secure resale platform to buy and sell your used tickets?',
    score: 0,
    answer: `We use the highest security protocol to protect you from ticket fraud. Specifically, we are connected to ticketing databases to check if the tickets offered for sale on our platform are valid. Then, as soon as a ticket is purchased on our platform, we instantly create a <strong>new ticket in your name</strong>.     <br>
      <br>
      <br>
    <span class="fw-700">Related question:</span>     <br>
      - <a href="/faq/acheteur#acheteur_frais-de-service" class="link --primary">How much is the service fee for purchasing a ticket?</a>
  `,
    children: [],
  },
  differentPage: {
    id: 63,
    fragment: 'acheteur_site-different',
    question: 'Why am I on a different page of the organizer\'s website?',
    score: 0,
    answer: `You are on the page dedicated to the event on our platform. The organizer has used the exchange Reelax Tickets to <strong>secure the resale of</strong> its tickets.      <br>
      <br>
      <br>
    <span class="fw-700">Related question:</span>     <br>
      - <a href="/faq/acheteur#acheteur_pourquoi-reelax" class="link --primary">Why is Reelax Tickets the most secure resale platform to buy and sell your used tickets?</a>     <br>
  `,
    children: [],
  },
  softwareCompatibility: {
    id: 41,
    fragment: 'organisateur_logiciel-compatible',
    question: 'Is my ticketing software compatible with Reelax Tickets?',
    score: 1,
    answer: `
    All ticketing softwares are <span class="fw-700">compatible</span> with our ticket marketplace.
    <br>
    With our partner solutions, integrations are even deeper and easier.
    `,
    children: [],
  },
  priceSettingUp: {
    id: 42,
    fragment: 'organisateur_cout',
    question: 'How much does it cost to setup a marketplace for my event?',
    score: 1,
    answer: '<span class="fw-700">0 euro !</span> Reelax Tickets only charges ticket buyers.',
    children: [],
  },
  howSettingUp: {
    id: 48,
    fragment: 'organisateur_mise-en-place',
    question: 'How does your ticket exchange work from start to finish?',
    score: 0,
    answer: `1 - Fill in a few questions for the <span class="fw-700">setting of your ticket exchange platform</span> (release date, seating plan, image, color of your platform, ...)
        <br><br>2 - Enable the <span class="fw-700">connection on your ticketing software</span> (depending on your ticketing software and distributors, we may require restricted access to your ticketing software).
        <br><br>3 - Sign our <span class="fw-700">partnership agreement</span> and you're done!
        <br><br>From then on, we will send you your login and your ticket exchange platform. All you have to do is <span class="fw-700">share it with your audience</span> (and be sure they will love it) !
        `,
    children: [],
  },
  timeSettingUp: {
    id: 43,
    fragment: 'organisateur_temps-installation',
    question: 'How much time does it take to setup a marketplace?',
    score: 0,
    answer: `
    Usually we set up a ticket marketplace in a <span class="fw-700">few hours</span>.
    <br>
    After a short call with our account manager, we setup the marketpace <span class="fw-700">with your settings</span> in no time.
    `,
    children: [],
  },
  reelaxConsultant: {
    id: 56,
    fragment: 'organisateur_accompagnement',
    question: 'Will I be accompanied by a consultant at Reelax Tickets?',
    score: 1,
    answer: `<span class="fw-700">Yes !</span>
        <br><br>From the creation to the end of your event and even well after, our consultants will respond to you in less than an hour on average during the week and in less than two hours on the weekend!
        <br><br>Reelax Tickets' #1 goal is the <span class="fw-700">satisfaction of our partner organizers and their audiences</span>! There is no greater pride for our team than a partner who sincerely recommends our service to another organizer.
        `,
    children: [],
  },
  canIRefundBuyers: {
    id: 51,
    fragment: 'organisateur_annulation-report',
    question: 'My event is postponed or cancelled, can I refund the last ticket holders with Reelax Tickets?',
    score: 0,
    answer: `<span class="fw-700">Of course!</span>
        <br><br>For tickets from your <span class="fw-700">ticketing software</span>, you have the ability to easily refund buyers who wish to do so (either via an XML file to send to your bank, or via our intermediary).
        <br><br>Regarding tickets from <span class="fw-700">distributors</span>, we automatically refund buyers since payment to sellers is made after the event for these tickets.
        `,
    children: [],
  },
  presenceReelax: {
    id: 47,
    fragment: 'organisateur_sur-place',
    question: 'Is Reelax Tickets present on the day of the event?',
    score: 1,
    answer: 'If your ticket exchange is a victim of its own success and you <span class="fw-700">feel the need</span> for support on the big day, an account manager will be happy to come and assist you with access control.',
    children: [],
  },
  whoTakesCareSAV: {
    id: 50,
    fragment: 'organisateur_responsabilite-sav',
    question: 'Who takes care of the spectators support?',
    score: 1,
    answer: `It's still <span class="fw-700">us</span> (but only if you agree)!
        <br><br>With the ambition to constantly improve our service, we are committed to working closely with our users to identify and understand their needs.
        `,
    children: [],
  },
  orga_whyReelax: {
    id: 45,
    fragment: 'organisateur_pourquoi-reelax',
    question: 'Why is Reelax Tickets the most secure platform on the market?',
    score: 0,
    answer: 'Reelax Tickets only allows the resale of tickets from its <span class="fw-700">partner events</span>. This choice allows us to guarantee the security of all tickets exchanged on our service.',
    children: [],
  },
  howSecure: {
    id: 46,
    fragment: 'organisateur_securite',
    question: 'How do we secure ticket exchanges?',
    score: 1,
    answer: `
      Our computer protocol automatically connects to your ticketing software and any distributors in order to <span class="fw-700">verify the validity</span> of the access code of the ticket offered for sale.
      <br><br>
      Then as soon as a ticket is sold on your ticket exchange, we repackage it, meaning <span class="fw-700">we invalidate the seller's access code</span> and assign <span class="fw-700">new access code to the buyer</span>.
      <br><br>
      In some cases, when your ticketing software does not allow us to change the access codes, the ticket is not repackaged, but the seller's money is blocked until the end of the event.
      `,
    children: [],
  },
  whyDoingThat: {
    id: 57,
    fragment: 'organisateur_demarche',
    question: 'Why are you doing all this?',
    score: 0,
    answer: `
        The founding team of Reelax Tickets consists of 4 former Mines-Telecom graduates who all share the need to have a <span class="fw-700">positive impact</span> on society as well as a <span class="fw-700">visceral passion for music</span>, and this through different prisms (event organizers, musicians and spectators).
        <br>
        <br>
        After having observed with a hundred of organizers that the secondary ticketing market was a complete aberration.
        The team has been developing for more than two years, a computer protocol <span class="fw-700">unique in Europe</span> in order to <span class="fw-700">facilitate ticket transactions</span> between individuals and to <span class="fw-700">suppress fraud</span>.
        This protocol is now <span class="fw-700">interoperable</span> with over 20 ticketing software programs and used by many organizers.
        <br>
        Given the very strong adoption of French organizers, we are convinced that France can become an example for many European countries!
        <br>
        <br>
        If you are inspired by the adventure to clean up the secondary ticketing market in France and Europe or if you simply want to give us a helping hand, don't hesitate to <span class="fw-700">write us</span> at: <a class="link --primary" href="mailto:organisateurs@reelax-tickets.com">organisateurs@reelax-tickets.com</a>
        `,
    children: [],
  },
  whereAreWe: {
    id: 58,
    fragment: 'organisateur_se-rencontrer',
    question: 'Where to find us?',
    score: 0,
    answer: `Reelax Tickets is a French company based in Rennes
        <br><br>
        Our team navigates between our office at IMT Atlantique (<span class="fw-700">Rennes</span>), our second office in Telecom Paritech (<span class="fw-700">Paris 14</span>) and in the <span class="fw-700">biggest events in France</span>.
        <br><br>
        Finally you will always find someone to welcome you with open arms <span class="fw-700">here:</span> <a class="link --primary" href="mailto:organisateurs@reelax-tickets.com">organisateurs@reelax-tickets.com</a>
        `,
    children: [],
  },
  interferenceDashboard: {
    id: 55,
    fragment: 'organisateur_journal-operation-bordereau-recette',
    question: 'Does Reelax Tickets interfere with my transaction log or receipt slip?',
    score: 1,
    answer: 'Reelax Tickets acts as an operator of the organizer\'s ticketing system by recording an update of the access code, but <span class="fw-700">does not act on the mandatory tax registration of the initial sales transaction or its amount</span>.',
    children: [],
  },
  reelaxInTicketingSystem: {
    id: 53,
    fragment: 'organisateur_systemes-billetteries',
    question: 'Do I have to declare Reelax Tickets in my computerized ticketing system declaration?',
    score: 0,
    answer: '<span class="fw-700">No</span>, Reelax Tickets is not a ticketing system as defined by the legislation. Therefore, you do not have <span class="fw-700">any declaration to make</span>.',
    children: [],
  },
  integrabilityData: {
    id: 44,
    fragment: 'organisateur_logiciel-data',
    question: 'Does ticket resale data show up in my data software?',
    score: 1,
    answer: `
    We are already connected to <span class="fw-700">many data softwares</span>, you will be able to receive all your dashboard informations directly inside those software.
    <br>
    All data can also be <span class="fw-700">exported</span> as csv or excel format.
    `,
    children: [],
  },
  whoOwnsData: {
    id: 49,
    fragment: 'organisateur_proprietaire-donnees',
    question: 'Who owns the data generated by our ticket exchanges?',
    score: 1,
    answer: `
        The data generated by our services <span class="fw-700">belongs to the organizers</span>. Our use is strictly limited to the creation and management of your ticket exchange. We will not sell, give away, rent or use your data for our own purposes under any circumstances.
        <br><br>
        In addition, all of our data is exclusively <span class="fw-700">hosted in France</span> and subject to <span class="fw-700">GDPR</span> regulations.
        `,
    children: [],
  },
  myCgv: {
    id: 54,
    fragment: 'organisateur_cgv',
    question: 'Can I add my T&C on Reelax Tickets?',
    score: 0,
    answer: '<span class="fw-700">Yes</span>, it is essential that all of your audience can have access to your terms and conditions. For this, you don\'t have to do anything, we take care of everything!',
    children: [],
  },
  reelaxProvider: {
    id: 52,
    fragment: 'organisateur_politique-confidentialite',
    question: 'Should I add Reelax Tickets as a provider in my privacy policy?',
    score: 0,
    answer: 'Mention is not required, but <span class="fw-700">recommended</span> to be as transparent as possible with your audience.',
    children: [],
  },

};
