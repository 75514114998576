import { Event } from './index';

export const PrinterVersions = [
  {
    value: 1,
    viewValue: 'Version 1',
  },
  {
    value: 2,
    viewValue: 'Version 2 (retours à la ligne)',
  },
  {
    value: 3,
    viewValue: 'Version 3 (v2 + placement aligné à gauche)',
  },
  {
    value: 4,
    viewValue: 'Version 4 (v3 + option catégorie en gros)',
  },
];

export class TicketTemplate {
  name: string;
  organizerLegalName: string;
  organizerLicense: string;
  templateUrl: string;
  codeXCoordinate: number;
  codeYCoordinate: number;
  isLandscape: boolean;
  id?: number;
  bigSeatingInfo?: boolean;
  bigCode128?: boolean;
  bigCategory?: boolean;
  printerVersion: number;

  // foreign keys properties
  public eventId: number;
  modifiedBy: number;
  event?: Event;

  constructor(data: Partial<TicketTemplate>) {
    Object.assign(this, data);
  }
}
