// eslint-disable-next-line max-classes-per-file
import { Platforms } from '.';
import { Utils } from '../shared/utils';
import { Language, LanguageMap } from './language.model';

// eslint-disable-next-line max-classes-per-file
export class CustomBuyerFields {
  isRequired: true;
  name: string;
  type: string;

  constructor(data) {
    Object.assign(this, data);
  }
}

export enum FieldType {
  // eslint-disable-next-line id-denylist
  string = 'string',
  // eslint-disable-next-line id-denylist
  number = 'number',
  // eslint-disable-next-line id-denylist
  boolean = 'boolean',
  date = 'date',
}

export interface CustomTicketField {
  isRequired: true;
  name: LanguageMap;
  type: FieldType;
  platformsForeignIds: {[key in Platforms]?: string};
}

export class EventPersonalization {
  public platformPagePartnershipText: LanguageMap;
  public platformPagePurchaseSubtitle: LanguageMap;
  public platformPagePurchaseTitle: LanguageMap;
  public platformPagePurchaseDeactivated: LanguageMap;
  public platformPageSellSubtitle: LanguageMap;
  public platformPageSellTitle: LanguageMap;
  public platformPageSellDeactivated: LanguageMap;
  public categoryChoiceSellingPage: LanguageMap;
  public commandBuyingPage: LanguageMap;
  public confirmationBuyingPage: LanguageMap;
  public confirmationSellingPage: LanguageMap;
  // Forms for custom questions
  public customBuyerFields: CustomBuyerFields[];
  public customTicketFields: {[index: number]: CustomTicketField}; // JSONB
  public customCommercialCommunication: LanguageMap;
  public informationsBuyingPage: LanguageMap;
  public informationsSellingPage: LanguageMap;
  public mail: LanguageMap;
  public mailTicketNumber: boolean;
  public paymentBuyingPage: LanguageMap;
  public platformPage: LanguageMap;
  public priceSellingPage: LanguageMap;
  public purchaseTicketNumber: boolean;
  public ticketsListBuyingPage: LanguageMap;
  public ticketsSellingPage: LanguageMap;
  public validity: LanguageMap;
  public primaryFontFamily: string;
  public accentFontFamily: string;
  public customFontUrls: string;
  public customCheckboxFields: Array<LanguageMap>;
  public ticketTemplateInformation: string;
  public id?: number;
  // waiting list process
  public categoryWaitlist: LanguageMap;
  public informationsWaitlist: LanguageMap;
  public ticketSearchWaitlist: LanguageMap;
  public autoPurchaseWaitlist: LanguageMap;
  public confirmationWaitlist: LanguageMap;
  // iframe
  public iframeTitle: LanguageMap;

  // foreign keys properties
  public eventId: number;

  constructor(options?: any) {
    if (options) {
      Object.assign(this, options);
      if (typeof options.customBuyerFields?.[0] === 'string' || options.customBuyerFields?.[0] instanceof String) {
        this.customBuyerFields = Object.values(options.customBuyerFields)
          .map( (x: string) => new CustomBuyerFields(JSON.parse(x)));
      } else if (!!options.customBuyerFields && Object.keys(options.customBuyerFields).length !== 0) {
        this.customBuyerFields = options.customBuyerFields;
      } else {
        this.customBuyerFields = null;
      }
      Object.keys(options).forEach((key) => {
        // simple string properties should not get an object type
        if(!options?.[key] &&
          !['customBuyerFields', 'mailTicketNumber', 'customCheckboxFields', 'ticketTemplateInformation',
            'purchaseTicketNumber', 'primaryFontFamily', 'accentFontFamily', 'customFontUrls'].includes(key)){
          this[key] = {};
        }
      });
    } else {
      this.platformPagePartnershipText = {};
      this.platformPagePurchaseSubtitle = {};
      this.platformPagePurchaseTitle = {};
      this.platformPagePurchaseDeactivated = {};
      this.platformPageSellSubtitle = {};
      this.platformPageSellTitle = {};
      this.platformPageSellDeactivated = {};
      this.categoryChoiceSellingPage = {};
      this.commandBuyingPage = {};
      this.confirmationBuyingPage = {};
      this.confirmationSellingPage = {};
      this.customCommercialCommunication = {};
      this.informationsBuyingPage = {};
      this.informationsSellingPage = {};
      this.mail = {};
      this.paymentBuyingPage = {};
      this.platformPage = {};
      this.priceSellingPage = {};
      this.ticketsListBuyingPage = {};
      this.ticketsSellingPage = {};
      this.validity = {};
      this.categoryWaitlist = {};
      this.informationsWaitlist = {};
      this.ticketSearchWaitlist = {};
      this.autoPurchaseWaitlist = {};
      this.confirmationWaitlist = {};
      this.iframeTitle = {};
    }
  }

  get hasCustomCheckboxFields() {
    return this.customCheckboxFields && this.customCheckboxFields.length > 0;
  }

  getFieldTrad(field: keyof EventPersonalization, language: Language) {
    return Utils.getTranslation(this[field] as LanguageMap, language);
  }

  hasTraductionInOtherLanguage(field, language: Language) {
    const personalization = this[field];
    const otherLanguage = [Language.frFR, Language.enUS, Language.esES].filter((l) => l !== language);
    if (!!personalization
      && !personalization?.[language]
      && typeof personalization === 'object'
      && !Array.isArray(personalization)
    ) {
      return !!otherLanguage.filter((l) => !!this[field]?.[l]).length;
    } else if (field === 'customCheckboxFields') {
      return !!this.customCheckboxFields?.find((cb) => !cb?.[language]);
    }
    return false;
  }

  hasAtLeastOneTrad(field) {
    return !!(this?.[field] && Object.values(this[field]).filter((e) => e).length);
  }

}
