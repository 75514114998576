<div class="dialog">

  <mat-icon class="close-icon" color="accent" (click)="onCloseClick()">close</mat-icon>

  <h1 mat-dialog-title class="dialog-title">{{data?.title}}</h1>

  <div mat-dialog-content class="dialog-content">

    <span [innerHTML]="data?.text"></span>

    <div class="mt-4 img-container">
      <img #img class="dialog-content__img" [src]="data?.imageSrc">
    </div>

    @if (data?.zoom) {
      <div class="buttons-group --row --centered">
        <ng-container *transloco="let tp; read 'purchase'">
          <button mat-button class="button --small --outlined-primary mt-4" [disabled]="currentZoom < 2" (click)="zoomOut()">
            <mat-icon class="button__icon">zoom_out</mat-icon>
            {{ tp('zoomOut') }}
          </button>
          <button mat-button class="button --small --outlined-primary mt-4" [disabled]="currentZoom > 3" (click)="zoomIn()">
            <mat-icon class="button__icon">zoom_in</mat-icon>
            {{ tp('zoomIn') }}
          </button>
        </ng-container>
      </div>
    }

  </div>
</div>
