<div class="dialog" *transloco="let tmwi; read 'myWaitlists.dialog.info'">

  <mat-icon class="close-icon" color="accent" (click)="onCloseClick()">close</mat-icon>

  <h1 mat-dialog-title class="dialog-title">⏳ {{ tmwi('howWaitListsWork') }}</h1>

  <div mat-dialog-content class="dialog-content">

    <p class="dialog-content__text my-4">
      🎟 <span [innerHTML]="tmwi('ticketAlert', {reservedTime: msToTime(waitListConfig.reservedTime)})"></span>
    </p>
    <p class="dialog-content__text my-4">
      🍀 <span [innerHTML]="tmwi('groupSelection')"></span>
      @switch (randomFactor) {
        @case (0) {
          <span [innerHTML]="tmwi('fullChrono')"></span>
        }
        @case (100) {
          <span [innerHTML]="tmwi('fullRandom')"></span>
        }
        @default {
          <span [innerHTML]="tmwi('chronoAndRandom', {chronologyFactor: 100 - randomFactor, randomFactor: randomFactor })"></span>
        }
      }
    </p>
    @if (waitListConfig.purchaseAutoEnabled) {
      <p class="dialog-content__text my-4">
        ⚡️ <span [innerHTML]="tmwi('purchaseAutoAvailable')"></span>
      </p>
    }
    @if (waitListConfig.displayGauge) {
      <p class="dialog-content__text my-4">
        🔮 <span [innerHTML]="tmwi('approximateRanking')"></span>
      </p>
      <div class="small-container my-4">
        <p class="fs-12">{{ data.gauge.categoryName }} : </p>
        <app-waitlist-gauge
          [color]="data.event?.color ?? '#242425'"
          [userRank]="data.gauge?.myRank"
          [currentRank]="data.gauge?.currentRank"
          [randomNotifications]="data.gauge?.randomNotifications"
          [user]="data.user"
          [inPopin]="true"
          >
        </app-waitlist-gauge>
        <p class="dialog-content__text italic center mt-3">
          <span [innerHTML]="tmwi('theGauge')"></span>
          <span [innerHTML]="tmwi('representsChronology')"></span>
        </p>
      </div>
    }
    <p class="dialog-content__text my-4">
      🧘 <span [innerHTML]="tmwi('freeEdition')"></span>
    </p>

    <p class="dialog-title mt-5">
      🎂 <span [innerHTML]="tmwi('wantMore.title')"></span>
    </p>
    <p class="dialog-content__text my-4">
      <span [innerHTML]="tmwi('wantMore.paragraph')"></span>
    </p>

    <p class="dialog-title mt-5">
      📜 <span [innerHTML]="tmwi('whyThis.title')"></span>
    </p>
    <p class="dialog-content__text my-4">
      <span [innerHTML]="tmwi('whyThis.paragraph')"></span>
    </p>

  </div>

</div>
