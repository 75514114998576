import { Injectable } from '@angular/core';
import { AbstractControl, AsyncValidator, UntypedFormGroup, ValidationErrors } from '@angular/forms';
import { AuthService } from '../services';
import { Observable, catchError, delay, map, of, switchMap, timeout } from 'rxjs';

export function passwordMatchValidator(control: UntypedFormGroup): ValidationErrors | null {
  const password = control.get('password');
  const passwordConfirm = control.get('passwordConfirm');

  return password && passwordConfirm && password.value !== passwordConfirm.value ? { noMatch: true } : null;
}

@Injectable({ providedIn: 'root' })
export class PasswordCheckValidator implements AsyncValidator {

  constructor(private authService: AuthService) {}

  validate(
    control: AbstractControl,
  ): Observable<ValidationErrors | null> {
    return of(control.value).pipe(
      delay(500),
      switchMap((pswd) => this.authService.checkPasswordValidity(pswd).pipe(
        timeout(1000),
        map( (res) => (res?.isValid ? null : { inDico: true })),
        catchError((err) => of(null)),
      )),
    );
  }
}
