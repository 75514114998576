<div class="card">
  <ng-template #cardContent>
    @if (isLoading) {
      <img class="card__content__img-placeholder" [alt]="data.title"
        src="assets/img/reelax-arrows.svg?v=1">
      }
      <img class="card__content__img"
        [alt]="data.title"
        loading="lazy"
        [class.--isLoading]="isLoading"
        (load)="isLoading = false"
        [src]="!!data.imgUrl ? data.imgUrl : 'assets/img/default-event-cover.jpg'">
        <div class="card__content__info">
          <p class="card__content__info__name truncate-2">
            {{data.title}}
          </p>
          <p class="card__content__info__location truncate-2">
            {{data.content}}
          </p>
          <p class="card__content__info__date">
            <span>{{data.date | date: 'dd.MM.yyyy'}}</span>
            @if (data.secondDate) {
              <span> - {{data.secondDate | date: 'dd.MM.yyyy'}}</span>
            }
          </p>
        </div>
      </ng-template>

      <!-- Internal link -->
      @if (!externalLink && data) {
        <a mat-button [routerLink]="data.url" queryParamsHandling="preserve" class="card__content">
          <ng-container *ngTemplateOutlet="cardContent"></ng-container>
        </a>
      }
      <!-- External link -->
      @if (externalLink && data) {
        <a mat-button [href]="data.url" class="card__content" target="_blank">
          <ng-container *ngTemplateOutlet="cardContent"></ng-container>
        </a>
      }

    </div>
