import { Component, Input } from '@angular/core';
import { MatIcon } from '@angular/material/icon';

@Component({
  selector: 'app-calendar-icon',
  templateUrl: './calendar-icon.component.html',
  styleUrls: ['./calendar-icon.component.scss'],
  standalone: true,
  imports: [MatIcon],
})
export class CalendarIconComponent {
  @Input() public date: Date;

}
