import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { Observable, catchError, tap } from 'rxjs';
import { AlertMessageService, AppService } from '../services';

@Injectable()
export class AlertMessageInterceptor implements HttpInterceptor {
  private settings: {
    disabledEmailVerification?: boolean;
  } = {};

  constructor(
    private alertMessageService: AlertMessageService,
    private appService: AppService,
  ) {}

  public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    return next.handle(req).pipe(
      tap( (res) => {
        this.parseHeaders(res);
      }),
      catchError( (err) => {
        this.parseHeaders(err);
        throw err;
      }),
    );
  }

  private parseHeaders(res: HttpEvent<any> | HttpErrorResponse) {
    if (res instanceof HttpResponse || res instanceof HttpErrorResponse) {
      if (res.headers.get('X-RT-alert') === 'true') {
        this.alertMessageService.openAlert.next('true');
      }
      if (res.headers.get('X-RT-email-verify') === 'false' && !this.settings.disabledEmailVerification) {
        this.appService.settings.next({
          ...this.appService.settings.value,
          disabledEmailVerification: true,
        });
        this.settings.disabledEmailVerification = true;
      } else if (res.headers.get('X-RT-email-verify') !== 'false' && this.settings.disabledEmailVerification) {
        this.appService.settings.next({
          ...this.appService.settings.value,
          disabledEmailVerification: false,
        });
        this.settings.disabledEmailVerification = false;
      }
    }
  }
}
