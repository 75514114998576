import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { Observable, switchMap, tap } from 'rxjs';
import { AuthService } from '../services';
import { WebToken } from '../models';
import { TokenStore } from '../stores';
import { Platform } from '@angular/cdk/platform';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  private isBrowser = false;

  constructor(
    private authService: AuthService,
    private tokenStore: TokenStore,
    private platform: Platform,
  ) {
    this.isBrowser = this.platform.isBrowser;
  }

  public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // case ressoursse is not behind api or SSR
    if (!req.url.includes('/api') || !this.isBrowser) {
      return next.handle(req);
    }

    // if request is from anon login
    if (this.anonTokenReq(req.url)) {
      return next.handle(this.requestWithToken(req)).pipe(
        tap( (res) => {
          if (res instanceof HttpResponse) {
            const tokenRes = res.headers.get('X-RT-webtoken');
            if (tokenRes) {
              this.authService.webToken = new WebToken(tokenRes);
              this.tokenStore.setToken(tokenRes);
            }
          }
        }),
      );
    }

    // if request is refresh token we can return early
    if (this.newTokenReq(req.url)) {
      return next.handle(req).pipe(
        tap( (res) => {
          if (res instanceof HttpResponse) {
            const tokenRes = res.headers.get('X-RT-webtoken');
            if (tokenRes) {
              this.authService.webToken = new WebToken(tokenRes);
              this.tokenStore.setToken(tokenRes);
            }
          }
          if (res instanceof HttpErrorResponse && res.status === 401) {
            this.authService.webToken = undefined;
          }
        }),
      );
    }

    // request to Reelax Tickets backend requires webtoken
    if (this.shouldAddAuthHeader(req.url)) {
      // request a webtoken only if user is authenticated
      let requestNewToken = !!this.authService.webToken;
      // check if webtoken missing expired
      if (this.authService.webToken) {
        requestNewToken = this.authService.webToken.isExpired;
      }

      if (requestNewToken) {
        return this.authService.refreshToken().pipe(
          switchMap(() => next.handle(this.requestWithToken(req))),
        );
      }
      return next.handle(this.requestWithToken(req));
    }
    // default case
    return next.handle(req);
  }

  private requestWithToken(req: HttpRequest<any>) {
    return req.clone({
      headers: req.headers.append('Authorization', 'Bearer ' + this.authService.webToken?.token),
    });
  }

  private anonTokenReq(url: string) {
    let routeUrl = url;
    if (url.startsWith(window.location.origin)) {
      routeUrl = routeUrl.replace(window.location.origin, '');
    }
    const urlSendingNewToken = [
      '/api/bucket',
    ];
    return urlSendingNewToken.some( (u) => routeUrl.startsWith(u) );
  }

  private newTokenReq(url: string) {
    let routeUrl = url;
    if (url.startsWith(window.location.origin)) {
      routeUrl = routeUrl.replace(window.location.origin, '');
    }
    const urlSendingNewToken = [
      '/api/login',
      '/api/register',
      '/api/authtoken',
      '/api/bucket',
    ];
    return urlSendingNewToken.some( (u) => routeUrl.startsWith(u) );
  }

  private shouldAddAuthHeader(url: string) {
    // prevents infinite auth token request
    if (url.includes('authtoken')) {
      return false;
    }
    return url.startsWith('/api') || url.startsWith(window.location.origin);
  }

}
