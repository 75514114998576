<div class="carousel" (mouseenter)="stop()" (mouseleave)="run()">
  @if (buttons && items?.length >= 1) {
    <mat-icon class="carousel__button --previous" (click)="previous()">keyboard_arrow_left</mat-icon>
  }

  <div #carousel_container class="carousel__container">
    @for (item of items; track item) {
      <div class="carousel__item" [style.transform]="getTransform()">
        <ng-container *ngTemplateOutlet="template; context: {item: item}"></ng-container>
      </div>
    }
  </div>

  @if (buttons && items?.length >= 1) {
    <mat-icon class="carousel__button --next" (click)="next()">keyboard_arrow_right</mat-icon>
  }
</div>
