import { Organizer } from '../organizer.model';
import { UserProfile } from '../user-profile.model';

export enum Right {
  Create = 'C',
  Read = 'R',
  Write = 'W',
  Delete = 'D',
}

export const RightInfos = {
  [Right.Create]: {
    key : 'C',
    name : 'Create',
  },
  [Right.Read]: {
    key : 'R',
    name : 'Read',
  },
  [Right.Write]: {
    key : 'W',
    name : 'Write',
  },
  [Right.Delete]: {
    key : 'D',
    name : 'Delete',
  },
};

export class AccessRight {
  public id!: number;
  public partnerId: number; // foreign key => users Info
  public ressource: string; // events, seller, buyer, waiter
  public entity: string; // event organizer
  public foreignId: string; // entity primary key
  public right: Right;

  public readonly createdAt!: Date;
  public readonly updatedAt!: Date;

  public Partner?: UserProfile;
  public EntityOrganizer?: Organizer;

  constructor(data: Partial<AccessRight>) {
    Object.assign(this, data);
  }

  // entity is unknwown : could be Organizer or Event
  public get Entity() {
    return this.EntityOrganizer;
  }
}
