import { AppModule } from './app.module';
import { NgModule } from '@angular/core';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { FingerprintInterceptor } from './interceptors/fingerprint.interceptor';
import { Router } from '@angular/router';
import { browserRoutes } from './browser.routes';

@NgModule({
  imports: [
    AppModule,
    BrowserAnimationsModule,
  ],
  declarations: [],
  exports: [],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: FingerprintInterceptor,
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppBrowserModule {

  constructor(
    private router: Router,
  ) {
    this.router.config.find( (r) => r.path === '')?.children.push(...browserRoutes);
    this.router.resetConfig([
      ...this.router.config,
    ]);
  }
}
