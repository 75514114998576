import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpErrorResponse } from '@angular/common/http';
import { catchError, Observable, tap } from 'rxjs';
import { RateLimitService } from '../services/rate-limit.service';

@Injectable()
export class RateLimitInterceptor implements HttpInterceptor {

  constructor(
    private rateLimitService: RateLimitService,
  ) {}

  public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    return next.handle(req).pipe(
      catchError( (err) => {
        if (err instanceof HttpErrorResponse && err.status === 429) {
          const rateLimitReset = new Date(err.headers.get('x-ratelimit-reset'));
          this.rateLimitService.userLimitedDialog(rateLimitReset);
        }
        throw err;
      }),
    );
  }

}
