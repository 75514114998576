import { Injectable } from '@angular/core';
import { StorageService } from '../services';

@Injectable({
  providedIn: 'root',
})
export class CookieStore {
  private storageKey = '[cookie]';

  constructor(
    private storageService: StorageService,
  ) {
  }

  getCookie() {
    return this.storageService.getItem<boolean>(this.storageKey);
  }

  setCookie(displayed: boolean) {
    this.storageService.setItem(this.storageKey, displayed);
  }

}
