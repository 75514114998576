import { isPlatformBrowser, NgClass, NgTemplateOutlet } from '@angular/common';
import {
  AfterViewChecked,
  Component,
  ContentChild,
  ElementRef,
  Inject,
  Input,
  PLATFORM_ID,
  TemplateRef,
  ViewChild,
} from '@angular/core';

@Component({
  selector: 'app-carousel-vertical',
  templateUrl: './carousel-vertical.component.html',
  styleUrls: ['./carousel-vertical.component.scss'],
  standalone: true,
  imports: [
    NgClass,
    NgTemplateOutlet,
  ],
})
export class CarouselVerticalComponent<Item = unknown> implements AfterViewChecked {
  @ContentChild(TemplateRef) template: TemplateRef<any>;
  @ViewChild('carousel_container') container: ElementRef<HTMLDivElement>;
  @Input() items: Item[];
  @Input() reverse = false;
  @Input() fullHeight = false;
  isBrowser = false;

  constructor(
    @Inject(PLATFORM_ID) platformId,
  ) {
    this.isBrowser = isPlatformBrowser(platformId);
  }

  ngAfterViewChecked() {
    if (this.isBrowser) {
      let animationName = 'slidingAnimation';
      if (this.reverse) {
        animationName += 'Reverse';
      }
      if (this.fullHeight) {
        animationName += '100';
      }
      this.container.nativeElement.style.animationName = animationName;
      this.container.nativeElement.style.animationDuration = (12 * this.items?.length) + 's'; // nb of seconds per item
    }
  }

}

