/** @description STRING(10) with format `provider-channel` (example : brevo-sms) */
export enum MessageType {
  BrevoMail = 'brevo-mail',
  BrevoSMS = 'brevo-sms',
}


export const MessageTypeSelections = [
  {
    value: MessageType.BrevoMail,
    viewValue: MessageType.BrevoMail,
  },
  {
    value: MessageType.BrevoSMS,
    viewValue: MessageType.BrevoSMS,
  },
];

export class MessageError {
  public id!: number;
  public attempts: number;
  public messageData: any;
  public type: MessageType;
  public error: string;
  public readonly createdAt!: Date;
  public readonly updatedAt!: Date;

  constructor(data: Partial<MessageError>) {
    Object.assign(this, data);
  }
}
