import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

declare global {
  interface Window {
    iFrameResizer,
    parentIFrame: {
      sendMessage: (params: any) => {},
      size: (dimension: number) => {},
      autoResize: (toggle: boolean) => {},
      scrollTo: (x: number, y: number) => {},
      moveToAnchor: (e: string) => {}, // did not work
      scrollToOffset: (x: number, y: number) => {},
    }
  }
}

@Injectable({
  providedIn: 'root',
})
export class IframeService {
  public iframeState = new BehaviorSubject<{
    isIframed: boolean,
    ready: boolean,
  }>({
    isIframed: false,
    ready: false,
  });
  private initiated = false;

  constructor(
    @Inject(DOCUMENT) private document: Document,
  ) {
  }

  private get iFrameResizer() {
    return window.iFrameResizer;
  }

  public isIframed() {
    return this.document.URL?.includes('wref=http');
  }

  /**
   * Should be called once only in app component
   */
  public init() {
    if (this.initiated) {
      return;
    }
    this.initiated = true;
    if (!this.iFrameResizer) {
      const messageHandling = (message) => {
        if (typeof message === 'object') {
          if (message.iframed) {
            this.iframeState.next({
              ...this.iframeState.value,
              isIframed: true,
            });
          }
        }
      };
      window.iFrameResizer = {
        onMessage: (m) => messageHandling(m),
        onReady: (m) => {
          this.iframeState.next({
            ...this.iframeState.value,
            ready: true,
            isIframed: true, // must be iframed to be ready
          });
        },
      };
    }
  }

  public sendMessage(params) {
    if (!window.parentIFrame) {
      return;
    }
    window.parentIFrame.sendMessage(params);
  }

}
