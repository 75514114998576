export enum Language {
  deDE = 'de-DE',
  enGB = 'en-GB',
  enUS = 'en-US',
  esES = 'es-ES',
  frFR = 'fr-FR',
  itIT = 'it-IT',
}

export type LanguageMap = {[key in Language]?: string};
