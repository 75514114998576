<ng-container *transloco="let te; read 'events'">
  @if (['online', 'private'].includes(event?.status)) {
    @if (atLeastOneSellActivated) {
      <div class="section">
        <p class="section__title">
          {{ event.EventPersonalization?.getFieldTrad('platformPageSellTitle', currentLang) || te('gotAnExtraTicket') }}
        </p>
        <p class="section__text">
          @if (!(event.EventPersonalization?.getFieldTrad('platformPageSellSubtitle', currentLang))) {
            <mat-icon class="mr-3">bolt_outline</mat-icon>
          }
          {{ event.EventPersonalization?.getFieldTrad('platformPageSellSubtitle', currentLang) || te('sellYourTicket') }}
        </p>
      </div>
      <div class="buttons-group --row --full-width">
        <a data-cy="sale" [routerLink]="['/e/n/', event.url, 'vente']" [target]="widget ? '_blank' : '_self'">
          <button mat-button class="button --custom-event --xlarge --full-width" [ngClass]="{'--widget': widget}">
            {{ te('sellATicket') }}
          </button>
        </a>
      </div>
    } @else {
      <div class="section">
        <mat-card class="fs-16">
          <mat-card-content>
            <p class="fw-700">
              {{ event.EventPersonalization?.getFieldTrad('platformPageSellDeactivated', currentLang) || te('resaleNotAvailable') }}
            </p>
          </mat-card-content>
        </mat-card>
      </div>
    }
    
    @if (atLeastOnePurchaseActivated) {
      <div class="section">
        <p class="section__title">
          {{ event.EventPersonalization?.getFieldTrad('platformPagePurchaseTitle', currentLang) || te('lookingForTickets') }}
        </p>
        <p class="section__text">
          @if (!(event.EventPersonalization?.getFieldTrad('platformPagePurchaseSubtitle', currentLang))) {
          <mat-icon class="mr-3">lock</mat-icon>
          }
          {{ event.EventPersonalization?.getFieldTrad('platformPagePurchaseSubtitle', currentLang) || te('getAUniqueTicket') }}
        </p>
      </div>
      <div class="buttons-group --row --full-width">
        <a data-cy="purchase" [routerLink]="['/e/n/', event.url, 'achat']" [target]="widget ? '_blank' : '_self'">
          <button mat-button class="button --custom-event --xlarge --full-width" [ngClass]="{'--widget': widget}">
            {{ te('buyATicket') }}
          </button>
        </a>
      </div>
      <div class="section">
        @if (event?.lastTicketSold >= 0) {
          <p class="section__text">
            <mat-icon class="mr-3">timer</mat-icon>
            {{ te('lastTicketBought') }}
            <ng-container [ngPlural]="event?.lastTicketSold">
              <ng-template ngPluralCase="=0">{{ te('today') }}</ng-template>
              <ng-template ngPluralCase="=1">{{ te('oneDayAgo') }}</ng-template>
              <ng-template ngPluralCase="other">{{ te('daysAgo', {days: event?.lastTicketSold}) }}</ng-template>
            </ng-container>
          </p>
        }
      </div>
    } @else {
      <div class="section">
        <mat-card class="fs-16">
          <mat-card-content>
            <p class="fw-700">
              {{ event.EventPersonalization?.getFieldTrad('platformPagePurchaseDeactivated', currentLang) || te('purchaseNotAvailable') }}
            </p>
          </mat-card-content>
        </mat-card>
      </div>
    }

  } @else {
    <div class="section">
      <mat-card class="fs-16">
        <mat-card-content>
          @if (event.status === EventStatus.Scheduled) {
            <p class="fw-700"
              [innerHTML]="te('resaleNotYetAvailable')">
            </p>
          } @else {
            <p class="fw-700 mb-3" [innerHTML]="te('resaleNoLongerAvailable')"></p>
            <p class="fw-400">{{te('resaleNoLongerAvailableSubtitle')}}</p>
          }
        </mat-card-content>
      </mat-card>
    </div>
  }

</ng-container>