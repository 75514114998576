<ng-container *transloco="let tc; read 'checkSeller'">

  <h2 class="subtitle --center my-4">
    <mat-icon class="subtitle__icon">badge</mat-icon>
    {{ tc('identityDocument') }}
  </h2>

  <div class="id-container mb-5">

    @if (isLoadingUserKyc) {
      <app-loader diameter="40" class="my-3 mx-auto"></app-loader>
    }

    @if (!isLoadingUserKyc && !isLoadingUserBankingInfo) {
      <!-- if kyc already submitted but not yet validated -->
      @if (userBanking && (userBanking.mangoKYCLevel === KYCLevel.CREATED)) {
        <mat-card>
          <mat-card-content class="id-container__message">
            <mat-icon class="orange">cached</mat-icon>
            <span class="id-container__message__text">
              {{ tc('IDBeingValidated') }}
              <br>
                <br>
                  {{ tc('youCanContinueSellingTickets') }}
                </span>
              </mat-card-content>
            </mat-card>
          }
          <!-- if kyc already submitted but refused -->
          @if (userBanking?.mangoKYCLevel === 'REFUSED') {
            <mat-card class="mb-3">
              <mat-card-content class="id-container__message">
                <mat-icon class="red">close</mat-icon>
                <span class="id-container__message__text">
                  {{ tc('IDNotValidatedSendAnother') }}
                </span>
              </mat-card-content>
            </mat-card>
            @if (userKyc.kycRefusedReasonType) {
              <p class="error fs-16">
                {{ tc('kycErrorType.refusedReason') }} {{ tc('kycErrorType.' + userKyc.kycRefusedReasonType) }}
              </p>
            }
            @if (userKyc.kycRefusedReasonMessage) {
              <p class="error fs-16">
                {{userKyc.kycRefusedReasonMessage}}
              </p>
            }
            @if (userKyc.kycRefusedFlags?.length > 0) {
              <p class="error fs-16">
                {{ tc('moreInformation') }} :
                @for (errMsg of userKyc.kycRefusedFlags; track errMsg) {
                  {{ tc('kycErrorFlags.' + errMsg) }}
                }
              </p>
            }
          }
          <!-- if kyc validated -->
          @if (userBanking?.mangoKYCLevel === KYCLevel.STRONG) {
            <mat-card>
              <mat-card-content class="id-container__message">
                <mat-icon class="green">check</mat-icon>
                <span class="id-container__message__text">
                  {{ tc('IDValidatedWeCanPayYou') }}
                </span>
              </mat-card-content>
            </mat-card>
          }
          <!-- if kyc not validated and no kyc currently being reviewed -->
          @if (!userBanking?.mangoKYCId && ([KYCLevel.REFUSED, KYCLevel.LIGHT].includes(userBanking?.mangoKYCLevel) || (userBanking?.mangoKYCLevel === KYCLevel.CREATED && user.UserBankingInfo?.updatedAt < twoHoursAgo))) {
            <p class="id-container__explanation">
              {{ tc('chooseTheIDDocument') }}
            </p>
            <form [formGroup]="kycTypeForm">
              <mat-radio-group formControlName="choosenKYCDocumentType" class="column-radios">
                @for (docType of KYCDocumentTypeInfos | keyvalue; track docType) {
                  <mat-radio-button type="radio"
                    class="mb-3" [value]="docType.value.identifier">
                    <span class="fs-16">{{ tc(docType.value.identifier) }}</span>
                  </mat-radio-button>
                }
              </mat-radio-group>
            </form>
            @if (kycTypeForm.value.choosenKYCDocumentType) {
              <img [src]="'assets/img/' + KYCDocumentTypeInfos[kycTypeForm?.value?.choosenKYCDocumentType].imgUrl">
              <app-uploader acceptedFiles=".jpeg,.jpg,.png"></app-uploader>
              <form [formGroup]="checkboxesForm" class="id-checkboxes">
                <p class="id-checkboxes__phrase">
                  {{ tc('iCertifyIAttachedADocument') }}
                </p>
                <div class="id-checkboxes__boxes">
                  <mat-checkbox name="readable" formControlName="readable" color="accent" required>
                    <p class="checkbox-text">
                      <span>{{ tc('readableColorDirection') }}</span>
                      @if (KYCDocumentTypeInfos[kycTypeForm?.value?.choosenKYCDocumentType]?.isRectoVerso) {
                        <span>,
                          {{ tc('withFrontAndBack') }}
                        </span>
                      }
                      @if (checkboxesForm.controls['readable'].touched && !checkboxesForm.controls['readable'].valid) {
                        <mat-error class="fs-12">
                          {{ tc('readableError') }}
                        </mat-error>
                      }
                    </p>
                  </mat-checkbox>
                  <mat-checkbox name="valid" formControlName="valid" color="accent" required>
                    <p class="checkbox-text">{{ tc('notExpiredNotFalsified') }}</p>
                    @if (checkboxesForm.controls['valid'].touched && !checkboxesForm.controls['valid'].valid) {
                      <mat-error class="fs-12">
                        {{ tc('expiredError') }}
                      </mat-error>
                    }
                  </mat-checkbox>
                  <mat-checkbox name="owner" formControlName="owner" color="accent" required>
                    <p class="checkbox-text">{{ tc('containingTheFollowingInformation') }}</p>
                  </mat-checkbox>
                  <div class="owner-info">
                    <p>{{ tc('firstname') }} <span class="fw-600">{{user?.firstname | titlecase}}</span></p>
                    <p>{{ tc('birthname') }} <span class="fw-600">{{user?.lastname | uppercase}}</span></p>
                    <p>{{ tc('dateOfBirth') }} <span class="fw-600">{{user?.birthday | date: 'longDate':'+0000':('language.locale' | transloco) }}</span></p>
                    <p>{{ tc('toEdit') }}
                      <a class="link --primary" target="_blank" href="/mon-compte/informations-personnelles">{{ tc('clickHere') }}</a>.
                    </p>
                    <p>{{ tc('doNotRefresh') }}</p>
                    @if (checkboxesForm.controls['owner'].touched && !checkboxesForm.controls['owner'].valid) {
                      <mat-error class="fs-12">
                        {{ tc('ownerDataMatchError') }}
                      </mat-error>
                    }
                  </div>
                </div>
              </form>
            }
            <div class="dialog-link">
              <p class="dialog-link__text">{{ tc('whyDoYouNeedMyID') }}</p>
              <mat-icon class="dialog-link__icon" [matTooltip]="tc('moreInformation')" (click)="openDialog()">info</mat-icon>
            </div>
          }
        }

      </div>


      <h2 class="subtitle --center my-4">
        <mat-icon class="subtitle__icon">account_balance</mat-icon>
        {{ tc('yourBankAccount') }}
      </h2>

      <form [formGroup]="bankAccountFormGroup" class="check-seller-container">

        <mat-card class="check-seller-container__column">
          <mat-card-content>
            @if (isLoadingUserBankingInfo) {
              <app-loader diameter="40" class="my-5 mx-auto"></app-loader>
            }

            @if (!isLoadingUserBankingInfo) {
              <div class="form">
                @if (!registeredBankingInfo?.hasBankAccount || isEdition) {
                  <!-- type -->
                  <app-select-form-field
                    class="form__field --full"
                    [selections]="bankAccountTypeOptions"
                    [form]="bankAccountFormGroup"
                    [controlName]="'type'"
                    [formModel]="userBanking"
                    [validators]="['required']"
              [options]="{
                label: 'checkSeller.accountType'
              }"
                  ></app-select-form-field>
                  <!-- owner name -->
                  <app-text-form-field
                    class="mb-2 form__field --full"
                    [controlName]="'ownerName'"
                    [form]="bankAccountFormGroup"
                    [formModel]="userBanking"
                    [validators]="['required', 'pattern']"
              [options]="{
                label: 'checkSeller.accountHolder',
                pattern: nameRegex,
                trim: true
              }"
                  ></app-text-form-field>
                  <!-- IBAN -->
                  @if (bankAccountFormGroup.value?.type === BankAccountType.IBAN) {
                    <app-text-form-field
                      class="mb-2 form__field --full"
                      [controlName]="'IBAN'"
                      [form]="bankAccountFormGroup"
                      [formModel]="userBanking"
                      [validators]="['required', 'minLength', 'pattern', 'uppercaseSpace4', 'maxLength', 'IBAN']"
                [options]="{
                  label: 'checkSeller.IBAN',
                  minLength: 15,
                  maxLength: 39,
                  pattern: '[A-Z]{2}[0-9]{2} ([a-zA-Z0-9]{4} ){2,7}[a-zA-Z0-9]{1,4}',
                  trim: true
                }"
                    ></app-text-form-field>
                  }
                  <!-- US account -->
                  @if (bankAccountFormGroup.value?.type === BankAccountType.US) {
                    <app-text-form-field
                      class="mb-2 form__field --full"
                      [controlName]="'USAccountNumber'"
                      [form]="bankAccountFormGroup"
                      [formModel]="userBanking"
                      [validators]="['required', 'pattern']"
                [options]="{
                  label: 'checkSeller.accountNumber',
                  pattern: '[0-9]*'
                }"
                    ></app-text-form-field>
                    <app-text-form-field
                      class="mb-2 form__field --full"
                      [controlName]="'USAbaRouting'"
                      [form]="bankAccountFormGroup"
                      [formModel]="userBanking"
                      [validators]="['required', 'pattern', 'minLength', 'maxLength']"
                [options]="{
                  label: 'checkSeller.abaRouting',
                  pattern: '[0-9]{9}',
                  minLength: 9,
                  maxLength: 9
                }"
                    ></app-text-form-field>
                    {{ tc('additionalInformation') }}
                    <app-text-form-field
                      class="mb-2 form__field --full"
                      [controlName]="'address'"
                      [form]="addressForm"
                      [validators]="['required']"
                [options]="{
                  label: 'checkSeller.address',
                  trim: true
                }"
                    ></app-text-form-field>
                    <app-text-form-field
                      class="mb-2 form__field --full"
                      [controlName]="'addressSupplement'"
                      [form]="addressForm"
                [options]="{
                  label: 'checkSeller.addressSupplement',
                  trim: true
                }"
                    ></app-text-form-field>
                    <app-text-form-field
                      class="mb-2 form__field --full"
                      [controlName]="'postCode'"
                      [form]="addressForm"
                      [validators]="['required']"
                [options]="{
                  label: 'checkSeller.postcode',
                  trim: true
                }"
                    ></app-text-form-field>
                    <app-text-form-field
                      class="mb-2 form__field --full"
                      [controlName]="'city'"
                      [form]="addressForm"
                      [validators]="['required']"
                [options]="{
                  label: 'checkSeller.city',
                  trim: true
                }"
                    ></app-text-form-field>
                    <app-text-form-field
                      class="mb-2 form__field --full"
                      [controlName]="'region'"
                      [form]="addressForm"
                      [formModel]="userCopy"
                      [validators]="['required']"
              [options]="{
                label: 'checkSeller.region',
                trim: true
              }"
                    ></app-text-form-field>
                    <app-autocomplete-form-field
                      class="form__field --full"
                      [controlName]="'countryOfResidence'"
                      [selections]="countries"
                      [form]="addressForm"
                      [formModel]="userCopy"
                      [validators]="['required']"
              [options]="{
                label: 'accountInfos.countryCode' | transloco
              }"
                    ></app-autocomplete-form-field>
                  }
                  <!-- CA account -->
                  @if (bankAccountFormGroup.value?.type === BankAccountType.CA) {
                    <app-text-form-field
                      class="mb-2 form__field --full"
                      [controlName]="'CABranchCode'"
                      [form]="bankAccountFormGroup"
                      [formModel]="userBanking"
                      [validators]="['required', 'pattern', 'minLength', 'maxLength']"
                [options]="{
                  label: 'checkSeller.branchCode',
                  trim: true,
                  pattern: '[0-9]{5}',
                  minLength: 5,
                  maxLength: 5
                }"
                    ></app-text-form-field>
                    <app-text-form-field
                      class="mb-2 form__field --full"
                      [controlName]="'CAInstitutionNumber'"
                      [form]="bankAccountFormGroup"
                      [formModel]="userBanking"
                      [validators]="['required', 'pattern', 'minLength', 'maxLength']"
                [options]="{
                  label: 'checkSeller.instituationNumber',
                  pattern: '[0-9]{3}',
                  minLength: 3,
                  maxLength: 3
                }"
                    ></app-text-form-field>
                    <app-text-form-field
                      class="mb-2 form__field --full"
                      [controlName]="'CAAccountNumber'"
                      [form]="bankAccountFormGroup"
                      [formModel]="userBanking"
                      [validators]="['required', 'pattern', 'minLength', 'maxLength']"
                [options]="{
                  label: 'checkSeller.accountNumber',
                  pattern: '[0-9]{7,35}',
                  minLength: 7,
                  maxLength: 35
                }"
                    ></app-text-form-field>
                    <app-text-form-field
                      class="mb-2 form__field --full"
                      [controlName]="'CABankName'"
                      [form]="bankAccountFormGroup"
                      [formModel]="userBanking"
                      [validators]="['required', 'pattern']"
                [options]="{
                  label: 'checkSeller.bankName',
                  pattern: nameRegex,
                  trim: true
                }"
                    ></app-text-form-field>
                    {{ tc('additionalInformation') }}
                    <app-text-form-field
                      class="mb-2 form__field --full"
                      [controlName]="'address'"
                      [form]="addressForm"
                      [validators]="['required']"
                [options]="{
                  label: 'checkSeller.address',
                  trim: true
                }"
                    ></app-text-form-field>
                    <app-text-form-field
                      class="mb-2 form__field --full"
                      [controlName]="'addressSupplement'"
                      [form]="addressForm"
                [options]="{
                  label: 'checkSeller.addressSupplement',
                  trim: true
                }"
                    ></app-text-form-field>
                    <app-text-form-field
                      class="mb-2 form__field --full"
                      [controlName]="'postCode'"
                      [form]="addressForm"
                      [validators]="['required']"
                [options]="{
                  label: 'checkSeller.postcode',
                  trim: true
                }"
                    ></app-text-form-field>
                    <app-text-form-field
                      class="mb-2 form__field --full"
                      [controlName]="'city'"
                      [form]="addressForm"
                      [validators]="['required']"
                [options]="{
                  label: 'checkSeller.city',
                  trim: true
                }"
                    ></app-text-form-field>
                    <app-text-form-field
                      class="mb-2 form__field --full"
                      [controlName]="'region'"
                      [form]="addressForm"
                      [formModel]="userCopy"
                      [validators]="['required']"
                [options]="{
                  label: 'checkSeller.region',
                  trim: true
                }"
                    ></app-text-form-field>
                    <app-autocomplete-form-field
                      class="form__field --full"
                      [controlName]="'countryOfResidence'"
                      [selections]="countries"
                      [form]="addressForm"
                      [formModel]="userCopy"
                      [validators]="['required']"
                [options]="{
                  label: 'accountInfos.countryCode' | transloco
                }"
                    ></app-autocomplete-form-field>
                    @if (addressForm.hasError('wrongCountryName')) {
                      <mat-error>{{ 'formErrors.wrongCountryName' | transloco }}</mat-error>
                    }
                  }
                  @if (registeredBankingInfo?.hasBankAccount) {
                    <div class="buttons-group mb-0 --row --centered">
                      <button
                        mat-button
                        (click)="isEdition = false"
                        type="button"
                        class="button --outlined-primary --small"
                        >
                        {{ 'button.back' | transloco }}
                      </button>
                    </div>
                  }
                } @else {
                  @if (registeredBankingInfo?.ownerName) {
                    <div class="d-flex align-items">
                      <p class="mr-2">{{ tc('ownerNamePreview') }}</p>
                      <p class="fw-600">{{registeredBankingInfo.ownerName}}</p>
                    </div>
                  }
                  @if (registeredBankingInfo?.type) {
                    <div class="d-flex align-items">
                      <p class="mr-2">{{ tc('typePreview') }}</p>
                      <p class="fw-600">{{registeredBankingInfo.type}}</p>
                    </div>
                  }
                  @if (registeredBankingInfo?.accountNumberPreview) {
                    <div class="d-flex align-items">
                      <p class="mr-2">{{ tc('accountNumberPreview') }}</p>
                      <p class="fw-600">{{registeredBankingInfo.accountNumberPreview}}</p>
                    </div>
                  }
                  <div class="buttons-group mb-0 --row --centered">
                    <button
                      mat-button
                      (click)="confirmAccountDelete()"
                      type="button"
                      class="button --outlined-danger --small"
                      >
                      {{ tc('deleteThisAccount') }}
                    </button>
                    <button
                      mat-button
                      (click)="isEdition = true"
                      type="button"
                      class="button --outlined-primary --small"
                      >
                      {{ 'button.update' | transloco }}
                    </button>
                  </div>
                }
              </div>
            }
          </mat-card-content>
        </mat-card>

      </form>

      @if (isSavingData) {
        <div class="my-5">
          <app-loader diameter="40" class="mx-auto"></app-loader>
          @if (userBanking?.mangoKYCLevel !== 'STRONG' && !userBanking?.mangoKYCId) {
            <p class="mx-auto center fs-16">
              {{ tc('wait1Minute') }}
            </p>
          }
        </div>
      }

      @if (!bankAccountFormValid) {
        <p class="error fs-16 center mx-3 my-auto">{{ 'formErrors.bankAccountFormError' | transloco}}</p>
      }
      @if (!!error) {
        <p class="error fs-16 center mx-3 my-auto">{{ tc(error) }}</p>
      }
    </ng-container>
