import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AppService {
  public settings = new BehaviorSubject<{
    isWidget: boolean,
    referer: string,
    disabledEmailVerification: boolean,
  }>({
    isWidget: false,
    referer: undefined,
    disabledEmailVerification: false,
  });

  constructor() { }

}
