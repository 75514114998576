import { Platform } from '@angular/cdk/platform';
import { Injectable } from '@angular/core';

declare global {
  interface Window { _paq: Array<any>; }
}

@Injectable({
  providedIn: 'root',
})
export class TrackingService {
  private isBrowser = false;

  constructor(
    private platform: Platform,
  ) {
    this.isBrowser = this.platform.isBrowser;
    if (!this.eventArray && this.isBrowser) {
      // eslint-disable-next-line no-underscore-dangle
      window._paq = [];
    }
  }

  private get eventArray() {
    // eslint-disable-next-line no-underscore-dangle
    return this.isBrowser ? window._paq : [];
  }

  public addEvent(e: {category: string, action: string, name: string, value?: number, customDimension?: {}}) {
    this.eventArray.push(['trackEvent', e.category, e.action, e.name, e.value, e.customDimension]);
  }
}
