import { Injectable, OnDestroy } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest } from '@angular/common/http';
import { Observable, Subscription } from 'rxjs';
import { AppService } from '../services';

@Injectable()
export class RefererInterceptor implements HttpInterceptor, OnDestroy {
  private settings: {
    isWidget?: boolean;
    referer?: string;
  };
  private settingsSub: Subscription;
  private noRefererUrls = [
    'https://webpayment.payline.com/webpayment/getToken',
    'https://homologation-webpayment.payline.com',
    'https://pci.sandbox.mangopay.com/api/',
  ];

  constructor(
    public appService: AppService,
  ) {
    this.settingsSub = this.appService.settings.asObservable()
      .subscribe( (settings) => {
        this.settings = settings;
      });
  }

  public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // in iframe mode we need to add the referer header
    if (this.shouldAddRefererHeader(req.url)) {
      const clonedReq = req.clone({
        headers: req.headers.append('X-RT-referer', this.settings.referer),
      });
      return next.handle(clonedReq);
    }
    // default case
    return next.handle(req);
  }

  private shouldAddRefererHeader(url: string) {
    return this.settings.referer && !this.noRefererUrls.some((noref) => url.startsWith(noref));
  }

  ngOnDestroy(): void {
    this.settingsSub?.unsubscribe();
  }

}
