<button
  type="button"
  mat-button
  [class]="cssClass"
  (click)="onClick($event)"
  >
  @switch (status) {
    @case ('indeterminate') {
      <mat-icon class="button__icon">indeterminate_check_box</mat-icon>
    }
    @case (true) {
      <mat-icon class="button__icon">check_box</mat-icon>
    }
    @case (false) {
      <mat-icon class="button__icon">check_box_outline_blank</mat-icon>
    }
  }
  {{ options.label }}
</button>
