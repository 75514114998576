
<mat-form-field
  class="form__field"
  [subscriptSizing]="'dynamic'"
>
  <mat-label>{{ options?.label }}</mat-label>
  <textarea
    class="form__field__textarea"
    cols="1"
    type="text"
    matInput
    cdkTextareaAutosize
    [cdkAutosizeMaxRows]="options?.maxRows"
    [formControl]="control"
    [required]="validators.includes('required')"
  ></textarea>
  <mat-hint>{{options?.hint}}</mat-hint>
</mat-form-field>
