import { Platform } from '@angular/cdk/platform';
import {ErrorHandler, Injectable} from '@angular/core';
import { SentryErrorHandler, createErrorHandler } from '@sentry/angular';

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {
  private sentry: SentryErrorHandler;
  private isBrowser = false;

  constructor(
    private platform: Platform,
  ) {
    this.isBrowser = this.platform.isBrowser;
    this.sentry = createErrorHandler({
      showDialog: true,
    });
  }

  handleError(error: Error): void {
    const chunkFailedMessage = /Loading chunk [\d]+ failed/;
    if (
      error.name === 'IndexSizeError' &&
      error.message === 'Index or size is negative or greater than the allowed amount' &&
      error.stack.includes('quaggaScan')
    ) {
      // eslint-disable-next-line no-console
      console.error('caught quaggaScan IndexSizeError ', error);
      return;
    }
    if (chunkFailedMessage.test(error.message) && this.isBrowser) {
      window.setTimeout(() => {
        window.location.reload();
      }, 200);
    } else {
      this.sentry.handleError(error);
    }
  }

}
