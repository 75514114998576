import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import { Category, Event, SeatDetails, Ticket, TicketSaleData } from '../../../models';
import { AppService, TicketsService } from '../../../services';
import { DialogBasicComponent } from '../dialog-basic/dialog-basic.component';
import { TranslocoService, TranslocoModule } from '@ngneat/transloco';
import { EventCustomMessageComponent } from '../event-custom-message/event-custom-message.component';
import { MatIcon } from '@angular/material/icon';
import { MatCard, MatCardContent } from '@angular/material/card';
import { AsyncPipe, DecimalPipe } from '@angular/common';
import { SeatDetailsComponent } from '../seat-details/seat-details.component';
import { MatFormField, MatSuffix, MatError } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { MatButton } from '@angular/material/button';
import { LetDirective } from '../../directives/let.directive';
import { PipesModule } from '../../../pipes/pipes.module';

@Component({
  selector: 'app-ticket-price',
  templateUrl: './ticket-price.component.html',
  styleUrls: ['./ticket-price.component.scss'],
  standalone: true,
  imports: [
    TranslocoModule,
    EventCustomMessageComponent,
    MatIcon,
    MatCard,
    MatCardContent,
    SeatDetailsComponent,
    MatFormField,
    FormsModule,
    MatInput,
    ReactiveFormsModule,
    MatSuffix,
    MatError,
    MatButton,
    LetDirective,
    AsyncPipe,
    DecimalPipe,
    PipesModule,
  ],
})
export class TicketPriceComponent implements OnInit, OnDestroy {
  @Input() category: Category;
  @Input() form: UntypedFormGroup;
  @Input() saleTickets?: TicketSaleData[];
  @Input() ticket?: Ticket;
  // eslint-disable-next-line no-underscore-dangle
  private _event: Event;
  @Input()
  get event(): Event {
    // eslint-disable-next-line no-underscore-dangle
    return this._event;
  }
  set event(event: Event) {
    this.setEventData(event);
  }

  isPartner = false;
  isSamePrice = false;
  perTenThoushandVar = 10000;
  minimalPlatformPrice: number;
  maximalPlatformPrice: number;
  formError = '';
  selectedPriceControl;
  platformPriceControl;

  private ticketPriceSub: Subscription;

  constructor(
    public dialog: MatDialog,
    public appService: AppService,
    private ticketsService: TicketsService,
    private translocoService: TranslocoService,
  ) { }

  ngOnInit() {
    if (!this.form.controls.selectedPrice || !this.form.controls.selectedPrice) {
      this.addControls();
    }
  }

  addControls() {
    this.selectedPriceControl = new UntypedFormControl( '', [
      Validators.required,
      Validators.pattern('[0-9,.]*'),
    ]);
    this.platformPriceControl = new UntypedFormControl( '', [
      Validators.required,
      Validators.pattern('[0-9,.]*'),
    ]);
    this.form.addControl('selectedPrice', this.selectedPriceControl);
    this.form.addControl('platformPrice', this.platformPriceControl);
  }

  hasMatchingProperty(displayedInfo: any, seatDetails: SeatDetails): boolean {
    return Object.keys(displayedInfo).some((key) => !!displayedInfo[key] && key in seatDetails && !!seatDetails[key]);
  }

  setEventData(event: Event) {
    // eslint-disable-next-line no-underscore-dangle
    this._event = event;
    if (!this.category) {
      throw new Error(`Missing category with id : ${this.category?.id} for event ${this.event.id} ${this.event.name}`);
    }
    this.isSamePrice = this.category.minimalSalePrice === this.category.maximalSalePrice;
    if (!this.form.controls.selectedPrice || !this.form.controls.selectedPrice) {
      this.addControls();
    }
    this.form.controls.selectedPrice.setValidators([
      Validators.required,
      Validators.pattern('[0-9,.]*'),
      Validators.min(this.category.minimalSalePrice / 100),
      Validators.max(this.category.maximalSalePrice / 100),
    ]);
    if (this.isSamePrice) {
      this.form.controls.selectedPrice.patchValue(this.category.maximalSalePrice / 100);
      this.form.controls.selectedPrice?.disable();
      this.form.controls.platformPrice?.disable();
      const platformPrice = this.getPriceWithFees(this.form.controls.selectedPrice.value);
      this.updatePlatformPrice(platformPrice);
    }
    if (this.ticket) {
      const decimalPrice = this.ticket.price / 100;
      this.updateSelectedPrice(decimalPrice);
      this.onSelectedPriceInput({target: {value: decimalPrice}});
    }
    this.getInSaleTicketsPrices(event.id, this.category.id);
  }

  public getInSaleTicketsPrices(eventId, categoryId) {
    this.ticketPriceSub?.unsubscribe();
    // retrieve price range of ticket currently in sale
    this.ticketPriceSub = this.ticketsService.getInSaleTicketsPrices(eventId, categoryId).subscribe({
      next: (res) => {
        if (res && res[0]?.minimalPrice && res[0]?.maximalPrice) {
          this.minimalPlatformPrice = this.getPriceWithFees(res[0].minimalPrice / 100);
          this.maximalPlatformPrice = this.getPriceWithFees(res[0].maximalPrice / 100);
        }
      },
      error: (error) => {
        // eslint-disable-next-line no-console
        console.log('Error in getInSaleTicketsPrice function on sale-step-second component : ', error);
      },
    });
  }

  getPriceWithFees(priceWithoutFees) {
    return priceWithoutFees * (
      1 +
      ((this.category.feesCoefficient ?? 0) / this.perTenThoushandVar)
    ) + this.category.feesFlat / 100;
  }
  getPriceWithoutFees(priceWithFees) {
    return (priceWithFees - this.category.feesFlat / 100)
      /
      (1 + ((this.category.feesCoefficient ?? 0) / this.perTenThoushandVar));
  }

  onSelectedPriceChange(event) {
    const value = event.target.value;
    this.updateSelectedPrice(value);
  }

  onSelectedPriceInput(event) {
    const selectedPrice = event.target.value;
    const platformPrice = (this.category.feesCoefficient || this.category.feesFlat) ?
      this.getPriceWithFees(selectedPrice) : selectedPrice;
    this.updatePlatformPrice(platformPrice);
  }

  updateSelectedPrice(price) {
    const formatedPrice = this.formatDecimals(price);
    this.form.controls.selectedPrice.setValue(formatedPrice);
  }

  onPlatformPriceChange(event) {
    const value = event.target.value;
    this.updatePlatformPrice(value);
    // we update again platformPrice according to selectedPrice to escape math round issues
    const selectedPrice = this.form.controls.selectedPrice.value;
    const platformPrice = this.getPriceWithFees(selectedPrice);
    this.updatePlatformPrice(platformPrice);
  }
  onPlatformPriceInput(event) {
    const platformPrice = event.target.value;
    const selectedPrice = (this.category.feesCoefficient || this.category.feesFlat) ?
      this.getPriceWithoutFees(platformPrice) : platformPrice;
    this.updateSelectedPrice(selectedPrice);
  }
  updatePlatformPrice(price) {
    const formatedPrice = this.formatDecimals(price);
    this.form.controls.platformPrice.setValue(formatedPrice);
  }

  formatDecimals(price) {
    return parseFloat(parseFloat(price).toFixed(2));
  }

  openDialog() {
    const dialogRef = this.dialog.open(DialogBasicComponent, {
      autoFocus: false,
      data: {
        title: this.translocoService.translate('ticketPrice.howIsThisPriceCalculated'),
        text: this.translocoService.translate('ticketPrice.howIsThisPriceCalculatedResponse'),
      },
    });
  }

  public onMaxPrice(){
    const maxPrice = this.category?.maximalSalePrice / 100;
    this.updateSelectedPrice(maxPrice);
    const platformPrice = this.getPriceWithFees(maxPrice);
    this.updatePlatformPrice(platformPrice);
  }

  public isMaxPrice() {
    return +this.form.get('selectedPrice').value === this.formatDecimals(this.category?.maximalSalePrice / 100);
  }

  ngOnDestroy(): void {
    this.ticketPriceSub?.unsubscribe();
  }
}
