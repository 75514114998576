// eslint-disable-next-line max-classes-per-file
import {
  getCurrencySymbol,
  priceFormatter,
  seatFormatter,
  Category,
  Currencies,
  Event,
  PayoutPolicy,
  SaleRecord,
  TicketRecall,
  TicketingPlatform,
  SeatDetails,
} from '.';
import { environment } from '../../environments/environment';

export enum TicketStatus {
  ApiError = 'apiError',
  Sold = 'sold',
  Resold = 'resold',
  PendingResold= 'pendingResold',
  PendingSellerConfirmation = 'pendingSellerConfirmation',
  PendingPurchase = 'pendingPurchase',
  Sale = 'sale',
  Withdrawn = 'withdrawn',
  NotValid = 'notValid',
  PendingPayment = 'pendingPayment',
  PendingTicketDelivery = 'pendingTicketDelivery',
  PendingValidation = 'pendingValidation',
  PrivateSale = 'privateSale',
  FriendSale = 'friendSale',
  PendingSwap = 'pendingSwap',
}

export enum Actions {
  apiError = 'Mettre en erreur api',
  sale= 'Remettre en vente',
  notValid= 'Invalider',
  friendSale= 'Mettre en vente à un(e) ami(e)',
  pendingPayment= 'Mettre en paiement en cours',
  pendingPurchase= 'Mettre en achat en cours',
  pendingSellerConfirmation= 'Mettre en attente de confirmation du vendeur',
  pendingTicketDelivery= 'Mettre en attente de livraison',
  pendingValidation= 'Mettre en attente de validation',
  withdrawn= 'Annuler',
  sold= 'Vendre',
}

export const ticketStatusInfos = {
  [TicketStatus.ApiError]: {
    key : 'apiError',
    name : 'erreur api',
    color: '#ce4358',
  },
  [TicketStatus.Sold]: {
    key : 'sold',
    name : 'vendu',
    color: '#3bceac',
  },
  [TicketStatus.PendingSellerConfirmation]: {
    key : 'pendingSellerConfirmation',
    name : 'en attente de confirmation du vendeur',
    color: '#ce4358',
  },
  [TicketStatus.PendingPurchase]: {
    key : 'pendingPurchase',
    name : 'en cours d\'achat',
    color: '#ce7d43',
  },
  [TicketStatus.Sale]: {
    key : 'sale',
    name : 'en vente',
    color: '#ce7d43',
  },
  [TicketStatus.Withdrawn]: {
    key : 'withdrawn',
    name : 'annulé',
    color: '#ce4358',
  },
  [TicketStatus.NotValid]: {
    key : 'notValid',
    name : 'non valide',
    color: '#ce4358',
  },
  [TicketStatus.PendingPayment]: {
    key : 'pendingPayment',
    name : 'paiement en cours',
    color: '#ce7d43',
  },
  [TicketStatus.PendingTicketDelivery]: {
    key : 'pendingTicketDelivery',
    name : 'en attente de livraison',
    color: '#ce4358',
  },
  [TicketStatus.PendingValidation]: {
    key : 'pendingValidation',
    name : 'en attente de validation',
    color: '#ce4358',
  },
  [TicketStatus.PrivateSale]: {
    key : 'privateSale',
    name : 'en vente privée',
    color: '#ce7d43',
  },
  [TicketStatus.FriendSale]: {
    key : 'friendSale',
    name : 'en vente à un ami',
    color: '#ce7d43',
  },
  [TicketStatus.PendingSwap]: {
    key : 'pendingSwap',
    name : 'en cours de swap',
    color: '#ce4358',
  },
};

export class TicketSaleData {
  eventId: number;
  originalLastName: string;
  originalFirstName: string;
  originalTicketNumber: string;
  price: number;
  categoryId: string;
  payoutPolicy: PayoutPolicy;
  sellerCommercialCommunication: boolean;
  cardRefundCeil: number;
  friendSale: boolean;
  seatDetails: SeatDetails;
  upgraded: boolean;

  constructor(options?: any) {
    if (options) {
      this.eventId = options.eventId;
      this.originalFirstName = options.originalFirstName;
      this.originalLastName = options.originalLastName;
      this.originalTicketNumber = options.originalTicketNumber;
      this.price = options.price;
      this.categoryId = options.categoryId;
      this.payoutPolicy = options.payoutPolicy;
      this.sellerCommercialCommunication = options.sellerCommercialCommunication;
      this.cardRefundCeil = options.cardRefundCeil;
      this.friendSale = options.friendSale;
      this.seatDetails = new SeatDetails(options.seatDetails);
      this.upgraded = options.upgraded;
    }
  }
}

export class CategoryPrices {
  minimalPrice: number;
  maximalPrice: number;
}

export class Ticket {
  id: number;
  Category: Category;
  categoryId: number;
  fees: number;
  price: number;
  currency: Currencies;
  status: TicketStatus;
  updatedAt: Date;
  createdAt: Date;
  modifiedBy: number;
  newLastName: string;
  newFirstName: string;
  SaleRecord: SaleRecord;
  seatDetails: SeatDetails;
  customBuyerFields: {[key: string]: string};
  public customTicketAnswers: {[fieldId: number]: unknown};
  Event?: Event;
  eventId: number;
  privateSaleToken: string;
  isFriendSale: boolean;
  directPurchaseToken: string;
  buyerCommercialCommunication: boolean;
  sellerCommercialCommunication: boolean;
  upgradeFees: number;
  upgradeCategoryId: number;
  waitlistToken?: string;
  TicketingPlatform: TicketingPlatform;
  UpgradeCategory: Category;
  TicketRecall: TicketRecall;

  constructor(options?: any) {
    if (options) {
      Object.assign(this, options);

      this.id = options.id || options.ticketId ;
      this.Category = new Category(options.Category);
      this.price = parseInt(options.price, 10);
      this.fees = parseInt(options.fees, 10);
      this.buyerCommercialCommunication = options.buyerCommercialCommunication;
      this.sellerCommercialCommunication = options.sellerCommercialCommunication;
      this.SaleRecord = new SaleRecord(options.SaleRecord);
      this.seatDetails = new SeatDetails(options.seatDetails);
      this.customBuyerFields = options.customBuyerFields;
      this.customTicketAnswers = options.customTicketAnswers;
      this.createdAt = options.createdAt ? new Date(options.createdAt) : null;
      this.updatedAt = options.updatedAt ? new Date(options.updatedAt) : null;
      this.Event = options.Event ? new Event(options.Event) : null;
      this.TicketingPlatform = options.TicketingPlatform ? new TicketingPlatform(options.TicketingPlatform) : undefined;
      this.UpgradeCategory = options.UpgradeCategory ? new Category(options.UpgradeCategory) : undefined;
      this.TicketRecall = options.TicketRecall ? new TicketRecall(options.TicketRecall) : undefined;
    }
  }

  get humanReadableSellerPrice(): string {
    return priceFormatter([this.price]);
  }

  get humanReadableBuyerPrice(): string {
    return priceFormatter([this.price, this.fees, (this.upgradeFees ?? 0)]);
  }

  get buyerPrice(): number {
    return this.price + this.fees + (this.upgradeFees ?? 0);
  }

  get downloadUrl(): string {
    return `${environment.apiUrl}purchase/pdf/${this.id}/${this.SaleRecord.ticketToken}`;
  }

  public get currencySymbol() {
    return getCurrencySymbol(this.currency);
  }

  humanReadableSeatDetails(translation: Record<string, string>, locale?: string): string {
    return seatFormatter(this.seatDetails, translation, {}, locale);
  }
}
