
<ng-container *appLet="(appService.settings.asObservable() | async) as setting">
  <div class="d-flex pt-5 mt-auto" *transloco="let tmw; read 'myWaitlists'">
    <!-- hiding icons enhance by 4 loading performance sometimes -->
    @if (!hideIcons) {
      @if (currentRank && userRank) {
        <object
          extra="origin"
          style="width: 2.5rem; height: 2.7rem; margin-top: -1rem;"
          class="custom-color-svg"
          type="image/svg+xml"
          [data]="'assets/img/illustration/ticket-envelope-close-animate-alert.svg' + (setting.referer ? '?wref=' + setting.referer : '') | safe:'resourceUrl'"
          [ngClass]="{'--background-logo': color === color}"
          >
          <param name="color" [value]="color">
          <param name="backgroundColor" [value]="backgroundColor">
        </object>
      }
      @if (!currentRank || !userRank) {
        <object
          extra="origin"
          style="width: 2.5rem; height: 2.7rem; margin-top: -1rem;"
          class="custom-color-svg"
          type="image/svg+xml"
          [data]="'assets/img/illustration/ticket-envelope-alert.svg' + (setting.referer ? '?wref=' + setting.referer : '') | safe:'resourceUrl'"
          [ngClass]="{'--background-logo': color === color}"
          >
          <param name="color" [value]="color">
          <param name="backgroundColor" [value]="backgroundColor">
        </object>
      }
    }
    <div class="ml-2 full-width">
      @if (currentRank && userRank) {
        <div class="gauge-section">
          @if (userRank > 0) {
            <div
              class="gauge-section__me"
              [style.left]="'max( max(3%, 1.6rem), ' + userRank + '% )'"
              >
              <p class="gauge-section__me__name">{{ user?.firstname ? user.firstname : tmw('me') }}</p>
            </div>
          }
          <div class="gauge-section__bar">
            <div
              class="gauge-section__bar__main-color"
              [style.width]="(currentRank ?? 0) + '%'"
              >
              <div
                class="gauge-section__bar__main-color --progress"
                [style.backgroundColor]="color"
              ></div>
              <div
                class="gauge-section__bar__main-color --background"
              ></div>
            </div>
          </div>
        </div>
      }
      @if (!currentRank || !userRank) {
        <div class="gauge-section__bar reelax-light-grey-bg"></div>
      }
      @if (currentRank && userRank) {
        <div class="d-flex --vertical-centered mt-3" [style.color]="color">
          <p class="fs-12" [ngClass]="{'clickable': !inPopin}" (click)="!inPopin ? openWaitListInfoDialog() : null">
            <span class="fw-700 fs-14" >
              {{ currentRank ?? 0 | number: '1.0-2':('language.locale' | transloco)}}&nbsp;%
            </span>
            @if (randomNotifications >= randomThreshold) {
              {{ tmw('peopleAlreadyGetNotifiedChronologically') }}
            } @else {
              {{ tmw('peopleAlreadyGetNotified') }}
            }
          </p>
        </div>
      }
      @if (randomNotifications >= randomThreshold) {
        <div class="d-flex --vertical-centered">
          <p class="fs-12" [ngClass]="{'clickable': !inPopin}" (click)="!inPopin ? openWaitListInfoDialog() : null">
            <span class="fw-700 fs-14" >
              {{ randomNotifications ?? 0 | number: '':('language.locale' | transloco)}}
            </span>
            {{ tmw('randomSelection', {randomAlerts: (100 - currentRank) ?? 0 | number: '1.0-2':('language.locale' | transloco)}) }}
          </p>
        </div>
      }
      @if (!currentRank || !userRank) {
        <div class="d-flex --vertical-centered mt-3">
          <p class="fs-12" [ngClass]="{'clickable': !inPopin}" (click)="!inPopin ? openWaitListInfoDialog() : null">
            {{ tmw('cannotShowGauge') }}
          </p>
        </div>
      }
    </div>
  </div>
</ng-container>