import { Pipe, PipeTransform } from '@angular/core';
import { LanguageMap } from '../models';

@Pipe({
  name: 'translateLanguageMap',
})
export class TranslateLanguageMapPipe implements PipeTransform {
  transform(value: string | LanguageMap, language: string) {
    if(!value) {
      return undefined;
    }
    if (typeof value === 'string') {
      return value;
    } else {
      return value?.[language] ?? value?.['fr-FR'];
    }
  }
}
