<form [formGroup]="paymentFormGroup" *transloco="let tppf; read 'purchase.paymentForm'">
  <div class="payment-form">

    @if (!isFreeProcess) {
      <div class="payment-form__card">
        <div class="payment-form__card__card-recto">
          <div class="payment-form__card__card-recto__card-info">
            <div class="payment-form__card__card-recto__card-info__fields">
              <div class="form payment-form__card__card-recto__card-info__fields__card-number">
                <mat-form-field class="form__field">
                  <mat-label class="payment-label">{{ tppf('creditCardNumber') }}</mat-label>
                  <input
                    [appTrim]="true"
                    [appUppercaseSpace4]="true"
                    matInput
                    formControlName="cardNumber"
                    (input)="onCardNumberChange($event)"
                    maxlength="19"
                    required
                    >
                </mat-form-field>
                @if (!!getError('cardNumber')) {
                  <div class="error-card">
                    <span class="error-card__error">{{ 'formErrors.' + getError('cardNumber') | transloco }}</span>
                  </div>
                }
              </div>
              <div>
                <div class="payment-form__card__card-recto__card-info__fields__expiry-cvv">
                  <div class="payment-form__card__card-recto__card-info__fields__expiry-cvv__expiry">
                    <div class="form payment-form__card__card-recto__card-info__fields__expiry-cvv__expiry__fields">
                      <mat-form-field class="form__field payment-form__card__card-recto__card-info__fields__expiry-cvv__expiry__fields__month">
                        <mat-label>{{ tppf('expiryDate') }}</mat-label>
                        <input
                          [appTrim]="true"
                          matInput
                          formControlName="expiryDate"
                          (input)="formatExpiryDate($event)"
                          maxlength="5"
                          required
                          [placeholder]="'timeUnit.short.MMYY' | transloco"
                          >
                      </mat-form-field>
                      @if (!!getError('expiryDate')) {
                        <div class="error-card">
                          <span class="error-card__error">{{ 'formErrors.' + getError('expiryDate') | transloco }}</span>
                        </div>
                      }
                      @if (paymentFormGroup.hasError('validExpiryDate')) {
                        <div class="error-card">
                          <span class="error-card__error">{{ 'formErrors.validExpiryDate'  | transloco }}</span>
                        </div>
                      }
                      <mat-form-field class="pl-3 form__field payment-form__card__card-recto__card-info__fields__expiry-cvv__expiry__fields__year">
                        <mat-label>{{ tppf('CVC') }}</mat-label>
                        <input
                          [appTrim]="true"
                          matInput
                          formControlName="cvv"
                          maxlength="4"
                          required
                          >
                      </mat-form-field>
                      @if (!!getError('cvv') && !getError('expiryDate')) {
                        <div class="error-card">
                          <span class="error-card__error --right">{{ 'formErrors.' + getError('cvv') | transloco }}</span>
                        </div>
                      }
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="payment-form__card__card-recto__card-info__right-column">
              <div class="brand-logo">
                @if (cardType === 'visa') {
                  <img class="brand-logo__img" src="assets/img/visa.png" alt="visa">
                }
                @if (cardType === 'mastercard') {
                  <img class="brand-logo__img" src="assets/img/mastercard.png" alt="mastercard">
                }
                @if (cardType === 'amex') {
                  <img class="brand-logo__img" src="assets/img/american-express.png" alt="american express">
                }
              </div>
            </div>
          </div>
          <div class="show-on-extra-small-only d-flex">
            <img alt="Mangopay" class="mangopay-logo" src="assets/img/mangopay-logo.png?v=2">
            @if (cardType === 'visa') {
              <img class="brand-logo__img" src="assets/img/visa.png" alt="visa">
            }
            @if (cardType === 'mastercard') {
              <img class="brand-logo__img" src="assets/img/mastercard.png" alt="mastercard">
            }
            @if (cardType === 'amex') {
              <img class="brand-logo__img" src="assets/img/american-express.png" alt="american express">
            }
          </div>
        </div>
        <div class="hide-on-extra-small-only">
          <div class="payment-form__card__card-verso">
            <div class="payment-form__card__card-verso__colored-bar"></div>
            <div class="payment-form__card__card-verso__mangopay">
              <img alt="Mangopay" class="mangopay-logo" src="assets/img/mangopay-logo.png?v=2">
            </div>
          </div>
        </div>
      </div>
      <div *transloco="let ta; read: 'accountInfos'" class="move-left">
        <mat-checkbox class="mb-3" formControlName="cgReelax" required>
          @if (!event?.organizerTerms) {
            <p class="checkbox-text">
              {{ ta('iAcceptThe') }}
              <a class="link --custom-event" href="https://reelax-tickets.com/assets/pdf/CGU_reelax.pdf" target="_blank">{{ ta('termsAndConditions') }}</a>
              {{ ta('andThe') }}
              <a class="link --custom-event" href="https://reelax-tickets.com/assets/pdf/PP_reelax.pdf" target="_blank">{{ ta('privacyPolicy') }}</a>
              {{ ta('ofReelaxTickets') }}.<span [matTooltip]="'formErrors.required' | transloco" class="custom-event">&nbsp;*</span>
            </p>
          } @else {
            <p class="checkbox-text">
              {{ ta('iAcceptThe') }}
              <a class="link --custom-event" href="https://reelax-tickets.com/assets/pdf/CGU_reelax.pdf" target="_blank">{{ ta('termsAndConditions') }}</a>
              {{ ta('andThe') }}
              <a class="link --custom-event" href="https://reelax-tickets.com/assets/pdf/PP_reelax.pdf" target="_blank">{{ ta('privacyPolicy') }}</a>
              {{ ta('ofReelaxTickets') }}
              {{ ta('asWellAsThe') }}
              <a class="link --custom-event" [href]="event.organizerTerms" target="_blank">{{ ta('termsAndConditions') }}</a>
              {{ ta('ofTheOrganizer') }}.<span [matTooltip]="'formErrors.required' | transloco" class="custom-event">&nbsp;*</span>
            </p>
          }
        </mat-checkbox>
        @if (!!getError('cgReelax')) {
          <div class="error-card --checkbox">
            <span class="error-card__error">{{ 'formErrors.' + getError('cgReelax') | transloco }}</span>
          </div>
        }
        <mat-checkbox class="mb-3" formControlName="cgMangopay" required><p class="checkbox-text">{{ ta('iAcceptThe') }} <a class="link --custom-event" href="https://reelax-tickets.com/assets/pdf/CGU_mangopay.pdf" target="_blank">{{ ta('termsAndConditions') }}</a> {{ ta('ofMangopay') }}.<span [matTooltip]="'formErrors.required' | transloco" class="custom-event">&nbsp;*</span></p></mat-checkbox>
        @if (!!getError('cgMangopay')) {
          <div class="error-card --checkbox">
            <span class="error-card__error">{{ 'formErrors.' + getError('cgMangopay') | transloco }}</span>
          </div>
        }
        @if (!hideCommercialCheckbox) {
          <mat-checkbox class="mb-3" name="commercialCommunication" formControlName="commercialCommunication">
            <p class="checkbox-text">
              {{ event.EventPersonalization?.getFieldTrad('customCommercialCommunication', currentLang) ??
              ta('defaultCommercialCommunicationAgreement') }}
            </p>
          </mat-checkbox>
        }
      </div>
      @if (isSavingData) {
        <app-loader diameter="40" class="m-auto"></app-loader>
      }
      @if (!!formError && !paymentFormGroup.valid) {
        <span class="error center">{{ 'formErrors.' + formError | transloco }}</span>
      }
    } @else {
      <div class="free-ticket">
        <p>{{ tppf('thisTicketIsFree') }}</p>
        <p class="free-ticket__advise">{{ tppf('pleaseConfirmToGetThePlace') }}</p>
        @if (!hideCommercialCheckbox) {
          <mat-checkbox name="commercialCommunication" formControlName="commercialCommunication">
            <p class="checkbox-text" *transloco="let ta; read: 'accountInfos'">
              {{ event.EventPersonalization?.getFieldTrad('customCommercialCommunication', currentLang) ??
              ta('defaultCommercialCommunicationAgreement') }}
            </p>
          </mat-checkbox>
        }
      </div>
    }

  </div>

</form>
