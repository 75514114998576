import { SelectOption } from '.';

export enum Currencies {
  United_Arab_Emirates_dirham = 'AED',
  Australian_dollar = 'AUD',
  Canadian_dollar = 'CAD',
  Swiss_franc = 'CHF',
  Czech_koruna = 'CZK',
  Danish_krone = 'DKK',
  Euro = 'EUR',
  Pound_sterling = 'GBP',
  Hong_Kong_dollar = 'HKD',
  Japanese_yen = 'JPY',
  Norwegian_krone = 'NOK',
  Polish_złoty = 'PLN',
  Swedish_krona = 'SEK',
  United_States_dollar = 'USD',
  South_African_rand = 'ZAR',
}
export const currencies = Object.values(Currencies);

export const CurrenciesData: SelectOption[] = [
  {
    value: Currencies.Euro,
    viewValue: '€',
  },
  {
    value: Currencies.Swiss_franc,
    viewValue: 'CHF',
  },
  {
    value: Currencies.Pound_sterling,
    viewValue: '£',
  },
];

export const getCurrencySymbol = (currency: Currencies) => CurrenciesData.find( (c) => c.value === currency)?.viewValue;
