
export enum RecallStatus {
  Created = 'created',
  Processing = 'processing',
  Error = 'error',
  Done = 'done',
}

export class TicketRecall {
  public id!: number;

  public status: RecallStatus;

  public readonly createdAt!: Date;
  public readonly updatedAt!: Date;

  // Foreign IDs
  public eventId: number;
  public ticketId: number;
  public authorId: number;
  public sellerId: number;
  constructor(data?: Partial<TicketRecall>) {
    Object.assign(this, data);
    this.createdAt = data.createdAt ? new Date(data.createdAt) : null;
    this.updatedAt = data.updatedAt ? new Date(data.updatedAt) : null;
  }
}
