<mat-form-field
  class="form__field --currency"
  [subscriptSizing]="'dynamic'"
  >
  <mat-label>{{options.label}}</mat-label>
  <input
    type="number"
    matInput
    [formControl]="control"
    [min]="options?.min" [max]="options?.max"
    [required]="validators.includes('required')"
    (onWheel)="$event?.preventDefault()"
    >
  @if (options?.iconName) {
    <mat-icon
      matSuffix
      class="currency__icon"
      >
      {{ options.iconName }}
    </mat-icon>
  }

  <div
    class="currency__icon"
    matSuffix
    >
    <mat-select
      [formControl]="currencyControl"
      (selectionChange)="updateCurrency($event)"
      required
      >
      @for (currency of CurrenciesData; track currency) {
        <mat-option [value]="currency.value">
          {{currency.viewValue}}
        </mat-option>
      }
    </mat-select>
  </div>
  <mat-hint>{{options?.hint}}</mat-hint>
  @if (control.errors) {
    <mat-error>{{ 'formErrors.' + Objectkeys(control.errors)?.[0] | transloco }}</mat-error>
  }
</mat-form-field>
