import { Injectable } from '@angular/core';
import { StorageService } from '../services';

@Injectable({
  providedIn: 'root',
})
export class UserStore {
  private storageKey = '[user]';

  constructor(
    private storageService: StorageService,
  ) {
  }

  getUser() {
    return this.storageService.getItem<{id: number}>(this.storageKey);
  }

  setUser(user: {id: number}) {
    this.storageService.setItem(this.storageKey, user);
  }

}
