
export class AuthorizedHostSource {
  public id!: number;
  public hostSource: string;

  public createdAt: Date;
  public updatedAt: Date;

  constructor(data: Partial<AuthorizedHostSource>) {
    Object.assign(this, data);
  }
}
