export const institutionPartners: Array<{imgUrl: string, name: string, website: string}> = [
  {
    imgUrl: 'institutions/cnm-logo.png',
    name: 'Centre National de la Musique',
    website: 'https://cnm.fr/',
  },
  {
    imgUrl: 'institutions/region-bretagne-logo.svg',
    name: 'Région Bretagne',
    website: 'https://www.bretagne.bzh/',
  },
  {
    imgUrl: 'institutions/france-2030.png',
    name: 'France 2030',
    website: 'https://www.economie.gouv.fr/france-2030',
  },
  {
    imgUrl: 'institutions/caisse-des-depots-logo.png',
    name: 'Caisse des Dépôts',
    website: 'https://www.caissedesdepots.fr/',
  },
];
