import { inject } from '@angular/core';
import { ResolveFn } from '@angular/router';
import { Translation, TranslocoService } from '@ngneat/transloco';
import { catchError, EMPTY, take } from 'rxjs';

export const SEOResolver: ResolveFn<Translation> = () => {
  const translocoService = inject(TranslocoService);
  return translocoService.selectTranslateObject('seo.main').pipe(
    take(1),
    catchError((err) => {
      // todo change for sentry
      // eslint-disable-next-line no-console
      console.error('seo translations not loaded', err);
      return EMPTY;
    }),
  );
};

