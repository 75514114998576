import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Category, Currencies, Entry } from '../models';
import { StorageService } from '../services';

@Injectable({providedIn: 'root'})
export class WaitListStore {
  private readonly entries = new BehaviorSubject<Entry[]>([]);
  public storageKey = 'waitlist';

  readonly entries$ = this.entries.asObservable();

  constructor(
    private storageService: StorageService,
  ) {}

  getStorageState() {
    return this.storageService.getItem<Entry[]>(this.storageKey);
  }

  getEntries(eventId?: number): Entry[] {
    if (eventId >= 0) {
      return this.getEntries().filter((e) => e.eventId === eventId);
    }
    return this.entries.getValue();
  }

  getEntriesWithStatus(eventId: number, status: boolean, eventCategories?: Category[]) {
    const entries = this.getEntries(eventId).filter((e) => e.activated === status);
    if (!eventCategories) {
      return entries.sort( (a, b) => a.Category?.order - b.Category?.order);
    }
    return entries.sort( (a, b) =>
      eventCategories.find( (cat) => cat.id === a.categoryId)?.order -
      eventCategories.find((cat) => cat.id === b.categoryId)?.order,
    );
  }

  entry(categoryId: number) {
    return this.getEntries().find((e) => e.categoryId === categoryId);
  }

  eventPreference(eventId: number) {
    const { maxPrice, quantity } = this.getEntries(eventId)?.pop() ?? {};
    return { maxPrice, quantity };
  }

  addEntry(entry: Entry) {
    if (this.entry(entry.categoryId)) {
      this.entry(entry.categoryId).activated = true;
      if (this.entry(entry.categoryId).oldValues.activated) {
        delete this.entry(entry.categoryId).oldValues?.activated;
      } else {
        this.entry(entry.categoryId).oldValues.activated = false;
      }
      return;
    }
    this.entries.next([
      ...this.getEntries(),
      entry,
    ]);
    this.storageService.setItem(this.storageKey, this.getEntries());
  }

  removeEntry(categoryId: number) {
    const entry = this.entry(categoryId);
    if (entry && entry.activated) {
      entry.activated = false;
      if (entry.oldValues.hasOwnProperty('activated')) {
        delete entry.oldValues.activated;
      } else {
        entry.oldValues.activated = true;
      }
      if (!entry.id) {
        this.entries.next(this.getEntries().filter((e) => e.categoryId !== entry.categoryId));
      }
      this.storageService.setItem(this.storageKey, this.getEntries());
    }
  }

  setPreferences(
    eventId: number,
    preferences: {
      maxPrice?: number,
      quantity?: number,
      commercialCommunication?: boolean;
      customWaitListFields?: any
    },
  ) {
    this.getEntries().filter((e) => e.eventId === eventId).forEach((e) => {
      if (e.oldValues.quantity !== preferences.quantity) {
        e.oldValues.quantity = e.quantity;
      } else {
        delete e.oldValues.quantity;
      }
      if (e.oldValues.maxPrice !== preferences.maxPrice) {
        e.oldValues.maxPrice = e.maxPrice;
      } else {
        delete e.oldValues.maxPrice;
      }
      if (e.oldValues.commercialCommunication !== preferences.commercialCommunication) {
        e.oldValues.commercialCommunication = e.commercialCommunication;
      } else {
        delete e.oldValues.commercialCommunication;
      }
      if (e.oldValues.customWaitListFields !== preferences.customWaitListFields) {
        e.oldValues.customWaitListFields = e.customWaitListFields;
      } else {
        delete e.oldValues.customWaitListFields;
      }
      e.quantity = preferences.quantity ?? e.quantity;
      e.maxPrice = preferences.maxPrice ?? e.maxPrice;
      e.commercialCommunication = preferences.commercialCommunication ?? e.commercialCommunication;
      e.customWaitListFields = preferences.customWaitListFields ?? e.customWaitListFields;
    });
    this.entries.next(this.getEntries());
    this.storageService.setItem(this.storageKey, this.getEntries());
  }

  loadEntries(entries: Entry[]) {
    this.entries.next(entries);
    this.storageService.setItem(this.storageKey, entries);
  }

}
