import { Injectable, Inject, PLATFORM_ID, Renderer2, RendererFactory2 } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { Meta } from '@angular/platform-browser';
import { BehaviorSubject } from 'rxjs';
import { Utils } from '../shared/utils';
import { AppService } from './app.service';

@Injectable({
  providedIn: 'root',
})
export class ColorService {
  public defaultColor = '#242425';
  public defaultBackgroundColor = '#F4F4F4';
  public color = new BehaviorSubject(this.defaultColor);
  private isBrowser: boolean;

  public reelaxWhite = 'FFFFFF';
  public reelaxBlack = '242425';
  public reelaxRed = 'A30B37';

  private defaultPrimaryFontFamily = 'Open Sans';
  private defaultSecondaryFontFamily = 'Raleway';
  private loadedFonts: {[url: string]: unknown} = {};

  private renderer: Renderer2;

  constructor(
    @Inject(PLATFORM_ID) platformId,
    private meta: Meta,
    private rendererFactory: RendererFactory2,
    private appService: AppService,
  ) {
    this.isBrowser = isPlatformBrowser(platformId);
    this.renderer = rendererFactory.createRenderer(null, null);
  }

  public getEventColor(lightOpacity = false): string {
    if (this.isBrowser) {
      const customColor = document.documentElement.style.getPropertyValue('--custom-event-color');
      if (lightOpacity) {
        // we add two hexadecimal digits at the end to set opacity
        return customColor + '0A';
      }
      return customColor;
    }
    return this.defaultColor;
  }

  public getEventTextColor(): string {
    if (this.isBrowser) {
      return document.documentElement.style.getPropertyValue('--custom-text-color');
    }
    return this.defaultColor;
  }

  public setEventColor(
    event: {
      color: string,
      secondaryColor?: string,
      backgroundColor?: string,
      EventPersonalization?: {
        primaryFontFamily?: string,
        accentFontFamily?: string,
        customFontUrls?: string,
      }
    },
  ) {
    const isWidget = this.appService.settings.value.isWidget;
    if (this.isBrowser) {
      if (!event.color) {
        event.color = this.defaultColor;
      }
      const backgroundColorValue = (isWidget && event.backgroundColor) ?
        event.backgroundColor :
        this.defaultBackgroundColor;
      // contrastRatio function spits out a number between 1 and 21, which serves as the first number in the ratio.
      // For our usage minimial contrast is 5 to 1
      const minimalContrast = 4;
      const hexColor = event.color.slice(1, 7);
      let textColor;
      if (event.secondaryColor) {
        textColor = event.secondaryColor.slice(1, 7);
      } else {
        textColor = Utils.contrastRatio(this.reelaxWhite, hexColor) > minimalContrast ?
          this.reelaxWhite : this.reelaxBlack;
      }
      // must be defined after textColor
      const errorColor = Utils.contrastRatio(this.reelaxRed, hexColor) > minimalContrast ? this.reelaxRed : textColor;
      document.documentElement.style.setProperty('--custom-event-color', event.color);
      document.documentElement.style.setProperty('--custom-text-color', '#' + textColor);
      // background color
      const backgroundColor = '#' + backgroundColorValue.slice(1, 7);
      document.documentElement.style.setProperty('--custom-background-color', backgroundColor);
      if (isWidget) {
        const backgroundHexColor = backgroundColorValue.slice(1, 7);
        const defaultColor = Utils.contrastRatio(this.reelaxBlack, backgroundHexColor) > minimalContrast ?
          this.reelaxBlack : this.reelaxWhite;
        document.documentElement.style.setProperty('--custom-element-on-background-color', '#' + defaultColor);
      }
      document.documentElement.style.setProperty('--custom-error-color', '#' + errorColor);

      this.setCurrentFont(event.EventPersonalization);

      if (event.color === this.defaultColor) {
        this.meta.updateTag({ name: 'theme-color', content: `#${this.reelaxWhite}` });
      } else {
        this.meta.updateTag({ name: 'theme-color', content: event.color });
      }
      this.color.next(event.color);
    }
  }

  public resetEventColor() {
    this.setEventColor({
      color: this.defaultColor,
      EventPersonalization: {
        primaryFontFamily: this.defaultPrimaryFontFamily,
        accentFontFamily: this.defaultSecondaryFontFamily,
      },
    });
  }

  private setCurrentFont(fonts: {primaryFontFamily?: string, accentFontFamily?: string, customFontUrls?: string}) {
    if (!fonts || !this.isBrowser) {
      return;
    }
    if (typeof fonts.customFontUrls === 'string' || (fonts.customFontUrls as unknown) instanceof String) {
      const fontArray = fonts.customFontUrls.split('\n');
      fontArray?.forEach( (u) => {
        if (!this.loadedFonts[u]) {
          this.loadedFonts[u] = this.renderer.createElement('link');
          this.renderer.setAttribute(this.loadedFonts[u], 'href', u);
          this.renderer.setAttribute(this.loadedFonts[u], 'rel', 'stylesheet');
          this.renderer.appendChild(document.body, this.loadedFonts[u]);
        }
      });
    }
    if (fonts?.primaryFontFamily) {
      document.documentElement.style.setProperty('--custom-primary-font-family', fonts.primaryFontFamily);
    } else {
      document.documentElement.style.setProperty('--custom-primary-font-family', this.defaultPrimaryFontFamily);
    }
    const accentFontFamily = fonts?.accentFontFamily ? fonts?.accentFontFamily : fonts?.primaryFontFamily;
    if (accentFontFamily) {
      document.documentElement.style.setProperty('--custom-accent-font-family', accentFontFamily);
    } else {
      document.documentElement.style.setProperty('--custom-accent-font-family', this.defaultSecondaryFontFamily);
    }
  }

}
