
<div class="carousel">
  <cdk-virtual-scroll-viewport
    #carousel_viewport
    [itemSize]="itemSize"
    class="carousel__viewport"
    orientation="horizontal"
    appDragScroll
    [interval]="interval"
    [style.height]="height"
  >
    <div
      *cdkVirtualFor="let item of items; let i = index;"
      class="carousel__item my-auto"
    >
      <ng-container *ngTemplateOutlet="template; context: {item: item, index: i}"></ng-container>
    </div>
  </cdk-virtual-scroll-viewport>
</div>
