<p class="pl-4">
  {{ options.label }}
  @if (isRequired()) {
    <span [matTooltip]="'formErrors.required' | transloco" class="custom-event"> *</span>
  }
</p>

<div class="form full-width d-flex --row --cg-4" *transloco="let tu; read 'timeUnit'">

  @if (init && availableDays && dateOjectFormModel) {
    <app-select-form-field
      class="form__field --day"
      [controlName]="'day'"
      [selections]="availableDays"
      [form]="dateFormGroup"
      [formModel]="dateOjectFormModel"
      [validators]="validators"
      [disabled]="disabled"
      [options]="{
        label: tu('long.day') | titlecase
      }"
    ></app-select-form-field>
    <app-select-form-field
      class="form__field d-flex__g"
      [selections]="availableMonths"
      [form]="dateFormGroup"
      [controlName]="'month'"
      [formModel]="dateOjectFormModel"
      [validators]="validators"
      [disabled]="disabled"
      [options]="{
        label: tu('long.month') | titlecase
      }"
    ></app-select-form-field>
    <app-select-form-field
      class="form__field d-flex__g"
      [controlName]="'year'"
      [selections]="availableYears"
      [form]="dateFormGroup"
      [formModel]="dateOjectFormModel"
      [validators]="validators"
      [disabled]="disabled"
      [options]="{
        label: tu('long.year') | titlecase
      }"
    ></app-select-form-field>
  }

</div>