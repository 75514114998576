<ng-container *transloco="let t;">

  @if (saved) {
    <mat-icon
      class="material-icons"
      [matTooltip]="t('common.saved')"
      >
      cloud_done
    </mat-icon>
  }

  @if (pendingSave) {
    <mat-icon
      class="material-icons"
      [matTooltip]="t('common.pendingSave')"
      >
      pending
    </mat-icon>
  }

  @if (saving) {
    <mat-icon
      class="material-icons"
      [matTooltip]="t('common.saving')"
      >
      cloud_sync
    </mat-icon>
  }

</ng-container>
