<ng-container *transloco="let twc; read 'waitlist.confirmation'">
  <!-- if purchase auto enabled -->
  @if (waitListPurchase?.purchaseAutoEnabled) {
    @if (
      getTotalPurchasedTickets() < waitListStore.getEntriesWithStatus(this.event.id, true)?.[0]?.quantity &&
      getTotalPurchasedTickets() - getManuallyPurchasedTickets() < getAskedAutoPurchaseNumber()
    ) {
      <span [innerHTML]="twc('activatedAutoPurchaseForTickets', { count: getAskedAutoPurchaseNumber() })"></span>
      <ng-container [ngPlural]="getAskedAutoPurchaseNumber()">
        <ng-template ngPluralCase="=1">
          <span [innerHTML]="twc('ticket')"></span>
        </ng-template>
        <ng-template ngPluralCase="other">
          <span [innerHTML]="twc('tickets')"></span>
        </ng-template>
        {{ twc('automatically') }}.
      </ng-container>
      <br>
      @if (numberOfOptions > 0) {
        <br>
        }
    }
    @if (numberOfOptions > 0) {
      {{ numberOfOptions > 1 ? twc('activatedOptions') : twc('activatedOption') }}
      @for (option of options | keyvalue; track option) {
        @if (option.value) {
          <br>
            <span class="custom-event">• </span>
            @if (getTotalPurchasedTickets() > 0) {
              <span [innerHTML]="twc(option.key === 'allOrNone' ? 'allAdditionalOrNone' : option.key, {quantity: getAskedAutoPurchaseNumber() - getTotalPurchasedTickets()})"></span>
            } @else {
              <span [innerHTML]="twc(option.key, {quantity: getAskedAutoPurchaseNumber()})"></span>
            }
          }
        }
        <br><br>
    }
    @if ( getTotalPurchasedTickets() > 0 ) {
      <span [innerHTML]="twc('alreadyReceived', { count: getTotalPurchasedTickets() })"></span>
      <ng-container [ngPlural]="getTotalPurchasedTickets()">
        <ng-template ngPluralCase="=1">
          <span [innerHTML]="twc('ticket')"></span>
        </ng-template>
        <ng-template ngPluralCase="other">
          <span [innerHTML]="twc('tickets')"></span>
        </ng-template>
        <span> (</span>
        @if (getManuallyPurchasedTickets() > 0) {
          <span>{{ twc('manually', { count: getManuallyPurchasedTickets() }) }}</span>
        }
        @if (getManuallyPurchasedTickets() > 0 && getManuallyPurchasedTickets() < getTotalPurchasedTickets()) {
          <span> {{ twc('and') }} </span>
        }
        @if (getManuallyPurchasedTickets() < getTotalPurchasedTickets()) {
          <span>{{ twc('automatically', { count: getTotalPurchasedTickets() - getManuallyPurchasedTickets() }) }}</span>
        }
        <span>).</span>
      </ng-container>
      <br>
      @if (getTotalPurchasedTickets() - getManuallyPurchasedTickets() < getAskedAutoPurchaseNumber()) {
        <br>
          <span [innerHTML]="twc('whenYourTurnYouWillBeCharged')"></span>
          <ng-container [ngPlural]="getAskedAutoPurchaseNumber() - (getTotalPurchasedTickets() - getManuallyPurchasedTickets())">
            <ng-template ngPluralCase="=1">
              <span [innerHTML]="twc('theRemainingTicket')"></span>
            </ng-template>
            <ng-template ngPluralCase="other">
              <span [innerHTML]="twc('theRemainingTickets')"></span>
            </ng-template>
          </ng-container>
          <span [innerHTML]="twc('byEmailIfAvailable')"></span>
      } @else {
        <br>
        <span [innerHTML]="twc('youWillNotBeChargedAndWontReceiveAnyMoreTickets')"></span>
        }
    } @else {
      <span [innerHTML]="twc('whenYourTurnYouWillBeCharged')"></span>
      <ng-container [ngPlural]="getAskedAutoPurchaseNumber()">
        <ng-template ngPluralCase="=1">
          <span [innerHTML]="twc('yourTicket')"></span>
        </ng-template>
        <ng-template ngPluralCase="other">
          <span [innerHTML]="twc('yourTickets')"></span>
        </ng-template>
      </ng-container>
      <span [innerHTML]="twc('byEmailIfAvailable')"></span>
    }
  } @else {
    <!-- if email alert enabled -->
    @if (getTotalPurchasedTickets() < waitListStore.getEntriesWithStatus(this.event.id, true)?.[0]?.quantity) {
      <span [innerHTML]="twc('weWillSendYouEmailIfTicketAvailable')"></span><br>
      <span [innerHTML]="twc('ticketWillOnlyBeAvailableForWaiters', {reservedTime: msToTime(waitListConfig.reservedTime)})"></span>
    } @else {
      <span [innerHTML]="twc('youWillNotBeAlerted')"></span>
    }
  }
</ng-container>

<ng-container *transloco="let twp; read 'waitlist.purchaseAuto'">
  @if (
    getTotalPurchasedTickets() < event.WaitListConfig?.maxTicketQuantity
    && getTotalPurchasedTickets() >= waitListStore.getEntriesWithStatus(this.event.id, true)?.[0]?.quantity
  ) {
    <br>
    <br>
    <span class="reelax-red">{{ twp('mustIncreaseAskedQuantity') }}</span>
  }
  @if (getTotalPurchasedTickets() >= event.WaitListConfig?.maxTicketQuantity) {
    <br>
    <br>
    <span class="reelax-red">{{ twp('alreadyGotMaxQuantity') }}</span>
  }
</ng-container>
