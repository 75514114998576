export enum SeatingInformation {
  Zone = 'zone',
  Gate = 'gate',
  Access = 'access',
  Row = 'row',
  Seat = 'seat',
  Type = 'type',
  DateStart = 'dateStart',
  Other = 'other',
  WeezOrderReference = 'weezOrderReference',
  GrandStand = 'grandStand',
  Section = 'section',
  Floor = 'floor',
}

export const defaultSeatingInformations = Object.values(SeatingInformation)
  .reduce( (acc, s) => ({...acc, [s]: true}), {});

export class SeatDetails {
  [SeatingInformation.DateStart]: string | Date;
  [SeatingInformation.Zone]: string;
  [SeatingInformation.Gate]: string;
  [SeatingInformation.Access]: string;
  [SeatingInformation.Row]: string;
  [SeatingInformation.Seat]: string;
  [SeatingInformation.Type]: string;

  constructor(data?: any) {
    if (data) {
      Object.assign(this, data);
    }
  }
}
