<div class="dialog" *transloco="let tcad; read 'checkSeller.accountDeletion'">

  <mat-icon class="close-icon" color="accent" (click)="onCloseClick()">close</mat-icon>

  <h1 mat-dialog-title class="dialog-title">{{ tcad('title') }}</h1>

  <div mat-dialog-content class="dialog-content">

    <p class="dialog-subtitle">{{ tcad('subtitle') }}</p>

    <mat-card class="tickets-info custom-border-left my-4 fs-16">
      <mat-card-content>
        @if (userBanking?.ownerName) {
          <div class="d-flex align-items">
            <p class="mr-2">{{ 'checkSeller.ownerNamePreview' | transloco }}</p>
            <p class="fw-600">{{userBanking.ownerName}}</p>
          </div>
        }
        @if (userBanking?.type) {
          <div class="d-flex align-items">
            <p class="mr-2">{{ 'checkSeller.typePreview' | transloco }}</p>
            <p class="fw-600">{{userBanking.type}}</p>
          </div>
        }
        @if (userBanking?.accountNumberPreview) {
          <div class="d-flex align-items">
            <p class="mr-2">{{ 'checkSeller.accountNumberPreview' | transloco }}</p>
            <p class="fw-600">{{userBanking.accountNumberPreview}}</p>
          </div>
        }
      </mat-card-content>
    </mat-card>

    <p class="dialog-content__text">
      {{ tcad('neededToSendYourMoney') }}
      <br><br>
      {{ tcad('dataNotStoredOnReelax') }}
    </p>

    <div class="buttons-group --row --centered mt-4">
      <button mat-button (click)="onConfirmClick()" class="button --danger">
        {{ 'button.confirm' | transloco }}
      </button>
    </div>

  </div>

</div>
