import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ColorService, SEOService } from '../../services';
import { Organizer } from '../../models';
import { environment } from '../../../environments/environment';
import { EventsBrowseService } from '../../events-browse/events-browse.service';

@Component({
  selector: 'app-organizer',
  templateUrl: './organizer.component.html',
  styleUrls: ['./organizer.component.scss'],
})
export class OrganizerComponent implements OnInit {
  public organizer: Organizer;
  public organizerText: string;

  constructor(
    private eventsBrowseService: EventsBrowseService,
    private activatedRoute: ActivatedRoute,
    private seoService: SEOService,
    private colorService: ColorService,
  ) {
    this.organizer = this.activatedRoute.snapshot.data.organizer;
    this.eventsBrowseService.organizer = this.activatedRoute.snapshot.data.organizer;

    if (this.organizer.eventsPageText) {
      this.organizerText = this.organizer.eventsPageText.split('\n').join('<br>');
    }

    const title = this.organizer && this.organizer.name ? this.organizer.name + ' - Bourses aux billets' : 'Bourses aux billets';
    this.seoService.updateTitle(title);
    this.seoService.updateUrl(environment.domain + 'o/' + this.organizer.url);
    // eslint-disable-next-line max-len
    this.seoService.updateDescription(this.organizer.name + ' a conclu un partenariat avec Reelax Tickets pour vous permettre d’acheter et de vendre vos billets en toute sécurité !');
    if (this.organizer.logoUrl) {
      this.seoService.updateImage(this.organizer.logoUrl, this.organizer.name);
    }

  }

  ngOnInit(): void {
    this.eventsBrowseService.widget = true;
    this.colorService.setEventColor(
      {
        color: this.eventsBrowseService.organizer?.color,
        backgroundColor: this.eventsBrowseService.organizer?.backgroundColor,
      },
    );
  }


}
