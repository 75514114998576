import { Injectable } from '@angular/core';
import { EventSearchOptions, IEventsMapTiles, Organizer } from '../models';
import { FormGroup, FormControl } from '@angular/forms';
import { FeatureCollection, Point } from 'geojson';

@Injectable({
  providedIn: 'root',
})
export class EventsBrowseService {
  public organizer: Organizer;
  public widget?: boolean;
  public disableMap = false;
  public eventsGeoJSON: {
    eventsGeoJSON: FeatureCollection<Point, {
      dateEnd?: Date,
      dateStart: Date,
      imgUrl: string,
      name: string,
      organizerName: string,
      url: string,
    }>;
    eventsMapTiles: IEventsMapTiles;
  };

  public sortOptions = [
    {
      value: 'popularity',
      viewValue: 'form.popularity',
    }, {
      value: 'date',
      viewValue: 'form.date',
    },
  ];
  public displayOptions = [
    {
      value: 'list',
      viewValue: 'form.list',
      icon: 'list',
    }, {
      value: 'map',
      viewValue: 'form.map',
      icon: 'map',
    },
  ];
  public browseModel: EventSearchOptions = {
    sort: this.sortOptions[0].value as 'popularity' | 'date',
    searchQuery: undefined,
    displayStyle: this.displayOptions[0].value as 'list',
  };
  public browseForm = new FormGroup({
    displayStyle: new FormControl('list'),
    sort: new FormControl(),
    searchQuery: new FormControl(),
  });

  constructor() { }

}
