import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormField } from '../form';
import { TranslocoModule } from '@ngneat/transloco';
import { MatFormField, MatLabel, MatSuffix, MatHint, MatError } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TrimDirective } from '../../../directives/trim.directive';
import { UppercaseSpace4Directive } from '../../../directives/uppercase-space4.directive';
import { MatIcon } from '@angular/material/icon';

@Component({
  selector: 'app-text-form-field',
  templateUrl: './text-form-field.component.html',
  styleUrls: ['./text-form-field.component.scss'],
  standalone: true,
  imports: [
    TranslocoModule,
    MatFormField,
    MatLabel,
    MatInput,
    FormsModule,
    TrimDirective,
    UppercaseSpace4Directive,
    ReactiveFormsModule,
    MatIcon,
    MatSuffix,
    MatHint,
    MatError,
  ],
})
export class TextFormFieldComponent extends FormField<string> implements OnInit, OnDestroy {

  constructor() {
    super();
  }

}
