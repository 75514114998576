<div class="content">

  @if (data.content) {
    <div mat-dialog-content>
      {{ data.content }}
    </div>
  }

  <mat-dialog-actions>
    @if (data.button) {
      <button mat-button [mat-dialog-close]="{button: data.button}" class="snack-button">
        {{ data.button }}
      </button>
    }
  </mat-dialog-actions>
</div>