import { Component, Input } from '@angular/core';
import { NgTemplateOutlet, DatePipe } from '@angular/common';
import { MatAnchor } from '@angular/material/button';
import { RouterLink } from '@angular/router';

interface CardData {
  title: string,
  content,
  imgUrl: string,
  url: string,
  date: Date,
  secondDate?: Date,
}

@Component({
  selector: 'app-card',
  templateUrl: './card.component.html',
  styleUrls: ['./card.component.scss'],
  standalone: true,
  imports: [
    MatAnchor,
    RouterLink,
    NgTemplateOutlet,
    DatePipe,
  ],
})
export class CardComponent {
  @Input() set data(data: CardData) {
    if (data.imgUrl !== this.data?.imgUrl) {
      this.isLoading = true;
    }
    if (data.url) {
      try {
        this.externalLink = new URL(data.url).origin !== location.origin;
      } catch (error) {
        this.externalLink = false;
      }
    }
    // eslint-disable-next-line no-underscore-dangle
    this._data = data;
  }
  get data() {
    // eslint-disable-next-line no-underscore-dangle
    return this._data;
  }
  private _data: CardData;

  public isLoading = true;
  public externalLink: boolean;

  constructor() { }

}
