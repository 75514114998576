import { UntypedFormGroup, ValidationErrors } from '@angular/forms';

export function IBANValidator(control: UntypedFormGroup): ValidationErrors | null {
  const iban = control?.value;
  if (!iban) {
    return null;  // Don't validate empty value
  }
  return isValidIBAN(iban) ? null : { invalidIBAN: true };
}

const isValidIBAN = (iban: string): boolean => {
  iban = iban.replace(/\s+/g, '').toUpperCase();
  const rearrangedIban = iban.slice(4) + iban.slice(0, 4);
  const numericIban = rearrangedIban.split('').map((char) => {
    const code = char.charCodeAt(0);
    return code >= 65 && code <= 90 ? (code - 55).toString() : char;
  }).join('');
  let remainder = numericIban;
  while (remainder.length > 2) {
    const block = remainder.slice(0, 9);
    remainder = (parseInt(block, 10) % 97) + remainder.slice(block.length);
  }
  return parseInt(remainder, 10) % 97 === 1;
};
