import { Input, OnInit, OnDestroy, Directive } from '@angular/core';
import { UntypedFormControl, FormControlOptions, UntypedFormGroup, Validators, AsyncValidator } from '@angular/forms';
import { Subscription } from 'rxjs';
import { distinctUntilChanged, tap } from 'rxjs/operators';
import { Utils } from '../../utils';
import { IBANValidator } from '../../../validators';

interface FormOptions {
  iconName?: string;
  label: string;
  hint?: string;
  required?: boolean;
  min?: number;
  max?: number;
  minLength?: number;
  maxLength?: number;
  pattern?: string | RegExp;
  placeholder?: string;
  trim?: boolean;
  labelParam?: Record<string, string>;
  minDate?: Date;
  maxDate?: Date;
  startAt?: Date;
  displayClearButton?: boolean;
  increment?: number;
  charNbrHint: boolean;
  maxRows?: number;
  selectableMinutes?: number[];
}

const defaultOptions = {
  label: '',
  hint: '',
  icon: '',
  required: false,
  minDate: undefined,
  maxDate: undefined,
};

// Component is a special type of Directive. So it is okay to inherit from an abstract Directive.
@Directive()
// eslint-disable-next-line @angular-eslint/directive-class-suffix
export abstract class FormField<T> implements OnInit, OnDestroy {
  @Input() form: UntypedFormGroup;
  @Input() public controlName: string;
  @Input() validators: string[] = [];
  @Input() asyncValidators?: AsyncValidator[] = [];
  @Input() debug = false;

  public control = new UntypedFormControl('', this.getFormControlOptions());

  /* eslint-disable no-underscore-dangle, id-blacklist, id-match */
  private _options: Partial<FormOptions> = {};
  protected _formModel = {};
  private _disabled: boolean;
  protected valueSub: Subscription;

  // OPTIONS INPUT SETTER / GETTER
  @Input()
  set options(value: Partial<FormOptions>) {
    this._options = {
      ...defaultOptions,
      ...value,
    };
    // run options dependent code, i.e class bindings etc ...
    this.onOptionsChange();
  }
  get options(): Partial<FormOptions> {
    return this._options;
  }

  // DISABLED INPUT SETTER / GETTER
  @Input()
  set disabled(value: boolean) {
    if (this.disabled !== value) {
      this._disabled = value;
      if (value) {
        this.control.disable();
      } else {
        this.control.enable();
      }
    }
  }
  get disabled(): boolean {
    return this._disabled;
  }

  @Input()
  set formModel(m: {}) { // & {controlName: T};
    this.setFormModel(m);
  }
  get formModel() {
    return this._formModel;
  }

  public Objectkeys = Object.keys;

  constructor() {
  }

  protected setFormModelValue(formModel: {}, controlName: string, value) {
    const controlPath = controlName.split('.');
    if (!formModel) {
      throw new Error('form model is undefined for ' + controlName);
    }
    if (controlPath.length === 1) {
      // case object is array
      if (controlPath[0].endsWith(']')) {
        const match = controlPath[0].match(/^(.*?)\[(.*?)\]/);
        const partBeforeFirstBracket: string | null = match ? match[1] : null;
        const partInsideBrackets: string | null = match ? match[2] : null;
        formModel[partBeforeFirstBracket][partInsideBrackets] = value;
      } else {
        formModel[controlPath[0]] = value;
      }
      setTimeout(() => {
        this.control.updateValueAndValidity();
      });
      return;
    } else {
      return this.setFormModelValue(formModel[controlPath[0]], controlPath.slice(1).join('.'), value);
    }
  }

  protected getFormModelValue(formModel: {}, controlName: string) {
    const controlPath = controlName?.split('.');
    // case last part of control path : returns
    if (formModel && controlPath && controlPath[0] && controlPath?.length === 1) {
      // case object is array
      if (controlPath[0].endsWith(']')) {
        const match = controlPath[0].match(/^(.*?)\[(.*?)\]/);
        const partBeforeFirstBracket: string | null = match ? match[1] : null;
        const partInsideBrackets: string | null = match ? match[2] : null;
        return formModel[partBeforeFirstBracket][partInsideBrackets];
      }
      return formModel[controlPath[0]];
    // we still have to split the control path
    } else if (formModel && controlPath && controlPath[0] && formModel[controlPath[0]]) {
      return this.getFormModelValue(formModel[controlPath[0]], controlPath.slice(1).join('.'));
    }
    throw new Error('undefined formModel[controlPath[0]] for ' + controlName + ' ' + JSON.stringify(formModel));
  }

  protected getFormControlOptions(): FormControlOptions {
    return {updateOn: 'change'};
  }

  ngOnInit() {
    this.initForm();
  }

  initForm() {
    this.setValidators();
    this.setAsyncValidators();

    if (this.form) {
      this.valueSub?.unsubscribe();
      const controlUrl = Utils.stringToURLallowed(this.controlName);
      if (this.form.get(controlUrl)) {
        this.removeControlFromForm();
      }
      try {
        this.form.addControl(controlUrl, this.control, {emitEvent: false});
        this.control.setParent(this.form);
      } catch (error) {
        // eslint-disable-next-line no-console
        console.log('error init form ', error);
        throw error;
      }
      this.valueSub = this.form.get(controlUrl).valueChanges.pipe(
        distinctUntilChanged(),
        tap((value) => {
          this.setFormModelValue(this.formModel, this.controlName, value);
          if (this.debug) {
            // eslint-disable-next-line no-console
            console.log(`[${this.controlName}]: value control, value model - formModel`,
              this.control.value,
              this.getFormModelValue(this.formModel, this.controlName),
              this.formModel,
            );
          }
        }),
      ).subscribe();
      if (this.debug) {
        // eslint-disable-next-line no-console
        console.log(
          `[${this.controlName}]:\n form :`,
          this.form,
          '\n control :',
          this.form.get(Utils.stringToURLallowed(this.controlName)),
        );
      }
    }
    if (this.control && this.disabled) {
      this.control.disable();
    }
    setTimeout(() => {
      this.control.updateValueAndValidity();
    });
  }

  protected removeControlFromForm() {
    this.form?.removeControl(Utils.stringToURLallowed(this.controlName));
    this.valueSub?.unsubscribe();
    this.form?.updateValueAndValidity();
    setTimeout(() => {
      this.form?.updateValueAndValidity();
    });
  }

  ngOnDestroy() {
    this.removeControlFromForm();
  }

  protected onOptionsChange() {
  }

  protected setFormModel(m) {
    this._formModel = m;
    this.control.setValue(this.getFormModelValue(m, this.controlName), {emitEvent: false});
    if (this.debug) {
      // eslint-disable-next-line no-console
      console.log(`[${this.controlName}]: value control, value model - formModel`,
        this.control.value,
        this.getFormModelValue(this.formModel, this.controlName),
        this.formModel,
      );
    }
    setTimeout(() => {
      this.control.updateValueAndValidity();
    });
  }

  protected setValidators() {
    const fcValidators = [];

    // LIST VALIDATORS
    // ---------------
    this.validators?.forEach((validator: string) => {

      if (['required', 'email', 'requiredTrue'].includes(validator)) {
        // angular native validators
        fcValidators.push(Validators[validator]);
      }
      if (validator === 'min' && this.options.min) {
        fcValidators.push(Validators.min(this.options.min));
      }
      if (validator === 'max' && this.options.max) {
        fcValidators.push(Validators.max(this.options.max));
      }
      if (validator === 'minLength' && this.options.minLength) {
        fcValidators.push(Validators.minLength(this.options.minLength));
      }
      if (validator === 'maxLength' && this.options.maxLength) {
        fcValidators.push(Validators.maxLength(this.options.maxLength));
      }
      if (validator === 'pattern' && this.options.pattern) {
        fcValidators.push(Validators.pattern(this.options.pattern));
      }
      if (validator === 'IBAN') {
        fcValidators.push(IBANValidator);
      }

      fcValidators.push(() => {});
    });

    // SET VALIDATORS
    // --------------
    if (this.control) {
      this.control.setValidators(fcValidators);
    }
  }

  private setAsyncValidators() {
    if (this.control && this.asyncValidators.length > 0) {
      this.control.setAsyncValidators(this.asyncValidators.map( (v) => v.validate.bind(v)));
    }
  }
}
